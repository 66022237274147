import React from 'react'
import Modal from 'react-bootstrap/Modal';
const PromotionPageModal = ({
    show,
    onHide,backdrop,
    keyboard,centered,
    handleClose,
    handleSubmitPromotionData
}) => {
  return (
    <div>
<Modal
          show={show}
          onHide={onHide}
          backdrop={backdrop}
          keyboard={keyboard}
          centered={centered}
           >
        <Modal.Header>
          <Modal.Title>Confirm Sending Notification</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to send notification ?
        </Modal.Body>
        <Modal.Footer>
          <button type="submit" className="btn btn-danger" onClick={handleClose}>
            Close
          </button>
          <button type="submit" className="btn btn-primary" onClick={handleSubmitPromotionData} variant="primary">Send Notification</button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default PromotionPageModal