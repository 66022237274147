import React from 'react';
import { Modal } from "react-bootstrap";

const DeleteInitialMenuPopup = ({ show, handleDeleteConfirm, handleCancelDelete}) => {
  return (
    <Modal show={show} backdrop="static">
    <Modal.Header>
      <Modal.Title>
        <p>Are you sure you want to delete this item?</p>
      </Modal.Title>
    </Modal.Header>
    <Modal.Footer>
      <button
        type="button"
        className="btn btn-primary"
        onClick={handleDeleteConfirm}
      >
       Yes, Delete
      </button>

      <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={handleCancelDelete}>
     Cancel
      </button>
    </Modal.Footer>
  </Modal>
  )
}

export default DeleteInitialMenuPopup