import React, { useState, useEffect } from "react";
import "./referral.css";
import GetAPI from "../APICalls/GetAPI";

const Order = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const campaignID = urlParams.get("campaignID");

  const [campaignIDValue, setCampaignIDValue] = useState(campaignID);
  const [downloads, setDownloads] = useState([]);
  const [placedOrderCount, setPlacedOrderCount] = useState(0);
  const [totalEarnings, setTotalEarnings] = useState(0);
  
  useEffect(() => {
    let environment = process.env.REACT_APP_NODE_ENV;
    const getDatabyCampaignID = () => {
      let referralAPI;
      if (environment === "PRODUCTION" || environment === undefined) {
        referralAPI = process.env.REACT_APP_PROD_REFERRALAPI;
      } else {
        referralAPI = process.env.REACT_APP_DEV_REFERRALAPI;
      }

      let completeUrl = referralAPI + "?referralUserId=" + JSON.parse(campaignIDValue);

      GetAPI(completeUrl)
        .then((res) => {
          if(res.data.statusCode === 200 || res.data.statusCode === 201) {
            if (Object.keys(res.data.body).length !== 0) {
              const array = JSON.parse(res.data.body).Items;
              setDownloads(array);
              const placedOrderCount = array.filter((data) => data.orderID !== undefined);
              setPlacedOrderCount(placedOrderCount.length);
              setTotalEarnings(placedOrderCount.length * 50);
            }
          }
        })
        .catch((e) => {
          console.error(e);
          alert("Failed to get Referral Details");
        });
    };

    getDatabyCampaignID();
  },[campaignIDValue]);
  

  return (
    <div className="referral container my-5">
      <div className="header">
       <img className="image" src="https://homeal-favourites-store.s3.ap-south-1.amazonaws.com/appdata/Homeal+Transparent+Logo.png" alt="Card cap" />
        <h2>Referral Earnings</h2>
      </div>
      {downloads.length > 0 ? (
        <div>
          <div className="countData">
           <button type="button" className="btn btn-warning mx-2">
            Downloaded <span className="badge badge-light">{downloads.length}</span>
            </button>
            <button type="button" className="btn btn-success mx-2">
            Placed Order <span className="badge badge-light">{placedOrderCount}</span>
            </button>
            <button type="button" className="btn btn-primary mx-2">
            Total Earnings <span className="badge badge-light">{totalEarnings}</span>
            </button>
            </div>
          <div>
          <ul className="list-group list-group-flush my-5">
          <li className="list-group-item">
            <p className="listSl">Sl. no.</p>
            <p className="listStyle">Downloaded App</p>
            <p className="listStyle">Placed Order</p>
            <p className="listStyle">Earned per order</p>
            <p className="listStyle">Order Count</p>
          </li> 
              {downloads.map((item, index) => {
                  return <div>
                          <li className="list-group-item">
                            <p className="listSl"> {index}</p>
                            <p className="listStyle">Yes</p>
                            <p className="listStyle">{item.orderDate ? item.orderDate : "No"}</p>
                            <p className="listStyle">{item.orderID ? "50/-" :  "0/-"}</p>
                            <p className="listStyle">{item.orderCount ? item.orderCount :  "0"}</p>
                          </li> 
                  </div>
                })}
                </ul>
      </div>
        </div>
      ) : (
        <div>
          <h2>No Referrals Found</h2>
        </div>
      )}
    </div>
  );
};
export default Order;
