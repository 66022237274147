import React from "react";
import RenderMenuItems from "./RenderMenuItems";
import "./ChefDisplayInitialMenu.css";
import { Modal } from "react-bootstrap";

const ChefDisplayInitialMenus = ({
  show,
  handleClose,
  items,
  setItems,
  isAdmin,
  setDeleteItemId,
  setShowDeleteModal,
  handleAdminSubmit,
  handleEditSave,
}) => {
  const handleDelete = (id) => {
    setDeleteItemId(id);
    setShowDeleteModal(true);
  };

  const handleApprove = (id, status) => {
    setItems(
      items.map((item) =>
        item.id === id ? { ...item, adminStatus: status } : item
      )
    );
  };

  const handleReject = (id, status) => {
    setItems(
      items.map((item) =>
        item.id === id ? { ...item, adminStatus: status } : item
      )
    );
  };

  const handleComments = (id, comments) => {
    setItems(
      items.map((item) =>
        item.id === id ? { ...item, adminComments: comments } : item
      )
    );
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      dialogClassName="custom-modal-width"
    >
      <Modal.Header className="d-flex g-2">
        <Modal.Title>Added Menus</Modal.Title>
        <p
          className="cross"
          onClick={() => {
            handleClose();
          }}
        >
          &#10060;
        </p>
      </Modal.Header>
      <Modal.Body>
        <>
          <div className="displayMenu">
            {items.map((data, index) => (
              <RenderMenuItems
                key={data.id}
                Slno={index + 1}
                id={data.id}
                data={data}
                adminStatus={data.adminStatus}
                adminComments={data.adminComments}
                isAdmin={isAdmin}
                handleDelete={handleDelete}
                handleApprove={handleApprove}
                handleReject={handleReject}
                handleEditSave={handleEditSave}
                handleComments={handleComments}
              />
            ))}
          </div>
          {isAdmin && (
            <button
              type="button"
              className="btn btn-success btn-sm btn-block my-3"
              onClick={handleAdminSubmit}
            >
              Save all
            </button>
          )}
        </>
      </Modal.Body>
    </Modal>
  );
};

export default ChefDisplayInitialMenus;
