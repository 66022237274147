import React, { useState, useEffect } from "react";
import GetAPI from "../APICalls/GetAPI";
import DeleteAPI from "../APICalls/DeleteAPI";
import AdminTabs from "../Admin/adminTabs";
import AdminModal from "../Admin/adminModal";
import "./BlogsEdit.css";
import { useHistory } from "react-router-dom";
import ConfirmAlert from "./ConfirmAlert";

const BlogsEdit = () => {
  const [notVerified, setNotVerified] = useState(true);
  const [data, setData] = useState([]);
  const [selectedBlogID, setSelectedBlogID] = useState(null);
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const history = useHistory();

  const handleVerified = (value) => {
    setNotVerified(value);
  };

  const getBlogs = () => {
    let environment = process.env.REACT_APP_NODE_ENV;
    let getSingleBlogApi;
    if (environment === "PRODUCTION" || environment === undefined) {
      getSingleBlogApi = process.env.REACT_APP_PROD_BLOGAPI;
    } else {
      getSingleBlogApi = process.env.REACT_APP_DEV_BLOGAPI;
    }

    GetAPI(`${getSingleBlogApi}?type=All`)
      .then((res) => {
        if (res.data.statusCode === 200) {
          setData(res.data.body);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    getBlogs();
  }, []);

  const handleBlogEdit = (blogID) => {
    history.push({
      pathname: `/blogseditor/${blogID}`,
      state: { blogID: blogID },
    });
  };

  const handleBlogDelete = (blogID) => {
    setSelectedBlogID(blogID);
    setShowDeletePopup(true);
  };

  const confirmBlogDelete = () => {
    let environment = process.env.REACT_APP_NODE_ENV;
    let blogAPI;
    if (environment === "PRODUCTION" || environment === undefined) {
      blogAPI = process.env.REACT_APP_PROD_BLOGAPI;
    } else {
      blogAPI = process.env.REACT_APP_DEV_BLOGAPI;
    }

    const url = blogAPI + "?blogID=" + selectedBlogID;

    DeleteAPI(`${url}`)
      .then((res) => {
        setShowDeletePopup(false);
        alert("delete successfully!!!");
        window.location.reload();
      })
      .catch((err) => {
        setShowDeletePopup(false);
        alert("error ", err);
        console.log(err);
      });
  };

  const handleCancel = () => {
    setSelectedBlogID(null);
    setShowDeletePopup(false);
  };

  return (
    <div className="container">
      <AdminTabs />
      {notVerified ? (
        <AdminModal handleVerified={handleVerified} />
      ) : (
        <>
          <ConfirmAlert
            show={showDeletePopup}
            message="Are you sure you want to delete the Blog?"
            onOK={confirmBlogDelete}
            onCancel={handleCancel}
          />
          <h5 className="my-3">List of Blogs</h5>
          <div class="list-group">
            {data.length > 0 &&
              data.map((value, index) => {
                return (
                  <>
                    <a class="list-group-item list-group-item-action flex-column align-items-start">
                      <div class="d-flex w-100 justify-content-between">
                        <h5 class="mb-1">{value.blogName}</h5>
                        {/* <p class="mb-1">{value.categories.join(", ")}</p> */}
                        <small>{value.date}</small>
                      </div>
                      <div className="buttonsContainer">
                        <button
                          type="button"
                          class="btn btn-info btn-sm"
                          onClick={() => handleBlogEdit(value.blogID)}
                        >
                          Edit
                        </button>
                        <button
                          type="button"
                          class="btn btn-danger btn-sm"
                          onClick={() => handleBlogDelete(value.blogID)}
                        >
                          Delete
                        </button>
                      </div>
                    </a>
                  </>
                );
              })}
          </div>
        </>
      )}
    </div>
  );
};

export default BlogsEdit;
