import React, { useState, useEffect } from "react";
import { SubscriptionContext } from "./subscriptionContext";
import GetAPI from "../../../APICalls/GetAPI";

const SubscriptionProvider = ({ children }) => {
  const [subscriptionOrderCount, setSubscriptionOrderCount] = useState({
    Pending: 0,
    New: 0,
    RequestedChef: 0,
    Active: 0,
    AboutToExpire: 0,
    Expired: 0,
    NotAvailable: 0
  });
  const [subscriptionData, setSubscriptionData] = useState({});

  return <SubscriptionContext.Provider value={{ subscriptionOrderCount, setSubscriptionOrderCount, subscriptionData, setSubscriptionData }}>{children}</SubscriptionContext.Provider>;
};

export default SubscriptionProvider;
