import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import "./ChefMenuModal.css";
import { FaCheckCircle } from "react-icons/fa";
import moment from "moment";
import PostAPI from "../../APICalls/PostAPI";
import PutAPI from "../../APICalls/PutAPI";
import DeleteAPI from "../../APICalls/DeleteAPI";
import { ThreeCircles } from "react-loader-spinner";
import Select from "react-select";
import { imageSizeCheck } from "../Helpers";
import { v4 as uuidv4 } from "uuid";
import GetAPI from "../../APICalls/GetAPI";

const ChefMenuModal = ({
  showModal,
  selectedChefData,
  selectedChefMenu,
  handleClose,
}) => {
  const [chefMenu, setChefMenu] = useState([]);
  const [activeButton, setActiveButton] = useState("");
  const [menuID, setMenuID] = useState(null);
  const [loading, setLoading] = useState(false);
  const [images, setImages] = useState({});

  const getBase64 = (file) =>
    new Promise(function (resolve, reject) {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject("Error: ", error);
    });

  const handleImageUpload = async (imageData, menuID) => {
    setLoading(true);

    const file = imageData.target.files[0];
    setMenuID(menuID);

    let processedFile;
    try {
      processedFile = await imageSizeCheck(file);
    } catch (error) {
      alert("Error processing image, please try again");
      setLoading(false);
      return;
    }

    let extension = processedFile.type.split("/")[1];
    let fileType = "." + extension;

    let encoded;
    getBase64(processedFile)
      .then((result) => {
        encoded = result;
        const splitArray = encoded.split("base64,");
        const updatedBase64Value = splitArray[1];

        const dataToPost = {
          userID: selectedChefData.userID,
          fileType: fileType,
          fileID: menuID,
          flag: "MENU",
          fileWithBase64: updatedBase64Value,
        };

        let environment = process.env.REACT_APP_NODE_ENV;
        let fileUploadApi;

        if (environment === "PRODUCTION" || environment === undefined) {
          fileUploadApi = process.env.REACT_APP_PROD_FILEUPLOAD;
        } else {
          fileUploadApi = process.env.REACT_APP_DEV_FILEUPLOAD;
        }

        PostAPI(fileUploadApi, dataToPost)
          .then((res) => {
            if (res.data.statusCode === 200) {
              setImages((prevImages) => ({
                ...prevImages,
                [menuID]: res.data.body,
              }));
              alert("Uploaded image successfully");
            } else {
              alert("Error uploading image, please try again");
            }
            setLoading(false);
          })
          .catch((e) => {
            setLoading(false);
            console.error(e);
            alert("Error uploading image, please try again");
          });
      })
      .catch((e) => {
        console.log(e);
        setLoading(false);
      });
  };

  useEffect(() => {}, [images]);

  let BreakfastMenu = new Set([]);
  let LunchMenu = new Set([]);
  let SnacksMenu = new Set([]);
  let DinnerMenu = new Set([]);
  let ReadyToServeMenu = new Set([]);
  let FestiveDelightsMenu = new Set([]);

  selectedChefMenu.forEach((item, index) => {
    if (
      item.availableDates.Breakfast !== undefined &&
      item.menuType.includes("Breakfast")
    ) {
      BreakfastMenu.add({
        id: index,
        itemName: item.itemName,
        itemDesc: item.itemDescription,
        orderBy: item.orderBy,
        chefCost: item.chefCost,
        itemPrice: item.itemPrice,
        menuID: item.menuID,
        imageUrl: item.imageUrl,
        availableDates: item.availableDates,
        itemForever: item.itemForever,
        menuType: "Breakfast",
      });
    }

    if (
      item.availableDates.Lunch !== undefined &&
      item.menuType.includes("Lunch")
    ) {
      LunchMenu.add({
        id: index,
        itemName: item.itemName,
        itemDesc: item.itemDescription,
        orderBy: item.orderBy,
        chefCost: item.chefCost,
        itemPrice: item.itemPrice,
        menuID: item.menuID,
        imageUrl: item.imageUrl,
        availableDates: item.availableDates,
        itemForever: item.itemForever,
        menuType: "Lunch",
      });
    }

    if (
      item.availableDates.Snacks !== undefined &&
      item.menuType.includes("Snacks")
    ) {
      SnacksMenu.add({
        id: index,
        itemName: item.itemName,
        itemDesc: item.itemDescription,
        orderBy: item.orderBy,
        chefCost: item.chefCost,
        itemPrice: item.itemPrice,
        menuID: item.menuID,
        imageUrl: item.imageUrl,
        availableDates: item.availableDates,
        itemForever: item.itemForever,
        menuType: "Snacks",
      });
    }

    if (
      item.availableDates.Dinner !== undefined &&
      item.menuType.includes("Dinner")
    ) {
      DinnerMenu.add({
        id: index,
        itemName: item.itemName,
        itemDesc: item.itemDescription,
        orderBy: item.orderBy,
        chefCost: item.chefCost,
        itemPrice: item.itemPrice,
        menuID: item.menuID,
        imageUrl: item.imageUrl,
        availableDates: item.availableDates,
        itemForever: item.itemForever,
        menuType: "Dinner",
      });
    }

    if (
      item.availableDates["Ready to Serve"] !== undefined &&
      item.menuType.includes("Ready to Serve")
    ) {
      ReadyToServeMenu.add({
        id: index,
        itemName: item.itemName,
        itemDesc: item.itemDescription,
        orderBy: item.orderBy,
        chefCost: item.chefCost,
        itemPrice: item.itemPrice,
        menuID: item.menuID,
        imageUrl: item.imageUrl,
        availableDates: item.availableDates,
        itemForever: item.itemForever,
        menuType: "Ready to Serve",
      });
    }

    if (
      item.availableDates["Festive Delights"] !== undefined &&
      item.menuType.includes("Festive Delights")
    ) {
      FestiveDelightsMenu.add({
        id: index,
        itemName: item.itemName,
        itemDesc: item.itemDescription,
        orderBy: item.orderBy,
        chefCost: item.chefCost,
        itemPrice: item.itemPrice,
        menuID: item.menuID,
        imageUrl: item.imageUrl,
        availableDates: item.availableDates,
        itemForever: item.itemForever,
        menuType: "Festive Delights",
      });
    }
  });

  useEffect(() => {
    if (Array.from(BreakfastMenu)) {
      setActiveButton("BREAKFAST");
      setChefMenu(Array.from(BreakfastMenu));
    }
  }, []);

  let newItemId = uuidv4();
  const initialValue = {
    appVersion: 226,
    availableDates: {
      Breakfast: [],
      Dinner: [],
      "Festive Delights": [],
      Lunch: [],
      "Ready To Serve": [],
      Snacks: [],
    },
    chefCost: null,
    foodStyle: "Not Specified",
    imageUrl: "N/A",
    isHomealItem: false,
    isUserAvailable: false,
    itemDescription: "",
    itemForever: false,
    itemID: newItemId,
    itemName: "",
    itemPrice: "",
    itemType: "Vegetarian",
    menuTags: [],
    menuType: [],
    orderBy: null,
    userID: selectedChefData.userID,
    isSaas: false,
    isBoth: false,
  };

  const [showAddModal, setShowAddModal] = useState(false);
  const handleCloseAddModal = () => setShowAddModal(false);
  const handleShowAddModal = () => setShowAddModal(true);
  const [addMenu, setAddMenu] = useState(initialValue);
  const [images2, setImages2] = useState({});
  const [m, setM] = useState("");
  const handleImageUpload2 = async (imageData, m) => {
    setM(m);
    setLoading(true);
    const file = imageData.target.files[0];
    let processedFile;
    try {
      processedFile = await imageSizeCheck(file);
    } catch (error) {
      alert("Error processing image, please try again");
      setLoading(false);
      return;
    }

    let extension = file.type.split("/")[1];
    let fileType = "." + extension;

    let encoded;

    getBase64(file)
      .then((result) => {
        encoded = result;
        const splitArray = encoded.split("base64,");
        const updatedBase64Value = splitArray[1];

        const dataToPost = {
          userID: selectedChefData.userID,
          fileType: fileType,
          fileID: m,
          flag: "MENU",
          fileWithBase64: updatedBase64Value,
        };

        let environment = process.env.REACT_APP_NODE_ENV;
        let fileUploadApi;

        if (environment === "PRODUCTION" || environment === undefined) {
          fileUploadApi = process.env.REACT_APP_PROD_FILEUPLOAD;
        } else {
          fileUploadApi = process.env.REACT_APP_DEV_FILEUPLOAD;
        }

        PostAPI(fileUploadApi, dataToPost)
          .then((res) => {
            if (res.data.statusCode === 200) {
              setImages2((prevImages) => ({
                ...prevImages,
                [m]: res.data.body,
              }));
              alert("Uploaded image successfully");
            } else {
              alert("Error uploading image, please try again");
            }
            setLoading(false);
          })
          .catch((e) => {
            setLoading(false);
            console.error(e);
            alert("Error uploading image, please try again");
          });
      })
      .catch((e) => console.log(e));
  };
  useEffect(() => {
    setAddMenu((prevState) => ({
      ...prevState,
      imageUrl: images2[m],
    }));
  }, [images2]);

  const options = [
    { value: "Crispy", label: "Crispy" },
    { value: "Healthy", label: "Healthy" },
    { value: "North Indian", label: "North Indian" },
    { value: "South Indian", label: "South Indian" },
    { value: "UttarKarnataka Style", label: "UttarKarnataka Style" },
    { value: "Tamilnadu Style", label: "Tamilnadu Style" },
  ];

  const [selectedOptions, setSelectedOptions] = useState([]);
  const [isDisabled, setIsDisabled] = useState(false);
  const [selectedMenuTypes, setSelectedMenuTypes] = useState([]);
  const [isAlways, setIsAlways] = useState(false);
  const [selectedFoodCategory, setSelectedFoodCategory] = useState('Vegetarian');

  const handleSelectChangeMenu = (selectedOptions) => {
    if (addMenu.menuTags.length == 1) {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
    const selectedValues = selectedOptions.map((option) => option.value);
    setAddMenu((prevState) => ({
      ...prevState,
      menuTags: selectedValues.slice(0, 2),
    }));
  };

  const handleChange = (e) => {
    e.preventDefault();
    if (e.target.name == "orderBy") {
      const timeArray = e.target.value.split(":");
      let hours = parseInt(timeArray[0]);
      const minutes = timeArray[1];
      let suffix = "AM";

      if (hours >= 12) {
        suffix = "PM";
        if (hours > 12) {
          hours -= 12;
        }
      }

      const formattedTime = `${hours}:${minutes} ${suffix}`;
      setAddMenu({ ...addMenu, [e.target.name]: formattedTime });
    } else {
      setAddMenu({ ...addMenu, [e.target.name]: e.target.value });
    }
  };

  const handleFoodCategoryCheckboxChange = (event) => {
    setSelectedFoodCategory(event.target.value)
  };

  const handleCheckboxChange = (event) => {
    setIsAlways(false);
    const { value, checked } = event.target;
    const updatedSelectedMenuTypes = checked
      ? [...selectedMenuTypes, value]
      : selectedMenuTypes.filter((item) => item !== value);
    setSelectedMenuTypes(updatedSelectedMenuTypes);
    const today = moment().format("YYYY-MM-DD");
    const updatedAvailableDates = { ...addMenu.availableDates }; // Make a copy of availableDates
    if (isAlways) {
      updatedSelectedMenuTypes.forEach((menuType) => {
        const datesArray = [];
        for (let i = 0; i < 7; i++) {
          datesArray.push(moment(today).add(i, "days").format("YYYY-MM-DD"));
        }
        updatedAvailableDates[menuType] = datesArray;
      });
    } else {
      updatedAvailableDates[value] = [];
    }

    setAddMenu({
      ...addMenu,
      itemForever: isAlways,
      availableDates: updatedAvailableDates,
      menuType: updatedSelectedMenuTypes,
    });
  };

  const handleIsAlwaysCheckboxChange = (event) => {
    const { checked } = event.target;
    if (checked) {
      const today = moment().format("YYYY-MM-DD");
      const datesArray = [];
      for (let i = 0; i < 7; i++) {
        datesArray.push(moment(today).add(i, "days").format("YYYY-MM-DD"));
      }
      const updatedAvailableDates = {};
      selectedMenuTypes.forEach((menuType) => {
        updatedAvailableDates[menuType] = datesArray;
      });
      setAddMenu({
        ...addMenu,
        itemForever: checked,
        availableDates: {
          ...addMenu.availableDates,
          ...updatedAvailableDates,
        },
        menuType: selectedMenuTypes,
      });
    } else {
      const updatedAvailableDates = {};
      selectedMenuTypes.forEach((menuType) => {
        updatedAvailableDates[menuType] = [];
      });
      setAddMenu({
        ...addMenu,
        itemForever: false,
        availableDates: {
          ...addMenu.availableDates,
          ...updatedAvailableDates,
        },
      });
    }
  };

  const handleIsSaasAvailable = (event) => {
    const { checked } = event.target;
    if (checked) {
      setAddMenu({
        ...addMenu,
        isSaas: true,
      });
    } else {
      setAddMenu({
        ...addMenu,
        isSaas: false,
      });
    }
  };

  const handleIsBothAvailable = (event) => {
    const { checked } = event.target;
    if (checked) {
      setAddMenu({
        ...addMenu,
        isBoth: checked,
        isSaas: checked,
      });
    } else {
      setAddMenu({
        ...addMenu,
        isBoth: false,
      });
    }
  };

  useEffect(() => {
    setAddMenu({ ...addMenu, menuType: selectedMenuTypes, itemForever: false, itemType: selectedFoodCategory });
    setIsAlways(false);
  }, [selectedMenuTypes, setAddMenu, isAlways, selectedFoodCategory]);

  const handleAddMenuClick = () => {
    let dates = {
      Breakfast: [],
      Dinner: [],
      "Festive Delights": [],
      Lunch: [],
      "Ready To Serve": [],
      Snacks: [],
    };
    if (
      addMenu.appVersion &&
      addMenu.chefCost &&
      addMenu.itemDescription &&
      addMenu.itemID &&
      addMenu.itemName &&
      addMenu.orderBy &&
      addMenu.userID &&
      addMenu.menuType?.length &&
      addMenu.menuTags?.length &&
      addMenu.itemType
    ) {
      let environment = process.env.REACT_APP_NODE_ENV;
      let chefMenuDataAPI;
      if (environment === "PRODUCTION" || environment === undefined) {
        chefMenuDataAPI = process.env.REACT_APP_PROD_CHEFMENU;
      } else {
        chefMenuDataAPI = process.env.REACT_APP_DEV_CHEFMENU;
      }

      const dataToPost = addMenu;

      PostAPI(chefMenuDataAPI, dataToPost)
        .then((res) => {
          if (res.statusCode == 200) {
            setLoading(false);
            alert("Menu added successfully");
            setLoading(false);

            setAddMenu(initialValue);

            setSelectedMenuTypes([]);
            handleCloseAddModal();
          } else {
            setAddMenu(initialValue);
            setLoading(false);
            setSelectedMenuTypes([]);
            handleCloseAddModal();
          }
        })
        .catch((e) => {
          console.error(e);
          setLoading(false);
          alert("Failed to add menu, Try again later!! ");
        });
    } else {
      console.error("Failed to add menu, Try again later!! ");
      alert("Please enter all details of menu");
    }
  };

  // ====================== EDIT ==================================
  const [showEditModal, setShowEditModal] = useState(false);
  const handleCloseEditModal = () => setShowEditModal(false);

  const [editItemData, setEditItemData] = useState(initialValue);
  const [selectedOptions3, setSelectedOptions3] = useState([]);
  const [isDisabled3, setIsDisabled3] = useState(false);
  const [selectedMenuTypes3, setSelectedMenuTypes3] = useState([]);
  const [isAlways3, setIsAlways3] = useState(false);
  const [images3, setImages3] = useState({});
  const [orderByDefault, setOrderByDefault] = useState("");
  const [selectedEditFoodCategory, setSelectedEditFoodCategory] = useState("Vegetarian")

  const handleShowEditModal = (item) => {
    function findItemByMenuID(menuID) {
      return selectedChefMenu.find((item) => item.menuID === menuID);
    }
    if (item) {
      const itemByMenuID = findItemByMenuID(item);
      setShowEditModal(true);
      setSelectedEditFoodCategory(itemByMenuID.itemType);
      setEditItemData(itemByMenuID);
      const [time, period] = itemByMenuID.orderBy.split(" ");
      const [hoursString, minutesString] = time.split(":");
      let hours = parseInt(hoursString);
      const minutes = parseInt(minutesString);

      if (period === "PM" && hours !== 12) {
        hours += 12;
      }

      const formattedHours = hours.toString().padStart(2, "0");
      const formattedMinutes = minutes.toString().padStart(2, "0");
      const result = `${formattedHours}:${formattedMinutes}`;
      setOrderByDefault(result);
    }
  };

  const handleChangeEdit = (e) => {
    e.preventDefault();
    if (e.target.name == "orderBy") {
      setOrderByDefault(e.target.value);
      const timeArray = e.target.value.split(":");
      let hours = parseInt(timeArray[0]);
      const minutes = timeArray[1];
      let suffix = "AM";

      if (hours >= 12) {
        suffix = "PM";
        if (hours > 12) {
          hours -= 12;
        }
      }

      const formattedTime = `${hours}:${minutes} ${suffix}`;
      setEditItemData({ ...editItemData, orderBy: formattedTime });
    } else {
      setEditItemData({ ...editItemData, [e.target.name]: e.target.value });
    }
  };
  useEffect(() => {
    handleShowEditModal();
  }, [showEditModal]);

  const handleEditFoodCategoryCheckboxChange = (event) => {
    setSelectedEditFoodCategory(event.target.value)
  };

  useEffect(() => {
    setEditItemData((prevState) => ({
      ...prevState,
      imageUrl: images[menuID],
    }));
  }, [images]);
  const handleCheckboxChangeEdit = (event) => {
    setIsAlways3(false);
    const { value, checked } = event.target;
    let updatedSelectedMenuTypes;

    if (checked) {
      if (!selectedMenuTypes3.includes(value)) {
        updatedSelectedMenuTypes = [...selectedMenuTypes3, value];
      } else {
        updatedSelectedMenuTypes = selectedMenuTypes3;
      }
    } else {
      updatedSelectedMenuTypes = selectedMenuTypes3.filter(
        (item) => item !== value
      );
    }

    setSelectedMenuTypes3(updatedSelectedMenuTypes);

    const today = moment().format("YYYY-MM-DD");
    const updatedAvailableDates = { ...editItemData.availableDates };
    updatedSelectedMenuTypes.forEach((menuType) => {
      const datesArray = [];
      if (menuType === "Breakfast" || menuType === "Lunch") {
        for (let i = 0; i < 7; i++) {
          datesArray.push(moment(today).add(i, "days").format("YYYY-MM-DD"));
        }
      }
      updatedAvailableDates[menuType] = datesArray;
    });

    Object.keys(updatedAvailableDates).forEach((menuType) => {
      if (!updatedSelectedMenuTypes.includes(menuType)) {
        updatedAvailableDates[menuType] = [];
      }
    });

    setEditItemData({
      ...editItemData,
      itemForever: isAlways3,
      availableDates: updatedAvailableDates,
      menuType: updatedSelectedMenuTypes,
    });
  };

  const handleIsAlwaysCheckboxChangeEdit = (event) => {
    const { checked } = event.target;
    if (checked) {
      const today = moment().format("YYYY-MM-DD");
      const datesArray = [];
      for (let i = 0; i < 7; i++) {
        datesArray.push(moment(today).add(i, "days").format("YYYY-MM-DD"));
      }
      const updatedAvailableDates = {};
      selectedMenuTypes3.forEach((menuType) => {
        updatedAvailableDates[menuType] = datesArray;
      });
      setEditItemData({
        ...editItemData,
        itemForever: checked,
        availableDates: {
          ...editItemData.availableDates,
          ...updatedAvailableDates,
        },
        menuType: selectedMenuTypes3,
      });
    } else {
      const updatedAvailableDates = {};
      selectedMenuTypes3.forEach((menuType) => {
        updatedAvailableDates[menuType] = [];
      });
      setEditItemData({
        ...editItemData,
        itemForever: false,
        availableDates: {
          ...editItemData.availableDates,
          ...updatedAvailableDates,
        },
      });
    }
  };

  const handleIsSaasCheckboxChangeEdit = (event) => {
    const { checked } = event.target;
    if (checked) {
      setEditItemData({
        ...editItemData,
        isSaas: true,
      });
    } else {
      setEditItemData({
        ...editItemData,
        isSaas: false,
      });
    }
  };

  const handleIsBothCheckboxChangeEdit = (event) => {
    const { checked } = event.target;
    if (checked) {
      setEditItemData({
        ...editItemData,
        isBoth: true,
        isSaas: true,
      });
    } else {
      setEditItemData({
        ...editItemData,
        isBoth: false,
      });
    }
  };

  const handleSelectChangeMenuEdit = (selectedOptions3) => {
    setIsDisabled3(selectedOptions3.length >= 1);
    const selectedValues = selectedOptions3.map((option) => option.value);
    setEditItemData((prevState) => ({
      ...prevState,
      menuTags: selectedValues.slice(0, 2),
    }));
  };

  const handleEditMenuClick = () => {
    let dates = {
      Breakfast: [],
      Dinner: [],
      "Festive Delights": [],
      Lunch: [],
      "Ready To Serve": [],
      Snacks: [],
    };
    if (
      editItemData.appVersion &&
      editItemData.chefCost &&
      editItemData.itemDescription &&
      editItemData.itemID &&
      editItemData.itemName &&
      editItemData.orderBy &&
      editItemData.userID &&
      editItemData.menuType?.length &&
      editItemData.menuTags?.length
    ) {
      let environment = process.env.REACT_APP_NODE_ENV;
      let chefMenuDataAPI;
      if (environment === "PRODUCTION" || environment === undefined) {
        chefMenuDataAPI = process.env.REACT_APP_PROD_CHEFMENU;
      } else {
        chefMenuDataAPI = process.env.REACT_APP_DEV_CHEFMENU;
      }

      const dataToPost = editItemData;

      PutAPI(chefMenuDataAPI, dataToPost)
        .then((res) => {
          if (res.data.statusCode === 200) {
            alert("Menu updated successfully");
            setLoading(false);

            handleCloseEditModal();
          } else {
            alert("unable to edit menu");

            setLoading(false);
            handleCloseEditModal();
          }
        })
        .catch((e) => {
          console.error(e);
          setLoading(false);
          alert("Failed to add menu, Try again later!! ", e);
        });
    } else {
      console.error("Failed to edit menu, Try again later!! ");
      alert("Please enter all details of menu");
    }
  };

  useEffect(() => {
    setEditItemData({
      ...editItemData,
      menuType: selectedMenuTypes3,
      itemForever: false,
    });
    setIsAlways3(false);
  }, [selectedMenuTypes3, setEditItemData, isAlways3]);

  useEffect(() => {
    setEditItemData({
      ...editItemData,
      itemType: selectedEditFoodCategory
    });
  }, [selectedEditFoodCategory]);

  const handleDeleteMenu = (menuID) => {
    let environment = process.env.REACT_APP_NODE_ENV;
    let menuAPI;
    if (environment === "PRODUCTION" || environment === undefined) {
      menuAPI = process.env.REACT_APP_PROD_CHEFMENU;
    } else {
      menuAPI = process.env.REACT_APP_DEV_CHEFMENU;
    }

    let deleteURL = menuAPI + "?menuID=" + menuID;

    DeleteAPI(deleteURL)
      .then((res) => {
        let responseData = res.data;
        let responseBody = responseData.body;
        if (responseData.statusCode === 200) {
          alert("Deleted Menu Successfully!");
          setLoading(false);
        }
      })
      .catch((e) => {
        console.log("Error occurred. Error =" + e);
        setLoading(false);
        alert("Error occurred. Error =" + e);
      });
  };

  const handleAISuggestions = (editItemData, type) => {
    let environment = process.env.REACT_APP_NODE_ENV;
    let menuAIAPI;
    if (environment === "PRODUCTION" || environment === undefined) {
      menuAIAPI = process.env.REACT_APP_PROD_AI_MENU;
    } else {
      menuAIAPI = process.env.REACT_APP_DEV_AI_MENU;
    }
    menuAIAPI = menuAIAPI + "=" + editItemData[type]
    console.log(menuAIAPI)
    GetAPI(menuAIAPI)
      .then((res) => {
        if (res.data.statusCode === 200) {
          const result = window.confirm("Do you want to proceed? \n"+ res.data.body);
            if (result) {
              console.log("User confirmed the action.");
              setEditItemData({
                ...editItemData,
                [type]: res.data.body,
              })
            } else {
              console.log("User canceled the action.");
            }

        } else {
          alert("Failed to get correct description");
          console.log(JSON.stringify(res));
        }
      })
      .catch((e) => {
        console.error(e);
        alert("Failed to get AI description!!!");
      });
    console.log(editItemData.itemDescription);
  }


  return (
    <>
      <Modal
        show={showModal}
        size="lg"
        scrollable={true}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header>
          <Modal.Title>
            {" "}
            <div className="sectionSplit">
              <div>
                In {selectedChefData.userKitchen}, {selectedChefMenu.length}{" "}
                menus are added
                <h6>Some may be enabled, some may be disabled</h6>
                <p id="note">**Item names in green are item Forever menu</p>
              </div>
              <p
                className="cross"
                onClick={() => {
                  handleClose();
                }}
              >
                x
              </p>
            </div>
            <div className="d-flex justify-content-between ">
              <div className="buttonCollection">
                <button
                  type="button"
                  className={`btn btn-sm ${
                    activeButton === "BREAKFAST"
                      ? "btn-primary"
                      : "btn-secondary"
                  }`}
                  onClick={() => {
                    setActiveButton("BREAKFAST");
                    setChefMenu(Array.from(BreakfastMenu));
                  }}
                >
                  Breakfast
                </button>
                <button
                  type="button"
                  className={`btn btn-sm ${
                    activeButton === "LUNCH" ? "btn-primary" : "btn-secondary"
                  }`}
                  onClick={() => {
                    setActiveButton("LUNCH");
                    setChefMenu(Array.from(LunchMenu));
                  }}
                >
                  Lunch
                </button>
                <button
                  type="button"
                  className={`btn btn-sm ${
                    activeButton === "SNACKS" ? "btn-primary" : "btn-secondary"
                  }`}
                  onClick={() => {
                    setActiveButton("SNACKS");
                    setChefMenu(Array.from(SnacksMenu));
                  }}
                >
                  Snacks
                </button>
                <button
                  type="button"
                  className={`btn btn-sm ${
                    activeButton === "DINNER" ? "btn-primary" : "btn-secondary"
                  }`}
                  onClick={() => {
                    setActiveButton("DINNER");
                    setChefMenu(Array.from(DinnerMenu));
                  }}
                >
                  Dinner
                </button>
                <button
                  type="button"
                  className={`btn btn-sm ${
                    activeButton === "READY TO SERVE"
                      ? "btn-primary"
                      : "btn-secondary"
                  }`}
                  onClick={() => {
                    setActiveButton("READY TO SERVE");
                    setChefMenu(Array.from(ReadyToServeMenu));
                  }}
                >
                  Ready To Serve
                </button>
                <button
                  type="button"
                  className={`btn btn-sm ${
                    activeButton === "FESTIVE DELIGHTS"
                      ? "btn-primary"
                      : "btn-secondary"
                  }`}
                  onClick={() => {
                    setActiveButton("FESTIVE DELIGHTS");
                    setChefMenu(Array.from(FestiveDelightsMenu));
                  }}
                >
                  Festive Delights
                </button>
              </div>
              <div className="buttonCollection pl-5">
                <button
                  type="button"
                  className={`btn btn-sm btn-success`}
                  onClick={handleShowAddModal}
                >
                  Add menu
                </button>
              </div>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="cardCollection">
            {loading && (
              <div className="spinnerContainer">
                <div className="spinner">
                  <ThreeCircles
                    color="blue"
                    height={110}
                    width={110}
                    ariaLabel="three-circles-rotating"
                  />
                </div>
              </div>
            )}
            {chefMenu.length > 0 ? (
              chefMenu
                .sort((a, b) => a.itemName.localeCompare(b.itemName))
                .map((item, index) => {
                  const today = new Date().toISOString().split("T")[0];
                  const tomorrow = new Date(Date.now() + 24 * 60 * 60 * 1000)
                    .toISOString()
                    .split("T")[0];

                  const isAvailableToday =
                    item.availableDates &&
                    item.availableDates[item.menuType].length > 0 &&
                    item.availableDates[item.menuType].includes(today);
                  const isAvailableTomorrow =
                    item.availableDates &&
                    item.availableDates[item.menuType].length > 0 &&
                    item.availableDates[item.menuType].includes(tomorrow);
                  return (
                    <div className={`card shadow my-2 ${moment().isAfter(moment(item.orderBy, 'hh:mm A')) ? 'bg-dark' : 'bg-white'}`} key={index}>
                      <div className="card-body">
                        <div className="nameSection">
                          <h5 className="card-title">#{index}</h5>
                          <h6 className="card-subtitle mb-2">
                            {item.itemName}
                          </h6>
                        </div>
                        <div className="d-flex flex-column justify-content-end">
                          <p className="card-text mb-0 text-right">
                            {isAvailableToday
                              ? "Available today : ✅"
                              : "Available today : ❌"}
                          </p>
                          <p className="card-text mb-0 text-right">
                            {isAvailableTomorrow
                              ? "Available tomorrow : ✅"
                              : "Available tomorrow : ❌"}
                          </p>
                        </div>
                        <p className="card-text">Desc:- {item.itemDesc}</p>
                        <p className={`card-text ${moment().isAfter(moment(item.orderBy, 'hh:mm A')) ? 'white' : ''}`}>Order By:- {item.orderBy}</p>
                        <div className="costSection">
                          <h5 className="card-text">
                            Chef Cost - {item.chefCost}/-
                          </h5>
                          <h6 className="card-text mb-2">
                            Item Cost - {item.itemPrice}/-
                          </h6>
                        </div>
                        {item.imageUrl !== "N/A" ? (
                          <div>
                            <img
                              src={
                                images[item.menuID] !== undefined
                                  ? images[item.menuID]
                                  : item.imageUrl
                              }
                              className="imagePreview"
                              alt="..."
                            />
                          </div>
                        ) : (
                          null
                        )}
                        <button
                          type="button"
                          className={`btn btn-sm btn-info btn-block my-2`}
                          onClick={() => handleShowEditModal(item.menuID)}
                        >
                          Edit menu
                        </button>

                        <button
                          type="button"
                          className={`btn btn-sm btn-danger btn-block my-2`}
                          onClick={() => handleDeleteMenu(item.menuID)}
                        >
                          Delete menu
                        </button>
                      </div>
                    </div>
                  );
                })
            ) : (
              <h6>No Items Found for {activeButton}</h6>
            )}
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={showAddModal}
        size="lg"
        scrollable={true}
        onHide={handleCloseAddModal}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header>
          <Modal.Title>
            {" "}
            <h6>Add Menu</h6>
          </Modal.Title>
          <p
            className="cross"
            onClick={() => {
              handleCloseAddModal();
            }}
          >
            {" "}
            &#10060;{" "}
          </p>
        </Modal.Header>
        <Modal.Body>
          <div className="cardCollection">
            {loading && (
              <div className="spinnerContainer">
                <div className="spinner">
                  <ThreeCircles
                    color="blue"
                    height={110}
                    width={110}
                    ariaLabel="three-circles-rotating"
                  />
                </div>
              </div>
            )}
            <div className="col-md-12">
              <form>
                <div className="form-row">
                  <div className="col-sm-12 col-md-12 col-lg-12 my-3">
                    <div className="input-group">
                      <div className="input-group-prepend">
                        <span
                          className="input-group-text"
                          id="validationTooltipUsernamePrepend"
                        >
                          Name of the Item
                        </span>
                      </div>
                      <input
                        type="text"
                        name="itemName"
                        onChange={handleChange}
                        className="form-control "
                        id="Name of the Item"
                        placeholder="Name of the Item"
                        aria-describedby="Name of the Item"
                        required
                      />
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-12 col-lg-12 my-3">
                    <div className="input-group">
                      <div className="input-group-prepend">
                        <span
                          className="input-group-text"
                          id="validationTooltipUsernamePrepend"
                        >
                          Description
                        </span>
                      </div>
                      <input
                        type="text"
                        className="form-control "
                        id="Description"
                        placeholder="Description"
                        aria-describedby="Description"
                        required
                        name="itemDescription"
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-4 col-lg-12 my-3">
                    <div className="input-group">
                      <div className="input-group-prepend">
                        <span
                          className="input-group-text"
                          id="validationTooltipUsernamePrepend"
                        >
                          Select category
                        </span>
                      </div>

                      <Select
                        defaultValue={[]}
                        isMulti
                        name="menuTags"
                        options={options.filter(
                          (option) =>
                            !addMenu.menuTags.includes(option.value) &&
                            addMenu.menuTags.length < 2
                        )}
                        onChange={handleSelectChangeMenu}
                        className="basic-multi-select col-md-12 col-lg-8 w-full px-0"
                        classNamePrefix="menuTags"
                        placeholder={
                          addMenu.menuTags.length < 2
                            ? "Select up to two options"
                            : "You can only select two options"
                        }
                      />
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-6 my-3">
                    <div className="input-group">
                      <div className="input-group-prepend">
                        <span
                          className="input-group-text"
                          id="validationTooltipUsernamePrepend"
                        >
                          Price
                        </span>
                      </div>
                      <input
                        type="number"
                        className="form-control "
                        id="price"
                        placeholder="Mention Your Price"
                        aria-describedby="Title"
                        required
                        min={0}
                        name="chefCost"
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-6 my-3">
                    <div className="input-group">
                      <div className="input-group-prepend">
                        <span
                          className="input-group-text"
                          id="validationTooltipUsernamePrepend"
                        >
                          orderBy
                        </span>
                      </div>
                      <input
                        type="time"
                        name="orderBy"
                        onChange={handleChange}
                        className="form-control "
                        placeholder="orderBy"
                        required
                      />
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-12 col-lg-6 my-3">
                  <h6>Select one or more Menu Type/(s)</h6>
                    <div className="form-row">
                      <div className="col-sm-12 col-md-6 col-lg-6">
                        <div class="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            value="Breakfast"
                            id="flexCheckDefault"
                            onChange={handleCheckboxChange}
                          />
                          <label
                            class="form-check-label"
                            for="flexCheckDefault"
                          >
                            Breakfast
                          </label>
                        </div>
                        <div class="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            value="Lunch"
                            id="flexCheckDefault"
                            onChange={handleCheckboxChange}
                          />
                          <label
                            class="form-check-label"
                            for="flexCheckDefault"
                          >
                            Lunch
                          </label>
                        </div>

                        <div class="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            value="Dinner"
                            id="flexCheckDefault"
                            onChange={handleCheckboxChange}
                          />
                          <label
                            class="form-check-label"
                            for="flexCheckDefault"
                          >
                            Dinner
                          </label>
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-6 col-lg-6">
                        <div class="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            value="Snacks"
                            id="flexCheckDefault"
                            onChange={handleCheckboxChange}
                          />
                          <label
                            class="form-check-label"
                            for="flexCheckDefault"
                          >
                            Snacks
                          </label>
                        </div>
                        <div class="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            value="Festive Delights"
                            id="flexCheckDefault"
                            onChange={handleCheckboxChange}
                          />
                          <label
                            class="form-check-label"
                            for="flexCheckDefault"
                          >
                            Festive Delights
                          </label>
                        </div>
                        <div class="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            value="Ready to Serve"
                            id="flexCheckDefault"
                            onChange={handleCheckboxChange}
                          />
                          <label
                            class="form-check-label"
                            for="flexCheckDefault"
                          >
                            Ready to Serve
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  {addMenu.imageUrl !== "N/A" ? (
                    <div className="col-sm-12 col-md-6 col-lg-6 my-3">
                      <img
                        src={images2[m]}
                        className="imagePreview"
                        alt="..."
                      />
                      <div className="buttonEffect">
                        <label htmlFor={`input-file-2`}>Upload again</label>
                        <input
                          type="file"
                          onChange={(e) => {
                            setImages2(e.target.files[0]);
                            handleImageUpload2(e, newItemId);
                          }}
                          id={`input-file-2`}
                          hidden
                        />
                      </div>
                      {images2 && (
                        <p>
                          <FaCheckCircle color="#65B741" /> Image selected
                        </p>
                      )}
                    </div>
                  ) : (
                    <div className="col-sm-12 col-md-6 col-lg-6 my-3">
                      <img
                        src={images2[m]}
                        className="imagePreview"
                        alt="..."
                      />
                      <div className="buttonEffect">
                        <label htmlFor={`input-file-2`}>Upload Image</label>
                        <input
                          type="file"
                          onChange={(e) => {
                            setImages2(e.target.files[0]);
                            handleImageUpload2(e, newItemId);
                          }}
                          id={`input-file-2`}
                          hidden
                        />
                      </div>
                    </div>
                  )}
                  <br />
                  <div>
                  <h6>Selected Food category</h6>
      <div className="form-check">
        <input
          className="form-check-input"
          type="radio"
          name="flexRadioDefault"
          id="flexRadioDefault1"
          value="Vegetarian"
          checked={selectedFoodCategory === 'Vegetarian'}
          onChange={handleFoodCategoryCheckboxChange}
        />
        <label className="form-check-label" htmlFor="flexRadioDefault1">
          Vegetarian
        </label>
      </div>
      <div className="form-check">
        <input
          className="form-check-input"
          type="radio"
          name="flexRadioDefault"
          id="flexRadioDefault2"
          value="NonVegetarian"
          checked={selectedFoodCategory === 'NonVegetarian'}
          onChange={handleFoodCategoryCheckboxChange}
        />
        <label className="form-check-label" htmlFor="flexRadioDefault2">
          Non Vegetarian
        </label>
      </div>

    
    </div>
                  <br />
                  <div className="col-sm-12 col-md-6 col-lg-6 my-3">
                    <div class="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="flexCheckDefault"
                        onChange={handleIsAlwaysCheckboxChange}
                        checked={addMenu.itemForever}
                      />
                      <label class="form-check-label" for="flexCheckDefault">
                        Available all days
                      </label>
                    </div>
                    {selectedChefData.saasUser ? (
                      <>
                        {!addMenu.isBoth ? (
                          <div class="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="flexCheckDefault"
                              onChange={handleIsSaasAvailable}
                              checked={addMenu.isSaas}
                            />
                            <label
                              class="form-check-label"
                              for="flexCheckDefault"
                            >
                              Available only for Saas
                            </label>
                          </div>
                        ) : null}
                        <div class="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="flexCheckDefault"
                            onChange={handleIsBothAvailable}
                            checked={addMenu.isBoth}
                          />
                          <label
                            class="form-check-label"
                            for="flexCheckDefault"
                          >
                            Available for Both Saas and App
                          </label>
                        </div>
                      </>
                    ) : null}
                  </div>
                </div>
              </form>

              <div className="my-1">
                <button
                  type="button"
                  className={`btn btn-sm btn-success btn-block`}
                  onClick={handleAddMenuClick}
                >
                  Add menu
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={showEditModal}
        editItemData={editItemData}
        size="lg"
        scrollable={true}
        onHide={handleCloseEditModal}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header>
          <Modal.Title>
            {" "}
            <h6>Edit Menu</h6>
          </Modal.Title>
          <p
            className="cross"
            onClick={() => {
              handleCloseEditModal();
            }}
          >
            {" "}
            &#10060;{" "}
          </p>
        </Modal.Header>
        <Modal.Body>
          <div className="cardCollection">
            {loading && (
              <div className="spinnerContainer">
                <div className="spinner">
                  <ThreeCircles
                    color="blue"
                    height={110}
                    width={110}
                    ariaLabel="three-circles-rotating"
                  />
                </div>
              </div>
            )}
            <div className="col-md-12">
              <form>
                <div className="form-row">
                  <div className="col-sm-12 col-md-12 col-lg-12 my-3">
                    <div className="input-group">
                      <div className="input-group-prepend">
                        <span
                          className="input-group-text"
                          id="validationTooltipUsernamePrepend"
                        >
                          Edit name of item
                        </span>
                      </div>
                      <input
                        type="text"
                        name="itemName"
                        onChange={handleChangeEdit}
                        value={editItemData.itemName}
                        className="form-control "
                        id="Name of the Item"
                        placeholder="Edit name of Item"
                        aria-describedby="Edit name of Item"
                        required
                      />
                      <button class="btn btn-primary mx-3" type = "button" onClick={() => handleAISuggestions(editItemData, "itemName") }>  Ask AI suggestion</button>                  
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-12 col-lg-12 my-3">
                    <div className="input-group">
                      <div className="input-group-prepend">
                        <span
                          className="input-group-text"
                          id="validationTooltipUsernamePrepend"
                        >
                          Edit Description
                        </span>
                      </div>
                      <input
                        type="text"
                        value={editItemData.itemDescription}
                        className="form-control "
                        id="Description"
                        placeholder="Edit Description"
                        aria-describedby="Description"
                        required
                        name="itemDescription"
                        onChange={handleChangeEdit}
                      />
                    <button class="btn btn-primary mx-3" type = "button" onClick={() => handleAISuggestions(editItemData, "itemDescription") }>  Ask AI suggestion</button>
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-4 col-lg-12 my-3">
                    <div className="input-group">
                      <div className="input-group-prepend">
                        <span
                          className="input-group-text"
                          id="validationTooltipUsernamePrepend"
                        >
                          Select category
                        </span>
                      </div>

                      <Select
                        defaultValue={editItemData.menuTags.map((tag) => ({
                          value: tag,
                          label: tag,
                        }))}
                        isMulti
                        name="menuTags"
                        options={options.filter(
                          (option) =>
                            !editItemData.menuTags.includes(option.value) &&
                            editItemData.menuTags.length < 2
                        )}
                        onChange={handleSelectChangeMenuEdit}
                        className="bbasic-multi-select col-md-12 col-lg-8 w-full px-0"
                        classNamePrefix="menuTags"
                        placeholder={
                          editItemData.menuTags.length < 2
                            ? "Select up to two options"
                            : "You can only select two options"
                        }
                      />
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-6 my-3">
                    <div className="input-group">
                      <div className="input-group-prepend">
                        <span
                          className="input-group-text"
                          id="validationTooltipUsernamePrepend"
                        >
                          Edit price
                        </span>
                      </div>
                      <input
                        type="number"
                        value={editItemData.chefCost}
                        className="form-control "
                        id="price"
                        placeholder="Mention Your Price"
                        aria-describedby="Title"
                        required
                        min={0}
                        name="chefCost"
                        onChange={handleChangeEdit}
                      />
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-6 my-3">
                    <div className="input-group">
                      <div className="input-group-prepend">
                        <span
                          className="input-group-text"
                          id="validationTooltipUsernamePrepend"
                        >
                          orderBy
                        </span>
                      </div>
                      <input
                        value={orderByDefault}
                        type="time"
                        name="orderBy"
                        onChange={handleChangeEdit}
                        className="form-control "
                        placeholder="orderBy"
                        required
                      />
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-12 col-lg-6 my-3">
                    <label className="bold">Menu Type</label>
                    <div className="form-row">
                      <div className="col-sm-12 col-md-6 col-lg-6">
                        <div class="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            value="Breakfast"
                            id="flexCheckDefault"
                            onChange={handleCheckboxChangeEdit}
                            checked={editItemData.menuType.includes(
                              "Breakfast"
                            )}
                          />
                          <label
                            class="form-check-label"
                            for="flexCheckDefault"
                          >
                            Breakfast
                          </label>
                        </div>
                        <div class="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            value="Lunch"
                            id="flexCheckDefault"
                            onChange={handleCheckboxChangeEdit}
                            checked={editItemData.menuType.includes("Lunch")}
                          />
                          <label
                            class="form-check-label"
                            for="flexCheckDefault"
                          >
                            Lunch
                          </label>
                        </div>

                        <div class="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            value="Dinner"
                            id="flexCheckDefault"
                            onChange={handleCheckboxChangeEdit}
                            checked={editItemData.menuType.includes("Dinner")}
                          />
                          <label
                            class="form-check-label"
                            for="flexCheckDefault"
                          >
                            Dinner
                          </label>
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-6 col-lg-6">
                        <div class="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            value="Snacks"
                            id="flexCheckDefault"
                            onChange={handleCheckboxChangeEdit}
                            checked={editItemData.menuType.includes("Snacks")}
                          />
                          <label
                            class="form-check-label"
                            for="flexCheckDefault"
                          >
                            Snacks
                          </label>
                        </div>
                        <div class="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            value="Festive Delights"
                            id="flexCheckDefault"
                            onChange={handleCheckboxChangeEdit}
                            checked={editItemData.menuType.includes(
                              "Festive Delights"
                            )}
                          />
                          <label
                            class="form-check-label"
                            for="flexCheckDefault"
                          >
                            Festive Delights
                          </label>
                        </div>
                        <div class="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            value="Ready to Serve"
                            id="flexCheckDefault"
                            onChange={handleCheckboxChangeEdit}
                            checked={editItemData.menuType.includes(
                              "Ready to Serve"
                            )}
                          />
                          <label
                            class="form-check-label"
                            for="flexCheckDefault"
                          >
                            Ready to Serve
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>

                  {editItemData.imageUrl !== "N/A" ? (
                    <div>
                      <img
                        src={
                          images[editItemData.menuID] !== undefined
                            ? images[editItemData.menuID]
                            : editItemData.imageUrl
                        }
                        className="imagePreview"
                        alt="..."
                      />
                      <div className="buttonEffect">
                        <label htmlFor={`input-file-${editItemData.menuID}`}>
                          Upload Again
                        </label>
                        <input
                          type="file"
                          onChange={(e) => {
                            handleImageUpload(e, editItemData.menuID);
                          }}
                          id={`input-file-${editItemData.menuID}`}
                          hidden
                        />
                      </div>
                      {menuID === editItemData.menuID ? (
                        <p>
                          <FaCheckCircle color="#65B741" /> Uploaded
                          successfully
                        </p>
                      ) : null}
                    </div>
                  ) : (
                    <div>
                      <img
                        src={
                          images[editItemData.menuID] !== undefined
                            ? images[editItemData.menuID]
                            : editItemData.imageUrl !== "N/A"
                            ? editItemData.imageUrl
                            : null
                        }
                        className="imagePreview"
                        alt=" "
                      />
                      <div className="buttonEffect">
                        <label htmlFor={`input-file-${editItemData.menuID}`}>
                          Upload Image
                        </label>
                        <input
                          type="file"
                          onChange={(e) => {
                            handleImageUpload(e, editItemData.menuID);
                          }}
                          id={`input-file-${editItemData.menuID}`}
                          hidden
                        />
                      </div>
                      {menuID === editItemData.menuID ? (
                        <p>
                          <FaCheckCircle color="#65B741" /> Uploaded
                          successfully
                        </p>
                      ) : null}
                    </div>
                  )}
                  <br />
                  <div>
                  <h6>Selected Food category</h6>
      <div className="form-check">
        <input
          className="form-check-input"
          type="radio"
          name="flexRadioDefault"
          id="flexRadioDefault1"
          value="Vegetarian"
          checked={editItemData.itemType === 'Vegetarian'}
          onChange={handleEditFoodCategoryCheckboxChange}
        />
        <label className="form-check-label" htmlFor="flexRadioDefault1">
          Vegetarian
        </label>
      </div>
      <div className="form-check">
        <input
          className="form-check-input"
          type="radio"
          name="flexRadioDefault"
          id="flexRadioDefault2"
          value="NonVegetarian"
          checked={editItemData.itemType === 'NonVegetarian'}
          onChange={handleEditFoodCategoryCheckboxChange}
        />
        <label className="form-check-label" htmlFor="flexRadioDefault2">
          Non Vegetarian
        </label>
      </div>

    
    </div>
                  <div className="col-sm-12 col-md-6 col-lg-6 my-3">
                    <div class="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="flexCheckDefault"
                        onChange={handleIsAlwaysCheckboxChangeEdit}
                        checked={editItemData.itemForever}
                      />
                      <label class="form-check-label" for="flexCheckDefault">
                        Available all days
                      </label>
                    </div>
                    {selectedChefData.saasUser ? (
                      <>
                        {!editItemData.isBoth ? (
                          <div class="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="flexCheckDefault"
                              onChange={handleIsSaasCheckboxChangeEdit}
                              checked={
                                editItemData.isSaas
                                  ? editItemData.isSaas
                                  : false
                              }
                            />
                            <label
                              class="form-check-label"
                              for="flexCheckDefault"
                            >
                              Available only for Saas
                            </label>
                          </div>
                        ) : null}
                        <div class="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="flexCheckDefault"
                            onChange={handleIsBothCheckboxChangeEdit}
                            checked={
                              editItemData.isBoth ? editItemData.isBoth : false
                            }
                          />
                          <label
                            class="form-check-label"
                            for="flexCheckDefault"
                          >
                            Available for Both Saas and App
                          </label>
                        </div>
                      </>
                    ) : null}
                  </div>
                </div>
              </form>

              <div className="my-1">
                <button
                  type="button"
                  className={`btn btn-sm btn-warning btn-block`}
                  onClick={handleEditMenuClick}
                >
                  Edit menu
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ChefMenuModal;