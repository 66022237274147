import React from "react";
import { Divider } from "antd";
import "antd/dist/antd.min.css";
import Navbar from "../Navbar/navbar";
import Footer from "../Footer/footer";
import "./chefStory.css";

const RenderLeftCard = ({ name, src, description }) => {
  return (
    <div className="card leftCard">
      <div className="card-body left-card-body">
        <img src={src} className="imageSize" alt="..." />
        <div>
          <Divider style={{ borderColor: "white" }}>
            <h1 style={{ color: "white" }} className="chefTitle">
              {name}
            </h1>
          </Divider>
          <p className="card-text" style={{ color: "white" }}>
            {description}
          </p>
        </div>
      </div>
    </div>
  );
};
const RenderRightCard = ({ name, src, description }) => {
  return (
    <div className="card">
      <div className="card-body">
        <div>
          <Divider style={{ borderColor: "#2D2D56" }}>
            <h1 style={{ color: "#2D2D56" }} className="chefTitle">
              {name}
            </h1>
          </Divider>
          <p className="card-text">{description}</p>
        </div>
        <img src={src} className="imageSize" alt="..." />
      </div>
    </div>
  );
};

const chefStory = () => {
  return (
    <div className="blogs">
      <Navbar />
      <h1 className="heading">Meet our Chefs</h1>
      <RenderLeftCard
        name="Home Chef Nagarani"
        src="https://d2nahbmqd5vvug.cloudfront.net/chefPhotos/chefNagarani.png"
        description="Home Chef Nagarani is proof that age is just a number. Striving to create an identity for herself, she transformed her talent into an earning career. By bringing different flavors of Tamilnadu to the table with her traditional culinary skills, she makes many Homeal customers reminisce about their grandmother’s aroma. "
      />
      <RenderRightCard
        name="Home Chef Suman"
        src="https://d2nahbmqd5vvug.cloudfront.net/chefPhotos/chefsuman.png"
        description="Suki's Kitchen - Suman, 
An entrepreneur, a homemaker, self-motivated and passionate about cooking. She likes to cook and serve food. She carries 23 years of work experience and has worked in the corporate world. Her main intention in starting this service is to  
help senior citizens in the community whose children are abroad, and it would be difficult for them with day-to-day cooking. She gives Homely, Healthy, and Hygiene
homemade food. She has been in this service for the last three years and catering across Bangalore South. Her strength is catering to South Indian traditional authentic Brahmin food. 
She has now joined hands with us to expand the taste of her cooking to many of our cravers. Her signature breakfast dishes are Akki roti with tomato chutney, Paddu with shenga chutney, Poori, and channa masala. Lunch are Jolada roti, Bisibelebath, Vegetable Pulao, Vegetable Biryani,
Avial, Puliyogre. She is part of the homeal team and inspires many home chefs to build their own identity."
      />
      <RenderLeftCard
        name="Home Chef Sheela"
        src="https://d2nahbmqd5vvug.cloudfront.net/chefPhotos/chefSheela.png"
        description="Home Chef Sheela, a strong mother and an excellent cook from a small village specializes in Nati style homemade food. With her ability to prepare
          bulk orders single handedly, she has been able to waddle through the financial crisis during the pandemic by delivering customers with her
          healthy and tasty homemade food made with hygiene and safety."
      />
      <RenderRightCard
        name="Home Chef Naresh"
        src="https://d2nahbmqd5vvug.cloudfront.net/chefPhotos/chefnaresh.png"
        description="Home Chef Naresh has been exploring his remarkable culinary skills for over 15 years. His palette offers a unique variety of North Indian, Chinese and Continental cuisine. His talent goes to crossing the highest order target of homemade food delivery in Bangalore in a short time at Homeal."
      />
      <RenderLeftCard
        name="Home Chef Venkatesh"
        src="https://d2nahbmqd5vvug.cloudfront.net/chefPhotos/chefvenkatesh.png"
        description="Home Chef Venkatesh has magic in his hands. With 18 plus years of his experience, he prepares all kinds of South 
        Indian food delicacies. Especially UttarKarnataka foods are his signature dishes. Driven by passion along with his incredible cooking skills, he is 
        able to win the heart of many home food cravers."
      />
      <RenderRightCard
        name="Home Chef Sowmya Sudhi"
        src="https://d2nahbmqd5vvug.cloudfront.net/chefPhotos/chefsowmyasudhi.png"
        description="Home Chef Soumya Sudhi brings innovation to the table through her creative baking skills.  Proudly owning her own business ‘Soumya’s Choco happy luscious’ did not stop her from expanding her platform to reach people with her delicious pastries and cookies. "
      />
      <RenderLeftCard
        name="Home Chef Arundhati"
        src="https://d2nahbmqd5vvug.cloudfront.net/chefPhotos/chefArundhati.png"
        description="Home Chef Arundhati is a passionate chef , who prepares delicious Khova jamoon and other sweets. She was a person who was only serving her sweets to her friends and families. but now after joining Homeal many of the cravers are able to try out her delicious sweets. "
      />
      <RenderRightCard
        name="Home Chef Prabha"
        src="https://d2nahbmqd5vvug.cloudfront.net/chefPhotos/chefPrabha.png"
        description="Home Chef Prabha makes yummy delicious healthy varieties of eggless cakes. Baking makes her happy. Mainly all her cakes are prepared with healthy ingredients. after joining Homeal she is able to get a lot of party orders. from kids to elders, all are great fan of her cakes."
      />
      <RenderLeftCard
        name="Home Chef Kavya"
        src="https://d2nahbmqd5vvug.cloudfront.net/chefPhotos/chefkavya.png"
        description="Home Chef Kavya loves cooking. cooking is something that makes her happy. She prepares mouthwatering dishes. her hands are magical and after joining Homeal she is able to connect to many cravers and provide soul nourishing home food."
      />
      <RenderRightCard
        name="Home Chef Sahana"
        src="https://d2nahbmqd5vvug.cloudfront.net/chefPhotos/chefSahana.png"
        description="Home Chef Sahana, being a mother and a corporate working professional did not stop her from starting her own business. Currently, she is serving cravers who are looking for authentic homemade vegetarian food. This is an example that Women are born achievers irrespective of their situation."
      />
      <RenderLeftCard
        name="Home Chef Sowmya"
        src="https://d2nahbmqd5vvug.cloudfront.net/chefPhotos/chefSowmya.png"
        description="Home Chef Sowmya was born in a remote village and currently is living in Bangalore. She always dreamt of starting her own business but circumstances were difficult. This did not stop her to follow her passion and onboarding in homeal.  She is serving many cravers through homeal and she made her own identity. We salute for her passion and hard work."
      />
      <Footer />
    </div>
  );
};

export default chefStory;
