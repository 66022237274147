import React, { useState, useEffect } from "react";
import "./status.css";
import { RenderCard } from "../Card/Rendercard";
import { callSubscriptionStatusAPI } from "../helpers";
import { ThreeCircles } from "react-loader-spinner";

export const RequestedChef = () => {
  const [requestedChefOrder, setRequestedChefOrder] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getNewOrder = async () => {
      try {
        const response = await callSubscriptionStatusAPI("REQUESTED CHEF");
        setRequestedChefOrder(response);
      } catch (error) {
        console.error("Error in getRequestedChefOrder: ", error);
      } finally {
        setLoading(false);
      }
    };

    getNewOrder();
  }, []);

  return (
    <div className="container displayCard">
        {loading && (
        <div className="spinnerContainer">
          <div className="spinner">
            <ThreeCircles color="blue" height={110} width={110} ariaLabel="three-circles-rotating" />
          </div>
        </div>
      )}
      {requestedChefOrder.length > 0 ? (
        requestedChefOrder.map((item, index) => <RenderCard key={index} subscription={item} />)
      ) : (
        <div>
          <h6>No Requested Chef Subscription Orders</h6>
        </div>
      )}
    </div>
  );
};
