import React, { useState, useEffect } from "react";
import "./orderStatus.css";
import Pending from "./Pending";
import New from "./New";
import Accepted from "./Accepted";
import ReadyToPick from "./ReadyToPick";
import Delivered from "./Delivered";
import Rejected from "./Rejected";
import Cancelled from "./Cancelled";
import { ThreeCircles } from "react-loader-spinner";
import { FcMoneyTransfer } from "react-icons/fc";
import moment from "moment";
import AdminTabs from "./adminTabs";
import AdminModal from "./adminModal";
import GetAPI from "../APICalls/GetAPI";
import PutAPI from "../APICalls/PutAPI";
import PostAPI from "../APICalls/PostAPI";

function OrderStatus() {
  const [tabValue, setTabValue] = useState(1);
  const [totalOrders, setTotalOrders] = useState([]);
  const [pendingOrderFound, setPendingOrderFound] = useState(false);
  const [pendingOrders, setPendingOrders] = useState([]);
  const [pendingOrdersCount, setPendingOrdersCount] = useState(0);
  const [newOrderFound, setNewOrderFound] = useState(false);
  const [newOrders, setNewOrders] = useState([]);
  const [newOrdersCount, setNewOrdersCount] = useState(0);
  const [newDayOrderscount, setNewDayOrderscount] = useState(0);
  const [acceptedOrderFound, setAcceptedOrderFound] = useState(false);
  const [acceptedOrders, setAcceptedOrders] = useState([]);
  const [acceptedOrdersCount, setAcceptedOrdersCount] = useState(0);
  const [readyToPickOrderFound, setReadyToPickOrderFound] = useState(false);
  const [readyToPickOrders, setReadyToPickOrders] = useState([]);
  const [readyToPickOrdersCount, setReadyToPickOrdersCount] = useState(0);
  const [deliveredOrderFound, setDeliveredOrderFound] = useState(false);
  const [deliveredOrders, setDeliveredOrders] = useState([]);
  const [deliveredOrdersCount, setDeliveredOrdersCount] = useState(0);
  const [rejectedOrderFound, setRejectedOrderFound] = useState(false);
  const [rejectedOrders, setRejectedOrders] = useState([]);
  const [rejectedOrdersCount, setRejectedOrdersCount] = useState(0);
  const [cancelledOrderFound, setCancelledOrderFound] = useState(false);
  const [cancelledOrders, setCancelledOrders] = useState([]);
  const [cancelledOrdersCount, setCancelledOrdersCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [notVerified, setNotVerified] = useState(true);
  const [date, setDate] = useState(moment().format("YYYY-MM-DD"));
  const [ordersCount, setTotalOrdersCount] = useState(0);
  const [orderType, setOrderType] = useState("Regular");
  const [adloggsWalletBalance, setAdloggsWalletBalance] = useState("");

  const handleVerified = (value) => {
    setNotVerified(value);
  };

  const [key, setKey] = useState("PENDING");

  useEffect(() => {
    const getActiveTab = JSON.parse(localStorage.getItem("activeTab"));
    if (getActiveTab) {
      setKey(getActiveTab);
    }

    let adloggsAPI;
    if (environment === "PRODUCTION" || environment === undefined) {
      adloggsAPI = process.env.REACT_APP_PROD_ADLOGGSAPI;
    } else {
      adloggsAPI = process.env.REACT_APP_DEV_ADLOGGSAPI;
    }
    const completeUrl = adloggsAPI + "/wallet/balance";

    const customHeader = {
      "x-api-key": `${process.env.REACT_APP_API_TOKEN}`,
    };
    PostAPI(completeUrl, null, customHeader)
      .then((res) => {
        setAdloggsWalletBalance(res.data.data?.wallet_balance);
      })
      .catch((e) => {
        console.error(e);
      });
  }, []);

  useEffect(() => {
    localStorage.setItem("activeTab", JSON.stringify(key));
  }, [key]);

  let environment = process.env.REACT_APP_NODE_ENV;
  useEffect(() => {
    const orderStatus = () => {
      let orderAPI;
      if (environment === "PRODUCTION" || environment === undefined) {
        orderAPI = process.env.REACT_APP_PROD_ORDERAPI;
      } else {
        orderAPI = process.env.REACT_APP_DEV_ORDERAPI;
      }

      let todayOrderURL;

      if (orderType === "All") {
        todayOrderURL =
          orderAPI + "?deliveryDate=" + moment(date).format("YYYY-MM-DD");
      } else {
        todayOrderURL =
          orderAPI +
          "?deliveryDate=" +
          moment(date).format("YYYY-MM-DD") +
          "&orderType=" +
          orderType;
      }

      GetAPI(todayOrderURL)
        .then((res) => {
          if (Object.keys(res.data.body).length !== 0) {
            setTotalOrders(res.data.body);
            let data = res.data.body.filter(
              (item) =>
                item.status.toUpperCase() !== "CANCELLED" &&
                item.status.toUpperCase() !== "PENDING" &&
                item.status.toUpperCase() !== "REJECTED"
            );
            setTotalOrdersCount(data.length);
            splitOrders(res.data.body);
          }
        })
        .catch((e) => {
          console.error(e);
          alert("Failed to get Order Details");
        });

      const DAUUrl =
        orderAPI +
        "?deliveryDate=" +
        moment(date).format("YYYY-MM-DD") +
        "&type=DAILYACTIVEUSERS";

      GetAPI(DAUUrl)
        .then((res) => {
          if (res.data.statusCode === 200) {
            setNewDayOrderscount(res.data.body.NewOrderCount);
          }
        })
        .catch((e) => {
          console.error(e);
          alert("Unable to load the data");
        });
    };

    orderStatus();
  }, [date, orderType]);

  const splitOrders = (data) => {
    let pendingSet = new Set([]);
    let newSet = new Set([]);
    let acceptedSet = new Set([]);
    let readyToPickSet = new Set([]);
    let rejectedSet = new Set([]);
    let cancelledSet = new Set([]);
    let deliveredSet = new Set([]);
    data.forEach((item) => {
      switch (item.status.toUpperCase()) {
        case "PENDING":
          setPendingOrderFound(true);
          pendingSet.add(item);

          break;
        case "NEW":
          setNewOrderFound(true);
          newSet.add(item);

          break;
        case "ACCEPTED":
          setAcceptedOrderFound(true);
          acceptedSet.add(item);

          break;
        case "READY TO PICK":
          setReadyToPickOrderFound(true);
          readyToPickSet.add(item);

          break;
        case "CANCELLED":
          setCancelledOrderFound(true);
          cancelledSet.add(item);

          break;
        case "REJECTED":
          setRejectedOrderFound(true);
          rejectedSet.add(item);

          break;
        case "DELIVERED":
          setDeliveredOrderFound(true);
          deliveredSet.add(item);

          break;
        default:
          break;
      }
    });
    setPendingOrders(Array.from(pendingSet));
    setNewOrders(Array.from(newSet));
    setAcceptedOrders(Array.from(acceptedSet));
    setReadyToPickOrders(Array.from(readyToPickSet));
    setCancelledOrders(Array.from(cancelledSet));
    setRejectedOrders(Array.from(rejectedSet));
    setDeliveredOrders(Array.from(deliveredSet));
  };

  useEffect(() => {
    setPendingOrdersCount(pendingOrders.length);
    setNewOrdersCount(newOrders.length);
    setAcceptedOrdersCount(acceptedOrders.length);
    setReadyToPickOrdersCount(readyToPickOrders.length);
    setCancelledOrdersCount(cancelledOrders.length);
    setRejectedOrdersCount(rejectedOrders.length);
    setDeliveredOrdersCount(deliveredOrders.length);
  }, [
    pendingOrders,
    newOrders,
    acceptedOrders,
    readyToPickOrders,
    cancelledOrders,
    rejectedOrders,
    deliveredOrders,
  ]);

  let orders;
  if (environment === "PRODUCTION") {
    orders = process.env.REACT_APP_PROD_ORDERAPI;
  } else {
    orders = process.env.REACT_APP_DEV_ORDERAPI;
  }

  const handleStatusClick = (orderID, status, reason, data) => {
    const dataToPut =
      reason !== undefined
        ? {
            orderID: orderID,
            status: status,
            reasonToReject: reason,
            cost: data.cost,
            paymentStatus: "REFUND",
          }
        : {
            orderID: orderID,
            status: status,
          };

    setLoading(true);

    PutAPI(orders, dataToPut)
      .then((res) => {
        window.location.reload();
      })
      .catch((e) => {
        console.error(e);
        setLoading(false);
        alert("Unable to update order status. Please try again later");
      });
  };

  const handleChef = (orderID, chef) => {
    setLoading(true);
    const dataToPut = {
      orderID: orderID,
      chefIncharge: chef,
    };
    PutAPI(orders, dataToPut)
      .then((res) => {
        handleStatusClick(orderID, "ACCEPTED");
      })
      .catch((e) => {
        console.error(e);
        setLoading(false);
        alert("Unable to update chef Incharge. Please try again later");
      });
  };

  const handleCancelDaas = (orderID, cancellationReason) => {
    if (cancellationReason === "") {
      alert("Enter a valid reason to cancel");
      return true;
    }
    setLoading(true);
    let environment = process.env.REACT_APP_NODE_ENV;
    let cancelDaasOrderAPI;
    if (environment === "PRODUCTION" || environment === undefined) {
      cancelDaasOrderAPI = process.env.REACT_APP_PROD_DAAS;
    } else {
      cancelDaasOrderAPI = process.env.REACT_APP_DEV_DAAS;
    }
    const completeDaasCancelUrl =
      cancelDaasOrderAPI + "/cancel?orderId=" + orderID;
    const dataToPost = {
      cancellationReason: cancellationReason,
    };

    PostAPI(completeDaasCancelUrl, dataToPost)
      .then((res) => {
        handleDeliveryClick(orderID, "N/A");
        alert("Successfully Cancelled Daas!!");
      })
      .catch((e) => {
        setLoading(false);
        console.log("Failed to cancel Daas response = " + e);
        alert("Failed to cancel Daas, try manual cancellation");
      });
  };

  const assignOrderHandler = (orderID) => {
    setLoading(true);
    const getAdminDetails = JSON.parse(localStorage.getItem("AdminDetails"));
    if (getAdminDetails) {
      const dataToPut = {
        orderID: orderID,
        handledBy: getAdminDetails.userName,
        handlerNumber: getAdminDetails.userMobile,
      };

      PutAPI(orders, dataToPut)
        .then((res) => {
          window.location.reload();
        })
        .catch((e) => {
          setLoading(false);
          console.log("Failed to assign order handler = " + e);
          alert("Failed to assign order handler");
        });
    }
  };

  const handleDeliveryClick = (orderID, value) => {
    const dataToPut = {
      orderID: orderID,
      deliveryPartner: value,
    };
    PutAPI(orders, dataToPut)
      .then((res) => {
        assignOrderHandler(orderID);
      })
      .catch((e) => {
        setLoading(false);
        console.log("Failed  in changing delivery Status= " + e);
        alert("Failed  in changing delivery Status");
      });
  };

  let classNameInactive = "nav-link";
  let classNameActive = "nav-link active show";
  let classNameInactiveComponent = "tab-pane fade";
  let classNameActiveComponent = "tab-pane fade show active";

  const handleChefPenality = (data, penalityAmt, penalityReason) => {
    setLoading(true);

    const getAdminDetails = JSON.parse(localStorage.getItem("AdminDetails"));
    if (getAdminDetails) {
      let orderAPI;
      if (environment === "PRODUCTION" || environment === undefined) {
        orderAPI = process.env.REACT_APP_PROD_ORDERAPI;
      } else {
        orderAPI = process.env.REACT_APP_DEV_ORDERAPI;
      }

      data.cost["chefCharge"] =
        parseFloat(data.cost["chefCharge"]) - parseFloat(penalityAmt);

      const dataToPut = {
        orderID: data.orderID,
        cost: data.cost,
        chefpenalityAmt: parseFloat(penalityAmt),
        chefpenalityReason: penalityReason,
        chefpenalityIncharge: {
          name:
            getAdminDetails.userName !== undefined
              ? getAdminDetails.userName
              : "N/A",
          number:
            getAdminDetails.userMobile !== undefined
              ? getAdminDetails.userMobile
              : "N/A",
        },
      };

      PutAPI(orderAPI, dataToPut)
        .then((res) => {
          alert("Added Chef Penality successfully!!!");
          window.location.reload();
        })
        .catch((e) => {
          setLoading(false);
          alert("Failed to add penality.Try again!");
          console.log("Error occurred. Please try again. Error =", e);
        });
    }
  };

  const handleBookDaas = (orderID) => {
    setLoading(true);
    let environment = process.env.REACT_APP_NODE_ENV;
    let daasCreateOrderAPI;
    if (environment === "PRODUCTION" || environment === undefined) {
      daasCreateOrderAPI = process.env.REACT_APP_PROD_DAAS;
    } else {
      daasCreateOrderAPI = process.env.REACT_APP_DEV_DAAS;
    }
    const completeURL = daasCreateOrderAPI + "/createTask?orderId=" + orderID;

    PostAPI(completeURL, null)
      .then((res) => {
        handleDeliveryClick(orderID, "DAAS");
      })
      .catch((e) => {
        setLoading(false);
        alert("Failed to place daas order, Try again later!!");
        console.log("Error occurred. Please try again. Error =", e);
      });
  };

  const handleComment = (orderID, comment) => {
    setLoading(true);

    const getAdminDetails = JSON.parse(localStorage.getItem("AdminDetails"));
    if (getAdminDetails) {
      let orderAPI;
      if (environment === "PRODUCTION" || environment === undefined) {
        orderAPI = process.env.REACT_APP_PROD_ORDERAPI;
      } else {
        orderAPI = process.env.REACT_APP_DEV_ORDERAPI;
      }

      let updatedComment = comment + " - " + getAdminDetails.userName;

      const dataToPut = {
        orderID: orderID,
        comment: updatedComment,
      };

      PutAPI(orderAPI, dataToPut)
        .then((res) => {
          alert("Added admin comment successfully!!!");
          window.location.reload();
        })
        .catch((e) => {
          setLoading(false);
          alert("Failed to add admin comment.Try again!");
          console.log("Error occurred. Please try again. Error =", e);
        });
    }
  };

  const handleOrderType = (orderTypeValue) => {
    setOrderType(orderTypeValue);
  };

  const handleRefund = (dataToPost) => {
    setLoading(true);
      let refundAPI;
      if (environment !== undefined && environment.toUpperCase() === "PRODUCTION") {
        refundAPI = process.env.REACT_APP_PROD_REFUND;
      } else {
        refundAPI = process.env.REACT_APP_DEV_REFUND;
      }

      PostAPI(refundAPI, dataToPost)
        .then((res) => {
          setLoading(false);
          if(res.data.statusCode == 200 || res.data.statusCode == 201){
            alert("Refund Sucessfull!!!");
            window.location.reload();
          } else {
            alert("Error in Refund, please try again!!!");
            console.log("Error =", res);
          }
        })
        .catch((e) => {
          setLoading(false);
          alert("Error in Refund, please try again!!!");
          console.log("Error =", e);
        });
    }

  return (
    <div className="adminTab">
      <AdminTabs />
      {loading && (
        <div className="spinnerContainer">
          <div className="spinner">
            <ThreeCircles
              color="blue"
              height={110}
              width={110}
              ariaLabel="three-circles-rotating"
            />
          </div>
        </div>
      )}
      {notVerified ? (
        <AdminModal handleVerified={handleVerified} />
      ) : (
        <div>
          <div className="topSection">
            <div className="btn-group">
              <div>
                <h3>Total Orders Count-{ordersCount}</h3>
              </div>
            </div>
            <div class="btn-group">
              <button
                type="button"
                class="btn btn-success btn-sm dropdown-toggle"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                {orderType} Orders
              </button>
              <div class="dropdown-menu">
                <p
                  class="dropdown-item"
                  onClick={() => handleOrderType("Regular")}
                >
                  Regular Orders
                </p>
                <p
                  class="dropdown-item"
                  onClick={() => handleOrderType("CORPORATE")}
                >
                  Corporate Orders
                </p>
                <p
                  class="dropdown-item"
                  onClick={() => handleOrderType("SAAS")}
                >
                  Saas Orders
                </p>
                <p class="dropdown-item" onClick={() => handleOrderType("All")}>
                  All Orders
                </p>
              </div>
            </div>
            <div className="dateSection">
              <div className="dateContainer">
                {" "}
                <h6 className="mx-2">{moment(date).format("dddd")}</h6>
                <input
                  type="date"
                  id="start"
                  name="trip-start"
                  value={date}
                  onChange={(e) => setDate(e.target.value)}
                ></input>
              </div>
            </div>
          </div>
          <h6 className="container">
            <FcMoneyTransfer /> Adloggs-₹{adloggsWalletBalance}
            &nbsp;&nbsp;&nbsp;&nbsp;
            <span> New Orders for the Day-{newDayOrderscount} </span>
          </h6>

          <ul className="nav nav-tabs listgroup" id="myTab" role="tablist">
            <li className="nav-item">
              <a
                className={
                  key === "PENDING" ? classNameActive : classNameInactive
                }
                id="pending-tab"
                data-toggle="tab"
                href="#pending"
                role="tab"
                onClick={() => {
                  setTabValue(1);
                  setKey("PENDING");
                }}
                aria-controls="pending"
                aria-selected="true"
              >
                Pending {pendingOrdersCount}
              </a>
            </li>
            <li className="nav-item">
              <a
                className={key === "NEW" ? classNameActive : classNameInactive}
                id="new-tab"
                data-toggle="tab"
                href="#new"
                role="tab"
                onClick={() => {
                  setTabValue(2);
                  setKey("NEW");
                }}
                aria-controls="new"
                aria-selected="false"
              >
                New {newOrdersCount}
              </a>
            </li>
            <li className="nav-item">
              <a
                className={
                  key === "ACCEPTED" ? classNameActive : classNameInactive
                }
                id="accepted-tab"
                data-toggle="tab"
                href="#accepted"
                role="tab"
                onClick={() => {
                  setTabValue(3);
                  setKey("ACCEPTED");
                }}
                aria-controls="accepted"
                aria-selected="false"
              >
                Accepted {acceptedOrdersCount}
              </a>
            </li>
            <li className="nav-item">
              <a
                className={
                  key === "REJECTED" ? classNameActive : classNameInactive
                }
                id="rejected-tab"
                data-toggle="tab"
                href="#rejected"
                role="tab"
                onClick={() => {
                  setTabValue(3);
                  setKey("REJECTED");
                }}
                aria-controls="rejected"
                aria-selected="false"
              >
                Rejected {rejectedOrdersCount}
              </a>
            </li>
            <li className="nav-item">
              <a
                className={
                  key === "CANCELLED" ? classNameActive : classNameInactive
                }
                id="cancelled-tab"
                data-toggle="tab"
                href="#cancelled"
                role="tab"
                onClick={() => {
                  setTabValue(3);
                  setKey("CANCELLED");
                }}
                aria-controls="cancelled"
                aria-selected="false"
              >
                Cancelled {cancelledOrdersCount}
              </a>
            </li>

            <li className="nav-item">
              <a
                className={
                  key === "READY TO PICK" ? classNameActive : classNameInactive
                }
                id="readytopick-tab"
                data-toggle="tab"
                href="#readytopick"
                role="tab"
                onClick={() => {
                  setTabValue(4);
                  setKey("READY TO PICK");
                }}
                aria-controls="readytopick"
                aria-selected="false"
              >
                Ready To Pick {readyToPickOrdersCount}
              </a>
            </li>
            <li className="nav-item">
              <a
                className={
                  key === "DELIVERED" ? classNameActive : classNameInactive
                }
                id="delivered-tab"
                data-toggle="tab"
                href="#delivered"
                role="tab"
                onClick={() => {
                  setTabValue(5);
                  setKey("DELIVERED");
                }}
                aria-controls="delivered"
                aria-selected="false"
              >
                Delivered {deliveredOrdersCount}
              </a>
            </li>
          </ul>
          <div className="tab-content" id="myTabContent">
            <div
              className={
                key === "PENDING"
                  ? classNameActiveComponent
                  : classNameInactiveComponent
              }
              id="pending"
              role="tabpanel"
              aria-labelledby="pending-tab"
            >
              <Pending
                tab={tabValue}
                ordersFound={pendingOrderFound}
                data={pendingOrders}
                handleChef={handleChef}
                handleStatusClick={handleStatusClick}
                handleDeliveryClick={handleDeliveryClick}
                handleComment={handleComment}
                handleRefund={handleRefund}
              />
            </div>
            <div
              className={
                key === "NEW"
                  ? classNameActiveComponent
                  : classNameInactiveComponent
              }
              id="new"
              role="tabpanel"
              aria-labelledby="new-tab"
            >
              <New
                tab={tabValue}
                ordersFound={newOrderFound}
                data={newOrders}
                handleChef={handleChef}
                handleStatusClick={handleStatusClick}
                handleDeliveryClick={handleDeliveryClick}
                handleComment={handleComment}
                handleRefund={handleRefund}
              />
            </div>
            <div
              className={
                key === "ACCEPTED"
                  ? classNameActiveComponent
                  : classNameInactiveComponent
              }
              id="accepted"
              role="tabpanel"
              aria-labelledby="accepted-tab"
            >
              <Accepted
                tab={tabValue}
                ordersFound={acceptedOrderFound}
                data={acceptedOrders}
                handleStatusClick={handleStatusClick}
                handleDeliveryClick={handleDeliveryClick}
                handleBookDaas={handleBookDaas}
                handleCancelDaas={handleCancelDaas}
                handleComment={handleComment}
                handleRefund={handleRefund}
              />
            </div>
            <div
              className={
                key === "REJECTED"
                  ? classNameActiveComponent
                  : classNameInactiveComponent
              }
              id="rejected"
              role="tabpanel"
              aria-labelledby="rejected-tab"
            >
              <Rejected
                tab={tabValue}
                ordersFound={rejectedOrderFound}
                data={rejectedOrders}
                handleChef={handleChef}
                handleStatusClick={handleStatusClick}
                handleDeliveryClick={handleDeliveryClick}
                handleComment={handleComment}
                handleRefund={handleRefund}
              />
            </div>
            <div
              className={
                key === "CANCELLED"
                  ? classNameActiveComponent
                  : classNameInactiveComponent
              }
              id="cancelled"
              role="tabpanel"
              aria-labelledby="cancelled-tab"
            >
              <Cancelled
                tab={tabValue}
                ordersFound={cancelledOrderFound}
                data={cancelledOrders}
                handleChef={handleChef}
                handleStatusClick={handleStatusClick}
                handleDeliveryClick={handleDeliveryClick}
                handleComment={handleComment}
                handleRefund={handleRefund}
              />
            </div>
            <div
              className={
                key === "READY TO PICK"
                  ? classNameActiveComponent
                  : classNameInactiveComponent
              }
              id="readytopick"
              role="tabpanel"
              aria-labelledby="readytopick-tab"
            >
              <ReadyToPick
                tab={tabValue}
                ordersFound={readyToPickOrderFound}
                data={readyToPickOrders}
                handleChef={handleChef}
                handleStatusClick={handleStatusClick}
                handleDeliveryClick={handleDeliveryClick}
                handleChefPenality={handleChefPenality}
                handleBookDaas={handleBookDaas}
                handleCancelDaas={handleCancelDaas}
                handleComment={handleComment}
                handleRefund={handleRefund}
              />
            </div>
            <div
              className={
                key === "DELIVERED"
                  ? classNameActiveComponent
                  : classNameInactiveComponent
              }
              id="delivered"
              role="tabpanel"
              aria-labelledby="delivered-tab"
            >
              <Delivered
                tab={tabValue}
                ordersFound={deliveredOrderFound}
                data={deliveredOrders}
                handleChef={handleChef}
                handleStatusClick={handleStatusClick}
                handleDeliveryClick={handleDeliveryClick}
                handleChefPenality={handleChefPenality}
                handleComment={handleComment}
                handleRefund={handleRefund}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
export default OrderStatus;
