import React, { useState, useEffect } from "react";
import "./status.css";
import { RenderCard } from "../Card/Rendercard";
import { callSubscriptionStatusAPI } from "../helpers";
import { ThreeCircles } from "react-loader-spinner";

export const AboutToExpire = () => {
  const [aboutToExpireOrder, setAboutToExpireOrder] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getAboutToExpireOrder = async () => {
      try {
        const response = await callSubscriptionStatusAPI("ABTTOEXP");
        setAboutToExpireOrder(response);
      } catch (error) {
        console.error("Error in getAbttoexpOrder: ", error);
      } finally {
        setLoading(false);
      }
    };

    getAboutToExpireOrder();
  }, []);

  return (
    <div className="container displayCard">
        {loading && (
        <div className="spinnerContainer">
          <div className="spinner">
            <ThreeCircles color="blue" height={110} width={110} ariaLabel="three-circles-rotating" />
          </div>
        </div>
      )}
      {aboutToExpireOrder.length > 0 ? (
        aboutToExpireOrder.map((item, index) =><RenderCard key={index} subscription={item} />)
      ) : (
        <div>
          <h6>No About to expire Subscription Orders</h6>
        </div>
      )}
    </div>
  );
};
