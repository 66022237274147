import AdminTabs from "../adminTabs";
import AdminModal from "../adminModal";
import React, { useEffect, useState } from "react";
import GetAPI from "../../APICalls/GetAPI";
import moment from "moment";
import "./metrics.css"

const Metrics = () => {
  //variable initialization
  const [DailyActiveUsersCount, setDailyActiveUsersCount] = useState(0);
  const [NewOrderCount, setNewOrderCount] = useState(0);

  const [notVerified, setNotVerified] = useState(true);
  const [date, setDate] = useState(moment().format("YYYY-MM-DD"));
  let fetchMetrics;
  let environment = process.env.REACT_APP_NODE_ENV;
  if (environment === "PRODUCTION" || environment === undefined) {
    fetchMetrics = process.env.REACT_APP_PROD_ORDERAPI;
  } else {
    fetchMetrics = process.env.REACT_APP_DEV_ORDERAPI;
  }

  useEffect(() => {
    const DAUUrl =
      fetchMetrics +
      "?deliveryDate=" +
      moment(date).format("YYYY-MM-DD") +
      "&type=DAILYACTIVEUSERS";

    GetAPI(DAUUrl)
      .then((res) => {
        if (res.data.statusCode === 200) {
          setDailyActiveUsersCount(res.data.body.DailyActiveUsers);
          setNewOrderCount(res.data.body.NewOrderCount);
        }
      })
      .catch((e) => {
        console.error(e);
        alert("Unable to load the data");
      });
  }, [date]);

  const handleVerified = (value) => {
    setNotVerified(value);
  };

  const downloadBase64Excel = (excelBuffer) => {
    let base64String = excelBuffer;
    // Assuming it starts with "UEsDBBQ..."

    const downloadLink = document.createElement("a");

    // Create a Blob from the base64 data
    const byteCharacters = atob(base64String);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    // Set the href attribute to the Blob
    downloadLink.href = URL.createObjectURL(blob);

    // Set the download filename
    let excelFileName = `${date}_NewOrders.xlsx`;
    downloadLink.download = excelFileName;

    // Simulate a click on the anchor element to trigger the download
    downloadLink.click();
  }

  const handleNewUsers = () => {
    const NewUsersUrl =
      fetchMetrics +
      "?deliveryDate=" +
      moment(date).format("YYYY-MM-DD") +
      "&type=NEWORDERS";
    GetAPI(NewUsersUrl)
      .then((res) => {
        if (res.data.statusCode === 200) {
          if (res.data.excelBuffer) {
            downloadBase64Excel(res.data.excelBuffer);
          } else {
            alert("No orders to show");
          }
        }
      })
      .catch((e) => {
        alert("Unable to load the data");
      });
  };

  return (
    <div className="metrics">
      <AdminTabs />
      {notVerified ? (
        <AdminModal handleVerified={handleVerified} />
      ) : (
        <div className="container">
          <h1>Daily Metrics</h1>
          <div className="dateContainer">
            {" "}
            <h6>{moment(date).format("dddd")}</h6>
            <input
              type="date"
              id="start"
              name="trip-start"
              value={date}
              onChange={(e) => setDate(e.target.value)}
            ></input>
          </div>
          <div className="HomealNewandActiveUsers" > 

            <span className="DailyUserCount"> Daily Active Users Count - {DailyActiveUsersCount}</span>
            <span className="NewOrderscount">New Orders for the Day - {NewOrderCount} </span>
          </div>
           <button
            type="button"
            className="btn"
            onClick={handleNewUsers}
          >
            Download New Users List
          </button>
       
        </div>
      )}
    </div>
  );
};

export default Metrics;
