import React, { useState } from "react";
import "../adminCard.css";
import { Modal } from "react-bootstrap";

const ConfirmPopupModal = ({ show, heading, handleConfirmPopup, handleDismiss, buttonText, hideBody }) => {
  const [reason, setReason] = useState("");
  return (
    <Modal show={show} backdrop="static">
      <Modal.Header>
        <Modal.Title>{heading}</Modal.Title>
      </Modal.Header>
      {!hideBody &&<Modal.Body>
        <div className="input-group input-group-sm mb-3">
          <div className="input-group-prepend">
            <span className="input-group-text" id="inputGroup-sizing-sm">
              Reason/Comments
            </span>
          </div>
          <input
            type="text"
            value={reason}
            onChange={(e) => {
              setReason(e.target.value);
            }}
            className="form-control"
            aria-label="Small"
            aria-describedby="inputGroup-sizing-sm"
          />
        </div>
      </Modal.Body>}
      <Modal.Footer>
        <button
          type="button"
          className="btn btn-primary"
          onClick={() => {
            handleConfirmPopup(reason);
          }}
        >
          {buttonText}
        </button>

        <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={() => handleDismiss()}>
          Close
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmPopupModal;
