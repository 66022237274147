import React from 'react'
import "./BlogCategoriesContainer.css";
import BlogCategoryCard from './BlogCategoryCard';

const BlogCategoriesContainer = () => {
  const  CATEGORIES=[
    {
    id:1,
    "title":"Recipe",
    "bgImage":"https://d2nahbmqd5vvug.cloudfront.net/appdata/Blog+card+-+recipes.png",

  },
  {
    id:2,
    "title":"Nutrition",
    "bgImage":"https://d2nahbmqd5vvug.cloudfront.net/appdata/blog+card+-+nutrition.png",
  
  },
  {
    id:3,
    "title":"Homeal",
    "bgImage":"https://d2nahbmqd5vvug.cloudfront.net/appdata/blog+card+-+homeal.png",
    
  },
  {
    id:4,
    "title":"Stories",
    "bgImage":"https://d2nahbmqd5vvug.cloudfront.net/appdata/blog+card+-+stories.png",
  },
  {
    id:5,
    "title":"Health",
    "bgImage":"https://d2nahbmqd5vvug.cloudfront.net/appdata/blog+card+-+health.png",
 
  },
]
    return (
      <div>
        <div className="trending-container-heading">
        <span>Categories</span>
        <div className="trending-container-heading-line"></div>
        </div>
        <div className="category-container">
          {CATEGORIES.map((data,index)=>{
              return(
                <BlogCategoryCard data={data} index={index} />
              )
          })}
      </div>
      </div>
  )
}

export default BlogCategoriesContainer