import React from "react";
import { useState } from "react";
import "./pushNotification.css";

const PushNotificationForNewUser = ({ setShow, setData }) => {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");

  const handleSubmit = () => {
    if (title === "") {
      alert("Please select the title to submit");
      return;
    }
    if (description === "") {
      alert("Please select the description to submit");
      return;
    }

    setData({
      title: title,
      message: description,
      type: "NEWUSERS",
    });
    setShow(true);
  };

  return (
    <div className="card">
      <div className="card-body">
        <h3 className="my-5 ">Send Notification for New Users</h3>
        <div>
          <form>
            <div className="form-row">
              <div className="col-sm-12 col-md-6 my-2 col-lg-12">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text" id="title">
                      Title
                    </span>
                  </div>
                  <input
                    type="text"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    className="form-control "
                    id="title"
                    placeholder="title"
                    aria-describedby="title"
                    required
                  />
                </div>
              </div>
            </div>
            <div className="form-row my-2">
              <div className="col-sm-12">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span
                      className="input-group-text"
                      id="validationTooltipUsernamePrepend"
                    >
                      Desc.
                    </span>
                  </div>
                  <input
                    type="text"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    className="form-control "
                    id="Desc"
                    placeholder="Description"
                    aria-describedby="Desc"
                    required
                  />
                </div>
              </div>
            </div>
          </form>
          <div className="col-sm-12 my-5">
            <button
              className="btn btn-primary btn-block"
              type="button"
              onClick={handleSubmit}
            >
              Submit
            </button>
          </div>
          <hr />
        </div>
      </div>
    </div>
  );
};

export default PushNotificationForNewUser;
