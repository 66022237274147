import React from "react";
import "./products.css";
import Navbar from "../Navbar/navbar";
import Footer from "../Footer/footer";

const Products = () => {
  const handleBook = () => {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;
    if (userAgent.includes("iPhone")) {
      return window.location.replace("https://apps.apple.com/us/app/homeal/id1571860810");
    } else if (userAgent.includes("Android")) {
      return window.location.replace("https://play.google.com/store/apps/details?id=com.localzi.homeal");
    } else {
      return window.location.replace("https://play.google.com/store/apps/details?id=com.localzi.homeal");
    }
  };
  const RenderCard = ({ name, desc, imgsrc, price }) => {
    return (
      <div className="card col-xs-12 col-sm-6 col-md-4 col-lg-3 ">
        <img src={imgsrc} className="card-img-top" alt="..." />
        <div className="card-body">
          <h4>{name}</h4>
          <p className="card-text">{desc}</p>
          <p className="card-text">{price}</p>
        </div>
        <button type="button" className="btn btn-info btn-sm" onClick={() => handleBook()}>
          Book Now
        </button>
      </div>
    );
  };
  return (
    <div className="products">
      <Navbar />
      <h3>Choose your favorite meal</h3>
      <div className="productsCollection">
        <RenderCard
          name="Roti Meals"
          imgsrc="https://d2nahbmqd5vvug.cloudfront.net/menuItems/rotimeals.png"
          desc="4 Roti served with Sabji and Dal"
          price="130/-"
        />
        <RenderCard
          name="Rice Meals"
          imgsrc="https://d2nahbmqd5vvug.cloudfront.net/menuItems/ricesambarpalya.png"
          desc="Plain Rice served with Sambar and Palya"
          price="130/-"
        />
        <RenderCard
          name="Full Meals"
          imgsrc="https://d2nahbmqd5vvug.cloudfront.net/menuItems/North+Thali.png"
          desc="2 Roti, 1 Sabji, Dal, Rice, Salad, Pickle/Curd"
          price="169/-"
        />
        <RenderCard
          name="North Breakfast Items"
          imgsrc="https://d2nahbmqd5vvug.cloudfront.net/menuItems/Paratha.png"
          desc="Aloo paratha/Methi paratha/Mix Veg paratha/Muli ke paratha/Besan ka chilla/Puri Aloo/Poha"
          price="Starting from 99/-"
        />
        <RenderCard
          name="South Breakfast Items"
          imgsrc="https://d2nahbmqd5vvug.cloudfront.net/menuItems/breakfastSouth.png"
          desc="Idli Chutney/Neer Dosa/Upma/Avalakki/Masala Dosa/Chow Chow Bath"
          price="Starting from 99/-"
        />
      </div>
      <Footer />
    </div>
  );
};
export default Products;
