import React, { useState, useEffect, useContext } from "react";
import MenuType from "../MenuType/menuType";
import SubscriptionContext from "../context/subscriptionContext";

const OrderedItems = () => {
    const { subscriptionData } = useContext(SubscriptionContext);
    const [showBreakfast, setShowBreakfast] = useState(false);
    const [showLunch, setShowLunch] = useState(false);
    const [showDinner, setShowDinner] = useState(false);
    const [breakfast, setBreakfast] = useState([]);
    const [lunch, setLunch] = useState([]);
    const [dinner, setDinner] = useState([]);

    useEffect(() => {
        let breakfastSet = new Set([]);
        let lunchSet = new Set([]);
        let dinnerSet = new Set([]);
        subscriptionData.orderedItems.forEach(item => {
            switch(item.menuType.toUpperCase()){
                case "BREAKFAST":
                    breakfastSet.add(item);
                    setShowBreakfast(true);
                    break;
                case "LUNCH":
                    lunchSet.add(item);
                    setShowLunch(true);
                    break;                    
                default:
                    dinnerSet.add(item);
                    setShowDinner(true);
                    break;
            }
        });
        setBreakfast(Array.from(breakfastSet));
        setLunch(Array.from(lunchSet));
        setDinner(Array.from(dinnerSet));
    },[])
 
  return (
    <div class="accordion" id="accordionExample">
     {showBreakfast ?   <div class="card">
       
            <div class="card-header" id="headingOne">
                <h2 class="mb-0">
                    <button class="btn btn-block text-left" type="button" data-toggle="collapse" data-target="#breakfast" aria-expanded="true" aria-controls="breakfast">
                    Breakfast
                    </button>
                </h2>
            </div>
            <div id="breakfast" class="collapse show" aria-labelledby="headingOne" data-parent="#accordionExample">
                {breakfast.map((item) => <MenuType item={item} data={subscriptionData}/>)}
            </div>
    </div>: null}

    {showLunch ? <div class="card">
    <div class="card-header" id="headingTwo">
        <h2 class="mb-0">
            <button class="btn btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#lunch" aria-expanded="false" aria-controls="lunch">
            Lunch
            </button>
        </h2>
        </div> 
        
        <div id="lunch" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
        {lunch.map((item) => <MenuType item={item} data={subscriptionData}/>)}
        </div>
    </div>: null}
    {showDinner ?  <div class="card">
           <div class="card-header" id="headingThree">
            <h2 class="mb-0">
                <button class="btn btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#dinner" aria-expanded="false" aria-controls="dinner">
                Dinner
                </button>
            </h2>
            </div>
        
            <div id="dinner" class="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
            {dinner.map((item) => <MenuType item={item} data={subscriptionData}/>)}
            </div>
        </div> : null}
</div>

  )

};

export default OrderedItems;
