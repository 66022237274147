import React from "react";
import "./menuType.css";
import AssignChef from "../AssignChef/assignChef";
import RenewSubscription from "../RenewSubscription/renewSubscription";
import { daysRemaining } from "../helpers";

const MenuType = ({ item, data }) => {
  const daysLeft = daysRemaining(item.endDate);

  return (
    <div className="card text-white bg-light mb-3 menuType">
    <div className="card-header ">{item.itemName}</div>
    <div className="card-body">
      <p className="card-text">
        {" "}
        <strong>Menu Style: </strong> {item.menuStyle}
      </p>
      <p className="card-text">
        {" "}
        <strong>Menu Price: </strong> Rs.{item.menuPrice}
      </p>
      <p className="card-text">
        <strong>Description:</strong> {item.description}
      </p>
      <p className="card-text">
        <strong>Special Cooking Instructions: </strong> {item.specialCookingInstruction}
      </p>
      <div className="dateSection">
        <p className="card-text">
          <strong>Start Date: </strong> {item.startDate}
        </p>
        <p className="card-text">
          <strong>End Date: </strong>
          {item.endDate}
        </p>
      </div>
      <p className="card-text">
        <strong>Days Left: </strong>
        {daysLeft}
      </p>
      <p className="card-text">
        <strong>Deliver By: </strong>
        {item.deliverBy}
      </p>
      {item.deliveryChargePerMenu !== undefined && (
        <p className="card-text">
          <strong>deliveryChargePerMenu: </strong>
          {item.deliveryChargePerMenu}
        </p>
      )}
      {item.distance !== undefined && (
        <p className="card-text">
          <strong>distance: </strong>
          {item.distance}
        </p>
      )}
      {item.totalDeliveryCharges !== undefined && (
        <p className="card-text">
          <strong>totalDeliveryCharges: </strong>
          {item.totalDeliveryCharges}
        </p>
      )}
      <div className="dateSection">
        <p className="card-text">
          <strong>ItemPrice x Quantity: </strong>Rs.{item.itemPrice}
        </p>
        <p className="card-text">x {item.itemQuantity}</p>
      </div>
      {data.lastUpdatedSubscriptionStatus !== undefined && (
        <p className="card-text">
          <strong>Last Updated {item.subscriptionStatus}:</strong> {data.lastUpdatedSubscriptionStatus}
        </p>
      )}
      <p className="card-text">
        <strong>Subscription Status:- </strong>
        {item.subscriptionStatus}
      </p>
      {data.lastChefs && (
    <div>
      {(item.menuType === "BREAKFAST")  && data.lastChefs.BREAKFAST.length > 0 && (
        <p className="card-text"> <strong>Previous AssignChef for Breakfast:-</strong> {data.lastChefs.BREAKFAST}</p> )}
      
      { (item.menuType==="LUNCH")  && data.lastChefs.LUNCH.length > 0 && (
        <p className="card-text"> <strong>Previous AssignChef for Lunch:-</strong> {data.lastChefs.LUNCH}</p>
      )}
      { (item.menuType==="DINNER")  && data.lastChefs.DINNER.length > 0 && (
        <p className="card-text"> <strong>Previous AssignChef for Dinner:-</strong> {data.lastChefs.DINNER}</p>
      )}
    </div>
  )}

      <div className="chefSection">
        <div>
          {item.chefName !== undefined && (
            <p className="card-text">
              <strong>Chef Name:- </strong>
              {item.chefName}
            </p>
          )}
          {item.chefCharge !== undefined && (
            <p className="card-text">
              <strong>Chef Charge:- </strong>
              {item.chefCharge}
            </p>
          )}
        </div>
      </div>
      <div className="flex justify-content-between">
        <RenewSubscription item={item} data={data} />
          <AssignChef item={item} data={data} />
        </div>
    </div>
  </div>
  )

};

export default MenuType;
