import React from "react";
import "./RenderItems.css";
import { FaRupeeSign } from "react-icons/fa";
import AddQuantity from "./AddQuantity";

const RenderItems = ({ menu }) => {
  return (
    <div className="renderItem ">
      {menu.length > 0
        ? menu.map((item) => (
            <div class="col-xs-12 col-sm-6 col-md-3 card item my-2 mx-1 shadow" key={item.menuID}>
              <div class="card-body">
                <div>
                  <h6>{item.itemName}</h6>
                  <p>{item.itemDescription}</p>
                  <p>
                    <FaRupeeSign size={12} style={{ marginTop: -4 }} />
                    {item.itemPrice}/-
                  </p>
                </div>
                <div className="rightSection">
                  {item.imageUrl !== "N/A" ? <img src={item.imageUrl} className="imageSize" alt="..." /> : null}
                  <div className="add_quantity">
                    <AddQuantity item={item} />
                  </div>
                </div>
              </div>
            </div>
          ))
        : null}
    </div>
  );
};

export default RenderItems;
