/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState } from "react";
import "./adminCard.css";
import { Modal } from "react-bootstrap";

const DeliveryModal = ({ title, heading, selectedOrderID, show, handleBookPartner, handleCancelPartner, handleDismiss, status }) => {
  const [cancellationReason, setCancellationReason] = useState("");
  return (
    <Modal show={show} backdrop="static">
      <Modal.Header>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h5>{heading}</h5>
        {status === "CANCEL" && (
          <div>
            <div className="btn-group">
              <button
                className="btn btn-secondary btn-sm dropdown-toggle"
                type="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                {cancellationReason === "" ? "Select Reason" : cancellationReason}
              </button>
              <div className="dropdown-menu">
                <a className="dropdown-item" href="#" onClick={() => setCancellationReason("Booked by mistake")}>
                  Booked by mistake
                </a>
                <a
                  className="dropdown-item"
                  href="#"
                  onClick={() => setCancellationReason("The delivery partner is taking long time to get assigned")}
                >
                  The delivery partner is taking long time to get assigned
                </a>
                <a className="dropdown-item" href="#" onClick={() => setCancellationReason("The delivery partner refused to take the order")}>
                  The delivery partner refused to take the order
                </a>
                <a className="dropdown-item" href="#" onClick={() => setCancellationReason("Other")}>
                  Other
                </a>
              </div>
            </div>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        {status === "BOOK" ? (
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => {
              handleBookPartner(selectedOrderID);
            }}
          >
            Book
          </button>
        ) : (
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => {
              handleCancelPartner(selectedOrderID, cancellationReason);
            }}
          >
            Cancel
          </button>
        )}
        <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={() => handleDismiss()}>
          Close
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default DeliveryModal;
