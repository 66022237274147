import React, { useState, useEffect, useContext } from "react";
import "./AddQuantity.css";
import { BsDashCircle } from "react-icons/bs";
import { FaPlusCircle } from "react-icons/fa";
import OrderContext from "./OrderContext";

const AddQuantity = ({ item, screen }) => {
  const { totalOrders, setTotalOrders, setTotalAmount, setDiscountAmount } = useContext(OrderContext);
  const [showQuantity, setShowQuantity] = useState(item.quantity ? true : false);
  const [quantity, setQuantity] = useState(item.quantity ? item.quantity : 0);

  const handleAdd = () => {
    setShowQuantity(true);
    setQuantity(1);
  };

  const handleMinusQuantity = () => {
    if (!(screen && totalOrders.length === 1 && quantity === 1)) {
      setQuantity(quantity - 1);
    }
  };

  const handlePlusQuantity = () => {
    setQuantity(quantity + 1);
  };

  useEffect(() => {
    if (quantity !== null) {
      if (quantity > 0) {
        const addItem = {
          chefPrice: item.chefCost ? item.chefCost : item.chefPrice,
          description: item.itemDescription ? item.itemDescription : item.description,
          menuID: item.menuID,
          menuName: item.itemName ? item.itemName : item.menuName,
          menuPrice: item.itemPrice ? item.itemPrice : item.menuPrice,
          quantity: quantity,
        };
        const existingIndex = totalOrders.findIndex((order) => order.menuID === addItem.menuID);

        if (existingIndex !== -1) {
          setTotalOrders((prevState) => {
            const updatedOrders = [...prevState];
            updatedOrders[existingIndex] = addItem;
            return updatedOrders;
          });
        } else {
          setTotalOrders((prevState) => [...prevState, addItem]);
        }
      } else {
        if (screen) {
          if (quantity === 0) {
            const addItem = {
              chefPrice: item.chefCost ? item.chefCost : item.chefPrice,
              description: item.itemDescription ? item.itemDescription : item.description,
              menuID: item.menuID,
              menuName: item.itemName ? item.itemName : item.menuName,
              menuPrice: item.itemPrice ? item.itemPrice : item.menuPrice,
              quantity: 0,
            };
            const existingIndex = totalOrders.findIndex((order) => order.menuID === addItem.menuID);

            if (existingIndex !== -1) {
              setTotalOrders((prevState) => {
                const updatedOrders = [...prevState];
                updatedOrders[existingIndex] = addItem;
                return updatedOrders;
              });

              setShowQuantity(false);
            }
          }
        } else {
          if (quantity <= 0) {
            setTotalOrders(totalOrders.filter((data) => data.menuID !== item.menuID));
            setShowQuantity(false);
          }
        }
      }
    }
  }, [quantity]);

  useEffect(() => {
    let amount = 0;
    totalOrders.length > 0 &&
      totalOrders.forEach((item) => {
        amount += item.menuPrice * item.quantity;
      });
    setTotalAmount(amount);
    setDiscountAmount(0);
  }, [totalOrders]);

  return (
    <div>
      {showQuantity ? (
        <div class="item-controls">
          <BsDashCircle size={20} color="white" onClick={() => handleMinusQuantity()} />
          <p className="quantity">{quantity}</p>
          <FaPlusCircle size={20} color="white" onClick={() => handlePlusQuantity()} />
        </div>
      ) : (
        <div>
          <button className="btn btn-primary addButton btn-sm  shadow" type="button" onClick={() => handleAdd()}>
            Add
          </button>
        </div>
      )}
    </div>
  );
};

export default AddQuantity;
