import axios from "axios";

const PostAPI = async (postapi, dataToPost, customHeader) => {
  const headersValue = {
    "Content-type": "application/json; charset=UTF-8",
    Authorization: "website"
  };
  const headers = customHeader !== undefined ? customHeader : headersValue;

  return axios
    .post(postapi, dataToPost, {
      headers,
    })
    .then((res) => {
      if (res.data.statusCode === 200 || res.data.statusCode === 201 || res.status === 200 || res.status === 201 || res.data.statusCode === 204) {
        return res;
      }
      if (res.data.statusCode === 500) {
        return "Internal Server Error";
      }
    })
    .catch((err) => {
      return err;
    });
};

export default PostAPI;
