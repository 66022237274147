import React, { useState } from "react";
import { FaEdit } from "react-icons/fa";
import "./ChefDisplayInitialMenu.css";
import ChefEditMenuModal from "./ChefEditMenuModal";

const RenderMenuItems = ({
  isAdmin,
  handleDelete,
  id,
  Slno,
  data,
  adminStatus,
  adminComments,
  handleApprove,
  handleReject,
  handleComments,
  handleEditSave,
}) => {
  const [showEdit, setShowEdit] = useState(false);
  const [comments, setComments] = useState("");
  const [showCard, setShowCard] = useState("");

  const handleEdit = (idValue) => {
    if (idValue === id) {
      setShowEdit(true);
    } else {
      setShowEdit(false);
    }
  };

  const RenderShowCard = ({ id, showCard }) => {
    return (
      <div>
        <div className="input-group input-group-sm my-2">
          <input
            type="text"
            autoFocus="true"
            className="form-control"
            placeholder="Add Comments"
            value={comments}
            onChange={(e) => {
              setComments(e.target.value);
            }}
          />
        </div>
        <button
          type="button"
          className="btn btn-info btn-sm "
          onClick={() => {
            handleComments(id, comments);
            setComments("");
            setShowCard("");
          }}
        >
          SaveChanges
        </button>
      </div>
    );
  };

  const Approve = (idValue) => {
    if (idValue === id) {
      setShowCard(idValue);
      handleApprove(idValue, "APPROVED");
    }
  };

  const Reject = (idValue) => {
    if (idValue === id) {
      setShowCard(idValue);
      handleReject(idValue, "REJECTED");
    }
  };

  const RenderValues = ({ title, value, className }) => {
    return (
      <p className={className}>
        {title} : <strong>{value}</strong>
      </p>
    );
  };

  const handleEditMenuClose = () => {
    setShowEdit(false);
  }

  return (
    <div className="miniCard">
      {!isAdmin && (
        <div data-toggle="modal" data-target="#exampleModal">
          <p
            className="cross"
            onClick={() => {
              handleDelete(id);
            }}
          >
            &#10060;
          </p>
        </div>
      )}
      {!isAdmin && (
        <div className="mx-5">
          <p
            className="edit"
            onClick={() => {
              handleEdit(id);
            }}
          >
            <FaEdit size={18} color="#393053" />
          </p>
        </div>
      )}
      <p>
        # <strong>{Slno}</strong>
      </p>
      {showEdit ? (
        <ChefEditMenuModal
        show={showEdit}
        handleClose={handleEditMenuClose}
        data={data}
        id={id}
        handleEditSave={handleEditSave}
      />
      ) : (
        <div>
          <RenderValues title="Name" value={data?.itemName} />
          <RenderValues title="Desc" value={data?.description} />
          <RenderValues title="Price" value={data?.chefCost} />
          <RenderValues title="OrderBy" value={data?.orderBy} />
          <RenderValues title="ItemType" value={data?.itemType} />
          <RenderValues title="foodStyle" value={data?.menuTags?.join(", ")} />
          <RenderValues title="MenuTypes" value={data?.menuType?.join(", ")} />
          <RenderValues title="Item Forever" value={data?.itemForever ? "yes" : "no"} />
          <RenderValues
            title="AdminStatus"
            value={adminStatus}
            className={
              adminStatus.toUpperCase() === "APPROVED" 
                ? "adminApprove" 
                : adminStatus.toUpperCase() === "REJECTED" 
                ? "adminReject" 
                : ""
            }
          />
        </div>
      )}
      <p>
        adminComments:<strong> {adminComments}</strong>
      </p>
      {isAdmin && (
        <div>
          <div className="adminButtons">
            <button
              type="button"
              className="btn btn-success btn-sm "
              onClick={() => Approve(id)}
            >
              Approve
            </button>
            <button
              type="button"
              className="btn btn-danger btn-sm "
              onClick={() => Reject(id)}
            >
              Reject
            </button>
          </div>
          {showCard === id && <RenderShowCard id={id} showCard={showCard} />}
        </div>
      )}
    </div>
  );
};

export default RenderMenuItems;
