/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable default-case */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import "./finance.css";
import moment from "moment";
import { FaArrowDown, FaArrowUp } from "react-icons/fa";
import { ThreeCircles } from "react-loader-spinner";
import AdminTabs from "../adminTabs";
import AdminModal from "../adminModal";
import GetAPI from "../../APICalls/GetAPI";
import PutAPI from "../../APICalls/PutAPI";

const Finance = () => {
  const [orderDetails, setOrderDetails] = useState(null);
  const [CODDetails, setCODDetails] = useState(null);
  const [showCOD, setShowCOD] = useState(true);
  const [loading, setLoading] = useState(false);
  const [date, setDate] = useState(moment().format("YYYY-MM-DD"));
  const [notVerified, setNotVerified] = useState(true);

  const handleVerified = (value) => {
    setNotVerified(value);
  };

  let environment = process.env.REACT_APP_NODE_ENV;

  const handleOrder = (orderID) => {
    window.open(`/order?orderID=${orderID}`, "_blank");
  };

  const getOrderStatistics = (date) => {
    let fetchOrderStatistics;
    if (environment === "PRODUCTION" || environment === undefined) {
      fetchOrderStatistics = process.env.REACT_APP_PROD_STATISTICS;
    } else {
      fetchOrderStatistics = process.env.REACT_APP_DEV_STATISTICS;
    }

    let orderDetailsURL =
      fetchOrderStatistics +
      "?deliveryDate=" +
      moment(date).format("YYYY-MM-DD") +
      "&type=orderStatistics";
    let CODDetailsURL =
      fetchOrderStatistics +
      "?deliveryDate=" +
      moment(date).format("YYYY-MM-DD") +
      "&type=CODStatistics";

    GetAPI(orderDetailsURL)
      .then((res) => {
        let responseData = res.data;
        let responseBody = responseData.body;
        if (responseData.statusCode === 200) {
          setOrderDetails(responseBody);
        }
      })
      .catch((e) => {
        console.error(e);
        alert("Unable to get order details");
      });

    GetAPI(CODDetailsURL)
      .then((res) => {
        let responseData = res.data;
        let responseBody = responseData.body;
        if (responseData.statusCode === 200) {
          setCODDetails(responseBody);
        }
      })
      .catch((e) => {
        console.error(e);
        alert("Unable to get cod details");
      });
  };

  useEffect(() => {
    getOrderStatistics(date);
  }, [date]);

  const handleDown = () => {
    setShowCOD(true);
  };

  const handleUp = () => {
    setShowCOD(false);
  };

  const handlePaid = (orderID) => {
    let orders;
    if (environment === "PRODUCTION") {
      orders = process.env.REACT_APP_PROD_ORDERAPI;
    } else {
      orders = process.env.REACT_APP_DEV_ORDERAPI;
    }

    setLoading(true);
    const dataToPut = {
      orderID: orderID,
      paymentStatus: "PAID",
    };

    PutAPI(orders, dataToPut)
      .then((res) => {
        if (res.data.statusCode === 200) {
          alert("Successfully changed payment status to paid!!!");
          window.location.reload();
        }
        if (res.data.statusCode === 500) {
          alert("Payment status internal server error");
          setLoading(false);
        }
      })
      .catch((e) => {
        setLoading(false);
        console.log("Error occurred. Please try again. Error =", e);
      });
  };

  return (
    <div>
      <AdminTabs />
      {notVerified ? (
        <AdminModal handleVerified={handleVerified} />
      ) : (
        <div className="finance container">
          {loading && (
            <div className="spinnerContainer">
              <div className="spinner">
                <ThreeCircles
                  color="blue"
                  height={110}
                  width={110}
                  ariaLabel="three-circles-rotating"
                />
              </div>
            </div>
          )}
          <h1>Finance Section</h1>
          <div className="dateContainer">
            {" "}
            <h6>{moment(date).format("dddd")}</h6>
            <input
              type="date"
              id="start"
              name="trip-start"
              value={date}
              onChange={(e) => setDate(e.target.value)}
            ></input>
          </div>

          {orderDetails !== null && (
            <div>
              <div className="section">
                {/* Subscription Details */}
                <div className="card text-white">
                  <div className="card-header">Subscription Details </div>
                  <div className="card-body">
                    <h6 className="card-title">
                      {" "}
                      Order Count -{" "}
                      {orderDetails.HomeChefSubscriptionOrderCount}{" "}
                    </h6>

                    <h6 className="card-title">
                    Taxable Amount  - Rs.{" "}
                      {orderDetails.HomeChefGMVSubscriptionTotalAmount}{" "}
                    </h6>
                    <p class="fw-light">(foodCharge + delivery charge - discount)</p>
                    <h6 className="card-title">
                      Chef Amount - Rs.{" "}
                      {orderDetails.HomeChefSubscriptionTotalAmount}
                    </h6>
                    <h6 className="card-title">
                     Homeal Delivery Charges - Rs.{" "}
                      {orderDetails.TotalHomeChefSubscriptionDeliveryCharges}
                    </h6>
                    <h6 className="card-title">
                      Vendor Delivery Charges - Rs.{" "}
                      {
                        orderDetails.TotalHomeChefVendorSubscriptionDeliveryCharges
                      }
                    </h6>

                    <h6 className="card-title">
                      Vendor Delivery Order Count -{" "}
                      {
                        orderDetails.TotalHomeChefVendorSubscriptionDeliveryChargesCount
                      }{" "}
                    </h6>
                  </div>
                </div>
                {/* Adhoc Details */}
                <div className="card text-white">
                  <div className="card-header">Adhoc Details </div>
                  <div className="card-body">
                    <h6 className="card-title">
                      {" "}
                      Order Count - {orderDetails.HomeChefRegularOrderCount}
                    </h6>

                    <h6 className="card-title">
                      Taxable Amount - Rs. {orderDetails.HomeChefGMVRegularTotalAmount}{" "}
                    </h6>
                    <p class="fw-light">(foodCharge + delivery charge - discount)</p>
                    <p></p>
                    <h6 className="card-title">
                      Chef Amount - Rs.{" "}
                      {orderDetails.HomeChefRegularTotalAmount}
                    </h6>
                    <h6 className="card-title">
                     Homeal Delivery Charges - Rs.{" "}
                      {orderDetails.TotalHomeChefRegularDeliveryCharges}
                    </h6>
                    <h6 className="card-title">
                      Vendor Delivery Charges - Rs.{" "}
                      {orderDetails.TotalHomeChefVendorRegularDeliveryCharges}
                    </h6>

                    <h6 className="card-title">
                      Vendor Delivery Order Count -{" "}
                      {orderDetails.TotalHomeChefVendorRegularDeliveryChargesCount}{" "}
                    </h6>
                  </div>
                </div>
                {/* Promo and Wallet Details */}
                <div className="card text-white">
                  <div className="card-header">Promo and Wallet Details </div>
                  <div className="card-body">
                    <h6 className="card-title">
                      {" "}
                      Homeal Promo - Rs.{" "}
                      {orderDetails.TotalHomealPromoDiscountAmount}
                    </h6>
                    <h6 className="card-title">
                      Home Chef Promo  - Rs.{" "}
                      {orderDetails.TotalHomeChefPromoDiscountAmount}{" "}
                    </h6>
                    <h6 className="card-title">
                      Wallet - Rs.{" "}
                      {orderDetails.TotalHomeChefWalletDeductedAmount}
                    </h6>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Finance;
