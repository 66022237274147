import React from "react";
import AdminCard from "./adminCard";
import "./accepted.css";

const Accepted = ({
  ordersFound,
  data,
  handleStatusClick,
  handleDeliveryClick,
  handleBookDaas,
  handleCancelDaas,
  handleComment,
  handleRefund
}) => {
  const handleOrder = (orderID) => {
    window.open(`/order?orderID=${orderID}`, "_blank");
  };

  return (
    <div className="accepted">
      {ordersFound ? (
        data.map((data, id) => (
          <AdminCard
            id={id}
            data={data}
            handleOrder={handleOrder}
            status="ACCEPTED"
            handleStatusClick={handleStatusClick}
            handleDeliveryClick={handleDeliveryClick}
            handleBookDaas={handleBookDaas}
            handleCancelDaas={handleCancelDaas}
            handleComment={handleComment}
            handleRefund={handleRefund}
          />
        ))
      ) : (
        <h1>No Accepted orders</h1>
      )}
    </div>
  );
};

export default Accepted;
