import React, { useState, useEffect } from "react";
import "./order.css";
import OrderedItem from "./orderedItem";
import GetAPI from "../APICalls/GetAPI";

const Order = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const orderID = urlParams.get("orderID");
  const [orderFound, setOrderFound] = useState(false);
  const [orderDetails, setOrderDetails] = useState({
    orderID: null,
    chefName: null,
    chefLocation: null,
    chefMobileNumber: null,
    itemsOrdered: null,
    menuType: null,
    cost: null,
    paymentStatus: null,
    craverName: null,
    craverMobileNumber: null,
    craverLocation: null,
    orderStatus: null,
    deliveryDate: null,
    craverHouseNo: null,
    craverApartment: null,
    craverLandmark: null,
    deliveryAgentName: null,
    deliveryAgentMobile: null,
  });

  const [encodedChefAddress, setEncodedChefAddress] = useState("");
  const [encodedCraverAddress, setEncodedCraverAddress] = useState("");

  let environment = process.env.REACT_APP_NODE_ENV;

  /* function to call order details */
  const orderData = () => {
    let orderAPI, userAPI;
    if (environment === "PRODUCTION" || environment === undefined) {
      orderAPI = process.env.REACT_APP_PROD_ORDERAPI;

      userAPI = process.env.REACT_APP_PROD_USERAPI;
    } else {
      orderAPI = process.env.REACT_APP_DEV_ORDERAPI;
      userAPI = process.env.REACT_APP_DEV_USERAPI;
    }

    let completeOrderURL = orderAPI + "?orderID=" + orderID;

    GetAPI(completeOrderURL)
      .then((response) => {
        if (Object.keys(response.data.body).length === 0) {
          setOrderFound(false);
        } else {
          setOrderFound(true);

          let completeUserURL = userAPI + "?userID=" + response.data.body.chefID;

          GetAPI(completeUserURL)
            .then((res) => {
              setOrderDetails((prevState) => ({
                ...prevState,
                orderID: orderID,
                cost: response.data.body.cost,
                itemsOrdered: response.data.body.orderedItems,
                menuType: response.data.body.menuType,
                craverID: response.data.body.craverID,
                craverName: response.data.body.craverDetails.craverName,
                craverMobileNumber: response.data.body.craverDetails.craverMobile,
                craverLocation: response.data.body.craverDetails.craverAddress,
                craverLat: response.data.body.craverDetails.craverAddressCoordinates.lat,
                craverLng: response.data.body.craverDetails.craverAddressCoordinates.lng,
                orderStatus: response.data.body.status,
                paymentStatus: response.data.body.paymentStatus,
                distance: response.data.body.distance,
                dunzoDeliveryCharges: response.data.body.dunzoDeliveryCharges,
                deliveryDate: response.data.body.deliverBy,
                craverHouseNo: response.data.body.craverDetails.craverHouseNo,
                craverApartment: response.data.body.craverDetails.craverApartmentName,
                craverLandmark: response.data.body.craverDetails.craverLandmark,
                deliveryAgentName: response.data.body.deliveryDetails.deliveryAgentName,
                deliveryAgentMobile: response.data.body.deliveryDetails.deliveryAgentMobile,
                chefID: response.data.body.chefID,
                chefName: res.data.body.userName,
                chefLocation: res.data.body.userAddress.address,
                chefHouseNo: res.data.body.userAddress.houseNo,
                chefApartment: res.data.body.userAddress.apartmentName,
                chefLandmark: res.data.body.userAddress.directionsToReach,
                chefMobileNumber: res.data.body.userMobile,
                chefLat: response.data.body.chefDetails.chefAddressCoordinates.lat,
                chefLng: response.data.body.chefDetails.chefAddressCoordinates.lng,
              }));
            })
            .catch((e) => {
              console.error(e);
              alert("Error Occured");
            });
        }
      })
      .catch((e) => {
        console.error(e);
        alert("Error Occured");
      });
  };

  useEffect(() => {
    orderData();
  }, []);

  useEffect(() => {
    if (orderDetails.chefLocation != null && orderDetails.craverLocation != null) {
      let chefAddress = orderDetails.chefLocation;
      if (chefAddress.charAt(0) === "#") {
        chefAddress = chefAddress.substring(1, chefAddress.length);
      }
      let craverAddress = orderDetails.craverLocation;
      if (craverAddress.charAt(0) === "#") {
        craverAddress = craverAddress.substring(1, craverAddress.length);
      }
      setEncodedChefAddress(encodeURI(chefAddress));
      setEncodedCraverAddress(encodeURI(craverAddress));
    }
  }, [orderDetails]);

  return (
    <div className="order">
      {orderFound ? (
        <div>
          <h3>
            Order ID:{"  "}
            {orderID}
            {"    "}Order Status:{"  "}
            {orderDetails.orderStatus}
          </h3>
          {orderDetails.orderStatus !== null && (
            <div className="card">
              <div className="card-body">
                <ul className="list-group list-group-flush">
                  <li className="list-group-item active">Chef Details</li>
                  <li className="list-group-item">
                    <strong>Chef ID: </strong> {orderDetails.chefID}
                  </li>
                  <li className="list-group-item">
                    <strong>Chef Name: </strong> {orderDetails.chefName}
                  </li>
                  <li className="list-group-item">
                    <strong>Chef Mobile No: </strong>{" "}
                    <a href={"tel:" + orderDetails.chefMobileNumber} rel="noreferrer">
                      {orderDetails.chefMobileNumber}
                    </a>
                  </li>
                  <li className="list-group-item">
                    <strong>Chef Address: </strong>
                    <a href={`http://maps.google.com/?q=${orderDetails.chefLat},${orderDetails.chefLng}`} rel="noreferrer" target="_blank">
                      {orderDetails.chefLocation}
                    </a>
                  </li>
                  <li className="list-group-item addressDetails">
                    <strong>Detailed Address:</strong>
                    <p>House No. : {orderDetails.chefHouseNo} </p>
                    <p>Apartments : {orderDetails.chefApartment} </p>
                    <p>Landmark : {orderDetails.chefLandmark} </p>
                  </li>
                </ul>
                <ul className="list-group list-group-flush">
                  <li className="list-group-item active">Craver Details</li>
                  <li className="list-group-item">
                    <strong>Craver ID: </strong> {orderDetails.craverID}
                  </li>
                  <li className="list-group-item">
                    <strong>Craver Name: </strong> {orderDetails.craverName}
                  </li>
                  <li className="list-group-item">
                    <strong>Craver Mobile No: </strong>{" "}
                    <a href={"tel:" + orderDetails.craverMobileNumber} rel="noreferrer">
                      {orderDetails.craverMobileNumber}
                    </a>
                  </li>

                  <li className="list-group-item addressDetails">
                    <strong>Detailed Address:</strong>
                    <p>House No. : {orderDetails.craverHouseNo} </p>
                    <p>Apartments : {orderDetails.craverApartment} </p>
                    <p>Landmark : {orderDetails.craverLandmark} </p>
                  </li>

                  <li className="list-group-item">
                    <strong>Craver Address: </strong>
                    <a href={`http://maps.google.com/?q=${orderDetails.craverLat},${orderDetails.craverLng}`} rel="noreferrer" target="_blank">
                      {orderDetails.craverLocation}
                    </a>
                  </li>
                </ul>

                <ul className="list-group list-group-flush">
                  <li className="list-group-item active">Order Details</li>
                  <li className="list-group-item">
                    <strong>Menu Type: </strong> {orderDetails.menuType}
                  </li>
                  <li className="list-group-item orderStatus" data-toggle="modal" data-target="#exampleModalCenter">
                    <strong>Order Status: </strong> {orderDetails.orderStatus}
                  </li>
                  <li className="list-group-item">
                    <strong>Delivery Date: </strong> {orderDetails.deliveryDate}
                  </li>
                </ul>

                <ul className="list-group list-group-flush">
                  <li className="list-group-item active">Ordered Items</li>
                  <div className="itemHeadingContainer">
                    <p className="itemNameHeading">
                      <strong>Item Name</strong>
                    </p>
                    <p>
                      <strong>Item Price</strong>
                    </p>
                    <p>
                      <strong>Item Quantity</strong>
                    </p>
                    <p>
                      <strong>Menu Type</strong>
                    </p>
                    <p>
                      <strong>Menu Style</strong>
                    </p>
                  </div>
                  {orderDetails.itemsOrdered.map((item) => (
                    <OrderedItem key={Math.random()} item={item} />
                  ))}
                </ul>

                <ul className="list-group list-group-flush">
                  <li className="list-group-item active">Cost details</li>
                  <li className="list-group-item">
                    <strong>Total Chef Charge: </strong>
                    {orderDetails.cost.chefCharge}
                  </li>
                  <li className="list-group-item">
                    <strong>Total Food Charges: </strong> {orderDetails.cost.foodCharge}
                  </li>
                  <li className="list-group-item">
                    <strong>Distance : </strong> {orderDetails.distance}
                  </li>
                  <li className="list-group-item">
                    <strong>Delivery Charges : </strong> {orderDetails.cost.deliveryCharge}
                  </li>
                  <li className="list-group-item">
                    <strong>Dunzo Delivery Charges : </strong> {orderDetails.dunzoDeliveryCharges}
                  </li>
                  <li className="list-group-item">
                    <strong>Promo Code: </strong> {orderDetails.cost.promoCode}
                  </li>
                  <li className="list-group-item">
                    <strong>Promo Discount Amount: </strong> {orderDetails.cost.promoDiscountAmount}
                  </li>
                  <li className="list-group-item">
                    <strong>Gst: </strong> {orderDetails.cost.gst}
                  </li>
                  <li className="list-group-item">
                    <strong>Grand Total: </strong> {orderDetails.cost.total}
                  </li>
                  <li className="list-group-item">
                    <strong>Deducted Wallet Amount: </strong> {orderDetails.cost.deductedWalletAmount}
                  </li>
                  <li className="list-group-item">
                    <strong>Payable Amount: </strong> {orderDetails.cost.payableAmount}
                  </li>
                </ul>
                <ul className="list-group list-group-flush">
                  <li className="list-group-item active">Payment Status</li>
                  <li className="list-group-item">
                    <strong>{orderDetails.paymentStatus}</strong>
                  </li>
                </ul>
                <ul className="list-group list-group-flush">
                  <li className="list-group-item active">Delivery Agent Details</li>
                  <li className="list-group-item">
                    <strong>Delivery Agent Name: </strong> {orderDetails.deliveryAgentName}
                  </li>
                  <li className="list-group-item">
                    <strong>Contact Details : </strong> {orderDetails.deliveryAgentMobile}
                  </li>
                </ul>
              </div>
            </div>
          )}
        </div>
      ) : (
        <div>
          <h2>No order found</h2>
        </div>
      )}
    </div>
  );
};
export default Order;
