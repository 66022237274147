import React, { useContext } from "react";
import "./OrderNowFooter.css";
import OrderContext from "./OrderContext";
import { FaRupeeSign } from "react-icons/fa";
import { useHistory } from "react-router-dom";

const OrderNowFooter = () => {
  const { totalAmount } = useContext(OrderContext);
  const history = useHistory();

  const handleOrderNow = () => {
    if (totalAmount === 0) {
      alert("Please add items to place order");
    } else {
      history.push("/checkout");
    }
  };

  return (
    <footer className="OrderNowfooter">
      <div className="row">
        <div className="col-md-6">
          <h5>
            Total Amount: <FaRupeeSign size={14} style={{ marginTop: -4 }} />
            {totalAmount}/-
          </h5>
        </div>

        <div className="col-md-6 text-right">
          <button className="btn btn-light" onClick={() => handleOrderNow()}>
            Order Now
          </button>
        </div>
      </div>
    </footer>
  );
};

export default OrderNowFooter;
