export const setCookie = (name, value, daysToExpire) => {
  const expirationDate = new Date();
  expirationDate.setDate(expirationDate.getDate() + daysToExpire);

  const cookieValue = encodeURIComponent(JSON.stringify(value)) + (daysToExpire ? "; expires=" + expirationDate.toUTCString() : "");
  document.cookie = name + "=" + cookieValue + "; path=/";
};

export const getCookie = (name) => {
  const cookieValue = document.cookie.match("(^|;)\\s*" + name + "\\s*=\\s*([^;]+)");
  return cookieValue ? JSON.parse(decodeURIComponent(cookieValue[2])) : null;
};
