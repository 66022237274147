import React from "react";
import AdminCard from "./adminCard";
import "./cancelled.css";

const Cancelled = ({ ordersFound, data, handleStatusClick, handleDeliveryClick, handleChef, handleComment, handleRefund }) => {
  const handleOrder = (orderID) => {
    window.open(`/order?orderID=${orderID}`, "_blank");
  };

  return (
    <div className="cancelled">
      {ordersFound ? (
        data.map((data, id) => (
          <AdminCard
            id={id}
            data={data}
            handleOrder={handleOrder}
            handleChef={handleChef}
            status="CANCELLED"
            handleStatusClick={handleStatusClick}
            handleDeliveryClick={handleDeliveryClick}
            handleComment={handleComment}
            handleRefund={handleRefund}
          />
        ))
      ) : (
        <h1>No Cancelled orders</h1>
      )}
    </div>
  );
};

export default Cancelled;
