import React from "react";
import "./landing.css";
import Navbar from "../Navbar/navbar";
import GooglePlay from "../../images/googlePlay.png";
import AppStore from "../../images/appStore.png";
import HeaderLogo from "../../images/headerLogo.png";

function Landing() {
  return (
    <div className="landing">
      <Navbar />
      <div className="subHeading">
        <img src={HeaderLogo} width="55" height="55" alt="Homeal" />
        <h2>Homeal</h2>
        <h1>Homemade Food Delivery in Bangalore </h1>
        <h5>Download Now</h5>
        <div className="follow-icons">
          <a href="https://play.google.com/store/apps/details?id=com.localzi.homeal" target="_blank" rel="noreferrer">
            <img src={GooglePlay} width="120" height="40" alt="googlePlay" />
          </a>
          <a href="https://apps.apple.com/in/app/homeal/id1571860810" target="_blank" rel="noreferrer">
            <img src={AppStore} width="120" height="40" alt="applePlay" />
          </a>
        </div>
      </div>
    </div>
  );
}
export default Landing;
