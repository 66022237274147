import React, { useContext, useEffect } from "react";
import "./TotalBill.css";
import OrderContext from "./OrderContext";
import axios from "axios";

const TotalBill = () => {
  const { totalAmount, discountAmount, gst, setGst, grandTotal, setGrandTotal } = useContext(OrderContext);

  useEffect(() => {
    let environment = process.env.REACT_APP_NODE_ENV;
    const headers = {
      "Content-Type": "application/json",
      Authorization: "website"
    };
    let getGst;
    if (environment === "PRODUCTION" || environment === undefined) {
      getGst = process.env.REACT_APP_PROD_UTILITY;
    } else {
      getGst = process.env.REACT_APP_DEV_UTILITY;
    }
    const getGST = () => {
      let GSTurl = getGst + "?type=GST";

      axios
        .get(GSTurl, {
          headers,
        })
        .then((response) => {
          if (response.data.statusCode === 200) {
            let gstValue = response.data.body[0].value;
            let totalGst = Math.round((totalAmount * gstValue) / 100);
            setGst(totalGst);
          }
        })
        .catch((error) => {
          console.log("Error occurred. Error =" + error);
          alert("Error occurred while fetching all promo codes!!!");
        });
    };
    getGST();
  }, [totalAmount]);

  useEffect(() => {
    setGrandTotal(totalAmount + gst - discountAmount);
  }, [totalAmount, discountAmount, gst]);

  return (
    <div className="totalBill">
      <h5>Bill Details</h5>
      <hr />
      <div className="totalSection">
        <p>ItemTotal</p>
        <p>Rs. {totalAmount}/-</p>
      </div>
      {discountAmount > 0 ? (
        <div className="discountSection">
          <p>Discount</p>
          <p>-Rs. {discountAmount}/-</p>
        </div>
      ) : null}
      <div className="totalSection">
        <p>Taxes & Charges</p>
        <p>Rs. {gst}/-</p>
      </div>
      <div className="totalSection">
        <h6>Grand Total</h6>
        <h6>Rs. {grandTotal}/-</h6>
      </div>
    </div>
  );
};

export default TotalBill;
