import React from "react";
import "./orderedItem.css";

function orderedItem(props) {
  return (
    <div>
      <div className="itemContainer">
        <p className="itemName">{props.item.menuName}</p>
        <p>Rs.{props.item.menuPrice}</p>
        <p>{props.item.quantity}</p>
        <p>{props.item.menuType}</p>
        <p>{props.item.menuStyle}</p>
      </div>
      <p className="descText">Desc: {props.item.description}</p>
    </div>
  );
}

export default orderedItem;
