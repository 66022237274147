/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/rules-of-hooks */
import React from "react";
import "./adminCard.css";
import { Modal } from "react-bootstrap";

const chefInchargeModal = ({ title, show, handleChef, selectedOrderID }) => {
  return (
    <Modal show={show} backdrop="static">
      <Modal.Header>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Footer>
        <button
          type="button"
          className="btn btn-primary"
          onClick={() => {
            handleChef(selectedOrderID, "NARESH");
          }}
        >
          Naresh
        </button>
        <button
          type="button"
          className="btn btn-primary"
          onClick={() => {
            handleChef(selectedOrderID, "SUNIL");
          }}
        >
          Sunil
        </button>

        {/* <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={() => handleDismiss()}>
          Close
        </button> */}
      </Modal.Footer>
    </Modal>
  );
};

export default chefInchargeModal;
