/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/rules-of-hooks */
import React from "react";
import "../adminCard.css";
import { Modal } from "react-bootstrap";

const ChefPerformancePopup = ({ data, show, selectedChefData, handleDismiss }) => {

  return (
    <Modal size="lg" show={show} backdrop="static">
      <Modal.Header>
        <Modal.Title>Performance Details of {selectedChefData.userName}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          <strong>Average Rating - {selectedChefData.avgRating} </strong>{" "}
        </p>
        <p>
          <strong>Total Orders - {data.totalOrders} </strong>
        </p>
        <p>
          <strong>Total Rejected Orders- {data.totalRejectedOrders} </strong>{" "}
        </p>
        <p>
          <strong>Last Received Order on {data.lastDeliveryDate} </strong>{" "}
        </p>
        <p>
          <strong>Last Order ID - {data.lastOrderID} </strong>{" "}
        </p>
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className="btn btn-secondary"
          data-dismiss="modal"
          onClick={() => handleDismiss()}
        >
          Close
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default ChefPerformancePopup;
