import React from "react";
import { Modal } from "react-bootstrap";
import "./CouponModalDisplay.css";
import RenderPromo from "./RenderPromo";

const CouponModalDisplay = ({ showModal, handleClose, promoCodes, handleApplyPromo }) => {
  return (
    <div>
      {" "}
      <Modal show={showModal} size="lg" scrollable={true} onHide={handleClose} backdrop="static" keyboard={false}>
        <Modal.Header>
          <Modal.Title>Select the Coupon Code</Modal.Title>
          <div>
            <p
              className="cross"
              onClick={() => {
                handleClose();
              }}
            >
              &#10060;
            </p>
          </div>
        </Modal.Header>

        <Modal.Body>{promoCodes.length > 0 && promoCodes.map((item) => <RenderPromo item={item} handleApplyPromo={handleApplyPromo} />)}</Modal.Body>
      </Modal>
    </div>
  );
};

export default CouponModalDisplay;
