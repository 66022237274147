import "./BlogContainer.css"
import Footer from '../Footer/footer';
import BlogWrapper from './BlogWrapper';
import BlogNavbar from "./BlogNavbar";


const BlogContainer = () => {
  
  return (
    <div>
  
      <BlogNavbar />
      <div  className="blog-container">
      <BlogWrapper/>
      </div>
      <Footer />
    </div>
  );
}

export default BlogContainer