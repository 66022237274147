import React from "react";
import { useState } from "react";

const PushNotificationviaWhatsApp = ({ setShow, setData }) => {
  const [fileID, setFileID] = useState("");
  const [chefID, setChefID] = useState("");
  const [message, setMessage] = useState("");

  const handleSubmit = () => {
    if (message === "") {
      setShow(false);
      alert("Please enter the message to submit");
      return;
    }
    if (chefID === "") {
      setShow(false);
      alert("Please enter the chef Id to submit");
      return;
    }

    setData({
      fileID: fileID !== "" ? fileID : undefined,
      message: message,
      chefID: chefID,
      type: "CHEFWISE",
    });
    setShow(true);
  };
  return (
    <div className="card ">
      <div className="card-body mx-2">
        <h3 className="my-3  mx-2 ">Send notifications via Whats App</h3>
        <div>
          <form>
            <div className="form-row">
              <div className="col-sm-12 col-md-12 col-lg-12 my-3">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span
                      className="input-group-text"
                      id="validationTooltipUsernamePrepend"
                    >
                      File Id
                    </span>
                  </div>
                  <input
                    type="text"
                    value={fileID}
                    onChange={(e) => setFileID(e.target.value)}
                    className="form-control "
                    id="fileID"
                    placeholder="File Id"
                    aria-describedby="Message"
                    required
                  />
                </div>
              </div>
              <div className="col-sm-12 col-md-12 col-lg-12 my-3">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span
                      className="input-group-text"
                      id="validationTooltipUsernamePrepend"
                    >
                      Message
                    </span>
                  </div>
                  <textarea
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    className="form-control"
                    style={{ height: "150px" }}
                    id="Message"
                    placeholder="Message"
                    aria-describedby="Message"
                    required
                  />
                </div>
              </div>
              <div className="col-sm-12 col-md-12 col-lg-12 my-3">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span
                      className="input-group-text"
                      id="validationTooltipUsernamePrepend"
                    >
                      Chef Id
                    </span>
                  </div>
                  <input
                    type="text"
                    value={chefID}
                    onChange={(e) => setChefID(e.target.value)}
                    className="form-control "
                    id="chefID"
                    placeholder="Chef Id"
                    aria-describedby="Message"
                    required
                  />
                </div>
              </div>
            </div>
          </form>
          <div className="col-sm-12 mx-2 my-5">
            <button
              className="btn btn-primary btn-block"
              onClick={handleSubmit}
            >
              {" "}
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PushNotificationviaWhatsApp;
