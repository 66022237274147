import "./BlogContainer.css"
import BlogTopContainer from './BlogTopContainer'
import BlogTrendingContainer from './BlogTrendingContainer'
import BlogCategoriesContainer from './BlogCategoriesContainer'

const BlogWrapper = () => {
  return (
    <div className='BlogWrapper-container'>
    <BlogTopContainer />
    <BlogTrendingContainer />
    <BlogCategoriesContainer />
    {/* <BlogTrendingContainer /> */}
    </div>
  )
}

export default BlogWrapper