/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState, useEffect } from "react";
import "./adminCard.css";

import { SiCodechef } from "react-icons/si";
import { FaUserAlt } from "react-icons/fa";
import { RiEBike2Line } from "react-icons/ri";
import { GiRiceCooker } from "react-icons/gi";
import { IoMdClock } from "react-icons/io";
import { GrLocation } from "react-icons/gr";
import { FiPhoneCall } from "react-icons/fi";
import { BiCommentDetail } from "react-icons/bi";
import { HashLink as Link } from "react-router-hash-link";
import DeliveryModal from "./deliveryModal";
import StatusConfirmModal from "./StatusConfirmModal";
import ShowCommentPopup from "./ShowCommentPopup";
import ChefInchargeModal from "./chefInchargeModal";
import PenalityModal from "./PenalityModal";
import { BsFillEmojiSunglassesFill } from "react-icons/bs";
import { MdOutlineRestaurantMenu } from "react-icons/md";
import { getCurrentTime, compareTime, calculateTimeDifferenceInMinutes, convert12to24 } from "./Helpers";
import RefundToWalletModal from "./RefundToWalletModal";
import GetAPI from "../APICalls/GetAPI";
import DaasRetryPopup from "./DaasRetryPopup";

const adminCard = ({
  id,
  data,
  handleOrder,
  status,
  handleStatusClick,
  handleChef,
  handleDeliveryClick,
  handleCancelDaas,
  handleChefPenality,
  handleBookDaas,
  handleComment,
  handleRefund
}) => {
  const [showChefIncharge, setShowChefIncharge] = useState(false);
  const [showDaas, setShowDaas] = useState(false);
  const [showDaasCancel, setShowDaasCancel] = useState(false);
  const [selectedOrderID, setSelectedOrderID] = useState(null);
  const [showPenality, setShowPenality] = useState(false);
  const [showRefundToWallet, setShowRefundToWallet] = useState(false);
  const [adminStatus, setAdminStatus] = useState("NORMAL");
  const [isUserNew, setIsUserNew] = useState("");
  const [showConfirmStatus, setShowConfirmStatus] = useState("");
  const [previousState, setPreviousState] = useState("");
  const [nextState, setNextState] = useState("");
  const [showRejectedConfirmStatus, setShowRejectedConfirmStatus] = useState(false);
  const [showComment, setShowComment] =  useState(false);
  const [showDaasRetry, setShowDaasRetry] = useState(false);

  useEffect(() => {
    if(data.isNewUser !== undefined && data.isNewUser === true) {
      setIsUserNew("NEWUSER");
    }
    if ((data.orderType === "REGULAR" || data.orderType === "SUBSCRIPTION") && (data.status === "NEW" || data.status === "ACCEPTED" || data.status === "READY TO PICK")) {
    const currentDate = new Date(data.deliveryDate);
    const today = new Date();
    if (currentDate <= today) {
      const currentTime = getCurrentTime();
      const currentIndianTime = currentTime.toLocaleString("en-US", { hour: "numeric", minute: "numeric", hour12: false });

      const beginningTime12hrformat = data.deliverBy.slice(0, 8);
  
      const beginningTime = convert12to24(beginningTime12hrformat);

      const differenceMinutes = calculateTimeDifferenceInMinutes(beginningTime, currentIndianTime);

      const isBeginningTimeBeforeCurrentTime = compareTime(beginningTime, currentIndianTime);

      const deliveryCondition = data.deliveryPartner === undefined || data.deliveryPartner.toUpperCase() === "N/A" || data.deliveryPartner.toUpperCase() === "DELIVERY PARTNER";

        if(isBeginningTimeBeforeCurrentTime && deliveryCondition){
            if (Math.abs(differenceMinutes) >= 30) {
              setAdminStatus("DANGER");
            } else if (Math.abs(differenceMinutes) >= 15) {
              setAdminStatus("WARNING");
            }
        }
        if(isBeginningTimeBeforeCurrentTime && !deliveryCondition && data.status !== "DELIVERED"){
          if (Math.abs(differenceMinutes) >= 30 && Math.abs(differenceMinutes) <= 45) {
            setAdminStatus("WARNING");
          } else if (Math.abs(differenceMinutes) >= 60) {
            setAdminStatus("DANGER");
          }
        }
    }
  }
  }, [data]);

  const AddChefPenality = () => {
    setShowPenality(true);
  };

  const AddRefundToWallet = (data) => {
    setSelectedOrderID(data);
    setShowRefundToWallet(true);
  }

  const handleDismiss = () => {
    setShowDaas(false);
    setShowDaasCancel(false);
    setShowConfirmStatus(false);
    setShowRejectedConfirmStatus(false);
    setShowRefundToWallet(false);
  };

  const cancelDaas = (orderID) => {
    if (
      data.deliveryPartner !== undefined &&
      (data.deliveryPartner.toUpperCase() === "PORTER" ||
        data.deliveryPartner.toUpperCase() === "ADLOGGS" ||
        data.deliveryPartner.toUpperCase() === "DUNZO" ||
        data.deliveryPartner.toUpperCase() === "DAAS")
    ) {
      setSelectedOrderID(orderID);
      setShowDaasCancel(true);
    }
  };

  const daasRetry = (orderID) => {
      setSelectedOrderID(orderID);
      setShowDaasRetry(true);
  };

  const handleCloseDaasRetry = () => {
    setShowDaasRetry(false);
  }

  const confirmDaasRetry = () => {
    let environment = process.env.REACT_APP_NODE_ENV;
    let orderAPI;
    if (environment === "PRODUCTION" || environment === undefined) {
      orderAPI = process.env.REACT_APP_PROD_ORDERAPI;
    } else {
      orderAPI = process.env.REACT_APP_DEV_ORDERAPI;
    }
    let daasRetryAPI = orderAPI + "?orderID=" + selectedOrderID + "&type=DAASRETRY";

    if (data.retryCount) {
      daasRetryAPI += "&retryCount=" + data.retryCount;
    }
    
    GetAPI(daasRetryAPI)
    .then((res) => {
      if(res.data.statusCode === 200) {
        alert("Successfully retried daas booking!!!")
      } else {
        alert("Failed to retry daas booking!!!");
        console.log(res);
      }
    })
    .catch((e) => {
      console.error(e);
      alert("Failed to retry daas booking!!!");
    });
  }

  const handleAccepted = () => {
    if (
      data.chefID === "37061636991593" ||
      data.chefID === "61481667989200" ||
      data.chefID === "74111650460340" ||
      data.chefID === "4021664183938" ||
      data.chefID === "83021664176723" ||
      data.chefID === "41501650373846" ||
      data.chefID === "64171651589341" ||
      data.chefID === "68761676011372" ||
      data.chefID === "1630562241"
    ) {
      setSelectedOrderID(data.orderID);
      setShowChefIncharge(true);
    } else {
      handleStatusClick(data.orderID, "ACCEPTED");
    }
  };

  const handleConfirmPopup = (data, state) => {
    setSelectedOrderID(data.orderID);
    setPreviousState(data.status);
    setNextState(state);
    state.toUpperCase() === "REJECTED" ? setShowRejectedConfirmStatus(true) : setShowConfirmStatus(true);
  };

  const handlePenalityDismiss = () => {
    setShowPenality(false);
  };

  const handleDAAS = (orderID) => {
    setShowDaas(true);
    setSelectedOrderID(orderID);
  };

  const handleConfirmStatusChange = (orderID, status) => {
    handleStatusClick(orderID, status);
  };

  const handleRejectedConfirmStatusChange = (orderID, status, reason) => {
    handleStatusClick(orderID, status, reason, data);
  };

  const handleShowComment = () => {
    setShowComment(true);
  }

  const handleAdminComment = (comment) => {
    setShowComment(false);
    handleComment(data.orderID, comment);
  }

  const handleCommentCancel = () => {
    setShowComment(false);
  }

  const defaultClasses = 'col-xs-12 col-sm-6 col-md-4 col-lg-3 adminCard';
  const specialClasses = isUserNew === "NEWUSER" ? 'isUserNew' : '';
    // Combine classes conditionally
    const combinedClasses = `${defaultClasses} ${specialClasses}`;

  return (
    <div className={combinedClasses} id={adminStatus}>
      {showChefIncharge && (
        <ChefInchargeModal
          title="Select the Chef Incharge"
          show={showChefIncharge}
          handleDismiss={handleDismiss}
          chefID={data.chefID}
          selectedOrderID={selectedOrderID}
          handleChef={handleChef}
        />
      )}
            {showDaasRetry && (
       <DaasRetryPopup show={showDaasRetry} handleDismiss={handleCloseDaasRetry} confirmDaasRetry={confirmDaasRetry} />
      )}
       {showRefundToWallet && (
        <RefundToWalletModal
          title="Refund to Wallet"
          show={showRefundToWallet}
          setShowRefundToWallet={setShowRefundToWallet}
          handleDismiss={handleDismiss}
          selectedOrder={data}
          handleRefund={handleRefund}
        />
      )}
      {showDaas && (
        <DeliveryModal
          title="DAAS Booking Confirmation"
          heading="Are you sure to book this order through daas?"
          selectedOrderID={selectedOrderID}
          handleBookPartner={handleBookDaas}
          show={showDaas}
          handleDismiss={handleDismiss}
          status="BOOK"
        />
      )}
      {showDaasCancel && (
        <DeliveryModal
          title="Daas Cancelling Confirmation"
          heading="Are you sure to cancel this order?"
          selectedOrderID={selectedOrderID}
          handleCancelPartner={handleCancelDaas}
          show={showDaasCancel}
          handleDismiss={handleDismiss}
          status="CANCEL"
        />
      )}
      {showConfirmStatus && (
        <StatusConfirmModal
          PreviousState={previousState}
          NextState={nextState}
          selectedOrderID={selectedOrderID}
          show={showConfirmStatus}
          handleConfirmStatusChange={handleConfirmStatusChange}
          handleDismiss={handleDismiss}
        />
      )}
      {showRejectedConfirmStatus && (
        <StatusConfirmModal
          PreviousState={previousState}
          NextState={nextState}
          selectedOrderID={selectedOrderID}
          show={showRejectedConfirmStatus}
          handleConfirmStatusChange={handleRejectedConfirmStatusChange}
          handleDismiss={handleDismiss}
        />
      )}
      {showPenality && (
        <PenalityModal show={showPenality} handlePenalityDismiss={handlePenalityDismiss} data={data} handleChefPenality={handleChefPenality} />
      )}
      <div className="card">
        <div className="card-body">
          <div className="section">
            <p>#{id}</p>
            <p
              onClick={() => {
                return handleOrder(data.orderID);
              }}
            >
              <span className="adminTitle">Order ID:</span> {data.orderID}
            </p>
          </div>
         
          {data.comment ? <p> <BiCommentDetail size={12} /> {data.comment}</p> : null}
          {showComment ? 
          <ShowCommentPopup handleAdminComment={handleAdminComment} handleCommentCancel={handleCommentCancel}/> : 
          <button type="button" class="btn btn-light btn-sm btn-block my-2" onClick={handleShowComment} >Add Comment</button>
          }
            <div className="newUserSection">
            {data.isNewUser !== undefined && data.isNewUser ? <p> <BsFillEmojiSunglassesFill color="white"/> NEW CUSTOMER</p> : null}
            <div>
            {data.chefIncharge !== undefined && <h6>Chef Incharge: {data.chefIncharge}</h6>}
          </div>
          {data.handledBy !== undefined && <h6 className="my-2">Order Incharge: {data.handledBy}</h6>}
          </div>
          <div className="section">
            <div className="dropdown">
              <button
                className="btn btn-info btn-sm dropdown-toggle"
                type="button"
                id="dropdownMenuButton"
                data-toggle="dropdown"
                aria-expanded="false"
              >
                Status
              </button>
              <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                {status !== "PENDING" && (
                  <a className="dropdown-item" href="#pending-tab" onClick={() => handleConfirmPopup(data, "PENDING")}>
                    Pending
                  </a>
                )}
                {status !== "NEW" && (
                  <a className="dropdown-item" href="#new-tab" onClick={() => handleStatusClick(data.orderID, "NEW")}>
                    New
                  </a>
                )}
                {status !== "ACCEPTED" && (
                  <a className="dropdown-item" href="#accepted-tab" onClick={() => handleAccepted()}>
                    Accepted
                  </a>
                )}
                {status !== "REJECTED" && (
                  <a className="dropdown-item" href="#rejected-tab" onClick={() => handleConfirmPopup(data, "REJECTED")}>
                    Rejected
                  </a>
                )}
                {status !== "READY TO PICK" && (
                  <a className="dropdown-item" href="#readytopick-tab" onClick={() => handleConfirmPopup(data, "READY TO PICK")}>
                    Ready To Pick
                  </a>
                )}
                {status !== "CANCELLED" && (
                  <a className="dropdown-item" href="#readytopick-tab" onClick={() => handleConfirmPopup(data, "CANCELLED")}>
                    Cancelled
                  </a>
                )}
                {status !== "DELIVERED" && (
                  <a className="dropdown-item" href="#delivered-tab" onClick={() => handleConfirmPopup(data, "DELIVERED")}>
                    Delivered
                  </a>
                )}
              </div>
            </div>
            <Link
              to={{
                pathname: "/print",
                state: data,
              }}
            >
              <button className="btn btn-primary btn-sm" type="button" id="printButton">
                Print
              </button>
            </Link>
            <select
              className="form-select form-select-sm"
              aria-label=".form-select-sm example"
              onChange={(event) => handleDeliveryClick(data.orderID, event.target.value)}
            >
              {data.deliveryDetails === "N/A" || (data.deliveryPartner !== undefined) ? (
                <option selected>{data.deliveryPartner !== undefined ? data.deliveryPartner : "N/A"}</option>
              ) : (
                <option selected disabled>
                  {data.deliveryPartner !== undefined ? data.deliveryPartner : "N/A"}
                </option>
              )}
              {data.deliveryDetails === "N/A" || (data.deliveryPartner !== undefined) ? (
                <option value="Homeal Rider">Homeal Rider</option>
              ) : (
                <option value="Homeal Rider" disabled>
                  Homeal Rider
                </option>
              )}
              {data.deliveryDetails === "N/A" ? (
                <option value="Manual Porter">Manual Porter</option>
              ) : (
                <option value="Manual Porter" disabled>
                  Manual Porter
                </option>
              )}
               {data.deliveryDetails === "N/A" ? (
                <option value="Manual Adloggs">Manual Adloggs</option>
              ) : (
                <option value="Manual Adloggs" disabled>
                  Manual Adloggs
                </option>
              )}
              {data.deliveryDetails === "N/A" ? (
                <option value="Manual Dunzo">Manual Dunzo</option>
              ) : (
                <option value="Manual Dunzo" disabled>
                  Manual Dunzo
                </option>
              )}
            </select>
          </div>
          <div className="dataSection">
            <p>
              <SiCodechef size={15} /> {data.chefDetails !== undefined && data.chefDetails.chefName}
            </p>
            <p>
              <FaUserAlt size={10} /> {data.craverDetails !== undefined && data.craverDetails.craverName}
            </p>

            {data.deliveryDetails !== undefined && (
              <p>
                <RiEBike2Line size={12} /> {data.deliveryDetails.deliveryAgentName}
              </p>
            )}
          </div>
          <p>
            <IoMdClock size={15} /> {data.deliverBy}
          </p>
          <p>
          <MdOutlineRestaurantMenu size={15}/> {data.menuType.toUpperCase() === 'READY TO SERVE' ? "Corporate Meals" : data.menuType}
          </p>
          {data.specialCookingInstruction !== "" && (
            <p>
              <GiRiceCooker size={12} />
              {data.specialCookingInstruction}
            </p>
          )}
          <p className="urlSection">
            {data.takeAway ? (
              <div>
                <a href={"tel:" + data.craverDetails.craverMobile} rel="noreferrer">
                  <FiPhoneCall size={12} /> {data.craverDetails.craverMobile}
                </a>
                <span>TAKEAWAY{"   "}</span>
                <span>{data.cost.payableAmount}/-</span>
              </div>
            ) : (
              "DELIVERY"
            )}
          </p>
          <p>
            <GrLocation size={15} />
            {data.craverDetails !== undefined && data.craverDetails.craverAddress}
          </p>
          <p>Order Type {data.orderType !== undefined && data.orderType}</p>
          <p>Distance {data.distance !== undefined && data.distance}</p>
          {(data.status.toUpperCase() === "ACCEPTED" || data.status.toUpperCase() === "READY TO PICK") && !data.takeAway && data.menuType.toUpperCase() != "READY TO SERVE" &&
            (data.deliveryPartner === undefined ||
              (data.deliveryPartner !== undefined && (data.deliveryPartner === "N/A" || data.deliveryPartner === "DAAS"))) && (
              <div className="bookingSection">
                { (data.deliveryStatus.toUpperCase() === "N/A" ||
                  data.deliveryStatus.toUpperCase() === "NOT ASSIGNED"  ||
                data.deliveryStatus.toUpperCase() === "CANCELLED" ||
                data.deliveryStatus.toUpperCase() === "FAILED" ||
                data.deliveryStatus.toUpperCase() === "CANCELLED DAAS BY ADMIN" ) && (
                    <button className="btn btn-light btn-sm my-2" type="button" onClick={() => handleDAAS(data.orderID)}>
                      Book DAAS
                    </button>
                  )}
              </div>
            )}
          {data.deliveryDetails !== undefined &&
          data.deliveryDetails !== "N/A" &&
          (data.deliveryDetails.dunzoOrderStatus === "pickup_complete" ||
            data.deliveryDetails.dunzoOrderStatus === "started_for_delivery" ||
            data.deliveryDetails.dunzoOrderStatus === "reached_for_delivery" ||
            data.deliveryDetails.dunzoOrderStatus === "delivered") ? (
              <>
            <h5>Cannot Cancel Delivery Partner</h5>
            <button className="btn btn-warning btn-sm my-2" type="button" onClick={() => daasRetry(data.orderID)}>
            Daas Retry
          </button>
          </>
          ) : (
            <div>
              {data.deliveryDetails !== "N/A" && data.deliveryPartner !== undefined  ? (
                // CONDITION TO BE REFACTORED
                data.deliveryPartner !== undefined &&
                (data.deliveryStatus.toUpperCase() !== "NOT ASSIGNED" &&
                data.deliveryStatus.toUpperCase() !== "CANCELLED" &&
                data.deliveryStatus.toUpperCase() !== "FAILED" &&
                data.deliveryStatus.toUpperCase() !== "CANCELLED DAAS BY ADMIN" )
                &&
                (data.deliveryPartner.toUpperCase() === "PORTER" || 
                data.deliveryPartner.toUpperCase() === "ADLOGGS" || 
                data.deliveryPartner.toUpperCase() === "DUNZO" || 
                data.deliveryPartner.toUpperCase() === "DAAS") && (
                  <button className="btn btn-warning btn-sm my-2" type="button" onClick={() => cancelDaas(data.orderID)}>
                    Cancel Daas
                  </button>
                )
              ) : null}
            </div>
          )}
          <div>

            {data.tracking_url !== undefined && data.deliveryPartner !== "N/A" && data.deliveryPartner !== "DELIVERY PARTNER" && (
              <div className="urlSection">
                <p>Tracking URL: </p>
                <a href={data.tracking_url} target="_blank" rel="noreferrer">
                 {data.tracking_url}
                </a>
              </div>
            )}
            {data.deliveryDetails !== undefined && data.deliveryDetails !== "N/A" && <p>Delivery Status: {data.deliveryStatus}</p>}
            {data.retryCount !== undefined && data.retryCount !== 0 && <p>Daas Retry Count: {data.retryCount}</p>}
            {data.deliveryStatus !== undefined && data.deliveryStatus.toUpperCase() === "CREATED" && <p>Delivery Status: Looking for Partner</p>}
            {((data.deliveryStatus !== undefined && data.deliveryStatus.toUpperCase() === "FAILED") ||
              (data.deliveryStatus !== undefined && data.deliveryStatus.toUpperCase() === "CANCELLED") ||
              (data.deliveryStatus !== undefined && data.deliveryStatus.toUpperCase() === "CANCELLED DAAS BY ADMIN")) && (
              <div>
                <p className="daasErrorText">Delivery Status:- {data.deliveryStatus}</p>
                <p className="daasErrorText">Book another delivery partner</p>
              </div>
            )}
            {data.deliveryPartner !== undefined &&
            (data.deliveryPartner.toUpperCase() === "N/A" || data.deliveryPartner.toUpperCase() === "DELIVERY PARTNER") &&
            data.dunzoBookingErrorMessage !== undefined ? (
              <p>Error: {data.dunzoBookingErrorMessage}</p>
            ) : null}
          </div>
          {data.refundAmount !== undefined && data.refundReason !== undefined && data.refundType ? (
            <>
              <p>Refunded: {data.refundAmount}</p>
              <p>Refunded Reason: {data.refundReason}</p>
              <p>Refund Type: {data.refundType}</p>
              </>
            ) : null}
          <div className="section">
          {(data.status === "READY TO PICK" || data.status === "DELIVERED") && (
            <div>
              {data.chefpenalityIncharge === undefined ? (
                <button className="btn btn-danger btn-sm" type="button" onClick={() => AddChefPenality(data)}>
                  Add Chef Penality
                </button>
              ) : (
                <p>Chef Penality Incharge: {data.chefpenalityIncharge.name}</p>
              )}
            </div>
          )}
          <button className="btn btn-warning btn-sm" type="button" onClick={() => AddRefundToWallet(data)}>
            Initiate Refund
          </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default adminCard;
