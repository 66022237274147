/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import React, { useState } from "react";
import "./subscriptionCard.css";
import RenderCard from "./RenderCard";
import { IoIosArrowDropdownCircle, IoIosArrowDropupCircle } from "react-icons/io";
import moment from "moment";

const SubscriptionCard = ({
  id,
  data,
  chefs,
  handleAssignChef,
  getChefDetails,
  handleAdminStatus,
  handleInvoice,
  handleConfirmChef,
  handleNotifyChef,
  handleConfirmRenew,
  handleChefAvailability,
}) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [invoiceStartDate, setInvoiceStartDate] = useState(data.orderedItems[0].startDate);
  const [invoiceEndDate, setInvoiceEndDate] = useState(data.orderedItems[0].endDate);
  const [invoiceStartDateError, setInvoiceStartDateError] = useState(false);
  const [invoiceEndDateError, setInvoiceEndDateError] = useState(false);
  const [deliveryCharges, setDeliveryCharges] = useState(data.cost.totalDeliveryCharges !== undefined ? data.cost.totalDeliveryCharges : 0);
  const [discount, setDiscount] = useState(0);
  const [selectedItem, setSelectedItem] = useState(null);
  const [showCard, setShowCard] = useState("");
  let comments;

  const handleStatus = (status) => {
    setShowCard(status);
  };

  const RenderShowCard = ({ showCard }) => {
    return (
      <div>
        <div className="input-group input-group-sm mb-3">
          <div className="input-group-prepend">
            <span className="input-group-text" id="basic-addon1">
              Comments
            </span>
          </div>
          <input
            className="form-control"
            type="text"
            aria-label="Comments"
            aria-describedby="basic-addon1"
            value={comments}
            onChange={(e) => {
              comments = e.target.value;
            }}
          />
        </div>

        <button
          type="button"
          className="btn btn-dark btn-sm"
          onClick={() => {
            handleAdminStatus(showCard, comments, data);
          }}
        >
          Save Admin Status
        </button>
      </div>
    );
  };

  const handleDropDown = () => {
    setShowDropdown(false);
  };

  const handleDropUp = () => {
    setShowDropdown(true);
  };

  const handleOverallInvoice = () => {
    if (invoiceStartDate.length !== 10) {
      setInvoiceStartDateError(true);
      return;
    }
    if (invoiceEndDate.length !== 10) {
      setInvoiceEndDateError(true);
      return;
    }

    let set = new Set([]);

    data.orderedItems.map((item) => {
      set.add({
        itemName: item.itemName,
        itemPrice: item.menuPrice,
        itemQuantity: item.itemQuantity,
      });
    });

    const overallInvoiceData = {
      refID: data.subscriptionID,
      craverName: data.craverDetails.craverName,
      billType: "subscription-order",
      fromDate: invoiceStartDate,
      toDate: invoiceEndDate,
      items: Array.from(set),
      discount: data.cost.promoCode !== "" ? data.cost.promoDiscountAmount.toString() : discount.toString(),
      deliveryCharges: deliveryCharges.toString(),
    };
    handleInvoice(overallInvoiceData);
  };

  return (
    <div className="col-xs-12 col-sm-6 col-md-4 subscriptionCard">
      <div className="card">
        <div className="card-body m-1">
          <div className="section">
            <p>
              <strong>#</strong>
              {id}
            </p>

            {moment(data.orderedItems[0].endDate).format("YYYY-MM-DD") < moment().format("YYYY-MM-DD") && (
              <p>
                <strong>Expired</strong>
              </p>
            )}
            <p>
              <strong>subscription ID:</strong> {data.subscriptionID}
            </p>
            {!showDropdown && <IoIosArrowDropdownCircle size={25} onClick={handleDropUp} />}
            {showDropdown && <IoIosArrowDropupCircle size={25} onClick={handleDropDown} />}
          </div>
          <p>
              <strong>App Version - </strong>
              {data.appVersion}
            </p>
          <div className="btn-group">
            <button type="button" className="btn btn-info btn-sm dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              {showCard !== "" ? showCard : data.adminStatus}
            </button>

            {data.paymentStatus !== undefined && data.paymentStatus === "PENDING" ?
            <div className="dropdown-menu">
            <p className="dropdown-item" onClick={() => handleStatus("New")}>
              New
            </p>
            <p className="dropdown-item" onClick={() => handleStatus("Removed")}>
                Removed
              </p>
            </div>
            :<div className="dropdown-menu">
              <p className="dropdown-item" onClick={() => handleStatus("Chef not Available")}>
                Chef not Available
              </p>
              <p className="dropdown-item" onClick={() => handleStatus("Requested Chef")}>
                Requested Chef
              </p>
              <p className="dropdown-item" onClick={() => handleStatus("Chef Rejected")}>
                Chef Rejected
              </p>
              <p className="dropdown-item" onClick={() => handleStatus("Sent Invoice")}>
                Sent Invoice
              </p>
              <p className="dropdown-item" onClick={() => handleStatus("Updated")}>
                Updated
              </p>
              <p className="dropdown-item" onClick={() => handleStatus("Renew Rejected")}>
                Renew Rejected
              </p>
              <p className="dropdown-item" onClick={() => handleStatus("Removed")}>
                Removed
              </p>
            </div>}
          </div>
          {data.admincomments !== undefined && (
            <p>
              Comments: <strong> {data.admincomments}</strong>
            </p>
          )}
          {showCard === "Pending" && <RenderShowCard showCard={showCard} />}
          {showCard === "New" && <RenderShowCard showCard={showCard} />}
          {showCard === "Chef not Available" && <RenderShowCard showCard={showCard} />}
          {showCard === "Requested Chef" && <RenderShowCard showCard={showCard} />}
          {showCard === "Chef Rejected" && <RenderShowCard showCard={showCard} />}
          {showCard === "Sent Invoice" && <RenderShowCard showCard={showCard} />}
          {showCard === "Updated" && <RenderShowCard showCard={showCard} />}
          {showCard === "Renew Rejected" && <RenderShowCard showCard={showCard} />}
          {showCard === "Removed" && <RenderShowCard showCard={showCard} />}

          <p>
            <strong>Name:</strong> {data.craverDetails.craverName}
          </p>
          <p>
            <strong>Mobile:</strong> {data.craverDetails.mobileNumber}
          </p>
          <p>
            <strong>Address:</strong> {data.craverDetails.address}
          </p>
          <p>
            <strong>Lat:</strong> {data.craverDetails.addressCoordinates.lat}
          </p>
          <p>
            <strong>Lng:</strong> {data.craverDetails.addressCoordinates.lng}
          </p>
          {data.cost.deliveryChargePerMenu !== undefined && (
            <p>
              <strong>DeliveryChargePerMenu:</strong> {data.cost.deliveryChargePerMenu}
            </p>
          )}
          {showDropdown &&
            data.orderedItems.map((item) => (
              <RenderCard
                data={data}
                item={item}
                chefs={chefs}
                handleAssignChef={handleAssignChef}
                getChefDetails={getChefDetails}
                handleInvoice={handleInvoice}
                handleConfirmChef={handleConfirmChef}
                handleNotifyChef={handleNotifyChef}
                handleConfirmRenew={handleConfirmRenew}
                handleChefAvailability={handleChefAvailability}
              />
            ))}
        </div>

        <div className="buttonContainer">
          <button
            className="btn btn-primary"
            type="button"
            data-toggle="collapse"
            data-target="#collapseExample"
            aria-expanded="false"
            aria-controls="collapseExample"
            onClick={() => {
              setSelectedItem(data);
            }}
          >
            Create Overall Invoice
          </button>
        </div>

        {selectedItem !== null && (
          <div className="collapse" id="collapseExample">
            <div className="card card-body">
              <h5>Invoice</h5>
              <div>
                <div className="input-group input-group-sm mb-3">
                  <div className="input-group-prepend">
                    <span className="input-group-text" id="inputGroup-sizing-sm">
                      Start Date
                    </span>
                  </div>
                  <input
                    type="text"
                    value={invoiceStartDate}
                    onChange={(e) => {
                      setInvoiceStartDateError(false);
                      setInvoiceStartDate(e.target.value);
                    }}
                    className="form-control"
                    aria-label="Small"
                    aria-describedby="inputGroup-sizing-sm"
                  />
                </div>
                {invoiceStartDateError && (
                  <div className="alert alert-danger" role="alert">
                    Enter valid Start Date
                  </div>
                )}
                <div className="input-group input-group-sm mb-3">
                  <div className="input-group-prepend">
                    <span className="input-group-text" id="inputGroup-sizing-sm">
                      End Date
                    </span>
                  </div>
                  <input
                    type="text"
                    value={invoiceEndDate}
                    onChange={(e) => {
                      setInvoiceEndDateError(false);
                      setInvoiceEndDate(e.target.value);
                    }}
                    className="form-control"
                    aria-label="Small"
                    aria-describedby="inputGroup-sizing-sm"
                  />
                </div>
                {invoiceEndDateError && (
                  <div className="alert alert-danger" role="alert">
                    Enter valid End Date
                  </div>
                )}
                <div className="input-group input-group-sm mb-3">
                  <div className="input-group-prepend">
                    <span className="input-group-text" id="inputGroup-sizing-sm">
                      Delivery Charge
                    </span>
                  </div>
                  <input
                    type="number"
                    value={deliveryCharges}
                    onChange={(e) => {
                      setDeliveryCharges(e.target.value);
                    }}
                    className="form-control"
                    aria-label="Small"
                    aria-describedby="inputGroup-sizing-sm"
                  />
                </div>
                {data.cost.promoCode !== "" && <h6>Applied Promo Code :- {data.cost.promoCode}</h6>}
                <div className="input-group input-group-sm mb-3">
                  <div className="input-group-prepend">
                    <span className="input-group-text" id="inputGroup-sizing-sm">
                      {data.cost.promoCode !== "" ? "Promo Discount" : "Discount"}
                    </span>
                  </div>
                  {data.cost.promoCode !== "" ? (
                    <input
                      type="number"
                      value={data.cost.promoDiscountAmount}
                      contentEditable={false}
                      className="form-control"
                      aria-label="Small"
                      aria-describedby="inputGroup-sizing-sm"
                    />
                  ) : (
                    <input
                      type="number"
                      value={discount}
                      onChange={(e) => {
                        setDiscount(e.target.value);
                      }}
                      className="form-control"
                      aria-label="Small"
                      aria-describedby="inputGroup-sizing-sm"
                    />
                  )}
                </div>

                <button type="button" className="btn btn-primary" onClick={handleOverallInvoice}>
                  Save changes
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SubscriptionCard;
