import { useContext } from "react";
import OrderContext from "../OrderContext";

const IsMinimumTotal = (isMinimumTotal) => {
  const { totalAmount } = useContext(OrderContext);

  return totalAmount >= isMinimumTotal;
};

export default IsMinimumTotal;
