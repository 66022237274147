import React, { useState, useEffect, memo, useCallback } from "react";
import AdminModal from "../adminModal";
import AdminTabs from "../adminTabs";
import "./corporateOrder.css";
import Select from "react-select";
import { ThreeCircles } from "react-loader-spinner";
import GetAPI from "../../APICalls/GetAPI";
import PostAPI from "../../APICalls/PostAPI";
import moment from "moment-timezone";
import ConfirmModal from "./confirmModal";

const menuTypesData = [
  { value: "Breakfast", label: "Breakfast" },
  { value: "Lunch", label: "Lunch" },
  { value: "Dinner", label: "Dinner" },
];

const today = moment().format("YYYY-MM-DD");

const RenderInput = memo(
  ({ value, setValue, placeholder, type, maxLength, min }) => {
    return (
      <input
        type={type}
        value={value}
        onChange={(e) => setValue(e.target.value)}
        placeholder={placeholder}
        className="form-control my-3"
        maxLength={maxLength}
        min={min}
      />
    );
  }
);

const CorporateOrder = () => {
  const [chefs, setChefs] = useState([]);
  const [menuType, setMenuType] = useState("");
  const [notVerified, setNotVerified] = useState(true);
  const [chefDetails, setChefDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [itemName, setItemName] = useState("");
  const [itemDesc, setItemDesc] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [address, setAddress] = useState("");
  const [lat, setLat] = useState(null);
  const [lng, setLng] = useState(null);
  const [houseNo, setHouseNo] = useState("");
  const [apartmentName, setApartmentName] = useState("");
  const [landmark, setLandmark] = useState("");
  const [slots, setSlots] = useState([]);
  const [deliverySlot, setDeliverySlot] = useState("");
  const [deliveryDate, setDeliveryDate] = useState("");
  const [contactNumber, setContactNumber] = useState(null);
  const [itemQuantity, setItemQuantity] = useState(null);
  const [chefPrice, setChefPrice] = useState(null);
  const [itemPrice, setItemPrice] = useState(null);
  const [deliveryPrice, setDeliveryPrice] = useState(null);
  const [distance, setDistance] = useState(null);
  const [foodGst, setFoodGst] = useState(0);
  const [deliveryGst, setDeliveryGst] = useState(0);
  const [userID, setUserID] = useState(null);
  const [showConfirm, setShowConfirm] = useState(false);
  const [totalPriceValue, setTotalPriceValue] = useState(0);
  const [dataToPost, setDataToPost] = useState({});

  const handleVerified = useCallback((value) => setNotVerified(value), []);
  const handleChefClick = useCallback((value) => setChefDetails(value), []);
  const handleMenutypeClick = useCallback((value) => setMenuType(value), []);
  const handleDayClick = useCallback((value) => setDeliveryDate(value), []);
  const handleSlotClick = useCallback((value) => setDeliverySlot(value), []);

  useEffect(() => {
    const getAdminDetails = localStorage.getItem("AdminDetails");
    if (getAdminDetails) {
      setUserID(JSON.parse(getAdminDetails).userID);
      const mobile = JSON.parse(getAdminDetails).userMobile.includes("+91")
        ? JSON.parse(getAdminDetails).userMobile.slice(3)
        : JSON.parse(getAdminDetails).userMobile;
      setContactNumber(mobile);
    }
  }, []);

  let environment = process.env.REACT_APP_NODE_ENV;
  let fetchChefDetails, utilityApi, orderApi;
  if (environment === "PRODUCTION") {
    fetchChefDetails = process.env.REACT_APP_PROD_USERAPI;
    utilityApi = process.env.REACT_APP_PROD_UTILITY;
    orderApi = process.env.REACT_APP_PROD_ORDERAPI;
  } else {
    fetchChefDetails = process.env.REACT_APP_DEV_USERAPI;
    utilityApi = process.env.REACT_APP_DEV_UTILITY;
    orderApi = process.env.REACT_APP_DEV_ORDERAPI;
  }

  useEffect(() => {
    const getChefDetails = () => {
      let completeDetailsURL = fetchChefDetails + "?chefLevel=Verified";
      GetAPI(completeDetailsURL)
        .then((res) => {
          if (res.data.statusCode === 200) {
            let set = new Set([]);
            res.data.body.forEach((item) => {
              if (item.isVerified) {
                set.add({
                  value: item,
                  label: item.userName + " - " + item.userKitchen,
                });
                setChefs(Array.from(set));
                setLoading(false);
              }
            });
          } else {
            console.log("Error occurred. Error =" + res);
            setLoading(false);
            alert("Failed to get chef details try again!!!");
          }
        })
        .catch((e) => {
          console.log("Error occurred. Error =" + e);
          setLoading(false);
          alert("Failed to get chef details try again!!!");
        });
    };
    getChefDetails();
  }, [fetchChefDetails]);

  useEffect(() => {
    const getMenuTypeTimings = () => {
      let completeUtilityURL = utilityApi + "?type=ALL";
      GetAPI(completeUtilityURL)
        .then((res) => {
          if (res.data.statusCode === 200) {
            const menuSlots = res.data.body[8].value[menuType];
            setFoodGst(res.data.body[0].value);
            setDeliveryGst(res.data.body[4].value);
            let set = new Set([]);
            menuSlots.forEach((item) => {
              set.add({
                value: item.slot,
                label: item.slot,
                isDisabled: moment(deliveryDate).isSame(today, "day")
                  ? item.disabled
                  : false,
              });
            });
            setSlots(Array.from(set));
            setLoading(false);
          } else {
            console.log("Error occurred. Error =" + res);
            setLoading(false);
            alert("Failed to get delivery slots ");
          }
        })
        .catch((e) => {
          console.log("Error occurred. Error =" + e);
          setLoading(false);
          alert("Failed to get delivery slots ");
        });
    };
    if (menuType !== "" && deliveryDate !== "") {
      setLoading(true);
      getMenuTypeTimings();
    }
  }, [menuType, deliveryDate]);

  const isValidPhoneNumber = (number) => /^[0-9]+$/.test(number);

  const getLatLng = (coordinates) => {
    let chefLat, chefLng;

    const regex = /LAT([^]+)LONG([^]+)/;
    const match = coordinates.match(regex);

    if (match) {
      chefLat = match[1];
      chefLng = match[2];
      return { chefLat, chefLng };
    } else {
      console.error("Invalid coordinates format");
      return { chefLat, chefLng };
    }
  };

  const handlePlaceOrder = useCallback(() => {
    if (companyName === "") {
      alert("Please select a company");
      return;
    }
    if (
      contactNumber === null ||
      contactNumber.length < 10 ||
      !isValidPhoneNumber(contactNumber)
    ) {
      alert("Please enter a valid contact number");
      return;
    }
    if (address == "") {
      alert("Please select an address");
      return;
    }
    if (lat == null || lng == null) {
      alert("Please select coordinates");
      return;
    }
    if (houseNo == "" || apartmentName == "" || landmark == "") {
      alert("Please select address details");
      return;
    }
    if (deliveryDate == "") {
      alert("Please select day");
      return;
    }
    if (menuType == "") {
      alert("Please select menuType");
      return;
    }

    if (deliverySlot == "") {
      alert("Please select delivery slot");
      return;
    }
    if (chefDetails === null) {
      alert("Please select a chef");
      return;
    }
    if (itemName === "") {
      alert("Please select item name");
      return;
    }
    if (itemQuantity === null) {
      alert("Enter item quantity");
      return;
    }
    if (chefPrice === null || itemPrice === null || deliveryPrice === null) {
      alert("Enter price details");
      return;
    }

    const chefAddressCoordinates = getLatLng(
      chefDetails.userAddress.coordinates
    );
    const craverAddressCoordinates = {
      lat: lat,
      lng: lng,
    };

    const secondsSinceEpoch = Math.round(Date.now() / 1000);
    const milliseconds = moment().milliseconds();

    const orderedItems = [
      {
        chefPrice: chefPrice,
        description: itemDesc,
        menuID:
          Math.floor(Math.random() * 10000) +
          (secondsSinceEpoch + milliseconds).toString(),
        menuName: itemName,
        menuPrice: itemPrice,
        quantity: itemQuantity,
        itemType: "NonVegetarian"
      },
    ];

    const itemGst = Number(itemPrice) * (foodGst / 100);
    const deliveryGstData = Number(deliveryPrice) * (deliveryGst / 100);
    const gstData = Math.ceil(itemGst + deliveryGstData);

    const totalPrice =
      Number(itemPrice) * Number(itemQuantity) +
      Number(deliveryPrice) +
      gstData;

    const data = {
      chefID: chefDetails.userID,
      orderType: "CORPORATE",
      orderTimeStamp: moment().tz("Asia/Kolkata").format("YYYY-MM-DD HH:mm:ss"),
      status: "NEW",
      deliverBy: deliverySlot + " " + moment(deliveryDate).format("YYYY-MM-DD"),
      orderedItems: orderedItems,
      takeAway: false,
      craverID: userID,
      distance: distance !== null ? distance + " km" : "3 km",
      duration: "10.0 minutes",
      chefDetails: {
        chefName: chefDetails.userName,
        chefAddress: chefDetails.userAddress.address,
        chefAddressCoordinates: {
          lat: chefAddressCoordinates.chefLat,
          lng: chefAddressCoordinates.chefLng,
        },
        chefMobile: chefDetails.userMobile,
        houseNo: chefDetails.userAddress.houseNo,
        apartmentName: chefDetails.userAddress.apartmentName,
        directionsToReach: chefDetails.userAddress.directionsToReach,
        kitchenName: chefDetails.userKitchen,
      },
      craverDetails: {
        craverName: companyName,
        craverAddress: address,
        craverHouseNo: houseNo,
        craverApartmentName: apartmentName,
        craverLandmark: landmark,
        craverMobile: contactNumber,
        craverAddressCoordinates: craverAddressCoordinates,
        addressType: "WORK",
      },
      specialCookingInstruction: "N/A",
      specialDeliveryInstructions: "N/A",
      isHomealKitchen: false,
      cost: {
        foodCharge: Number(itemPrice) * Number(itemQuantity),
        deliveryCharge: Number(deliveryPrice),
        actualChefCharge: Number(chefPrice) * Number(itemQuantity),
        chefCharge: Number(chefPrice) * Number(itemQuantity),
        payableAmount: totalPrice,
        gst: gstData,
        total: totalPrice,
        deductedWalletAmount: 0,
        promoCode: "N/A",
        promoDiscountAmount: 0,
        chefDiscountAmount: 0,
        homealDiscountAmount: 0,
        isHomealPromo: true,
        deliveryGst: deliveryGstData,
        itemGst: itemGst,
      },
      totalAmount: totalPrice,
      menuType: menuType,
      paymentStatus: "PAID",
      appVersion: 320,
      deliveryDate: moment(deliveryDate).format("YYYY-MM-DD"),
      ischefPenality: false,
    };

    setDataToPost(data);
    setTotalPriceValue(totalPrice);
  }, [
    companyName,
    contactNumber,
    address,
    lat,
    lng,
    houseNo,
    apartmentName,
    landmark,
    deliveryDate,
    menuType,
    deliverySlot,
    chefDetails,
    itemName,
    itemQuantity,
    chefPrice,
    itemPrice,
    deliveryPrice,
  ]);

  useEffect(() => {
    if (totalPriceValue > 0) {
      setShowConfirm(true);
    }
  }, [totalPriceValue]);

  const handleClose = () => {
    setShowConfirm(false);
    setTotalPriceValue(0);
  };

  const clearAllFields = () => {
  setMenuType("");
  setItemName("");
  setItemDesc("");
  setCompanyName("");
  setAddress("");
  setLat(0);
  setLng(0);
  setHouseNo("");
  setApartmentName("");
  setLandmark("");
  setSlots([]);
  setMenuType("");
  setDeliverySlot("");
  setDeliveryDate("");
  setItemQuantity(0);
  setChefPrice(0);
  setItemPrice(0);
  setDeliveryPrice(0);
  setDistance(0);
  setTotalPriceValue(0);
  setDataToPost({});
  }

  const handleConfirm = () => {
    setLoading(true);
    PostAPI(orderApi, dataToPost)
      .then((res) => {
        if (res.data.statusCode === 201 || res.data.statusCode === 200) {
          alert("Order placed successfully");
          setLoading(false);
          setShowConfirm(false);
          setTotalPriceValue(0);
        } else {
          setTotalPriceValue(0);
          setLoading(false);
          console.log("error ", res);
          alert("Error in placing order, try again later!!!");
        }
      })
      .catch((e) => {
        setLoading(false);
        setTotalPriceValue(0);
        console.log("error ", e);
        alert("Error in placing order, try again later!!!");
      });
  };

  return (
    <div>
      <AdminTabs />
      {notVerified ? (
        <AdminModal handleVerified={handleVerified} />
      ) : (
        <>
          {loading && (
            <div className="spinnerContainer">
              <div className="spinner">
                <ThreeCircles
                  color="blue"
                  height={110}
                  width={110}
                  ariaLabel="three-circles-rotating"
                />
              </div>
            </div>
          )}

          {showConfirm && (
            <ConfirmModal
              showModal={showConfirm}
              handleClose={handleClose}
              handleConfirm={handleConfirm}
              totalPrice={totalPriceValue}
            />
          )}
          <h3 className=" text-center mt-1">Corporate Order</h3>
          <div className="corporate">
            <div className="dropDetails">
              <RenderInput
                value={companyName}
                setValue={setCompanyName}
                placeholder="Enter Company Name"
                type="text"
              />
              <RenderInput
                value={contactNumber}
                setValue={setContactNumber}
                placeholder="Enter Contact Number"
                maxLength={10}
                type="text"
              />
              <RenderInput
                value={address}
                setValue={setAddress}
                placeholder="Enter Address"
                type="text"
              />
              <div className="corporate">
                <RenderInput
                  value={lat}
                  setValue={setLat}
                  placeholder="Enter Lat"
                  type="number"
                />
                <RenderInput
                  value={lng}
                  setValue={setLng}
                  placeholder="Enter Lng"
                  type="number"
                />
              </div>
              <div className="corporate">
                <RenderInput
                  value={houseNo}
                  setValue={setHouseNo}
                  placeholder="Enter house No"
                  type="text"
                />
                <RenderInput
                  value={apartmentName}
                  setValue={setApartmentName}
                  placeholder="Enter Apartment Name"
                  type="text"
                />
              </div>
              <div className="corporate">
                <RenderInput
                  value={landmark}
                  setValue={setLandmark}
                  placeholder="Enter Landmark"
                  type="text"
                />
                <RenderInput
                  value={deliveryDate}
                  setValue={setDeliveryDate}
                  placeholder="Enter Delivery Date"
                  type="date"
                  min={today}
                />
              </div>
              <div className="corporate">
                <Select
                  className="widthSection"
                  options={menuTypesData}
                  onChange={(e) => handleMenutypeClick(e.value)}
                  placeholder="Select menuType"
                />
                <Select
                  className="widthSection"
                  options={slots}
                  onChange={(e) => handleSlotClick(e.value)}
                  placeholder="Select Slot"
                />
              </div>
            </div>
            <div className="pickupDetails">
              <Select
                className="widthSection"
                options={chefs}
                onChange={(e) => handleChefClick(e.value)}
                placeholder="Search by Username or kitchen Name"
              />
              <div className="corporate">
                <RenderInput
                  value={itemName}
                  setValue={setItemName}
                  placeholder="Enter Item Name"
                  type="text"
                />
                <RenderInput
                  value={itemQuantity}
                  setValue={setItemQuantity}
                  placeholder="Enter Item Quantity"
                  type="text"
                />
              </div>

              <RenderInput
                value={itemDesc}
                setValue={setItemDesc}
                placeholder="Enter Item Description"
                type="text"
              />
              <RenderInput
                value={distance}
                setValue={setDistance}
                placeholder="Enter Distance"
                type="number"
              />
              <div className="corporate">
                <RenderInput
                  value={chefPrice}
                  setValue={setChefPrice}
                  placeholder="Enter Chef Price"
                  type="number"
                />
                <RenderInput
                  value={itemPrice}
                  setValue={setItemPrice}
                  placeholder="Enter Item Price"
                  type="number"
                />
                <RenderInput
                  value={deliveryPrice}
                  setValue={setDeliveryPrice}
                  placeholder="Enter Delivery Price"
                  type="number"
                />
              </div>
            </div>
          </div>
          <div className="corporate justify-content-center m-4">
         
            <button
              type="button"
              className="btn btn-info btn-block"
              onClick={clearAllFields}
            >

              Clear Fields
            </button>
         
       
            <button
              type="button"
              className="btn btn-primary btn-block"
              onClick={handlePlaceOrder}
            >
              Place Order
            </button>
        
          </div>
        </>
      )}
    </div>
  );
};

export default CorporateOrder;
