import axios from "axios";

const GetAPI = async (getapi, customHeader) => {
  const headersValue = {
    "Content-type": "application/json; charset=UTF-8",
    Authorization: "website"
  };

  const headers = customHeader !== undefined ? customHeader : headersValue;

  return axios
    .get(getapi, {
      headers,
    })
    .then((res) => {
      if (res.data.statusCode === 200 || res.data.statusCode === 201 || res.status === 200 || res.status === 201) {
        return res;
      }
    })
    .catch((err) => {
      return err;
    });
};

export default GetAPI;
