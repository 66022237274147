/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState } from "react";
import "../adminCard.css";
import { Modal } from "react-bootstrap";
import { imageSizeCheck } from "../Helpers";

const ProfilePopupModal = ({ data, show, handleUpdateChef, handleDismiss }) => {
  const [profilePicture, setProfilePicture] = useState(
    data.profileImage ? data.profileImage : ""
  );
  const [dragOver, setDragOver] = useState(false);
  const [triedImageUpload,setTriedImageUpload] =useState(false);

  
  

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    
    let processedFile;
    try {
      processedFile = imageSizeCheck(file);
    } catch (error) {
      alert("Error processing image, please try again");
      return;
    }
      if (file) {
        const reader = new FileReader();
        setTriedImageUpload(true);

        reader.onloadend = () => {
          setProfilePicture(reader.result);
        };
        reader.readAsDataURL(file);
      }
    
  };

  const handleDragOver = (e) => {
    setTriedImageUpload(true);
    e.preventDefault();
    setDragOver(true);
  };

  const handleDragLeave = (e) => {
    setTriedImageUpload(true);
    e.preventDefault();
    setDragOver(false);
  };

  const handleDrop = (e) => {
    setTriedImageUpload(true);
    e.preventDefault();
    setDragOver(false);
    const file = e.dataTransfer.files[0];
    let processedFile;
    try {
      processedFile = imageSizeCheck(file);
    } catch (error) {
      alert("Error processing image, please try again");
      return;
    }
      if (file) {
        const reader = new FileReader();
        reader.onloadend = () => {
          setProfilePicture(reader.result);
        };
        reader.readAsDataURL(file);
      }
    
  };

  const handleClickUpload = () => {
    document.getElementById("fileInput").click();
  };
  const handleClose=()=>{
    handleDismiss();
      }

  return (
    <Modal size="lg" show={show} backdrop="static">
      <Modal.Header>
        <Modal.Title>Add Chef Profile Picture</Modal.Title>
        <span onClick={handleClose}>&#10006;</span>
      </Modal.Header>
      <Modal.Body>
        <div className="custom-file-upload">
          <input
            type="file"
            accept="image/*"
            onChange={handleImageChange}
            className="form-control"
            aria-label="Big"
            aria-describedby="inputGroup-sizing-sm"
            id="fileInput"
          />
          <div
            className={`dropzone ${dragOver ? "dragover" : ""}`}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
            onClick={handleClickUpload} // Added onClick for "Click to Upload"
          >
            {profilePicture ? (
              <img
                src={profilePicture}
                alt="Profile"
                className="mt-2 img-fluid"
                style={{ maxHeight: "200px" }}
              />
            ) : (
              <p>
                Drag & Drop or Click to Upload
                <br />
                <small>
                  Please select Image of .png type and it should be less than
                  250kB
                </small>
              </p>
            )}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        {(triedImageUpload==true)? 
        <>
        <button
          type="button"
          className="btn btn-primary"
          onClick={() => {
            handleUpdateChef(profilePicture);
          }}
        >
          Update
        </button>
        <button
          type="button"
          className="btn btn-secondary"
          data-dismiss="modal"
          onClick={() => handleDismiss()}
        >
          Close
        </button>
        </>: null}
        
      </Modal.Footer>
    </Modal>
  );
};

export default ProfilePopupModal;
