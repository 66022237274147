import React, { useEffect, useState, useContext } from "react";
import "./ProceedOrder.css";
import moment from "moment";
import axios from "axios";
import OrderContext from "./OrderContext";
import UserDetailsContext from "./UserDetailsContext";
import ChefDetailsContext from "./ChefDetailsContext";
import ExtractLatLong from "./Utils/ExtractLatLng";
import { setCookie } from "./Utils/Cookies";

const ProceedOrder = () => {
  const { totalOrders, totalAmount, promoCode, discountAmount, gst, grandTotal } = useContext(OrderContext);
  const { userDetails } = useContext(UserDetailsContext);
  const { chefDetails } = useContext(ChefDetailsContext);
  let chefCoordinates = ExtractLatLong(chefDetails.userAddress.coordinates);
  const [currentDate, setCurrentDate] = useState(null);

  useEffect(() => {
    const currentDateValue = moment().format("YYYY-MM-DD");
    setCurrentDate(currentDateValue);
  }, []);
  const placeOrder = () => {
    if (userDetails.name === "") {
      //  setLoading(false);
      alert("Enter name to proceed!!!");
      return;
    }
    const isValidNumber = /^\d{10}$/.test(userDetails.mobileNumber);
    if (!isValidNumber) {
      alert("Enter valid mobile number to proceed!!!");
      return;
    }

    const UserData = {
      name: userDetails.name,
      mobileNumber: userDetails.mobileNumber,
    };

    setCookie("userData", UserData, 365);

    let orderAPI;
    let environment = process.env.REACT_APP_NODE_ENV;
    if (environment === "PRODUCTION") {
      orderAPI = process.env.REACT_APP_PROD_ORDERAPI;
    } else {
      orderAPI = process.env.REACT_APP_DEV_ORDERAPI;
    }
    const headers = {
      "Content-type": "application/json; charset=UTF-8",
      Authorization: "website"
    };

    let totalChefCharge = 0;

    totalOrders.forEach((data) => {
      totalChefCharge += parseInt(data.chefPrice);
    });

    const dataToPost = {
      chefID: chefDetails.userID,
      orderType: "REGULAR",
      orderTimeStamp: moment(),
      status: "NEW",
      deliverBy: "01:30 PM - 02:30 PM " + currentDate,
      orderedItems: totalOrders,
      takeAway: true,
      //   craverID: userID, ---?
      distance: "0 km",
      duration: "2 mins",
      chefDetails: {
        chefName: chefDetails.userName,
        chefAddress: chefDetails.userAddress,
        chefAddressCoordinates: {
          lat: chefCoordinates.latitude,
          lng: chefCoordinates.longitude,
        },
        chefMobile: chefDetails.userMobile,
        houseNo: chefDetails.userAddress.houseNo,
        apartmentName: chefDetails.userAddress.apartmentName,
        directionsToReach: chefDetails.userAddress.directionsToReach,
        kitchenName: chefDetails.userKitchen,
      },
      craverDetails: {
        craverName: userDetails.name,
        craverAddress: "N/A",
        craverHouseNo: "N/A",
        craverApartmentName: "N/A",
        craverLandmark: "N/A",
        craverMobile: "+91".concat(userDetails.mobileNumber),
        craverAddressCoordinates: "N/A",
        addressType: "N/A",
      },
      specialCookingInstruction: "Not Specified",
      specialDeliveryInstructions: "Leave food at doors",
      isHomealKitchen: true,
      cost: {
        foodCharge: totalAmount,
        deliveryCharge: 0,
        actualChefCharge: totalChefCharge,
        chefCharge: totalChefCharge,
        payableAmount: grandTotal,
        gst: gst,
        total: grandTotal,
        deductedWalletAmount: 0,
        promoCode: promoCode,
        promoDiscountAmount: discountAmount,
        isHomealPromo: true,
      },
      totalAmount: grandTotal,
      menuType: "Snacks",
      paymentStatus: "CASH ON DELIVERY",
      appVersion: 176,
      deliveryDate: currentDate,
      ischefPenality: false,
    };


    // axios
    //   .post(`${orderAPI}`, dataToPost, { headers })
    //   .then((response) => {
    //     if (response.data.statusCode === 201 || response.data.statusCode === 200) {
    //       alert("Order placed successfully!!!");
    //     } else {
    //       alert("Failed to place order. Please try again later!!!");
    //       console.log("order placing error ", response.data);
    //     }
    //   })
    //   .catch((error) => {
    //     alert("Failed to place order. Please try again later!!!");
    //     console.log("order placing error ", error);
    //   });
  };
  return (
    <div className="proceed">
      <button
        type="button"
        class="btn btn-success btn-sm btn-block"
        onClick={() => {
          placeOrder();
        }}
      >
        Place Order
      </button>
    </div>
  );
};

export default ProceedOrder;
