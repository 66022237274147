const CheckDate = (effectiveDate, expiryDate) => {
  var currentDate = new Date();

  var effectiveParts = effectiveDate.split("/");
  var expiryParts = expiryDate.split("/");
  var effective = new Date(effectiveParts[2], effectiveParts[1] - 1, effectiveParts[0]);
  var expiry = new Date(expiryParts[2], expiryParts[1] - 1, expiryParts[0]);

  return currentDate >= effective && currentDate <= expiry;
};

export default CheckDate;
