import React, { useState } from "react";

const ShowCommentPopup = ({handleAdminComment, handleCommentCancel}) => {
    const [comment, setComment] = useState("");
    return (
      <div>
        <div className="input-group input-group-sm mb-3">
          <input
            className="form-control"
            type="text"
            aria-label="Comments"
            aria-describedby="basic-addon1"
            value={comment}
            onChange={(e) => {
                setComment(e.target.value);
            }}
            maxLength={50}
          />
        </div>

        <button
          type="button"
          className="btn btn-dark btn-sm"
          onClick={() => {
            handleAdminComment(comment);
          }}
        >
          Save Admin Comment
        </button>
        <button
          type="button"
          className="btn btn-light btn-sm mx-2"
          onClick={() => {
            handleCommentCancel();
          }}
        >
          Cancel
        </button>
      </div>
    );
  };

  export default ShowCommentPopup;