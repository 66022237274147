import React, { useState, useEffect } from "react";
import "./chefNewMenu.css";
import ChefAddMenuModal from "./ChefAddMenuModal";
import GetAPI from "../../APICalls/GetAPI";
import PostAPI from "../../APICalls/PostAPI";
import PutAPI from "../../APICalls/PutAPI";
import { v4 as uuidv4 } from "uuid";
import { ThreeCircles } from "react-loader-spinner";
import ChefDisplayInitialMenus from "./ChefDisplayInitialMenus";
import DeleteInitialMenuPopup from "./DeleteInitialMenuPopup";
import { FaUser } from "react-icons/fa";
import { HiIdentification } from "react-icons/hi";
import { RiHomeHeartFill } from "react-icons/ri";
import { FaPhoneAlt } from "react-icons/fa";

const ChefNewMenu = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const chefID = urlParams.get("chefID");
  const kitchenName = urlParams.get("kitchenName");
  const ChefName = urlParams.get("ChefName");
  const mobileNumber = urlParams.get("mobileNumber");
  const [showAddModal, setShowAddModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [items, setItems] = useState([]);
  const [isAdmin, setIsAdmin] = useState(false);
  const [loading, setLoading] = useState(true);
  const [deleteItemId, setDeleteItemId] = useState(null);
  const [showMenuModal, setShowMenuModal] = useState(false);

  let environment = process.env.REACT_APP_NODE_ENV;

  let newChefMenuAPI;
  if (environment === "PRODUCTION" || environment === undefined) {
    newChefMenuAPI = process.env.REACT_APP_PROD_CHEFNEWMENU;
  } else {
    newChefMenuAPI = process.env.REACT_APP_DEV_CHEFNEWMENU;
  }

  useEffect(() => {
    setLoading(true);
    const getAdminDetails = localStorage.getItem("AdminDetails");
    if (getAdminDetails) {
      setIsAdmin(true);
    }

    const getChefsMenu = () => {
      let completeURL = newChefMenuAPI + "?userID=" + chefID;

      GetAPI(completeURL)
        .then((res) => {
          setLoading(false);
          if (
            res.data.statusCode === 200
          ) {
            if(Object.keys(res.data.body).length > 0){
              let set = new Set([]);
              res.data.body.itemNames.forEach((item) => {
                set.add({
                  id: item.id,
                  itemName: item.itemName,
                  description: item.description,
                  inApp: item.inApp,
                  chefCost: item.chefCost,
                  menuTags: item.menuTags,
                  itemType: item.itemType,
                  menuType: item.menuType,
                  itemForever: item.itemForever,
                  orderBy: item.orderBy,
                  adminStatus: item.adminStatus,
                  adminComments: item.adminComments,
                });
              });
  
              setItems(Array.from(set));
            }
           
          } else {
            console.log("Error occurred. Error =" + res);
            alert("Failed to get chef menu");
          }
        })
        .catch((e) => {
          setLoading(false);
          console.log("Error occurred. Error =" + e);
          alert("Failed to get chef menu");
        });
    };

    getChefsMenu();
  }, []);

  const handleAddMenu = () => {
    setShowAddModal(true);
  };

  const handleAddMenuClose = () => {
    setShowAddModal(false);
  };

  const handleShowMenu = () => {
    setShowMenuModal(true);
  }

  const handleShowMenuClose = () => {
    setShowMenuModal(false);
  }

  const handleCancelDelete = () => {
    setShowDeleteModal(false);
    setDeleteItemId(null);
  }

  const handleSubmit = (itemsArray) => {
    setItems(itemsArray);
    const dataToPost = {
      userID: chefID,
      itemNames: itemsArray,
      chefName: ChefName,
      kitchenName: kitchenName,
      userMobile: mobileNumber,
    };

    PostAPI(newChefMenuAPI, dataToPost)
      .then((res) => {
        alert("Successfully submitted your items");
      })
      .catch((e) => {
        setLoading(false);
        console.log("Error occurred. Please try again. Error =", e);
        alert("Failed to submit your items!!");
      });
  };

  const handleAdminSubmit = () => {
    setLoading(true);
    const dataToPut = {
      userID: chefID,
      itemNames: items,
      chefName: ChefName,
      kitchenName: kitchenName,
      userMobile: mobileNumber,
    };

    PutAPI(newChefMenuAPI, dataToPut)
      .then((res) => {
        alert("Successfully submitted admin status");
        window.location.reload();
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
        console.log("Error occurred. Please try again. Error =", e);
        alert("Failed to submit your admin Status!!");
      });
  };

  const handleDeleteConfirm = () => {
    setLoading(true);

    let itemsData = items;
    itemsData = itemsData.filter((item) => item.id !== deleteItemId)

    const dataToPut = {
      userID: chefID,
      itemNames: itemsData,
      chefName: ChefName,
      kitchenName: kitchenName,
      userMobile: mobileNumber,
    };
    
    PutAPI(newChefMenuAPI, dataToPut)
    .then((res) => {
      alert("Successfully deleted item!!!");
      setItems(items.filter((item) => item.id !== deleteItemId));
      console.log(res);
      setLoading(false);
    })
    .catch((e) => {
      setLoading(false);
      console.log("Error occurred. Please try again. Error =", e);
      alert("Failed to Edit!!");
    });
    setShowDeleteModal(false);
    setDeleteItemId(null);
  };


  const handleEditSave = (editedItem) => {
    setLoading(true);
    let itemsData = items;
    itemsData = itemsData.map((item) =>
        item.id === editedItem.id ? { ...item, ...editedItem} : item
      )

    const dataToPut = {
      userID: chefID,
      itemNames: itemsData,
      chefName: ChefName,
      kitchenName: kitchenName,
      userMobile: mobileNumber,
    };

    console.log("dataToPut: " + JSON.stringify(dataToPut));
    
    PutAPI(newChefMenuAPI, dataToPut)
    .then((res) => {
      alert("Successfully edited the item");
      setItems(itemsData);
      setLoading(false);
    })
    .catch((e) => {
      setLoading(false);
      console.log("Error occurred. Please try again. Error =", e);
      alert("Failed to Edit!!");
    });
  };

  return (
    <div className="chefNewMenu">
      {showAddModal ? (
        <ChefAddMenuModal
          show={showAddModal}
          handleClose={handleAddMenuClose}
          items={items}
          setLoading={setLoading}
          handleSubmit={handleSubmit}
        />
      ) : null}
      {showDeleteModal ?
    <DeleteInitialMenuPopup 
    show={showDeleteModal}
    handleDeleteConfirm={handleDeleteConfirm} 
    handleCancelDelete={handleCancelDelete}
    /> : null  
    }
      {showMenuModal && items ? (
        <ChefDisplayInitialMenus 
        show={showMenuModal}
        handleClose={handleShowMenuClose}
        items={items} 
        setItems={setItems}
        isAdmin={isAdmin}
        setDeleteItemId={setDeleteItemId}
        setShowDeleteModal={setShowDeleteModal}
        handleAdminSubmit={handleAdminSubmit} 
        handleEditSave={handleEditSave}
        />
      ) : null}
      {loading && (
        <div className="spinnerContainer">
          <div className="spinner">
            <ThreeCircles
              color="blue"
              height={110}
              width={110}
              ariaLabel="three-circles-rotating"
            />
          </div>
        </div>
      )}
 <div className="card">
        {isAdmin && (
          <p>
            <HiIdentification size={18} /> {chefID}
          </p>
        )}
        <p>
          <FaUser size={18} color="#EF9A53" /> {ChefName}
        </p>
        <p>
          <RiHomeHeartFill size={22} color="#1C3879" /> {kitchenName}
        </p>
        <p>
          <a href={"tel:" + mobileNumber} rel="noreferrer">
            <FaPhoneAlt size={16} color="#2B7A0B" /> {mobileNumber}
          </a>
        </p>
      </div>
      {!isAdmin ? <button type="button" className="btn btn-primary" onClick={handleAddMenu}>
        Add a Menu
      </button> : null}
      <button type="button" className="btn btn-success" onClick={handleShowMenu}>
        View Submitted Menus
      </button>
    </div>
  );
};

export default ChefNewMenu;
