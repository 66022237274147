import React, { memo } from "react";
import Card from "./Card";
import "./ChefComponent.css";

const ChefComponent = memo(
  ({
    type,
    handleUnVerifyChef,
    handleChefStatus,
    handleVerifyChef,
    chefLevelData,
  }) => {
    const handleChefNewMenu = (data) => {
      window.open(
        `/newChefMenu?chefID=${data.userID}&kitchenName=${data.userKitchen}&ChefName=${data.userName}&mobileNumber=${data.userMobile}`,
        "_blank"
      );
    };

    return (
      <>
        <div className="rowDisplayCards">
          {chefLevelData.length > 0 &&
            chefLevelData.map((data) => (
              <Card
                key={data.userID}
                data={data}
                type={type}
                handleChefNewMenu={handleChefNewMenu}
                handleUnVerifyChef={handleUnVerifyChef}
                handleChefStatus={handleChefStatus}
                handleVerifyChef={handleVerifyChef}
              />
            ))}
        </div>
      </>
    );
  }
);

export default ChefComponent;
