import React, { useContext, useEffect, useState } from "react";
import "./RenderTotalOrders.css";
import OrderContext from "./OrderContext";
import AddQuantity from "./AddQuantity";
import { FaRupeeSign } from "react-icons/fa";

const RenderTotalOrders = () => {
  const { totalOrders } = useContext(OrderContext);

  useEffect(() => {}, [totalOrders]);

  return (
    <div className="RenderTotalOrders">
      <div class="card  my-2">
        <div class="card-body">
          {totalOrders.length > 0 &&
            totalOrders.map((item) => (
              <div className="itemsDisplay">
                <p className="itemName"> {item.menuName}</p>
                <p className="menuPrice">
                  <FaRupeeSign size={12} style={{ marginTop: -4 }} />
                  {item.menuPrice}/-
                </p>
                <p className="cross">X</p>
                <div className="itemQuantity">
                  <AddQuantity item={item} screen="RenderTotalOrders" />
                </div>

                <p>
                  <FaRupeeSign size={12} style={{ marginTop: -4 }} />
                  {item.menuPrice * item.quantity}/-
                </p>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default RenderTotalOrders;
