import React, { useContext } from "react";
import UserDetailsContext from "./UserDetailsContext";
import "./InputField.css";

const InputFields = () => {
  const { userDetails, setUserDetails } = useContext(UserDetailsContext);

  const handleNameChange = (value) => {
    setUserDetails((prevUserDetails) => ({
      ...prevUserDetails,
      name: value,
    }));
  };

  const handleNumberChange = (value) => {
    setUserDetails((prevUserDetails) => ({
      ...prevUserDetails,
      mobileNumber: value,
    }));
  };

  return (
    <div className="inputFields">
      <div class="input-group mb-3">
        <span class="input-group-text" id="Name">
          Name
        </span>
        <input
          type="text"
          value={userDetails.name}
          onChange={(e) => handleNameChange(e.target.value)}
          class="form-control"
          placeholder="Name"
          aria-label="Name"
          aria-describedby="Name"
        />
      </div>
      <div class="input-group mb-3">
        <span class="input-group-text" id="Number">
          Number +91
        </span>
        <input
          type="text"
          value={userDetails.mobileNumber}
          onChange={(e) => handleNumberChange(e.target.value)}
          maxLength={10}
          class="form-control"
          placeholder="Number"
          aria-label="Number"
          aria-describedby="Number"
        />
      </div>
    </div>
  );
};

export default InputFields;
