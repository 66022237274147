import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import "./ConfirmModal.css";
import "./BlogCategories/IndividualBlogs/IndividualBlogs.css";

const ConfirmModal = ({
  blogData,
  blogImage,
  title,
  show,
  handleClose,
  handleSubmit,
  selectedCategoryBlog,
  setSelectedCategoryBlog,
}) => {
  const [thumbnailImage, setThumbnailImage] = useState(blogData ? blogData.thumbNailUrl : "");
  const [dragOver, setDragOver] = useState(false);

  useEffect(() => {
    if(blogData){
      setThumbnailImage(blogData.thumbNailUrl);
    }
    
  },[blogData])

  const handleCheckboxChange = (event) => {
    const { value, checked } = event.target;

    const updatedSelectedCategories = new Set(selectedCategoryBlog);

    if (checked) {
      updatedSelectedCategories.add(value);
    } else {
      updatedSelectedCategories.delete(value);
    }

    const uniqueSelectedCategories = [...updatedSelectedCategories];

    setSelectedCategoryBlog(uniqueSelectedCategories);
  };

  const RenderCategories = ({ category }) => {
    const checkboxId = `checkbox-${category}`; // Unique id for each checkbox

    return (
      <div className="form-check">
        <input
          className="form-check-input"
          type="checkbox"
          value={category}
          id={checkboxId} // Use unique id for each checkbox
          onChange={handleCheckboxChange}
          checked={selectedCategoryBlog.includes(category)} // Check if category is selected
        />
        <label className="form-check-label" htmlFor={checkboxId}>
          {category}
        </label>
      </div>
    );
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setThumbnailImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setDragOver(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    setDragOver(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setDragOver(false);
    const file = e.dataTransfer.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setThumbnailImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleClickUpload = () => {
    document.getElementById("fileInput").click();
  };


  const handleAdd  = () => {
    if(selectedCategoryBlog.length == 0){
      alert("Please select a category to add");
      return;
    }
    if(thumbnailImage == ""){
      alert("Please select a thumbnail picture to add");
      return;
    }
    console.log("selected Category: " + selectedCategoryBlog);
    console.log("selected thumbnail: " + thumbnailImage);

    handleSubmit(thumbnailImage);
  }

  return (
    <div>
      <Modal
        show={show}
        size="lg"
        scrollable={true}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header>
          <Modal.Title>
            {" "}
            <h5>Are you sure you want to {title} blog ?</h5>
          </Modal.Title>
          <p className="cross" onClick={handleClose}>
            {" "}
            &#10060;{" "}
          </p>
        </Modal.Header>
        <Modal.Body>
          <label>
            Select one or more Category/(s) and Add a Thumbnail Image
          </label>
          <div className="renderConfirmationContainer">
            <div className="col-sm-12 col-md-6 col-lg-6">
              <RenderCategories category="Recipe" />
              <RenderCategories category="Homeal" />
              <RenderCategories category="Nutrition" />
              <RenderCategories category="Stories" />
              <RenderCategories category="Health" />
            </div>

            <div className="custom-file-upload">
              <input
                type="file"
                accept="image/*"
                onChange={handleImageChange}
                className="form-control"
                aria-label="Big"
                aria-describedby="inputGroup-sizing-sm"
                id="fileInput"
              />
              <div
                className={`dropzone ${dragOver ? "dragover" : ""}`}
                onDragOver={handleDragOver}
                onDragLeave={handleDragLeave}
                onDrop={handleDrop}
                onClick={handleClickUpload} // Added onClick for "Click to Upload"
              >
                {thumbnailImage ? (
                  <img
                    src={thumbnailImage}
                    alt="Profile"
                    className="mt-2 img-fluid"
                    style={{ maxHeight: "200px" }}
                  />
                ) : (
                  <p>Drag & Drop or Click to Upload</p>
                )}
              </div>
            </div>
          </div>

          <div className="flex gap-2">
            <button className="btn btn-success" onClick={handleAdd}>
              {title}
            </button>
            <button className="btn btn-danger ml-4" onClick={handleClose}>
              Cancel
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default ConfirmModal;
