import imageCompression from "browser-image-compression";

export const getCurrentTime = () => {
  const indian_tz = "Asia/Kolkata";
  const utc_time = new Date();
  const indian_time = new Date(
    utc_time.toLocaleString("en-US", { timeZone: indian_tz })
  );
  return indian_time;
};

export const convert12to24 = (beginningTime) => {
  var parts = beginningTime.split(" ");
  var timePart = parts[0];
  var meridiemPart = parts[1];

  var timeParts = timePart.split(":");
  var hours = parseInt(timeParts[0]);
  var minutes = parseInt(timeParts[1]);

  if (meridiemPart === "PM" && hours !== 12) {
    hours += 12;
  } else if (meridiemPart === "AM" && hours === 12) {
    hours = 0;
  }

  var formattedHours = String(hours).padStart(2, "0");
  var formattedMinutes = String(minutes).padStart(2, "0");

  var result = formattedHours + ":" + formattedMinutes;

  return result;
};

export function calculateTimeDifferenceInMinutes(
  beginningTime,
  currentIndianTime
) {
  var startParts = beginningTime.split(":");
  var endParts = currentIndianTime.split(":");

  var startHours = parseInt(startParts[0]);
  var startMinutes = parseInt(startParts[1]);

  var endHours = parseInt(endParts[0]);
  var endMinutes = parseInt(endParts[1]);

  var totalStartMinutes = startHours * 60 + startMinutes;
  var totalEndMinutes = endHours * 60 + endMinutes;

  var differenceInMinutes = totalStartMinutes - totalEndMinutes;

  return differenceInMinutes;
}
export const getFormattedDate = (date) => {
   if (!(date instanceof Date)) {
    date = new Date(date); 
  }
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();
  return `${year}-${month}-${day}`;
};
export const imageSizeCheck = async (file) => {
  
  const options = {
    maxSizeMB: 0.25,
  };

  if (file.size / 1024 > 250) {
    try {
      const compressedFile = await imageCompression(file, options);
      return compressedFile;
    } catch (error) {
      console.log(error);
      throw error;
    }
  } else {
    return file;
  }
};


export function compareTime(beginningTime, currentIndianTime) {
  const dateX = new Date(`2000/01/01 ${beginningTime}`);
  const dateY = new Date(`2000/01/01 ${currentIndianTime}`);

  if (dateX <= dateY) {
    return true;
  } else {
    return false;
  }
}

export const extractLatLong = (coordinatesString) => {
  if (coordinatesString.includes("LAT") && coordinatesString.includes("LONG")) {
    var latMatch = coordinatesString.match(/LAT(-?\d+\.\d+)/);
    var longMatch = coordinatesString.match(/LONG(-?\d+\.\d+)/);

    if (latMatch && longMatch) {
      var latString = latMatch[1];
      var longString = longMatch[1];

      var latitude = parseFloat(latString);
      var longitude = parseFloat(longString);

      return { latitude: latitude, longitude: longitude };
    } else {
      return null;
    }
  } else {
    return null;
  }
};
