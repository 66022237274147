import React, { useState, useContext, useEffect } from "react";
import GetAPI from "../../../APICalls/GetAPI";
import PostAPI from "../../../APICalls/PostAPI";
import PutAPI from "../../../APICalls/PutAPI";
import Select from "react-select";
import SubscriptionContext from "../context/subscriptionContext";
import { ThreeCircles } from "react-loader-spinner";
import ConfirmPopupModal from "../../../Admin/ChefVerification/ConfirmPopupModal";
import { handleAssignChef } from "../helpers";

const customStyles = {
  option: (base, {isDisabled}) => {
  return {
    ...base,
    backgroundColor: isDisabled ? "#E8E8E8" : "white",
    color: "black"
  };
}
};

const AssignChef = ({item}) => {
    const { subscriptionData } = useContext(SubscriptionContext);
    const [selectedChef, setSelectedChef] = useState({});  
    const [distance, setDistance] = useState(null);
    const [itemStartDate, setItemStartDate] = useState(item.startDate);
    const [itemEndDate, setItemEndDate] = useState(item.endDate);
    const [itemStartDateError, setItemStartDateError] = useState(false);
    const [itemEndDateError, setItemEndDateError] = useState(false);
    const [chefPrice, setChefPrice] = useState(0);
    const [chefChargeError, setChefChargeError] = useState(false);
    const [chefs, setChefs] = useState([]);  
    const [loading, setLoading] = useState(false);
    const [notifyChefConfirmPopup, setNotifyChefConfirmPopup] = useState(false);
    const [assignChefConfirmPopup, setAssignChefConfirmPopup] = useState(false);
   
  
   
    const handleChefClick = (itemData) => {
        let distance = itemData.label.split("-")[1].trim();
        let environment = process.env.REACT_APP_NODE_ENV;
        let userAPI;
    
         if (environment === "PRODUCTION" || environment === undefined) {
          userAPI = process.env.REACT_APP_PROD_USERAPI;
        } else {
          userAPI = process.env.REACT_APP_DEV_USERAPI;
        }
    
        let completeUserURL = userAPI + "?userID=" + itemData.value;
    
        GetAPI(completeUserURL)
          .then((res) => {

            setSelectedChef(res.data.body);
            setDistance(distance);
          })
          .catch((e) => {
            console.error(e);
            alert("Unable to get user details");
          });
      };

      const handleNotify = () => {
        if (chefPrice === 0 || chefPrice === null || chefPrice === undefined) {
          setChefChargeError(true);
          return;
        }
        if (itemStartDate === null || itemStartDate === "" || itemStartDate === undefined) {
          setItemStartDateError(true);
          return;
        }
        if (itemEndDate === null || itemEndDate === "" || itemEndDate === undefined) {
          setItemEndDateError(true);
          return;
        }
        setNotifyChefConfirmPopup(true);
      };

      const handleDismiss = () => {
        setAssignChefConfirmPopup(false);
        setNotifyChefConfirmPopup(false);
      };

      const handleNotifyConfirm = (assignConfirm) => {
        let notifyChefAPI;
        const headers = {
          "Content-Type": "application/json",
          Token: `${process.env.REACT_APP_WASSENGER_TOKEN}`,
        };
        let environment = process.env.REACT_APP_NODE_ENV;
        if (environment === "PRODUCTION" || environment === undefined) {
          notifyChefAPI = process.env.REACT_APP_PROD_NOTIFYCHEF;
        } else {
          notifyChefAPI = process.env.REACT_APP_PROD_NOTIFYCHEF;
        }
    
        let orderText = "";
        if(assignConfirm){      
          switch (item.menuType) {
            case "BREAKFAST":
              orderText = "⏰ 06:00 AM for Breakfast"
              break;
            case "LUNCH":
              orderText = "⏰ 10:00 AM for Lunch"
              break;
            default:
              orderText = "⏰ 06:00 PM for Dinner"
              break;
          }
        }
       
    
        const message = assignConfirm
          ? `Hello Chef 👋 \nYour subscription has been **confirmed** 🤩\n CraverName- ${subscriptionData.craverDetails.craverName}\nSubscriptionID- ${subscriptionData.subscriptionID} \nPlease start preparing once order gets created \nOrders will be created at\n${orderText}\n **Please share 1 week menu so we can send to customer** `
          : `Hello Chef 👋 \nYou have a New Subscription🤩 \nSubscriptionID: ${subscriptionData.subscriptionID}\nMenu Type: ${
              item.menuType
            } \nMenu Style: ${item.menuStyle} \nCraver Name : ${subscriptionData.craverDetails.craverName} \nItem Name : ${item.itemName
            }\n*Quantity* : ${item.itemQuantity}\nDescription :  ${item.description}\nStart date : ${
              itemStartDate
            }\nEnd date : ${itemEndDate}\nSubscription Type : ${item.subscriptionType}\nDelivery time : ${
              item.deliverBy.split(" - ")[0]
            }\nChef Cost : ${
              chefPrice
            }/-\n*Note:-* \n > Sunday orders will not be there 🚫 \n > Only when the payment 💰 is done by the customer we will reconfirm your subscription, please start preparing after that. \nReply *Yes*  if you are ready to take this👍`;
        const chefDataToPost = {
          phone: assignConfirm ? selectedChef.userMobile : selectedChef.userMobile,
          message: message,
        };
    
        PostAPI(notifyChefAPI, chefDataToPost, headers)
          .then((res) => {
            if (res.data.deliveryStatus.toUpperCase() === "QUEUED") {
              setNotifyChefConfirmPopup(false);
              alert("your chef message is queued, will be delivered soon!!!");
            } else {
              setNotifyChefConfirmPopup(false);
              alert("your chef message has been delivered successfully!!!");
            }
          })
          .catch((e) => {
            console.log("Error occurred. Please try again. Error =", e);
            alert("Unable Notify chef. Please try again later!!!");
          });
    
          if(assignConfirm){
            const craverDataToPost =  {
              phone: subscriptionData.craverDetails.mobileNumber,
              message: `Hello ${subscriptionData.craverDetails.craverName},\nCongratulations 🥳, \nFor your subscription ID ${subscriptionData.subscriptionID}, \nhome chef has been assigned. \nHomechef is happy to serve you 😊, have healthy home food everyday `
            }
          PostAPI(notifyChefAPI, craverDataToPost, headers)
          .then((res) => {
            if (res.data.deliveryStatus.toUpperCase() === "QUEUED") {
              alert("your craver message is queued, will be delivered soon!!!");
              window.location.reload();
            } else {
              setNotifyChefConfirmPopup(false);
              alert("your craver message has been delivered successfully!!!");
              window.location.reload();
            }
          })
          .catch((e) => {
            console.log("Error occurred. Please try again. Error =", e);
            alert("Unable Notify craver. Please try again later!!!");
          });
        }
      };

      const handleSaveChef = () => {
        if (chefPrice === 0) {
          setChefChargeError(true);
          return;
        }
        setAssignChefConfirmPopup(true);
      };   

      const handleChefConfirm = () => {
        let subscriptionOrderAPI;
        let environment = process.env.REACT_APP_NODE_ENV;
        if (environment === "PRODUCTION" || environment === undefined) {
          subscriptionOrderAPI = process.env.REACT_APP_PROD_SUBSCRIPTION_ORDER_API;
        } else {
          subscriptionOrderAPI = process.env.REACT_APP_DEV_SUBSCRIPTION_ORDER_API;
        }
    
        const dataToPut = {
          subscriptionID: subscriptionData.subscriptionID,
          menuID: item.menuID,
          chefName: selectedChef.userName,
          chefID: selectedChef.userID,
          chefCharge: parseFloat(chefPrice),
          kitchenName: selectedChef.userKitchen,
          itemPrice: item.itemPrice,
          subscriptionType: item.subscriptionType,
          subscriptionStatus: item.subscriptionStatus,
          weekendFlag: subscriptionData.weekendFlag,
          distance: distance,
          deliverBy: item.deliverBy,
          menuPrice: item.menuPrice,
          itemQuantity: item.itemQuantity,
          specialCookingInstruction: item.specialCookingInstruction,
        };
    
        PutAPI(subscriptionOrderAPI, dataToPut)
          .then((res) => {
            handleNotifyConfirm(true);
          })
          .catch((e) => {
            console.log("Error occurred. Please try again. Error =", e);
            alert("Unable assign chef. Please try again later!!!");
          });
      };

      useEffect(() => {},[selectedChef,chefs]);

      useEffect(() => {
        const handleShowChefs = () => {
          setLoading(true);
          const getChefsByDistance = async () => {
            try {
              const response = await handleAssignChef(subscriptionData);
              setChefs(response)
            } catch (error) {
              console.error("Error in chefs by distance: ", error);
            } finally {
              setLoading(false);
            }
          };
          getChefsByDistance();
        }
        handleShowChefs()
      },[]);

  return (
    <div className="assign">
      {loading && (
        <div className="spinnerContainer">
          <div className="spinner">
            <ThreeCircles color="blue" height={110} width={110} ariaLabel="three-circles-rotating" />
          </div>
        </div>
      )}
        {notifyChefConfirmPopup && (
            <ConfirmPopupModal
              heading={"Are you sure you want to notify " + selectedChef.userName}
              show={notifyChefConfirmPopup}
              handleConfirmPopup={handleNotifyConfirm}
              handleDismiss={handleDismiss}
              buttonText={"Notify " + selectedChef.userName}
              hideBody={true}
            />
          )}
             {assignChefConfirmPopup && (
            <ConfirmPopupModal
              heading={"Are you sure you want to assign " + selectedChef.userName}
              show={assignChefConfirmPopup}
              handleConfirmPopup={handleChefConfirm}
              handleDismiss={handleDismiss}
              buttonText={"Assign " + selectedChef.userName}
              hideBody={true}
            />
          )}
      <button class="btn btn-light" data-toggle="collapse" href="#collapseExample" role="button" aria-expanded="false" aria-controls="collapseExample" aria-pressed="false">Assign Chef</button>
    <div class="collapse" id="collapseExample">
    <div className="dropdownSection">
          <div className="card card-body">
            <div>
              <Select styles={customStyles} options={chefs} onChange={handleChefClick} />
              {selectedChef !== null && (
                <div>
                  <div className="input-group input-group-sm my-3">
                    <div className="input-group-prepend">
                      <span className="input-group-text" id="inputGroup-sizing-sm">
                        Chef Number
                      </span>
                    </div>
                    <input
                      type="text"
                      value={selectedChef.userMobile}
                      editable={false}
                      className="form-control"
                      aria-label="Small"
                      aria-describedby="inputGroup-sizing-sm"
                    />
                  </div>
                  <div className="input-group input-group-sm mb-3">
                    <div className="input-group-prepend">
                      <span className="input-group-text" id="inputGroup-sizing-sm">
                        Start Date
                      </span>
                    </div>
                    <input
                      type="text"
                      value={itemStartDate}
                      onChange={(e) => {
                        setItemStartDate(e.target.value);
                      }}
                      className="form-control"
                      aria-label="Small"
                      aria-describedby="inputGroup-sizing-sm"
                    />
                  </div>
                  <div className="input-group input-group-sm mb-3">
                    <div className="input-group-prepend">
                      <span className="input-group-text" id="inputGroup-sizing-sm">
                        End Date
                      </span>
                    </div>
                    <input
                      type="text"
                      value={itemEndDate}
                      onChange={(e) => {
                        setItemEndDate(e.target.value);
                      }}
                      className="form-control"
                      aria-label="Small"
                      aria-describedby="inputGroup-sizing-sm"
                    />
                  </div>
                  {itemStartDateError && (
                  <div className="alert alert-danger" role="alert">
                    Invalid Start Date
                  </div>
                )}
                {itemEndDateError && (
                  <div className="alert alert-danger" role="alert">
                    Invalid End Date
                  </div>
                )}

                  <div className="input-group input-group-sm mb-3">
                    <div className="input-group-prepend">
                      <span className="input-group-text" id="inputGroup-sizing-sm">
                        Chef Price
                      </span>
                    </div>
                    <input
                      type="number"
                      value={chefPrice}
                      onChange={(e) => {
                        setChefChargeError(false);
                        setChefPrice(e.target.value);
                      }}
                      className="form-control"
                      aria-label="Small"
                      aria-describedby="inputGroup-sizing-sm"
                    />
                  </div>
                  {chefChargeError && (
                    <div className="alert alert-danger" role="alert">
                      Enter Chef Charge
                    </div>
                  )}
                </div>
              )}
              <div className="titleSection">
                <button type="button" className="btn btn-primary" onClick={handleNotify}>
                  Notify Chef
                </button>
                <button type="button" className="btn btn-primary" onClick={handleSaveChef}>
                  Save changes
                </button>
              </div>
            </div>
          </div>
      </div>
    </div>
    </div>
  )
};

export default AssignChef;
