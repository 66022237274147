/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState } from "react";
import "./adminCard.css";
import { Modal } from "react-bootstrap";

const PenalityModal = ({ data, show, handlePenalityDismiss, handleChefPenality }) => {
  const [penalityAmt, setPenalityAmt] = useState(0);
  const [penalityReason, setPenalityReason] = useState("");

  const handlePenality = () => {
    if (penalityAmt === 0) {
      alert("Add chef penality amt to proceed");
      return;
    }
    if (penalityReason === "") {
      alert("Add penality reason to proceed!!!");
      return;
    }
    handleChefPenality(data, penalityAmt, penalityReason);
  };

  return (
    <Modal show={show} backdrop="static">
      <Modal.Header>
        <Modal.Title>Penality to chef {data.chefDetails.chefName}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <ul className="list-group list-group-flush">
            <li className="list-group-item active">Ordered Items</li>

            <div className="itemHeadingContainer">
              <p className="itemNameHeading">
                <strong>Item Name</strong>
              </p>
              <p>
                <strong>Chef Price</strong>
              </p>
              <p>
                <strong>Qty</strong>
              </p>
            </div>
            {data.orderedItems.map((item) => (
              <div>
                <div className="itemHeadingContainer">
                  <p className="itemNameHeading">{item.menuName}</p>
                  <p>{item.chefPrice}/-</p>
                  <p>{item.quantity}</p>
                </div>
                <p className="chefItemDescription">{item.description}</p>
              </div>
            ))}
          </ul>
          {data.cost.actualChefCharge !== data.cost.chefCharge ? (
            <div className="chefCharges">
              <p>
                <strong>Total Chef Price:- </strong>
                {data.cost.actualChefCharge}/-
              </p>
              <p>
                <strong>Discounted Chef Price:- </strong>
                {data.cost.chefCharge}/-
              </p>
            </div>
          ) : (
            <p>
              <strong>Total Chef Price:- </strong>
              {data.cost.chefCharge}/-
            </p>
          )}
          <div className="penalityAmt">
            <div className="input-group  input-group-sm  mb-3">
              <div className="input-group-prepend">
                <span className="input-group-text" id="amt">
                  Penality Amt
                </span>
              </div>
              <input
                type="number"
                value={penalityAmt}
                onChange={(e) => setPenalityAmt(e.target.value)}
                className="form-control"
                placeholder="Enter penality amt"
                aria-label="amt"
                aria-describedby="amt"
              />
            </div>
            <p>
              <strong>Updated Chef Price: </strong>
              {data.cost.chefCharge - penalityAmt}/-
            </p>
          </div>
          <div className="input-group  input-group-sm  mb-3">
            <div className="input-group-prepend">
              <span className="input-group-text" id="amt">
                Reason
              </span>
            </div>
            <input
              type="text"
              value={penalityReason}
              onChange={(e) => setPenalityReason(e.target.value)}
              className="form-control"
              placeholder="Enter reason for penality"
              aria-label="amt"
              aria-describedby="amt"
            />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className="btn btn-primary"
          onClick={() => {
            handlePenality();
          }}
        >
          Add Penality
        </button>

        <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={() => handlePenalityDismiss()}>
          Close
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default PenalityModal;
