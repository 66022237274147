/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/rules-of-hooks */
import React from "react";
import "../adminCard.css";
import { Modal } from "react-bootstrap";

const ChefEmiHistoryPopup = ({ data, show, chefName, handleDismiss }) => {
  const dataValue = JSON.parse(data);
  return (
    <Modal  size="lg" show={show} backdrop="static">
      <Modal.Header>
        <Modal.Title>{chefName} Emi details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <ul className="emiSection">
            <li><h6>Sl. no.</h6></li>
            <li><h6>Deducted Amount</h6></li>
            <li><h6>Deducted On</h6></li>
          </ul>
        {dataValue.Items.length > 0 ? 
        dataValue.Items.map((item, index) => {
          return(
          <ul className="emiSection">
            <li>#{index+1}</li>
            <li>{item.deductedAmount}/-</li>
            <li>{item.lastUpdatedTimeStamp}</li>
          </ul>
          )
        })
        :null}
      </Modal.Body>
      <Modal.Footer>
        <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={() => handleDismiss()}>
          Close
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default ChefEmiHistoryPopup;
