import React from "react";
import "./adminTabs.css";


function AdminTabs() {
  return (

    <div className="adminTab">
      <div>
        <div className="row row-section adminTab">
          <nav className="navbar navbar-expand-lg navbar-light bg-light py-0">
            <a className="navbar-brand" href="/admin">
              Admin Panel
            </a>
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>

            <div className="collapse navbar-collapse" id="navbarSupportedContent">
              <ul className="navbar-nav mr-auto">
                <li className="nav-item">
                  <a className="nav-link" href="/orderStatus">
                    Orders
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/sub">
                    Subscriptions2.0
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/subscription">
                    Subscriptions
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/metrics">
                    Metrics
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/finance">
                    Finance
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/corporateOrder">
                    Corporate Order
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/chefDetails">
                    Chefs Availability
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/chefVerification">
                    Chef Verification
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/referralGenerate">
                    Referral
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/pushNotification">
                    Push Notification
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/wallet">
                    Wallet
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/blogsAdd">
                    Add Blog
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/blogsEdit">
                    Edit Blog
                  </a>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </div>
    </div>

  );
}
export default AdminTabs;
