import React, { useState, useEffect } from "react";
import "./subscriptionCard.css";
import "./renderCard.css";
import moment from "moment";
import Select from "react-select";
import GetAPI from "../../APICalls/GetAPI";

const customStyles = {
  option: (base, {isDisabled}) => {
  return {
    ...base,
    backgroundColor: isDisabled ? "#E8E8E8" : "white",
    color: "black"
  };
}
};


const RenderCard = ({
  data,
  item,
  chefs,
  handleAssignChef,
  handleInvoice,
  handleConfirmChef,
  handleNotifyChef,
  handleConfirmRenew,
  handleChefAvailability,
}) => {
  const [chefPrice, setChefPrice] = useState(0);
  const [chefChargeError, setChefChargeError] = useState(false);
  const [itemStartDateError, setItemStartDateError] = useState(false);
  const [itemEndDateError, setItemEndDateError] = useState(false);
  const [renewError, setRenewError] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [customStartDate, setCustomStartDate] = useState(new Date());
  const [customEndDate, setCustomEndDate] = useState(new Date());
  const [custom, setCustom] = useState(null);
  const [selectedInvoiceItem, setSelectedInvoiceItem] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedRenewItem, setSelectedRenewItem] = useState(null);
  const [itemStartDate, setItemStartDate] = useState(null);
  const [itemEndDate, setItemEndDate] = useState(null);
  const [subscriptionType, setSubscriptionType] = useState(null);
  const [selectedItemQuantity, setSelectedItemQuantity] = useState(0);
  const [selectedChef, setSelectedChef] = useState(null);
  const [distance, setDistance] = useState("");
  const [selectedInvoiceStartDate, setInvoiceStartDate] = useState(data.orderedItems[0].startDate);
  const [selectedInvoiceEndDate, setInvoiceEndDate] = useState(data.orderedItems[0].endDate);
  const [invoiceStartDateError, setInvoiceStartDateError] = useState(false);
  const [invoiceEndDateError, setInvoiceEndDateError] = useState(false);
  const [deliveryCharges, setDeliveryCharges] = useState(0);
  const [discount, setDiscount] = useState(0);

  const handleWeekdayRenew = (itemValue) => {
    setSelectedItemQuantity(item.itemQuantity);
    setSubscriptionType(itemValue);
    let startDate, startDay, startDayValue;
    const todayDate = moment().format("YYYY-MM-DD");
    startDate = moment(todayDate, "YYYY-MM-DD").add(1, "days").format("YYYY-MM-DD");
    startDay = moment(startDate).format("dddd");
    startDayValue = new Date(startDate).getDay();
    while (startDayValue > 5 || startDayValue === 0) {
      startDate = moment(startDate, "YYYY-MM-DD").add(1, "days").format("YYYY-MM-DD"); // Extend till it is non weekend
      startDayValue = new Date(startDate).getDay();
    }
    startDay = moment(startDate).format("dddd");
    let weeklyEndDate = moment(startDate, "YYYY-MM-DD").add(4, "days").format("YYYY-MM-DD");
    let monthlyEndDate = moment(startDate, "YYYY-MM-DD").add(25, "days").format("YYYY-MM-DD");

    switch (itemValue.toUpperCase()) {
      case "WEEKLY":
        setStartDate(startDate);
        if (startDay.toUpperCase() === "MONDAY") {
          setEndDate(weeklyEndDate);
        } else {
          weeklyEndDate = moment(weeklyEndDate, "YYYY-MM-DD").add(2, "days").format("YYYY-MM-DD");
          setEndDate(weeklyEndDate);
        }
        setCustom(null);
        break;
      case "MONTHLY":
        setStartDate(startDate);
        if (startDay.toUpperCase() === "MONDAY") {
          setEndDate(monthlyEndDate);
        } else {
          monthlyEndDate = moment(monthlyEndDate, "YYYY-MM-DD").add(2, "days").format("YYYY-MM-DD");
          setEndDate(monthlyEndDate);
        }
        setCustom(null);
        break;
      default:
        setStartDate(null);
        setEndDate(null);
        setCustom("CUSTOM");
        break;
    }
  };

  const handleWeekendRenew = (itemValue) => {
    setSelectedItemQuantity(item.itemQuantity);
    setSubscriptionType(itemValue);
    const today = moment().format("YYYY-MM-DD");
    const startDate = moment(today, "YYYY-MM-DD").add(1, "days").format("YYYY-MM-DD");
    const weeklyEndDate = moment(startDate, "YYYY-MM-DD").add(6, "days").format("YYYY-MM-DD");
    const monthlyEndDate = moment(startDate, "YYYY-MM-DD").add(27, "days").format("YYYY-MM-DD");

    switch (itemValue.toUpperCase()) {
      case "WEEKLY":
        setStartDate(startDate);
        setEndDate(weeklyEndDate);
        setCustom(null);
        break;
      case "MONTHLY":
        setStartDate(startDate);
        setEndDate(monthlyEndDate);
        setCustom(null);
        break;
      default:
        setStartDate(null);
        setEndDate(null);
        setCustom("CUSTOM");
        break;
    }
  };

  const handleRenew = (itemValue) => {
    if (data.weekendFlag) {
      handleWeekendRenew(itemValue);
    } else {
      handleWeekdayRenew(itemValue);
    }
  };

  const onChangeStartDate = (date) => {
    setCustomStartDate(moment(date).format("YYYY-MM-DD"));
  };

  const onChangeEndDate = (date) => {
    setCustomEndDate(moment(date).format("YYYY-MM-DD"));
  };

  useEffect(() => {}, [customEndDate, customStartDate]);

  const handleChefClick = (item) => {
    let distance = item.label.split("-")[1].trim();
    let environment = process.env.REACT_APP_NODE_ENV;
    let userAPI;

    if (environment === "PRODUCTION" || environment === undefined) {
      userAPI = process.env.REACT_APP_PROD_USERAPI;
    } else {
      userAPI = process.env.REACT_APP_DEV_USERAPI;
    }

    let completeUserURL = userAPI + "?userID=" + item.value;

    GetAPI(completeUserURL)
      .then((res) => {
        setSelectedChef(res.data.body);
        setDistance(distance);
      })
      .catch((e) => {
        console.error(e);
        alert("Unable to get user details");
      });
  };

  const handleNotify = () => {
    if (chefPrice === 0 || chefPrice === null || chefPrice === undefined) {
      setChefChargeError(true);
      return;
    }
    if (itemStartDate === null || itemStartDate === "" || itemStartDate === undefined) {
      setItemStartDateError(true);
      return;
    }
    if (itemEndDate === null || itemEndDate === "" || itemEndDate === undefined) {
      setItemEndDateError(true);
      return;
    }
    handleNotifyChef(data, item, selectedChef, itemStartDate, itemEndDate, chefPrice);
  };

  const handleSaveChef = () => {
    if (chefPrice === 0) {
      setChefChargeError(true);
      return;
    }
    handleConfirmChef(data, selectedItem, selectedChef, chefPrice, distance);
  };

  const handleRenewChanges = () => {
    if (item.chefID === undefined) {
      setRenewError(true);
      return;
    }
    const startDay = custom !== null && custom.toUpperCase() === "CUSTOM" ? moment(customStartDate).format("dddd") : moment(startDate).format("dddd");
    const endDay = custom !== null && custom.toUpperCase() === "CUSTOM" ? moment(customEndDate).format("dddd") : moment(endDate).format("dddd");
    if (
      startDay.toUpperCase() === "SUNDAY" ||
      endDay.toUpperCase() === "SUNDAY"
    ) {
      alert("Start or End day cannot be sunday. Renew manually");
      return;
    }
    const startDateValue = custom !== null && custom.toUpperCase() === "CUSTOM" ? customStartDate : startDate;
    const endDateValue = custom !== null && custom.toUpperCase() === "CUSTOM" ? customEndDate : endDate;
    handleConfirmRenew(data, item, chefPrice, startDateValue, endDateValue, selectedItemQuantity, subscriptionType, custom);
  };

  useEffect(() => {
    if (selectedItem !== null) {
      setItemStartDate(selectedItem.startDate);
      setItemEndDate(selectedItem.endDate);
    }
  }, [selectedItem]);

  const handleSingleInvoice = () => {
    if (selectedInvoiceStartDate.length !== 10) {
      setInvoiceStartDateError(true);
      return;
    }
    if (selectedInvoiceEndDate.length !== 10) {
      setInvoiceEndDateError(true);
      return;
    }
    let set = new Set([]);
    set.add({
      itemName: selectedInvoiceItem.itemName,
      itemPrice: selectedInvoiceItem.menuPrice,
      itemQuantity: selectedInvoiceItem.itemQuantity,
    });

    const selectedInvoiceData = {
      refID: data.subscriptionID,
      craverName: data.craverDetails.craverName,
      billType: "subscription-order",
      fromDate: selectedInvoiceStartDate,
      toDate: selectedInvoiceEndDate,
      items: Array.from(set),
      discount: selectedInvoiceItem.promoCode !== "" ? selectedInvoiceItem.promoDiscountAmount.toString() : discount.toString(),
      deliveryCharges: selectedInvoiceItem.subscriptionType.toUpperCase() === "MONTHLY" ? 400 : 100,
    };
    handleInvoice(selectedInvoiceData);
  };

  const handleCreateInvoice = () => {
    let deliveryCharges = item.deliveryCharges !== undefined ? item.deliveryCharges : 0;
    setDeliveryCharges(deliveryCharges);
    setSelectedInvoiceItem(item);
  };  

  return (
    <div className="card text-white bg-light mb-3 renderCard">
      <div className="card-header ">{item.menuType}</div>
      <div className="card-body">
        <h6 className="card-title text-white">{item.itemName}</h6>
        <p className="card-text">
          {" "}
          <strong>Menu Style: </strong> {item.menuStyle}
        </p>
        <p className="card-text">
          {" "}
          <strong>Menu Price: </strong> Rs.{item.menuPrice}
        </p>
        <p className="card-text">
          <strong>Description:</strong> {item.description}
        </p>
        <p className="card-text">
          <strong>Special Cooking Instructions: </strong> {item.specialCookingInstruction}
        </p>
        <div className="dateSection">
          <p className="card-text">
            <strong>Start Date: </strong> {item.startDate}
          </p>
          <p className="card-text">
            <strong>End Date: </strong>
            {item.endDate}
          </p>
        </div>
        <p className="card-text">
          <strong>Deliver By: </strong>
          {item.deliverBy}
        </p>
        {item.deliveryChargePerMenu !== undefined && (
          <p className="card-text">
            <strong>deliveryChargePerMenu: </strong>
            {item.deliveryChargePerMenu}
          </p>
        )}
        {item.distance !== undefined && (
          <p className="card-text">
            <strong>distance: </strong>
            {item.distance}
          </p>
        )}
        {item.totalDeliveryCharges !== undefined && (
          <p className="card-text">
            <strong>totalDeliveryCharges: </strong>
            {item.totalDeliveryCharges}
          </p>
        )}
        <div className="dateSection">
          <p className="card-text">
            <strong>ItemPrice x Quantity: </strong>Rs.{item.itemPrice}
          </p>
          <p className="card-text">x {item.itemQuantity}</p>
        </div>
        {data.lastUpdatedSubscriptionStatus !== undefined && (
          <p className="card-text">
            <strong>Last Updated {item.subscriptionStatus}:</strong> {data.lastUpdatedSubscriptionStatus}
          </p>
        )}
        <p className="card-text">
          <strong>Subscription Status:- </strong>
          {item.subscriptionStatus}
        </p>

        <div className="chefSection">
          <div>
            {item.chefName !== undefined && (
              <p className="card-text">
                <strong>Chef Name:- </strong>
                {item.chefName}
              </p>
            )}
            {item.chefCharge !== undefined && (
              <p className="card-text">
                <strong>Chef Charge:- </strong>
                {item.chefCharge}
              </p>
            )}
          </div>
          {item.chefID !== undefined && (
            <button
              className="btn btn-warning btn-sm my-1"
              type="button"
              onClick={() => {
                handleChefAvailability(item.chefID);
              }}
            >
              Check Chef Availability
            </button>
          )}
        </div>
        <div className="buttonSection">
          <button
            className="btn btn-primary btn-sm my-1"
            type="button"
            onClick={() => {
              handleCreateInvoice();
            }}
          >
            Create Invoice
          </button>
          <button
            className="btn btn-primary btn-sm my-1"
            type="button"
            onClick={() => {
              handleAssignChef(data);
              setSelectedItem(item);
            }}
          >
            Assign Chef
          </button>

          <button
            className="btn btn-primary btn-sm my-1"
            type="button"
            onClick={() => {
              setRenewError(false);
              setSelectedRenewItem(item);
            }}
          >
            Renew
          </button>
        </div>
        <div className="dropdownSection">
          {selectedInvoiceItem !== null && selectedInvoiceItem.menuID === item.menuID && (
            <div className="card card-body">
              <div className="titleSection">
                <h5> {selectedInvoiceItem.menuType} Invoice</h5>
                <p
                  className="cross"
                  onClick={() => {
                    setSelectedInvoiceItem(null);
                  }}
                >
                  &#10060;
                </p>
              </div>

              <div>
                <div className="input-group input-group-sm mb-3">
                  <div className="input-group-prepend">
                    <span className="input-group-text" id="inputGroup-sizing-sm">
                      Start Date
                    </span>
                  </div>
                  <input
                    type="text"
                    value={selectedInvoiceStartDate}
                    onChange={(e) => {
                      setInvoiceStartDateError(false);
                      setInvoiceStartDate(e.target.value);
                    }}
                    className="form-control"
                    aria-label="Small"
                    aria-describedby="inputGroup-sizing-sm"
                  />
                </div>
                {invoiceStartDateError && (
                  <div className="alert alert-danger" role="alert">
                    Enter valid Start Date
                  </div>
                )}
                <div className="input-group input-group-sm mb-3">
                  <div className="input-group-prepend">
                    <span className="input-group-text" id="inputGroup-sizing-sm">
                      End Date
                    </span>
                  </div>
                  <input
                    type="text"
                    value={selectedInvoiceEndDate}
                    onChange={(e) => {
                      setInvoiceEndDateError(false);
                      setInvoiceEndDate(e.target.value);
                    }}
                    className="form-control"
                    aria-label="Small"
                    aria-describedby="inputGroup-sizing-sm"
                  />
                </div>
                {invoiceEndDateError && (
                  <div className="alert alert-danger" role="alert">
                    Enter valid End Date
                  </div>
                )}
                <div className="input-group input-group-sm mb-3">
                  <div className="input-group-prepend">
                    <span className="input-group-text" id="inputGroup-sizing-sm">
                      Delivery Charge
                    </span>
                  </div>
                  <input
                    type="number"
                    value={deliveryCharges}
                    onChange={(e) => {
                      setDeliveryCharges(e.target.value);
                    }}
                    className="form-control"
                    aria-label="Small"
                    aria-describedby="inputGroup-sizing-sm"
                  />
                </div>
                {selectedInvoiceItem.promoCode !== "" && <h6>Applied Promo Code :- {selectedInvoiceItem.promoCode}</h6>}
                <div className="input-group input-group-sm mb-3">
                  <div className="input-group-prepend">
                    <span className="input-group-text" id="inputGroup-sizing-sm">
                      {selectedInvoiceItem.promoCode !== "" ? "Promo Discount" : "Discount"}
                    </span>
                  </div>
                  {selectedInvoiceItem.promoCode !== "" ? (
                    <input
                      type="number"
                      value={selectedInvoiceItem.promoDiscountAmount}
                      contentEditable={false}
                      className="form-control"
                      aria-label="Small"
                      aria-describedby="inputGroup-sizing-sm"
                    />
                  ) : (
                    <input
                      type="number"
                      value={discount}
                      onChange={(e) => {
                        setDiscount(e.target.value);
                      }}
                      className="form-control"
                      aria-label="Small"
                      aria-describedby="inputGroup-sizing-sm"
                    />
                  )}
                </div>

                <button type="button" className="btn btn-primary" onClick={handleSingleInvoice}>
                  Save changes
                </button>
              </div>
            </div>
          )}
          {selectedItem && selectedItem.menuID === item.menuID && (
            <div className="card card-body">
              <div className="titleSection">
                <h5>Assign Chef</h5>
                <p
                  className="cross"
                  onClick={() => {
                    setSelectedItem(null);
                  }}
                >
                  &#10060;
                </p>
              </div>
              <div>
                <Select styles={customStyles} options={chefs} onChange={handleChefClick} />
                {selectedChef !== null && (
                  <div>
                    <div className="input-group input-group-sm my-3">
                      <div className="input-group-prepend">
                        <span className="input-group-text" id="inputGroup-sizing-sm">
                          Chef Number
                        </span>
                      </div>
                      <input
                        type="text"
                        value={selectedChef.userMobile}
                        editable={false}
                        className="form-control"
                        aria-label="Small"
                        aria-describedby="inputGroup-sizing-sm"
                      />
                    </div>
                    <div className="input-group input-group-sm mb-3">
                      <div className="input-group-prepend">
                        <span className="input-group-text" id="inputGroup-sizing-sm">
                          Start Date
                        </span>
                      </div>
                      <input
                        type="text"
                        value={itemStartDate}
                        onChange={(e) => {
                          setItemStartDate(e.target.value);
                        }}
                        className="form-control"
                        aria-label="Small"
                        aria-describedby="inputGroup-sizing-sm"
                      />
                    </div>
                    <div className="input-group input-group-sm mb-3">
                      <div className="input-group-prepend">
                        <span className="input-group-text" id="inputGroup-sizing-sm">
                          End Date
                        </span>
                      </div>
                      <input
                        type="text"
                        value={itemEndDate}
                        onChange={(e) => {
                          setItemEndDate(e.target.value);
                        }}
                        className="form-control"
                        aria-label="Small"
                        aria-describedby="inputGroup-sizing-sm"
                      />
                    </div>
                    <div className="input-group input-group-sm mb-3">
                      <div className="input-group-prepend">
                        <span className="input-group-text" id="inputGroup-sizing-sm">
                          Chef Price
                        </span>
                      </div>
                      <input
                        type="number"
                        value={chefPrice}
                        onChange={(e) => {
                          setChefChargeError(false);
                          setChefPrice(e.target.value);
                        }}
                        className="form-control"
                        aria-label="Small"
                        aria-describedby="inputGroup-sizing-sm"
                      />
                    </div>
                    {chefChargeError && (
                      <div className="alert alert-danger" role="alert">
                        Enter Chef Charge
                      </div>
                    )}
                  </div>
                )}
                <div className="titleSection">
                  <button type="button" className="btn btn-primary" onClick={handleNotify}>
                    Notify Chef
                  </button>
                  <button type="button" className="btn btn-primary" onClick={handleSaveChef}>
                    Save changes
                  </button>
                </div>
              </div>
            </div>
          )}
          {selectedRenewItem && selectedRenewItem.menuID === item.menuID && (
            <div className="card card-body">
              <div className="titleSection">
                <h5>Renew</h5>
                <p
                  className="cross"
                  onClick={() => {
                    setSelectedRenewItem(null);
                  }}
                >
                  &#10060;
                </p>
              </div>
              <div>
                <div className="dataSection">
                  <div className="btn-group">
                    <button
                      className="btn btn-secondary btn-sm btn-block dropdown-toggle"
                      type="button"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      {subscriptionType !== null ? subscriptionType : "Select"}
                    </button>
                    <div className="dropdown-menu">
                      <p className="dropdown-item" onClick={() => handleRenew("WEEKLY")}>
                        Weekly
                      </p>
                      <p className="dropdown-item" onClick={() => handleRenew("MONTHLY")}>
                        Monthly
                      </p>
                      <p className="dropdown-item" onClick={() => handleRenew("CUSTOM")}>
                        Custom
                      </p>
                    </div>
                  </div>
                  <div className="input-group input-group-sm mb-3 mx-5">
                    <div className="input-group-prepend">
                      <span className="input-group-text" id="inputGroup-sizing-sm">
                        Quantity
                      </span>
                    </div>
                    <input
                      type="number"
                      className="form-control"
                      aria-label="Small"
                      aria-describedby="inputGroup-sizing-sm"
                      value={parseInt(selectedItemQuantity)}
                      onChange={(e) => {
                        setSelectedItemQuantity(e.target.value);
                      }}
                    />
                  </div>
                </div>
                {startDate !== null && <p>Start date: {startDate}</p>}
                {endDate !== null && <p>End date: {endDate}</p>}
                {custom === "CUSTOM" && (
                  <div>
                    <p>Start Date:</p>
                    <input
                      type="date"
                      minDate={new Date()}
                      id="start"
                      name="start-date"
                      value={startDate}
                      onChange={(e) => onChangeStartDate(e.target.value)}
                    ></input>

                    <p>End Date:</p>
                    <input
                      type="date"
                      minDate={new Date()}
                      id="end"
                      name="end-date"
                      value={endDate}
                      onChange={(e) => onChangeEndDate(e.target.value)}
                    ></input>
                  </div>
                )}
                {itemStartDateError && (
                  <div className="alert alert-danger" role="alert">
                    Invalid Start Date
                  </div>
                )}
                {itemEndDateError && (
                  <div className="alert alert-danger" role="alert">
                    Invalid End Date
                  </div>
                )}

                {renewError && (
                  <div className="alert alert-danger" role="alert">
                    Without Assigning Chef you cant renew
                  </div>
                )}
                <button type="button" className="btn btn-primary" onClick={handleRenewChanges}>
                  Save changes
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default RenderCard;
