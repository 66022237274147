/* eslint-disable jsx-a11y/img-redundant-alt */
import React from 'react'
import Carousel from 'react-bootstrap/Carousel';
import { createCleanURL } from "./Helpers";

import { useHistory } from 'react-router-dom';

const BootstrapCarousel = ({data}) => {
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const month = date.toLocaleString('default', { month: 'short' });
    const year = date.getFullYear();
    return `${month} ${year}`;
  };
  const history = useHistory();

  const goToBlogPost = ({blogName,blogID}) => {
    const cleanBlogName = createCleanURL(data.blogName);
    history.push({
      pathname: `/blogs/${cleanBlogName}/${data.blogID}`
    });
  };
  return (
    <Carousel className='carousel-container' fade>
    {data.slice(0, 3).map((value, index) => (
      <Carousel.Item key={index}  className='carousel-container-content' interval={5000} onClick={() => goToBlogPost(value)}>
        <img src={value.thumbNailUrl ? value.thumbNailUrl : "https://d2nahbmqd5vvug.cloudfront.net/appdata/placeholder-blog.png"} alt={`image ${index}`} className='image'/>          
        <Carousel.Caption>
          <div className="category">
          {value.Stories === 1 && <span className="text">Stories</span>}
              {value.Homeal === 1 && <span className="text">Homeal</span>}
              {value.Recipe === 1 && <span className="text">Recipe</span>}
              {value.Nutrition === 1 && <span className="text">Nutrition</span>}
              {value.Health === 1 && <span className="text">Health</span>}
              </div>
          <div className="title">{value.blogName}</div>
          <div className="single-line">
          <div className="date"> {formatDate(value.date)}</div>
          {/* <div className='line'>|</div>
          <div className="time"><IoMdTime size={16} />{value.reading_time}</div> */}
          </div>
        </Carousel.Caption>
     </Carousel.Item>

    ))}
  </Carousel>
  )
}

export default BootstrapCarousel