import Blogs from "./AllBlogData.json"
import BlogTopCard from './BlogTopCard'

const BlogTopList = ({data}) => {
  return (
    <div className='blog-top-list-right-container'>
        {data.slice(0, 5).map((data,index)=>{
            return(
       <BlogTopCard data={data} index={index} />
            )
        })}

    </div>
  )
}

export default BlogTopList