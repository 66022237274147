import { createCleanURL } from "./Helpers";
import { useHistory } from 'react-router-dom';
const BlogTopCard = ({data,index}) => {
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const month = date.toLocaleString('default', { month: 'short' });
    const year = date.getFullYear();
    return `${month} ${year}`;
  };
  const history = useHistory();

  const goToBlogPost = () => {
    const cleanBlogName = createCleanURL(data.blogName);
    history.push({
      pathname: `/blogs/${cleanBlogName}/${data.blogID}`
    });
}
  return (
      
          <div className='blog-top-list-right-side' key={index} onClick={goToBlogPost}>
            <div className='blog-top-list-right-side-image'>
             <img src={data.thumbNailUrl ? data.thumbNailUrl : "https://d2nahbmqd5vvug.cloudfront.net/appdata/placeholder-blog.png"} alt="" />
            </div>
            <div className='blog-top-list-right-side-text'>
             <div className='blog-title'>
                {data.blogName}
             </div>
             <div  className='blog-post-info'>
              <div className='blog-post-info-left'>
                <div className='blog-post-info-date'>{formatDate(data.date)}</div>
              </div>
              {/* <div className='line'>|</div>
              <div className='blog-post-info-right'>
                <IoMdTime size={16} /> 
                <div className='blog-post-info-time'>{data.reading_time}</div>
              </div> */}
            </div>
            </div>
            </div>
          )
}

export default BlogTopCard