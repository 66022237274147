import React,{useEffect} from 'react'
import {Link} from "react-router-dom"
import "./BlogTrendingContainer.css"

const BlogCategoryCard = ({data,index}) => {
  useEffect(()=>{
    window.scrollTo(0,0);
  },[])
 
  return (
      <div className='blog-category-container' key={index}>
        <Link to={`/singleCategory/${data.title}`} >
        <img src={data.bgImage} alt="" className="blog-category-cards" />
        </Link>
            </div>
          )
}

export default BlogCategoryCard