import GooglePlay from "../../images/googlePlay.png";
import AppStore from "../../images/appStore.png";
import "./registerAsChef.css";
import Navbar from "../Navbar/navbar";
import Footer from "../Footer/footer";

const registerAsChef = () => {
  return (
    <div className="registerAsChef">
      <Navbar />
      <div className="registerAsChefContainer">
        <h1>
          {" "}
          To <strong>Register as Chef</strong> in Homeal Please download the app
        </h1>

        <div className="registerAsChef__follow-icons">
          <a href="https://play.google.com/store/apps/details?id=com.localzi.homeal" target="_blank" rel="noreferrer">
            <img src={GooglePlay} className="iconSize" alt="googlePlay" />
          </a>
          <a href="https://apps.apple.com/in/app/homeal/id1571860810" target="_blank" rel="noreferrer">
            <img src={AppStore} className="iconSize" alt="applePlay" />
          </a>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default registerAsChef;
