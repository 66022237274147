import React, { useState, useEffect } from "react";
import "./ChefAddMenuModal.css";
import { Modal } from "react-bootstrap";
import GetAPI from "../../APICalls/GetAPI";

const MenuTypeBox = ({ title, selected, onSelect }) => (
  <div
    className={`menu-type-box ${selected ? "selected" : ""}`}
    onClick={() => onSelect(title)}
  >
    {title}
  </div>
);

const RenderInputs = ({ title, value, setValue, placeholder, type, name }) => (
  <div className="input-group input-group-sm my-2">
    <div className="input-group-prepend">
      <span className="input-group-text" id={`basic-addon-${name}`}>
        {title}
      </span>
    </div>
    <input
      type={type}
      className="form-control"
      name={name}
      placeholder={placeholder}
      value={value}
      onChange={(e) => setValue(e.target.value)}
    />
  </div>
);

const RenderDropdown = ({ title, array, value, setValue }) => (
  <div className="dropdown">
    <button
      className="btn btn-secondary dropdown-toggle"
      type="button"
      data-toggle="dropdown"
      aria-expanded="false"
    >
      {value !== null ? value : title}
    </button>
    <div className="dropdown-menu">
      {array.map((item) => (
        <a
          key={item}
          className="dropdown-item"
          href="#"
          onClick={(e) => {
            e.preventDefault();
            setValue(item);
          }}
        >
          {item}
        </a>
      ))}
    </div>
  </div>
);

const convertTo24HourFormat = (time) => {
  let [timePart, modifier] = time.split(" ");
  let [hours, minutes] = timePart.split(":");

  if (hours === "12") {
    hours = "00";
  }

  if (modifier === "PM") {
    hours = String(parseInt(hours, 10) + 12);
  }

  if (hours.length < 2) {
    hours = "0" + hours;
  }

  return `${hours}:${minutes}`;
};

const ChefEditMenuModal = ({ show, handleClose, data, id, handleEditSave }) => {
  const [itemName, setItemName] = useState(data.itemName);
  const [itemDesc, setItemDesc] = useState(data.description);
  const [chefCost, setChefCost] = useState(data.chefCost);
  const [itemForever, setItemForever] = useState(data.itemForever);
  const [itemType, setItemType] = useState(data.itemType);
  const [foodStyles, setFoodStyles] = useState([]);
  const [menuTags, setMenuTags] = useState(data.menuTags.join(''));
  const [menuType, setMenuType] = useState(data.menuType);
  const [orderBy, setOrderBy] = useState(convertTo24HourFormat(data.orderBy));

  const handleMenuTypeSelect = (title) => {
    setMenuType((prevMenuTypes) =>
      prevMenuTypes?.includes(title)
        ? prevMenuTypes.filter((item) => item !== title)
        : [...prevMenuTypes, title]
    );
  };

  const itemTypes = ["Vegetarian", "NonVegetarian", "Eggatarian"];

  let environment = process.env.REACT_APP_NODE_ENV;
  let utilityApi =
    environment === "PRODUCTION"
      ? process.env.REACT_APP_PROD_UTILITY
      : process.env.REACT_APP_DEV_UTILITY;

  useEffect(() => {
    const completeUtilityURL = `${utilityApi}?type=ALL`;
    GetAPI(completeUtilityURL)
      .then((res) => {
        if (res.data.statusCode === 200) {
          const titles = res.data.body[1].value?.map((item) => item.title);
          setFoodStyles(titles || []);
        } else {
          console.error("Error occurred: ", res);
          alert("Failed to get delivery slots ");
        }
      })
      .catch((e) => {
        console.error("Error occurred: ", e);
        alert("Failed to get delivery slots ");
      });
  }, [utilityApi]);

  const handleEditSubmit = (idValue) => {
    if (!itemName) {
      alert("Please add the item name to proceed");
      return;
    }
    if (!itemDesc) {
      alert("Please add the description to proceed");
      return;
    }
    if (!chefCost || chefCost === "0") {
      alert("Please add the price to proceed");
      return;
    }
    if (!itemType) {
      alert("Please select the item Type to proceed");
      return;
    }
    if (!menuTags) {
      alert("Please select the food Type to proceed");
      return;
    }
    if (menuType.length === 0) {
      alert("Please select the menuType to proceed");
      return;
    }
    if (orderBy === "") {
      alert("Please enter orderBy time to proceed");
      return;
    }

    const timeArray = orderBy.split(":");
    let hours = parseInt(timeArray[0]);
    const minutes = timeArray[1];
    let suffix = "AM";

    if (hours >= 12) {
      suffix = "PM";
      if (hours > 12) {
        hours -= 12;
      }
    }

    const formattedTime = `${hours}:${minutes} ${suffix}`;

    if (idValue === id) {
      handleClose();
      let editedItem = {
        id: idValue,
        itemName: itemName,
        description: itemDesc,
        chefCost: chefCost,
        adminStatus: data.adminStatus,
        inApp: data.inApp,
        adminComments: data.adminComments,
        menuTags: menuTags.split(','),
        portion: data.portion,
        isHomealItem: false,
        itemForever: itemForever,
        orderBy: formattedTime,
        imageUrl: data.imageUrl,
        itemType: itemType,
        menuType: menuType,
      };
      handleEditSave(editedItem);
    }
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header className="d-flex g-2">
        <Modal.Title>Edit the Menu</Modal.Title>
        <p
          className="cross"
          onClick={() => {
            handleClose();
          }}
        >
          &#10060;
        </p>
      </Modal.Header>
      <Modal.Body>
        <RenderInputs
          title="Item Name"
          name="itemName"
          value={itemName}
          setValue={setItemName}
          type="text"
          placeholder="Ex. Idly, paratha, meals etc"
        />
        <RenderInputs
          title="Item Desc"
          name="itemDesc"
          value={itemDesc}
          setValue={setItemDesc}
          type="text"
          placeholder="Ex. 4 idlis served with 250 ml chutney"
        />
        <RenderInputs
          title="Item Price"
          name="itemPrice"
          value={chefCost}
          setValue={setChefCost}
          type="text"
          placeholder="Ex. 50"
        />
        <div className="inline my-2">
          <RenderDropdown
            title="Item Type"
            array={itemTypes}
            value={itemType}
            setValue={setItemType}
          />
          <RenderDropdown
            title="Food Style"
            array={foodStyles}
            value={menuTags}
            setValue={setMenuTags}
          />
        </div>
        <h6>Select Menu Types</h6>
        <div className="menu-type-container">
          {["Breakfast", "Lunch", "Snacks", "Dinner"].map((title) => (
            <MenuTypeBox
              key={title}
              title={title}
              selected={menuType?.includes(title)}
              onSelect={handleMenuTypeSelect}
            />
          ))}
        </div>
        <RenderInputs
          title="Order By"
          name="orderBy"
          type="time"
          value={orderBy}
          setValue={setOrderBy}
          placeholder="Ex. 09:30"
        />
        <div className="form-check">
          <input
            className="form-check-input"
            type="checkbox"
            checked={itemForever}
            onChange={() => setItemForever((prev) => !prev)}
            id="flexCheckDefault"
          />
          <label className="form-check-label" htmlFor="flexCheckDefault">
            Do you want this item forever?
          </label>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className="btn btn-primary"
          onClick={() => handleEditSubmit(data.id)}
        >
          Edit & Submit
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default ChefEditMenuModal;
