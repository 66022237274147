import React, { useState, useEffect } from "react";
import "./billing.css";
import CardItem from "./itemCard";
import moment from "moment";
import GetAPI from "../APICalls/GetAPI";
import PostAPI from "../APICalls/PostAPI";

const Billing = () => {
  const [name, setName] = useState("");
  const [mobile, setMobile] = useState("");
  const [orderSaved, setOrderSaved] = useState(false);
  const [orderFailed, setOrderFailed] = useState(false);
  const [fetchMenu, setFetchMenu] = useState([]);
  const [grandTotal, setGrandTotal] = useState(0);
  const [quantityReset, setQuantityReset] = useState(false);
  const todayDate = moment().format("YYYY-MM-DD");

  let fetchMenuItems, saveStall;
  let environment = process.env.REACT_APP_NODE_ENV;
  if (environment === "PRODUCTION") {
    fetchMenuItems = process.env.REACT_APP_PROD_STALLAPI;
    saveStall = process.env.REACT_APP_PROD_SAVESTALLAPI;
  } else {
    fetchMenuItems = process.env.REACT_APP_DEV_STALLAPI;
    saveStall = process.env.REACT_APP_DEV_SAVESTALLAPI;
  }

  useEffect(() => {
    setInterval(() => {
      setOrderSaved(false);
      setOrderFailed(false);
    }, 5000);
    let completeFetchMenuURL = fetchMenuItems + "?date=" + todayDate;

    GetAPI(completeFetchMenuURL)
      .then((res) => {
        if (res.data.body.length > 0) {
          setFetchMenu(res.data.body);
        }
      })
      .catch((e) => {
        console.error(e);
        alert("Unable to get details");
      });

    // eslint-disable-next-line
  }, [quantityReset]);

  const handleMinus = (data) => {
    setFetchMenu((prevState) => {
      return prevState.map((item) =>
        item.menuID === data.menuID
          ? {
              ...item,
              quantity: parseInt(item.quantity) > 0 ? parseInt(item.quantity) - 1 : 0,
              total: parseInt(item.quantity) > 0 ? (parseInt(item.quantity) - 1) * parseInt(item.menuPrice) : 0,
            }
          : item
      );
    });
  };

  const handlePlus = (data) => {
    setFetchMenu((prevState) => {
      return prevState.map((item) =>
        item.menuID === data.menuID
          ? {
              ...item,
              quantity: parseInt(item.quantity) + 1,
              total: (parseInt(item.quantity) + 1) * parseInt(item.menuPrice),
            }
          : item
      );
    });
  };

  useEffect(() => {
    let sum = 0;
    fetchMenu.map((menu) => {
      sum = sum + parseInt(menu.total);
    });
    setGrandTotal(sum.toFixed(2));
  }, [fetchMenu]);

  const handleSubmit = () => {
    let itemsOrdered = [];
    fetchMenu.forEach((item) => {
      item.quantity > 0 &&
        itemsOrdered.push({
          itemName: item.menuName,
          itemQuantity: item.quantity,
          itemPrice: item.total,
        });
    });

    const dataToPost = {
      craverName: name,
      craverMobile: "+91".concat(mobile),
      total: grandTotal,
      itemOrdered: itemsOrdered,
    };

    PostAPI(saveStall, dataToPost)
      .then((res) => {
        if (res.data.statusCode === 201 || res.data.statusCode === 200) {
          setOrderSaved(true);
          setName("");
          setMobile("");
          setQuantityReset(true);
        } else {
          console.log("Failed to save order.Please try again later!!");
          setOrderFailed(true);
          setName("");
          setMobile("");
          setQuantityReset(true);
        }
      })
      .catch((e) => {
        console.log("Error occurred. Please try again. Error =", e);
        setOrderFailed(true);
        setName("");
        setMobile("");
        setQuantityReset(true);
        alert("Unable to post data. Please try again later");
      });
  };

  return (
    <div className="billing">
      <input
        type="text"
        className="form-control m-3 p-3"
        placeholder="Enter Name"
        value={name}
        onChange={(event) => {
          setOrderSaved(false);
          setOrderFailed(false);
          setName(event.target.value);
        }}
      />
      <input
        inputmode="numeric"
        maxlength="10"
        type="text"
        className="form-control m-3 p-3"
        placeholder="Enter Mobile"
        value={mobile}
        onChange={(event) => {
          setOrderSaved(false);
          setOrderFailed(false);
          setMobile(event.target.value);
        }}
      />
      {fetchMenu.map((item, index) => (
        <CardItem
          key={index}
          serial={index + 1}
          item={item}
          handleMinus={() => {
            setOrderSaved(false);
            setOrderFailed(false);
            handleMinus(item);
          }}
          handlePlus={() => {
            setOrderSaved(false);
            setOrderFailed(false);
            handlePlus(item);
          }}
        />
      ))}
      {orderSaved && (
        <div className="alert alert-success" role="alert">
          Order Saved Successfully!!
        </div>
      )}

      {orderFailed && (
        <div className="alert alert-danger" role="alert">
          Order Failed to Save, try again later!!
        </div>
      )}
      <div className="finalSection">
        <h1 className="totalTitle">Grand Total : {grandTotal}</h1>
        <button type="button" className="btn btn-outline-primary button" onClick={handleSubmit}>
          Bill Now
        </button>
      </div>
    </div>
  );
};

export default Billing;
