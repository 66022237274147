import React from "react";
import Landing from "./components/Landing/landing";
import WhyUs from "./components/WhyUs/whyUs";
import About from "./components/About/about";
import Faq from "./components/Faq/faq";
import Footer from "./components/Footer/footer";
import ChefStorySection from "./components/ChefStorySection/chefStorySection";

function home() {
  return (
    <div>
      <Landing />
      <div className="container">
        <About />
        <WhyUs />
        <ChefStorySection />
        <Faq />
      </div>
      <Footer />
    </div>
  );
}
export default home;
