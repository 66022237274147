import React, { useState, useEffect, useContext } from "react";
import { FaPhoneAlt, FaHome } from "react-icons/fa";
import { Modal } from "react-bootstrap";
import GetAPI from "../../../APICalls/GetAPI";
import PutAPI from "../../../APICalls/PutAPI";
import "./modal.css";
import { StatusDropdown } from "../helpers";
import OrderedItems from "../OrderedItems/orderedItems";
import { ThreeCircles } from "react-loader-spinner";
import SubscriptionContext from "../context/subscriptionContext";
import { RenderShowCard } from "../helpers";

const SubscriptionModal = ({ showModal, subscriptionID, handleClose }) => {
  const { subscriptionData, setSubscriptionData } = useContext(SubscriptionContext);
  const [loading, setLoading] = useState(true);
  const [showCard, setShowCard] = useState("");

  useEffect(() => {
    let environment = process.env.REACT_APP_NODE_ENV;
    let subscriptionAPI;
    if (environment === "PRODUCTION" || environment === undefined) {
      subscriptionAPI = process.env.REACT_APP_PROD_SUBSCRIPTION_ORDER_API;
    } else {
      subscriptionAPI = process.env.REACT_APP_DEV_SUBSCRIPTION_ORDER_API;
    }
    let completeURL = subscriptionAPI + "?subscriptionID=" + subscriptionID;
    GetAPI(completeURL)
      .then((res) => {

        if (res.data.statusCode === 200) {
          setLoading(false);
          setSubscriptionData(res.data.body)
        } else {
          setLoading(false);
          alert("Unable to get subscription details. Technical Error. Please try again!!!");
        }
      })
      .catch((e) => {
        alert("Unable to get subscription details. Technical Error. Please try again!!!");
        console.log("Error occurred. Error =" + e);
      });

  }, []);

  const handleStatus = (status) => {
    setShowCard(status);
  }

  const handleAdminStatus = (showCard, comments) => {
    setLoading(true);
    let environment = process.env.REACT_APP_NODE_ENV;
    let subscriptionOrderAPI;
    if (environment === "PRODUCTION" || environment === undefined) {
      subscriptionOrderAPI = process.env.REACT_APP_PROD_SUBSCRIPTION_ORDER_API;
    } else {
      subscriptionOrderAPI = process.env.REACT_APP_DEV_SUBSCRIPTION_ORDER_API;
    }

    const getAdminDetails = JSON.parse(localStorage.getItem("AdminDetails"));
    if (getAdminDetails) {
      const dataToPut = showCard.toUpperCase() === "NEW" ? {
        subscriptionID: subscriptionData.subscriptionID,
        paymentStatus: "PAID",
        orderedItems: subscriptionData.orderedItems,
        handledBy: getAdminDetails.userName,
        handlerNumber: getAdminDetails.userMobile,
      } : {
        subscriptionID: subscriptionData.subscriptionID,
        admincomments: comments === undefined ? "N/A" : comments,
        adminStatus: showCard,
        orderedItems: subscriptionData.orderedItems,
        handledBy: getAdminDetails.userName,
        handlerNumber: getAdminDetails.userMobile,
      }

      PutAPI(subscriptionOrderAPI, dataToPut)
        .then((res) => {
          window.location.reload();
        })
        .catch((e) => {
          console.error(e);
          setLoading(false);
          alert("Error occurred. Please try again.");
        });
    }
  };

  return (
    <Modal className="subscriptionModal" show={showModal} size="lg" scrollable={true} onHide={handleClose} backdrop="static" keyboard={false}>
      {loading && (
        <div className="spinnerContainer">
          <div className="spinner">
            <ThreeCircles color="blue" height={110} width={110} ariaLabel="three-circles-rotating" />
          </div>
        </div>
      )}
      <Modal.Header>
        <Modal.Title>
          {subscriptionData.subscriptionID} subscription ID Details
        </Modal.Title>
        <p className="cross" onClick={() => { handleClose() }}> &#10060; </p>
      </Modal.Header>
      <Modal.Body>
        {Object.keys(subscriptionData).length !== 0 ? <div class="card shadow p-3 mb-5 bg-white rounded modalCard">
          <div class="card-body">
            <div className="nameSection">
              <p className="card-text"><strong>{subscriptionData.craverDetails.craverName}</strong></p>
              <p className="card-text text-muted">AppVersion-{subscriptionData.appVersion}</p>
            </div>
            <StatusDropdown showCard={showCard} handleStatus={handleStatus} subscriptionData={subscriptionData} />
            {subscriptionData.admincomments !== undefined && (
              <p className="card-text minute text-muted my-2">
                Comments: <strong> {subscriptionData.admincomments}</strong> <span>{subscriptionData.handledBy !== undefined && <p>Order Incharge: {subscriptionData.handledBy}</p>}</span>
              </p>
            )}
            {showCard === "Pending" && <RenderShowCard showCard={showCard} handleAdminStatus={handleAdminStatus} />}
            {showCard === "New" && <RenderShowCard showCard={showCard} handleAdminStatus={handleAdminStatus} />}
            {showCard === "Chef not Available" && <RenderShowCard showCard={showCard} handleAdminStatus={handleAdminStatus} />}
            {showCard === "Requested Chef" && <RenderShowCard showCard={showCard} handleAdminStatus={handleAdminStatus} />}
            {showCard === "Chef Rejected" && <RenderShowCard showCard={showCard} handleAdminStatus={handleAdminStatus} />}
            {showCard === "Sent Invoice" && <RenderShowCard showCard={showCard} handleAdminStatus={handleAdminStatus} />}
            {showCard === "Updated" && <RenderShowCard showCard={showCard} handleAdminStatus={handleAdminStatus} />}
            {showCard === "Renew Rejected" && <RenderShowCard showCard={showCard} handleAdminStatus={handleAdminStatus} />}
            {showCard === "Removed" && <RenderShowCard showCard={showCard} handleAdminStatus={handleAdminStatus} />}
            <p class="card-text">
              <a href={"tel:" + subscriptionData.craverDetails.mobileNumber} rel="noreferrer">
                <FaPhoneAlt size={10} /> {subscriptionData.craverDetails.mobileNumber}
              </a>
            </p>
            <a href={`http://maps.google.com/?q=${subscriptionData.craverDetails.addressCoordinates.lat},${subscriptionData.craverDetails.addressCoordinates.lng}`} rel="noreferrer" target="_blank">
              <div className="addressSection">
                <p class="card-text minute"><FaHome size={12} />{subscriptionData.craverDetails.houseNo + " " + subscriptionData.craverDetails.apartmentName + " " + subscriptionData.craverDetails.landmark + " " + subscriptionData.craverDetails.address}</p>
              </div>
            </a>
          </div>
          <OrderedItems />
        </div> : null}
      </Modal.Body>
    </Modal>
  );
};

export default SubscriptionModal;
