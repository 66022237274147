import React from "react";
import "./whyUs.css";
import heart from "../../images/heart.png";
import homeFood from "../../images/homeFood.png";
import food from "../../images/food.png";
import balance from "../../images/balance.png";
import delivery from "../../images/delivery.png";
import group from "../../images/group.png";

function WhyUs() {
  return (
    <div className="whyUs">
      <hr />
      <h3>Why Homeal?</h3>
      <div className="row">
        <div className="col-sm-4 mt-2">
          <div className="card">
            <div className="card-body">
              <img src={heart} alt="heart" width="100" height="100" />
              <p className="card-text">
                <strong>Eat Healthily</strong> In the busy work schedule, we frequently drop out on the health quotient, but not anymore. Homeal will
                provide your healthy home-cooked food by delivering them from Homeal Chefs to you. Now you can eat your home-cooked food.
              </p>
            </div>
          </div>
        </div>
        <div className="col-sm-4 mt-2">
          <div className="card">
            <div className="card-body">
              <img src={homeFood} alt="homeFood" width="100" height="100" />
              <p className="card-text">
                <strong>Freshly Cooked Food</strong> A significant concern of eating home food during work is the freshness of the food. We have a way
                for you. Order with Homeal and have your home-cooked meals by delivering at your place and enjoy 100% freshly cooked food at your
                service.
              </p>
            </div>
          </div>
        </div>
        <div className="col-sm-4 mt-2">
          <div className="card">
            <div className="card-body">
              <img src={food} alt="food" width="100" height="100" />
              <p className="card-text">
                <strong>Diet Choices</strong> Are you on a vegan diet, or are you a pure vegetarian?
                Do not worry. We got you!! According to your diet, we deliver your home meal to your place.
              </p>
            </div>
          </div>
        </div>
        <div className="col-sm-4">
          <div className="card">
            <div className="card-body">
              <img src={balance} alt="balance" width="100" height="100" />
              <p className="card-text">
                <strong>Balanced nutrition food</strong> Usually, restaurants prepare food items high in fat, salt, and sugar. When we prepare food at
                home, we know which ingredients and how much each is going into our food, which helps to keep stress levels low for our health. When
                we cook at home, it will be healthy with good tasty food.
              </p>
            </div>
          </div>
        </div>
        <div className="col-sm-4 ">
          <div className="card">
            <div className="card-body">
              <img src={delivery} alt="Homemade Food Delivery in Bangalore" width="100" height="100" />

              <p className="card-text">
                <strong>Our Daily Delivery Services</strong> can guarantee you the ultimate co-operation you need, especially when you are occupied in
                any work. We try to make it convenient for you. Whether it is a breakfast, lunch, or dinner, even snacks and ready to eat savory are
                also included.
              </p>
            </div>
          </div>
        </div>
        <div className="col-sm-4 ">
          <div className="card">
            <div className="card-body">
              <img src={group} alt="group" width="100" height="100" />

              <p className="card-text">
                <strong>Our Team </strong> Serving together as teams provide people more opportunities to release their creative ideas and increases
                their sense of belonging. Our team will develop a sense of fellowship as you work toward making healthy food our goal. Teamwork builds
                morale for Homeal.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default WhyUs;
