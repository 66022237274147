import React, { useEffect, useContext } from "react";
import "./KitchenMenu.css";
import TopCard from "./TopCard";
import DisplayMenu from "./DisplayMenu";
import OrderNowFooter from "./OrderNowFooter";
import axios from "axios";
import ChefDetailsContext from "./ChefDetailsContext";

const KitchenMenu = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const chefID = urlParams.get("chefID");
  const { setChefDetails } = useContext(ChefDetailsContext);

  useEffect(() => {
    let environment = process.env.REACT_APP_NODE_ENV;
    const headers = {
      "Content-Type": "application/json",
      Authorization: "website"
    };
    let fetchChefDetails;

    if (environment === "PRODUCTION" || environment === undefined) {
      fetchChefDetails = process.env.REACT_APP_PROD_USERAPI;
    } else {
      fetchChefDetails = process.env.REACT_APP_DEV_USERAPI;
    }
    const getChefDetails = () => {
      let completeDetailsURL = fetchChefDetails + "?userID=" + chefID;

      axios
        .get(completeDetailsURL, {
          headers,
        })
        .then((response) => {
          let responseData = response.data;
          if (responseData.statusCode === 200 || responseData.statusCode === 201) {
            setChefDetails(responseData.body);
          } else {
            console.log("Error occurred. Error =" + response);
            alert("Error occurred while fetching chef details!!!");
          }
        })
        .catch((error) => {
          console.log("Error occurred. Error =" + error);
          alert("Error occurred while fetching chef details!!!");
        });
    };
    getChefDetails();
  }, []);

  return (
    <>
      <div className="kitchenMenu container">
        <>
          {/* Top card view */}
          <TopCard />
          {/* Display Items */}
          <DisplayMenu />
        </>
      </div>
      <OrderNowFooter />
    </>
  );
};

export default KitchenMenu;
