import React, { useState } from "react";
import { UserDetailsContext } from "./UserDetailsContext";
import { getCookie } from "./Utils/Cookies";
import { useEffect } from "react";

const UserDetailsProvider = ({ children }) => {
  const [userDetails, setUserDetails] = useState({
    name: "",
    mobileNumber: "",
  });

  useEffect(() => {
    const userData = getCookie("userData");
    if (userData !== null) {
      setUserDetails(userData);
    }
  }, []);

  return <UserDetailsContext.Provider value={{ userDetails, setUserDetails }}>{children}</UserDetailsContext.Provider>;
};

export default UserDetailsProvider;
