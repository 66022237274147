import React from "react";
import { useState } from "react";
import PostAPI from "../../APICalls/PostAPI";
import "./pushNotification.css";

const PushNotificationByMenuType = ({ setLoading }) => {
  const [date, setDate] = useState("");
  const [menuType, setMenuType] = useState("Breakfast");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");

  const handleSubmit = () => {
    setLoading(true);

    let environment = process.env.REACT_APP_NODE_ENV;
    let promotionDataAPI;
    if (environment === "PRODUCTION" || environment === undefined) {
      promotionDataAPI = process.env.REACT_APP_PROD_PUSHNOTIFICATION;
    } else {
      promotionDataAPI = process.env.REACT_APP_DEV_PUSHNOTIFICATION;
    }

    if (date === "") {
      alert("Please select the date to submit");
      return;
    }
    if (title === "") {
      alert("Please select the title to submit");
      return;
    }
    if (description === "") {
      alert("Please select the description to submit");
      return;
    }

    const dataToPost = {
      promotionTitle: title,
      promotionDesc: description,
      promotionMenu: menuType,
      promotionDate: date,
    };

    // Assuming PostAPI is defined somewhere else
    PostAPI(promotionDataAPI, dataToPost)
      .then((res) => {
        console.log(res);
        setLoading(false);
        setDate("");
        setMenuType("Breakfast");
        setTitle("");
        setDescription("");
        alert("Push notification added successfully");
      })
      .catch((e) => {
        console.error(e);
        setLoading(false);
        alert("Failed to add push notification, Try again later!! ", e);
      });
  };

  return (
    <div className="card">
      <div className="card-body mx-2">
        <h3 className="my-3  mx-2 ">Send Notification by Menu Type</h3>
        <div>
          <form>
            <div className="form-row">
              <div className="col-sm-12 col-md-8 col-lg-6 my-2">
                <input
                  type="date"
                  value={date}
                  onChange={(e) => {
                    setDate(e.target.value);
                  }}
                  className="form-control "
                  placeholder="Select Date"
                  required
                />
              </div>
              <div className="col-sm-12 col-md-8 col-lg-6 my-2">
                <select
                  className="form-control"
                  value={menuType}
                  onChange={(e) => setMenuType(e.target.value)}
                >
                  <option>Breakfast</option>
                  <option>POBreakfast</option>
                  <option>Lunch</option>
                  <option>Snacks</option>
                  <option>Dinner</option>
                </select>
              </div>
              <div className="col-sm-12 col-md-6 col-lg-12 my-2">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text" id="title">
                      Title
                    </span>
                  </div>
                  <input
                    type="text"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    className="form-control "
                    id="title"
                    placeholder="title"
                    aria-describedby="title"
                    required
                  />
                </div>
              </div>
            </div>
            <div className="form-row my-2">
              <div className="col-sm-12">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span
                      className="input-group-text"
                      id="validationTooltipUsernamePrepend"
                    >
                      Desc.
                    </span>
                  </div>
                  <input
                    type="text"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    className="form-control "
                    id="Desc"
                    placeholder="Description"
                    aria-describedby="Desc"
                    required
                  />
                </div>
              </div>
            </div>
          </form>
          <div className="col-sm-12 mx-2 my-5">
            <button
              className="btn btn-primary btn-block"
              type="button"
              onClick={handleSubmit}
            >
              Submit
            </button>
          </div>
        </div>
        <hr />
      </div>
    </div>
  );
};

export default PushNotificationByMenuType;
