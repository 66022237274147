import React from "react";
import "./billing.css";

const CardItem = (props) => {
  return (
    <div className="card">
      <div className="card-body">
        <div className="item">
          <p className="p-1 serial">{props.serial} -</p>
          <p className="w-25 p-1">{props.item.menuName}</p>
          <p className="w-5 p-1 priceTag">Rs.{props.item.menuPrice}/-</p>
          <div className="quantity w-10">
            <button type="button" className="btn btn-outline-primary btn-sm widthPlusMinus" onClick={props.handleMinus}>
              -
            </button>
            <div className="quantityInput">
              <input className="form-control form-control-sm" type="text" value={props.item.quantity} placeholder="quantity" />
            </div>
            <button type="button" className="btn btn-outline-primary btn-sm  widthPlusMinus" onClick={props.handlePlus}>
              +
            </button>
          </div>
          <div className="w-2 total">
            <p>{props.item.total}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardItem;
