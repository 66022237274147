import "./BlogTrendingContainer.css";
import { createCleanURL } from "./Helpers";

import { useHistory } from 'react-router-dom';

const BlogGeneralCard = ({ data, index }) => {
  const history = useHistory();

  const goToBlogPost = () => {
    const cleanBlogName = createCleanURL(data.blogName);
    history.push({
      pathname: `/blogs/${cleanBlogName}/${data.blogID}`
    });
  };
  const generateCategoryString = () => {
    const categories = [];
    if (data.Recipe === 1) categories.push('Recipe');
    if (data.Health === 1) categories.push('Health');
    if (data.Nutrition === 1) categories.push('Nutrition');
    if (data.Homeal === 1) categories.push('Homeal');
    if (data.Stories === 1) categories.push('Stories');
    return categories.join(', ');
  };
  return (
    <>
      <div className='blog-general-container' key={index} onClick={goToBlogPost}>

        <>
          <div className='blog-general-top-image'>
            <img src={data.thumbNailUrl ? data.thumbNailUrl : "https://d2nahbmqd5vvug.cloudfront.net/appdata/placeholder-blog.png"} alt="" />
          </div>
          <div className='blog-general-bottom-text'>
            <div className='blog-category-title'>
              {generateCategoryString()}
            </div>
            <div className='blog-title'>
              {data.blogName}
            </div>
            <div className='blog-post-info'>
              <div className='blog-post-info-left'>

                <div className='blog-post-info-date'>{data.date}</div>
              </div>
            </div>
          </div>
        </>

      </div>
    </>)
}

export default BlogGeneralCard