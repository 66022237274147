import React, { useEffect, useContext } from "react";
import RenderTotalOrders from "./RenderTotalOrders";
import InputFields from "./InputFields";
import PromoCode from "./PromoCode";
import TotalBill from "./TotalBill";
import UserDetailsProvider from "./UserDetailsProvider";
import OrderContext from "./OrderContext";
import ProceedOrder from "./ProceedOrder";

const CheckoutScreen = () => {
  const { setDiscountAmount, setTotalAmount, setTotalOrders, setPromoCode } = useContext(OrderContext);

  useEffect(() => {
    const handleBackButton = () => {
      setDiscountAmount(0);
      setTotalAmount(0);
      setTotalOrders([]);
      setPromoCode("");
    };

    window.addEventListener("popstate", handleBackButton);

    return () => {
      window.removeEventListener("popstate", handleBackButton);
    };
  }, []);

  return (
    <div className="container">
      <UserDetailsProvider>
        <RenderTotalOrders />
        <InputFields />
        <PromoCode />
        <TotalBill />
        <ProceedOrder />
      </UserDetailsProvider>
    </div>
  );
};

export default CheckoutScreen;
