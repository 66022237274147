const ExtractLatLong = (inputString) => {
  const regex = /LAT([\d.]+)LONG([\d.]+)/;
  const matches = inputString.match(regex);

  if (matches && matches.length === 3) {
    const latitude = parseFloat(matches[1]);
    const longitude = parseFloat(matches[2]);
    return { latitude, longitude };
  } else {
    throw new Error("Invalid input string format");
  }
};

export default ExtractLatLong;
