import React, { useState } from "react";
import { OrderContext } from "./OrderContext";

const OrderProvider = ({ children }) => {
  const [totalOrders, setTotalOrders] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const [discountAmount, setDiscountAmount] = useState(0);
  const [gst, setGst] = useState(0);
  const [grandTotal, setGrandTotal] = useState(0);
  const [promoCode, setPromoCode] = useState("");

  return (
    <OrderContext.Provider
      value={{
        totalOrders,
        setTotalOrders,
        totalAmount,
        setTotalAmount,
        promoCode,
        setPromoCode,
        discountAmount,
        setDiscountAmount,
        gst,
        setGst,
        grandTotal,
        setGrandTotal,
      }}
    >
      {children}
    </OrderContext.Provider>
  );
};

export default OrderProvider;
