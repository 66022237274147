import React, { useState, useEffect, useContext } from "react";
import AdminTabs from "../adminTabs";
import AdminModal from "../adminModal";
import { Tab, TabContent, downloadBase64Excel } from "./helpers";
import SubscriptionContext from "./context/subscriptionContext";
import { Pending } from "./StatusFiles/pending";
import { New } from "./StatusFiles/New";
import { RequestedChef } from "./StatusFiles/Requestedchef";
import { Active } from "./StatusFiles/Active";
import { Inactive } from "./StatusFiles/Inactive";
import { Expired } from "./StatusFiles/Expired";
import { AboutToExpire } from "./StatusFiles/AboutToExpire";
import GetAPI from "../../APICalls/GetAPI";

const Subscription = () => {
  const [activeTab, setActiveTab] = useState("home");
  const [notVerified, setNotVerified] = useState(true);

  const handleVerified = (value) => {
    setNotVerified(value);
  };
  const { subscriptionOrderCount, setSubscriptionOrderCount } =
    useContext(SubscriptionContext);

  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
  };

  let environment = process.env.REACT_APP_NODE_ENV;
  let subscriptionCustomerHistory;
  if (environment !== undefined && environment.toUpperCase() === "PRODUCTION") {
    subscriptionCustomerHistory =
      process.env.REACT_APP_PROD_SUBSCRIPTIONHISTORY;
  } else {
    subscriptionCustomerHistory = process.env.REACT_APP_DEV_SUBSCRIPTIONHISTORY;
  }

  useEffect(() => {
    GetAPI(subscriptionCustomerHistory)
      .then((res) => {
        if (res.data.statusCode === 201) {
          let subData = res.data.body;
          setSubscriptionOrderCount({
            Pending: subData.PENDING,
            New: subData.NEW,
            RequestedChef: subData.REQUESTEDCHEF,
            Active: subData.ACTIVE,
            AboutToExpire: subData.ABTTOEXP,
            Expired: subData.EXPIRED,
            Inactive: subData.INACTIVE,
          });
        } else {
          alert("Unable to get subscription count");
        }
      })
      .catch((e) => {
        console.error(e);
        alert("Unable to get subscription count");
      });
  }, []);

  const handleExpAbtExp = () => {
    const completeUrl = subscriptionCustomerHistory + "?type=EXPABTTOEXP";
    GetAPI(completeUrl)
      .then((res) => {
        if (res.data.statusCode === 201 || res.data.statusCode === 200) {
          downloadBase64Excel(res.data.body.excelBuffer);
        } else {
          alert("Unable to get expired and about to expire data");
        }
      })
      .catch((e) => {
        console.error(e);
        alert("Unable to get expired and about to expire data");
      });
  };

  return (
    <div>
      {/* Navbar */}
      <AdminTabs />
      {notVerified ? (
        <AdminModal handleVerified={handleVerified} />
      ) : (
        <div classNameName="container">
          {/* tab to switch between subscription status */}
          <ul className="nav nav-tabs my-2" id="myTab" role="tablist">
            <Tab
              tabId="pending"
              isActive={activeTab === "pending"}
              count={subscriptionOrderCount.Pending}
              handleTabClick={handleTabClick}
            />
            <Tab
              tabId="new"
              isActive={activeTab === "new"}
              count={subscriptionOrderCount.New}
              handleTabClick={handleTabClick}
            />
            <Tab
              tabId="requested chef"
              isActive={activeTab === "requested chef"}
              count={subscriptionOrderCount.RequestedChef}
              handleTabClick={handleTabClick}
            />
            <Tab
              tabId="active"
              isActive={activeTab === "active"}
              count={subscriptionOrderCount.Active}
              handleTabClick={handleTabClick}
            />
            <Tab
              tabId="About To Expire"
              isActive={activeTab === "About To Expire"}
              count={subscriptionOrderCount.AboutToExpire}
              handleTabClick={handleTabClick}
            />
            <Tab
              tabId="Expired"
              isActive={activeTab === "Expired"}
              count={subscriptionOrderCount.Expired}
              handleTabClick={handleTabClick}
            />
            <Tab
              tabId="Inactive"
              isActive={activeTab === "Inactive"}
              count={subscriptionOrderCount.Inactive}
              handleTabClick={handleTabClick}
            />
            <button
              type="button"
              class="btn btn-warning btn-sm m-2"
              onClick={handleExpAbtExp}
            >
              Download Expired & About to expire
            </button>
          </ul>
          <div className="tab-content" id="myTabContent">
            <TabContent tabId="pending" isActive={activeTab === "pending"}>
              <Pending />
            </TabContent>
            <TabContent tabId="new" isActive={activeTab === "new"}>
              <New />
            </TabContent>
            <TabContent
              tabId="requested chef"
              isActive={activeTab === "requested chef"}
            >
              <RequestedChef />
            </TabContent>
            <TabContent tabId="active" isActive={activeTab === "active"}>
              <Active />
            </TabContent>
            <TabContent
              tabId="About To Expire"
              isActive={activeTab === "About To Expire"}
            >
              <AboutToExpire />
            </TabContent>
            <TabContent tabId="Expired" isActive={activeTab === "Expired"}>
              <Expired />
            </TabContent>
            <TabContent tabId="Inactive" isActive={activeTab === "Inactive"}>
              <Inactive />
            </TabContent>
          </div>
        </div>
      )}
    </div>
  );
};

export default Subscription;
