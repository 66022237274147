import React, { useState } from "react";
import { getFormattedDate } from "../../Helpers";
import PutAPI from "../../../APICalls/PutAPI";

const RenewSubscription = ({ item, data }) => {
  const [startDate, setStartDate] = useState(getFormattedDate(new Date()));
  const [endDate, setEndDate] = useState("");
  const [showForm, setShowForm] = useState(false);

  const getTomorrowDate = () => {
    const date = new Date();
    date.setDate(date.getDate() + 1);
    return date;
  };

  const handleRenewChanges = () => {
    if (item.endDate > startDate) {
      setEndDate(endDate);
      setStartDate(getFormattedDate(new Date(item.startDate)));
    } else {
      const newDate = getFormattedDate(getTomorrowDate());
      setStartDate(newDate);
    }

    let environment = process.env.REACT_APP_NODE_ENV;
    let subscriptionOrderAPI;
    if (environment === "PRODUCTION" || environment === undefined) {
      subscriptionOrderAPI = process.env.REACT_APP_PROD_SUBSCRIPTION_ORDER_API;
    } else {
      subscriptionOrderAPI = process.env.REACT_APP_DEV_SUBSCRIPTION_ORDER_API;
    }

    const dataToPut = {
      subscriptionID: data.subscriptionID,
      menuID: item.menuID,
      chefName: item.chefName,
      chefID: item.chefID,
      chefCharge: item.chefCharge,
      itemPrice: item.itemPrice,
      menuPrice: item.menuPrice,
      subscriptionStatus: item.subscriptionStatus,
      subscriptionType: item.subscriptionStatus,
      deliverBy: item.deliverBy,
      startDate: startDate,
      endDate: endDate,
      distance: item.distance,
      specialCookingInstruction: item.specialCookingInstruction,
      weekendFlag: item.weekendFlag,
      itemQuantity: item.itemQuantity,
      gst: item.gst,
      itemTotal: item.itemTotal,
      grandTotal: item.grandTotal,
      promoCode: item.promoCode,
      promoDiscountAmount: item.promoDiscountAmount,
    };

    PutAPI(subscriptionOrderAPI, dataToPut)
      .then((res) => {
        alert("Subscription Renewed");
        window.location.reload();
      })
      .catch((e) => {
        console.error(e);
        alert("Unable to update the end dates");
      });
  };

  const onChangeEndDate = (date) => {
    setEndDate( date);
  };

  const handleRenewButtonClick = () => {
    setShowForm(!showForm);
  };

  return (
    <div>
      <div className="mb-3">
        <button
          className="btn btn-light"
          type="button"
          onClick={handleRenewButtonClick}
        >
          {showForm ? "Close" : "Renew"}
        </button>
      </div>

      {showForm && (
        <div>
          <div className="form-group mb-3">
            <label>End Date</label>
            <input
              type="date"
              className="form-control"
              value={endDate}
              min={getFormattedDate(getTomorrowDate())}
              onChange={(e) => onChangeEndDate(e.target.value)}
            />
          </div>
          <div className="mb-3">
            <button className="btn btn-primary" onClick={handleRenewChanges}>
              Confirm Renew
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default RenewSubscription;
