import React, { useState, useEffect } from "react";
import "./subscription.css";
import { HashLink as Link } from "react-router-hash-link";
import { ThreeCircles } from "react-loader-spinner";
import SubscriptionCard from "./SubscriptionCard";
import ConfirmPopupModal from "../ChefVerification/ConfirmPopupModal";
import moment from "moment";
import AdminTabs from "../adminTabs";
import AdminModal from "../adminModal";
import GetAPI from "../../APICalls/GetAPI";
import PutAPI from "../../APICalls/PutAPI";
import PostAPI from "../../APICalls/PostAPI";

function Subscriptions() {
  const [activeKey, setActiveKey] = useState("NEW");
  const [pendingSubscriptionOrderFound, setPendingSubscriptionOrderFound] = useState(false)
  const [pendingSubscriptionOrders, setPendingSubscriptionOrders] = useState([])
  const [pendingSubscriptionOrdersCount, setPendingSubscriptionOrdersCount] = useState(0)
  const [newSubscriptionOrderFound, setNewSubscriptionOrderFound] = useState(false);
  const [newSubscriptionOrders, setNewSubscriptionOrders] = useState([]);
  const [newSubscriptionOrdersCount, setNewSubscriptionOrdersCount] = useState(0);
  const [requestedChefSubscriptionOrdersFound, setRequestedChefSubscriptionOrdersFound] = useState(false);
  const [requestedChefSubscriptionOrders, setRequestedChefSubscriptionOrders] = useState([]);
  const [requestedChefSubscriptionOrdersCount, setRequestedChefSubscriptionOrdersCount] = useState(0);
  const [sentInvoiceSubscriptionOrdersFound, setSentInvoiceSubscriptionOrdersFound] = useState(false);
  const [sentInvoiceSubscriptionOrders, setSentInvoiceSubscriptionOrders] = useState([]);
  const [sentInvoiceSubscriptionOrdersCount, setSentInvoiceSubscriptionOrdersCount] = useState(0);
  const [chefNotAvailableSubscriptionOrders, setChefNotAvailableSubscriptionOrders] = useState([]);
  const [chefNotAvailableSubscriptionOrdersFound, setChefNotAvailableSubscriptionOrdersFound] = useState([]);
  const [chefNotAvailableSubscriptionOrdersCount, setChefNotAvailableSubscriptionOrdersCount] = useState(0);
  const [chefRejectedSubscriptionOrdersFound, setChefRejectedSubscriptionOrdersFound] = useState([]);
  const [chefRejectedSubscriptionOrders, setChefRejectedSubscriptionOrders] = useState([]);
  const [chefRejectedSubscriptionOrdersCount, setChefRejectedSubscriptionOrdersCount] = useState(0);
  const [updatedSubscriptionOrderFound, setUpdatedSubscriptionOrderFound] = useState(false);
  const [updatedSubscriptionOrders, setUpdatedSubscriptionOrders] = useState([]);
  const [updatedSubscriptionOrdersCount, setUpdatedSubscriptionOrdersCount] = useState(0);
  const [notVerified, setNotVerified] = useState(true);
  const [chefs, setChefs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showConfirmPopup, setShowConfirmPopup] = useState(false);
  const [selectedInvoice, setSelectedInvoice] = useState(null);
  const [selectedAssignChef, setSelectedAssignChef] = useState(null);
  const [assignChefConfirmPopup, setAssignChefConfirmPopup] = useState(false);
  const [selectedNotifyChef, setSelectedNotifyChef] = useState(null);
  const [notifyChefConfirmPopup, setNotifyChefConfirmPopup] = useState(false);
  const [selectedRenew, setSelectedRenew] = useState(null);
  const [renewConfirmPopup, setRenewConfirmPopup] = useState(false);
  const [partiallyexpired, setPartiallyExpired] = useState(0);
  const [activeSubscriptions, setActiveSubscriptions] = useState(0);

  const handleVerified = (value) => {
    setNotVerified(value);
  };

  useEffect(() => {
    const getAdminDetails = localStorage.getItem("AdminDetails");
    if (getAdminDetails) {
      setNotVerified(false);
    }
  }, []);

  useEffect(() => {
    const getActiveTab = JSON.parse(localStorage.getItem("activeTab"));
    if (getActiveTab) {
      setActiveKey(getActiveTab);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("activeTab", JSON.stringify(activeKey));
  }, [activeKey]);

  let environment = process.env.REACT_APP_NODE_ENV;

  useEffect(() => {
    /* function to call order details */
    const subscriptionOrderStatus = () => {
      let subscriptionOrderAPI;
      if (environment === "PRODUCTION" || environment === undefined) {
        subscriptionOrderAPI = process.env.REACT_APP_PROD_SUBSCRIPTION_ORDER_API;
      } else {
        subscriptionOrderAPI = process.env.REACT_APP_DEV_SUBSCRIPTION_ORDER_API;
      }

      let subscriptionNewOrderURL = subscriptionOrderAPI + "?isChefAssigned=false";
      let subscriptionUpdatedOrderURL = subscriptionOrderAPI + "?isChefAssigned=true";

      GetAPI(subscriptionNewOrderURL)
        .then((res) => {
          let pendingSet = new Set();
          let newSet = new Set();
          let sentInvoiceSet = new Set();
          let requestedChefSet = new Set();
          let chefNotAvailableSet = new Set();
          let chefRejectedSet = new Set();

          if (Object.keys(res.data.body).length === 0) {
            setPendingSubscriptionOrderFound(false);
            setNewSubscriptionOrderFound(false);
            setRequestedChefSubscriptionOrdersFound(false);
            setSentInvoiceSubscriptionOrdersFound(false);
            setChefNotAvailableSubscriptionOrdersFound(false);
            setChefRejectedSubscriptionOrdersFound(false);
          } else {
            setPendingSubscriptionOrderFound(true);
            setNewSubscriptionOrderFound(true);
            setRequestedChefSubscriptionOrdersFound(true);
            setSentInvoiceSubscriptionOrdersFound(true);
            setChefNotAvailableSubscriptionOrdersFound(true);
            setChefRejectedSubscriptionOrdersFound(true);

            res.data.body.forEach((item) => {
              if(item.paymentStatus !== undefined && item.paymentStatus.toUpperCase() ==='PENDING' && item.adminStatus !== undefined && item.adminStatus.toUpperCase() !== 'REMOVED'){
                pendingSet.add(item);
              }
              else {
                switch (item.adminStatus.toUpperCase()) {
                  case "NEW":
                    newSet.add(item);
                    break;
                  case "N/A":
                    newSet.add(item);
                    break;
                  case "SENT INVOICE":
                    sentInvoiceSet.add(item);
                    break;
                  case "REQUESTED CHEF":
                    requestedChefSet.add(item);
                    break;
                  case "CHEF NOT AVAILABLE":
                    chefNotAvailableSet.add(item);
                    break;
                  case "CHEF REJECTED":
                    chefRejectedSet.add(item);
                    break;
                  default:
                    break;
                }
              }
             
            });
            setPendingSubscriptionOrders(Array.from(pendingSet));
            setPendingSubscriptionOrdersCount(Array.from(pendingSet).length);
            setNewSubscriptionOrders(Array.from(newSet));
            setNewSubscriptionOrdersCount(Array.from(newSet).length);
            setSentInvoiceSubscriptionOrders(Array.from(sentInvoiceSet));
            setSentInvoiceSubscriptionOrdersCount(Array.from(sentInvoiceSet).length);
            setChefNotAvailableSubscriptionOrders(Array.from(chefNotAvailableSet));
            setChefNotAvailableSubscriptionOrdersCount(Array.from(chefNotAvailableSet).length);
            setChefRejectedSubscriptionOrders(Array.from(chefRejectedSet));
            setChefRejectedSubscriptionOrdersCount(Array.from(chefRejectedSet).length);
            setRequestedChefSubscriptionOrders(Array.from(requestedChefSet));
            setRequestedChefSubscriptionOrdersCount(Array.from(requestedChefSet).length);

            newSet = new Set();
            sentInvoiceSet = new Set();
            requestedChefSet = new Set();
            chefNotAvailableSet = new Set();
            chefRejectedSet = new Set();
          }
        })
        .catch((e) => {
          console.error(e);
          alert("Unable to get subscription data");
        });

      GetAPI(subscriptionUpdatedOrderURL)
        .then((res) => {
          if (Object.keys(res.data.body).length === 0) {
            setUpdatedSubscriptionOrderFound(false);
          } else {
            setUpdatedSubscriptionOrderFound(true);

            let updatedSet = new Set();

            res.data.body.forEach((item) => {
              if (item.adminStatus.toUpperCase() !== "REMOVED") {
                updatedSet.add(item);
              }
            });

            Array.from(updatedSet).forEach((item) => {
              if (moment(item.orderedItems[0].endDate).format("YYYY-MM-DD") < moment().format("YYYY-MM-DD")) {
                setPartiallyExpired((prevState) => prevState + 1);
              } else {
                setActiveSubscriptions((prevState) => prevState + 1);
              }
            });

            setUpdatedSubscriptionOrders(Array.from(updatedSet));
            setUpdatedSubscriptionOrdersCount(Array.from(updatedSet).length);

            updatedSet = new Set();
          }
        })
        .catch((e) => {
          console.error(e);
          alert("Unable to get subscription data");
        });
    };

    subscriptionOrderStatus();
  }, []);

  let classNameInactive = "nav-link";
  let classNameActive = "nav-link active show";
  let classNameInactiveComponent = "tab-pane fade";
  let classNameActiveComponent = "tab-pane fade show active";

  const handleChefAvailability = (chefID) => {
    setLoading(true);
    let getUserAPI;
    if (environment === "PRODUCTION" || environment === undefined) {
      getUserAPI = process.env.REACT_APP_PROD_USERAPI;
    } else {
      getUserAPI = process.env.REACT_APP_DEV_USERAPI;
    }

    let completeURL = getUserAPI + "?userID=" + chefID;
    let isUserAvailable, chefName;

    GetAPI(completeURL)
      .then((res) => {
        isUserAvailable = res.data.body.isUserAvailable;
        chefName = res.data.body.userName;
        alert(`${chefName} is ${isUserAvailable ? "Available" : "Not Available"}`);
        setLoading(false);
      })
      .catch((e) => {
        console.log("Error occurred. Error =" + e);
        alert("There is error in getting the chef availability. Try again!!!");
        setLoading(false);
        return null;
      });
  };

  const handleAssignChef = (data) => {
    setLoading(true);
    let getChefsAPI;
    if (environment === "PRODUCTION" || environment === undefined) {
      getChefsAPI = process.env.REACT_APP_PROD_GETCHEFBYDIS;
    } else {
      getChefsAPI = process.env.REACT_APP_DEV_GETCHEFBYDIS;
    }

    const lat = data.craverDetails.addressCoordinates.lat;
    const lng = data.craverDetails.addressCoordinates.lng;

    let getChefsURL = getChefsAPI + "?lat=" + lat + "&lng=" + lng;

    GetAPI(getChefsURL)
      .then((res) => {
        setLoading(false);
        if (res.data.length > 0) {
          let set = new Set([]);
          res.data.forEach((item) => {
            set.add({
              value: item.chefID,
              label: item.chefName + " - " + item.chefDistance,
              isDisabled: item.isUserAvailable !== undefined ? item.isUserAvailable.toUpperCase() === "TRUE" ? false : true : false
            });
            setChefs(Array.from(set));
          });
        }
      })
      .catch((e) => {
        console.log("Error occurred. Error =" + e);
        setLoading(false);
      });
  };

  const handleAdminStatus = (showCard, comments, data) => {
    setLoading(true);
    let subscriptionOrderAPI;
    if (environment === "PRODUCTION" || environment === undefined) {
      subscriptionOrderAPI = process.env.REACT_APP_PROD_SUBSCRIPTION_ORDER_API;
    } else {
      subscriptionOrderAPI = process.env.REACT_APP_DEV_SUBSCRIPTION_ORDER_API;
    }

    const dataToPut = showCard.toUpperCase() === "NEW" ? {
      subscriptionID: data.subscriptionID,
      paymentStatus: "PAID",
      orderedItems: data.orderedItems
    } : {
      subscriptionID: data.subscriptionID,
      admincomments: comments === undefined ? "N/A" : comments,
      adminStatus: showCard,
      orderedItems: data.orderedItems
    }

    PutAPI(subscriptionOrderAPI, dataToPut)
      .then((res) => {
        window.location.reload();
      })
      .catch((e) => {
        console.error(e);
        setLoading(false);
        alert("Error occurred. Please try again.");
      });
  };

  const RenderListTitle = ({ keyValue, value, count }) => {
    return (
      <li className="nav-item">
        <a
          className={activeKey === keyValue ? classNameActive : classNameInactive}
          id={`${value}-tab`}
          data-toggle="tab"
          href={`#${value}`}
          role="tab"
          onClick={() => {
            setActiveKey(keyValue);
          }}
          aria-controls={value}
          aria-selected="true"
        >
          {keyValue} {count}
        </a>
      </li>
    );
  };

  const handleInvoice = (selectedInvoiceData) => {
    setShowConfirmPopup(true);
    setSelectedInvoice(selectedInvoiceData);
  };

  const handleInvoiceConfirm = () => {
    setLoading(true);

    let billGeneratorAPI;
    if (environment === "PRODUCTION" || environment === undefined) {
      billGeneratorAPI = process.env.REACT_APP_PROD_BILL_GENERATOR;
    } else {
      billGeneratorAPI = process.env.REACT_APP_DEV_BILL_GENERATOR;
    }

    PostAPI(billGeneratorAPI, selectedInvoice)
      .then((res) => {
        let responseData = res.data;
        if (responseData.statusCode === 200 || responseData.statusCode === 201) {
          alert("Successfully created invoice!!!");
          window.location.reload();
        }
      })
      .catch((e) => {
        console.log("Error occurred. Please try again. Error =", e);
        alert("Unable to create Invoice. Please try again later");
      });
  };

  const handleConfirmChef = (data, selectedItem, selectedChef, chefPrice, distance) => {
    setAssignChefConfirmPopup(true);
    setSelectedAssignChef({
      data: data,
      selectedItem: selectedItem,
      selectedChef: selectedChef,
      chefPrice: chefPrice,
      distance: distance,
    });
  };

  const handleNotifyChef = (data, item, selectedChef, itemStartDate, itemEndDate, chefPrice) => {
    setNotifyChefConfirmPopup(true);
    setSelectedNotifyChef({
      data: data,
      item: item,
      selectedChef: selectedChef,
      itemStartDate: itemStartDate,
      itemEndDate: itemEndDate,
      chefPrice: chefPrice,
    });
  };

  const handleConfirmRenew = (data, item, chefPrice, startDateValue, endDateValue, selectedItemQuantity, subscriptionType, custom) => {
    setRenewConfirmPopup(true);
    setSelectedRenew({
      data: data,
      item: item,
      chefPrice: chefPrice,
      startDate: startDateValue,
      endDate: endDateValue,
      selectedItemQuantity: selectedItemQuantity,
      subscriptionType: subscriptionType,
      custom: custom,
    });
  };
  const handleChefConfirm = () => {
    let subscriptionOrderAPI;
    if (environment === "PRODUCTION" || environment === undefined) {
      subscriptionOrderAPI = process.env.REACT_APP_PROD_SUBSCRIPTION_ORDER_API;
    } else {
      subscriptionOrderAPI = process.env.REACT_APP_DEV_SUBSCRIPTION_ORDER_API;
    }

    const dataToPut = {
      subscriptionID: selectedAssignChef.data.subscriptionID,
      menuID: selectedAssignChef.selectedItem.menuID,
      chefName: selectedAssignChef.selectedChef.userName,
      chefID: selectedAssignChef.selectedChef.userID,
      chefCharge: parseFloat(selectedAssignChef.chefPrice),
      kitchenName: selectedAssignChef.selectedChef.userKitchen,
      itemPrice: selectedAssignChef.selectedItem.itemPrice,
      subscriptionType: selectedAssignChef.selectedItem.subscriptionType,
      subscriptionStatus: selectedAssignChef.selectedItem.subscriptionStatus,
      weekendFlag: selectedAssignChef.data.weekendFlag,
      distance: selectedAssignChef.distance,
      deliverBy: selectedAssignChef.selectedItem.deliverBy,
      menuPrice: selectedAssignChef.selectedItem.menuPrice,
      itemQuantity: selectedAssignChef.selectedItem.itemQuantity,
      specialCookingInstruction: selectedAssignChef.selectedItem.specialCookingInstruction,
    };

    PutAPI(subscriptionOrderAPI, dataToPut)
      .then((res) => {
        handleNotifyConfirm(true);
      })
      .catch((e) => {
        console.log("Error occurred. Please try again. Error =", e);
        alert("Unable assign chef. Please try again later!!!");
      });
  };

  const handleNotifyConfirm = (assignConfirm) => {
    let notifyChefAPI;
    const headers = {
      "Content-Type": "application/json",
      Token: `${process.env.REACT_APP_WASSENGER_TOKEN}`,
    };
    if (environment === "PRODUCTION" || environment === undefined) {
      notifyChefAPI = process.env.REACT_APP_PROD_NOTIFYCHEF;
    } else {
      notifyChefAPI = process.env.REACT_APP_PROD_NOTIFYCHEF;
    }

    let orderText = "";
    if(assignConfirm){      
      switch (selectedAssignChef.selectedItem.menuType) {
        case "BREAKFAST":
          orderText = "⏰ 06:00 AM for Breakfast"
          break;
        case "LUNCH":
          orderText = "⏰ 10:00 AM for Lunch"
          break;
        default:
          orderText = "⏰ 06:00 PM for Dinner"
          break;
      }
    }
   

    const message = assignConfirm
      ? `Hello Chef 👋 \nYour subscription has been **confirmed** 🤩\n CraverName- ${selectedAssignChef.data.craverDetails.craverName}\nSubscriptionID- ${selectedAssignChef.data.subscriptionID} \nPlease start preparing once order gets created \nOrders will be created at\n${orderText}\n **Please share 1 week menu so we can send to customer** `
      : `Hello Chef 👋 \nYou have a New Subscription🤩 \nSubscriptionID: ${selectedNotifyChef.data.subscriptionID}\nMenu Type: ${
          selectedNotifyChef.item.menuType
        } \nMenu Style: ${selectedNotifyChef.item.menuStyle} \nCraver Name : ${selectedNotifyChef.data.craverDetails.craverName} \nItem Name : ${
          selectedNotifyChef.item.itemName
        }\n*Quantity* : ${selectedNotifyChef.item.itemQuantity}\nDescription :  ${selectedNotifyChef.item.description}\nStart date : ${
          selectedNotifyChef.itemStartDate
        }\nEnd date : ${selectedNotifyChef.itemEndDate}\nSubscription Type : ${selectedNotifyChef.item.subscriptionType}\nDelivery time : ${
          selectedNotifyChef.item.deliverBy.split(" - ")[0]
        }\nChef Cost : ${
          selectedNotifyChef.chefPrice
        }/-\n*Note:-* \n > Sunday orders will not be there 🚫 \n > Only when the payment 💰 is done by the customer we will reconfirm your subscription, please start preparing after that. \nReply *Yes*  if you are ready to take this👍`;
    const chefDataToPost = {
      phone: assignConfirm ? selectedAssignChef.selectedChef.userMobile : selectedNotifyChef.selectedChef.userMobile,
      message: message,
    };

    PostAPI(notifyChefAPI, chefDataToPost, headers)
      .then((res) => {
        if (res.data.deliveryStatus.toUpperCase() === "QUEUED") {
          setNotifyChefConfirmPopup(false);
          alert("your chef message is queued, will be delivered soon!!!");
          window.location.reload();
        } else {
          setNotifyChefConfirmPopup(false);
          alert("your chef message has been delivered successfully!!!");
          window.location.reload();
        }
      })
      .catch((e) => {
        console.log("Error occurred. Please try again. Error =", e);
        alert("Unable Notify chef. Please try again later!!!");
      });

      if(assignConfirm){
        const craverDataToPost =  {
          phone: selectedAssignChef.data.craverDetails.mobileNumber,
          message: `Hello ${selectedAssignChef.data.craverDetails.craverName},\nCongratulations 🥳, \nFor your subscription ID ${selectedAssignChef.data.subscriptionID}, \nhome chef has been assigned. \nHomechef is happy to serve you 😊, have healthy home food everyday `
        }
      PostAPI(notifyChefAPI, craverDataToPost, headers)
      .then((res) => {
        if (res.data.deliveryStatus.toUpperCase() === "QUEUED") {
          alert("your craver message is queued, will be delivered soon!!!");
          window.location.reload();
        } else {
          setNotifyChefConfirmPopup(false);
          alert("your craver message has been delivered successfully!!!");
          window.location.reload();
        }
      })
      .catch((e) => {
        console.log("Error occurred. Please try again. Error =", e);
        alert("Unable Notify craver. Please try again later!!!");
      });
    }
  };

  const handleRenewConfirm = () => {
    let subscriptionOrderAPI, subscriptionMenuAPI, dataToPut;
    if (environment === "PRODUCTION" || environment === undefined) {
      subscriptionOrderAPI = process.env.REACT_APP_PROD_SUBSCRIPTION_ORDER_API;
      subscriptionMenuAPI = process.env.REACT_APP_PROD_SUBSCRIPTION_MENU_API;
    } else {
      subscriptionOrderAPI = process.env.REACT_APP_DEV_SUBSCRIPTION_ORDER_API;
      subscriptionMenuAPI = process.env.REACT_APP_DEV_SUBSCRIPTION_MENU_API;
    }

    if (selectedRenew.subscriptionType.toUpperCase() === selectedRenew.item.subscriptionType.toUpperCase()) {
      dataToPut = {
        subscriptionID: selectedRenew.data.subscriptionID,
        menuID: selectedRenew.item.menuID,
        chefName: selectedRenew.item.chefName,
        chefID: selectedRenew.item.chefID,
        chefCharge: parseFloat(selectedRenew.item.chefCharge),
        itemPrice: selectedRenew.item.itemPrice,
        menuPrice: selectedRenew.item.menuPrice,
        subscriptionStatus: selectedRenew.item.subscriptionStatus,
        subscriptionType: selectedRenew.item.subscriptionType,
        deliverBy: selectedRenew.item.deliverBy,
        startDate: selectedRenew.startDate,
        endDate: selectedRenew.endDate,
        distance: selectedRenew.item.distance,
        specialCookingInstruction: selectedRenew.item.specialCookingInstruction,
        weekendFlag: selectedRenew.data.weekendFlag === undefined ? false : selectedRenew.data.weekendFlag,
        itemQuantity: parseInt(selectedRenew.selectedItemQuantity),
        gst: selectedRenew.item.gst,
        itemTotal: selectedRenew.item.itemTotal,
        grandTotal: selectedRenew.item.grandTotal,
        promoCode: "",
        promoDiscountAmount: 0,
      };

      PutAPI(subscriptionOrderAPI, dataToPut)
        .then((res) => {
          if (res.data.statusCode === 200) {
            window.location.reload();
          }
        })
        .catch((e) => {
          console.log("Error occurred. Please try again. Error =", e);
          alert("Unable to renew. Please try again later!!!");
        });
    } else {
      const completeURL = subscriptionMenuAPI + "?menuID=" + selectedRenew.item.menuID;

      GetAPI(completeURL)
        .then((res) => {
          const response = res.data.body;
          const itemTotalValue =
            selectedRenew.subscriptionType.toUpperCase() === "MONTHLY"
              ? parseFloat(response.monthlyMenuPrice) * parseInt(selectedRenew.selectedItemQuantity)
              : parseFloat(response.menuPrice) * parseInt(selectedRenew.selectedItemQuantity);
          const monthlyGst = Math.ceil((itemTotalValue + 400) * 0.05);
          const weeklyGst = Math.ceil((itemTotalValue + 100) * 0.05);
          const gstValue = selectedRenew.subscriptionType.toUpperCase() === "MONTHLY" ? monthlyGst : weeklyGst;
          const deliveryChargesValue = selectedRenew.subscriptionType.toUpperCase() === "MONTHLY" ? 400 : 100;
          const grandTotalValue = itemTotalValue + deliveryChargesValue + gstValue;

          dataToPut = {
            subscriptionID: selectedRenew.data.subscriptionID,
            menuID: selectedRenew.item.menuID,
            chefName: selectedRenew.item.chefName,
            chefID: selectedRenew.item.chefID,
            chefCharge: parseFloat(selectedRenew.item.chefCharge),
            itemPrice: selectedRenew.item.itemPrice,
            menuPrice: selectedRenew.subscriptionType.toUpperCase() === "MONTHLY" ? response.monthlyMenuPrice : response.menuPrice,
            subscriptionStatus: selectedRenew.item.subscriptionStatus,
            subscriptionType: selectedRenew.custom === "CUSTOM" ? selectedRenew.item.subscriptionType : selectedRenew.subscriptionType.toUpperCase(),
            startDate: selectedRenew.startDate,
            endDate: selectedRenew.endDate,
            weekendFlag: selectedRenew.data.weekendFlag === undefined ? false : selectedRenew.data.weekendFlag,
            deliverBy: selectedRenew.item.deliverBy,
            distance: selectedRenew.item.distance,
            specialCookingInstruction: selectedRenew.item.specialCookingInstruction,
            itemTotal: itemTotalValue,
            gst: gstValue,
            promoCode: "",
            promoDiscountAmount: 0,
            itemQuantity: parseInt(selectedRenew.selectedItemQuantity),
            grandTotal: grandTotalValue,
          };

          PutAPI(subscriptionOrderAPI, dataToPut)
            .then((res) => {
              window.location.reload();
            })
            .catch((e) => {
              console.log("Error occurred. Please try again. Error =", e);
              alert("Unable to renew. Please try again later");
            });
        })
        .catch((e) => {
          console.log("Error occurred. Please try again. Error =", e);
          alert("Unable to get menu prices to compare. Please try again later");
        });
    }
  };

  const handleDismiss = () => {
    setShowConfirmPopup(false);
    setAssignChefConfirmPopup(false);
    setNotifyChefConfirmPopup(false);
    setRenewConfirmPopup(false);
  };

  return (
    <div>
      <AdminTabs />
      {notVerified ? (
        <AdminModal handleVerified={handleVerified} />
      ) : (
        <div className="subscription container">
          {loading && (
            <div className="spinnerContainer">
              <div className="spinner">
                <ThreeCircles color="blue" height={110} width={110} ariaLabel="three-circles-rotating" />
              </div>
            </div>
          )}
          {showConfirmPopup && (
            <ConfirmPopupModal
              heading="Are you sure you want to create invoice?"
              show={showConfirmPopup}
              handleConfirmPopup={handleInvoiceConfirm}
              handleDismiss={handleDismiss}
              buttonText="Create Invoice"
              hideBody={true}
            />
          )}
          {assignChefConfirmPopup && (
            <ConfirmPopupModal
              heading={"Are you sure you want to assign " + selectedAssignChef.selectedChef.userName}
              show={assignChefConfirmPopup}
              handleConfirmPopup={handleChefConfirm}
              handleDismiss={handleDismiss}
              buttonText={"Assign " + selectedAssignChef.selectedChef.userName}
              hideBody={true}
            />
          )}
          {notifyChefConfirmPopup && (
            <ConfirmPopupModal
              heading={"Are you sure you want to notify " + selectedNotifyChef.selectedChef.userName}
              show={notifyChefConfirmPopup}
              handleConfirmPopup={handleNotifyConfirm}
              handleDismiss={handleDismiss}
              buttonText={"Notify " + selectedNotifyChef.selectedChef.userName}
              hideBody={true}
            />
          )}
          {renewConfirmPopup && (
            <ConfirmPopupModal
              heading="Are you sure you want to renew?"
              show={renewConfirmPopup}
              handleConfirmPopup={handleRenewConfirm}
              handleDismiss={handleDismiss}
              buttonText="Confirm Renew"
              hideBody={true}
            />
          )}

          <div>
            <Link to="/old">
              <button type="button" className="btn btn-sm btn-info my-2">
                Go to old/Rejected Subscriptions
              </button>
            </Link>
            <ul className="nav nav-tabs listgroup" id="myTab" role="tablist">
            <RenderListTitle keyValue="PENDING" value="pending" count={pendingSubscriptionOrdersCount} />
              <RenderListTitle keyValue="NEW" value="new" count={newSubscriptionOrdersCount} />
              <RenderListTitle keyValue="REQUESTED CHEF" value="requestedchef" count={requestedChefSubscriptionOrdersCount} />
              <RenderListTitle keyValue="SENT INVOICE" value="sentinvoice" count={sentInvoiceSubscriptionOrdersCount} />
              <RenderListTitle keyValue="CHEF NOT AVAILABLE" value="chefnotavailable" count={chefNotAvailableSubscriptionOrdersCount} />
              <RenderListTitle keyValue="CHEF REJECTED" value="chefrejected" count={chefRejectedSubscriptionOrdersCount} />
              <RenderListTitle keyValue="UPDATED" value="updated" count={updatedSubscriptionOrdersCount} />
            </ul>
            <div className="tab-content" id="myTabContent">
            <div
                className={activeKey === "PENDING" ? classNameActiveComponent : classNameInactiveComponent}
                id="pending"
                role="tabpanel"
                aria-labelledby="pending-tab"
              >
                <div className="SubscriptionRender">
                  {pendingSubscriptionOrderFound ? (
                    pendingSubscriptionOrders.map((data, id) => (
                      <SubscriptionCard
                        id={id + 1}
                        data={data}
                        chefs={chefs}
                        handleAssignChef={handleAssignChef}
                        handleAdminStatus={handleAdminStatus}
                        handleInvoice={handleInvoice}
                        handleConfirmChef={handleConfirmChef}
                        handleNotifyChef={handleNotifyChef}
                        handleConfirmRenew={handleConfirmRenew}
                      />
                    ))
                  ) : (
                    <h1>No Pending Available</h1>
                  )}
                </div>
              </div>
              <div
                className={activeKey === "NEW" ? classNameActiveComponent : classNameInactiveComponent}
                id="new"
                role="tabpanel"
                aria-labelledby="new-tab"
              >
                <div className="SubscriptionRender">
                  {newSubscriptionOrderFound ? (
                    newSubscriptionOrders.map((data, id) => (
                      <SubscriptionCard
                        id={id + 1}
                        data={data}
                        chefs={chefs}
                        handleAssignChef={handleAssignChef}
                        handleAdminStatus={handleAdminStatus}
                        handleInvoice={handleInvoice}
                        handleConfirmChef={handleConfirmChef}
                        handleNotifyChef={handleNotifyChef}
                        handleConfirmRenew={handleConfirmRenew}
                      />
                    ))
                  ) : (
                    <h1>No New Available</h1>
                  )}
                </div>
              </div>
              <div
                className={activeKey === "REQUESTED CHEF" ? classNameActiveComponent : classNameInactiveComponent}
                id="requestedchef"
                role="tabpanel"
                aria-labelledby="requestedchef-tab"
              >
                <div className="SubscriptionRender">
                  {requestedChefSubscriptionOrdersFound ? (
                    requestedChefSubscriptionOrders.map((data, id) => (
                      <SubscriptionCard
                        id={id + 1}
                        data={data}
                        chefs={chefs}
                        handleAssignChef={handleAssignChef}
                        handleAdminStatus={handleAdminStatus}
                        handleInvoice={handleInvoice}
                        handleConfirmChef={handleConfirmChef}
                        handleNotifyChef={handleNotifyChef}
                        handleConfirmRenew={handleConfirmRenew}
                      />
                    ))
                  ) : (
                    <h1>No Requested Chef Available</h1>
                  )}
                </div>
              </div>
              <div
                className={activeKey === "SENT INVOICE" ? classNameActiveComponent : classNameInactiveComponent}
                id="sentinvoice"
                role="tabpanel"
                aria-labelledby="sentinvoice-tab"
              >
                <div className="SubscriptionRender">
                  {sentInvoiceSubscriptionOrdersFound ? (
                    sentInvoiceSubscriptionOrders.map((data, id) => (
                      <SubscriptionCard
                        id={id + 1}
                        data={data}
                        chefs={chefs}
                        handleAssignChef={handleAssignChef}
                        handleAdminStatus={handleAdminStatus}
                        handleInvoice={handleInvoice}
                        handleConfirmChef={handleConfirmChef}
                        handleNotifyChef={handleNotifyChef}
                        handleConfirmRenew={handleConfirmRenew}
                      />
                    ))
                  ) : (
                    <h1>No Sent Invoice Available</h1>
                  )}
                </div>
              </div>
              <div
                className={activeKey === "CHEF NOT AVAILABLE" ? classNameActiveComponent : classNameInactiveComponent}
                id="chefnotavailable"
                role="tabpanel"
                aria-labelledby="chefnotavailable-tab"
              >
                <div className="SubscriptionRender">
                  {chefNotAvailableSubscriptionOrdersFound ? (
                    chefNotAvailableSubscriptionOrders.map((data, id) => (
                      <SubscriptionCard
                        id={id + 1}
                        data={data}
                        chefs={chefs}
                        handleAssignChef={handleAssignChef}
                        handleAdminStatus={handleAdminStatus}
                        handleInvoice={handleInvoice}
                        handleConfirmChef={handleConfirmChef}
                        handleNotifyChef={handleNotifyChef}
                        handleConfirmRenew={handleConfirmRenew}
                      />
                    ))
                  ) : (
                    <h1>No Chef Not Available data</h1>
                  )}
                </div>
              </div>
              <div
                className={activeKey === "CHEF REJECTED" ? classNameActiveComponent : classNameInactiveComponent}
                id="chefrejected"
                role="tabpanel"
                aria-labelledby="chefrejected-tab"
              >
                <div className="SubscriptionRender">
                  {chefRejectedSubscriptionOrdersFound ? (
                    chefRejectedSubscriptionOrders.map((data, id) => (
                      <SubscriptionCard
                        id={id + 1}
                        data={data}
                        chefs={chefs}
                        handleAssignChef={handleAssignChef}
                        handleAdminStatus={handleAdminStatus}
                        handleInvoice={handleInvoice}
                        handleConfirmChef={handleConfirmChef}
                        handleNotifyChef={handleNotifyChef}
                        handleConfirmRenew={handleConfirmRenew}
                      />
                    ))
                  ) : (
                    <h1>No Chef rejected Available</h1>
                  )}
                </div>
              </div>
              <div
                className={activeKey === "UPDATED" ? classNameActiveComponent : classNameInactiveComponent}
                id="updated"
                role="tabpanel"
                aria-labelledby="updated-tab"
              >
                <div className="subscriptionCountContainer">
                  <h6 id="activeSubscripionCount">Active Subscriptions : {activeSubscriptions}</h6>
                  <h6 id="expiredSubscripionCount">Recently/Partially Expired : {partiallyexpired}</h6>
                </div>
                <div className="SubscriptionRender">
                  {updatedSubscriptionOrderFound ? (
                    updatedSubscriptionOrders.map((data, id) => (
                      <SubscriptionCard
                        id={id + 1}
                        data={data}
                        chefs={chefs}
                        handleAssignChef={handleAssignChef}
                        handleAdminStatus={handleAdminStatus}
                        handleInvoice={handleInvoice}
                        handleConfirmChef={handleConfirmChef}
                        handleNotifyChef={handleNotifyChef}
                        handleConfirmRenew={handleConfirmRenew}
                        handleChefAvailability={handleChefAvailability}
                      />
                    ))
                  ) : (
                    <h1>No Updated Available</h1>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
export default Subscriptions;
