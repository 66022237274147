import React, { useState } from "react";
import { ChefDetailsContext } from "./ChefDetailsContext";

const ChefDetailsProvider = ({ children }) => {
  const [chefDetails, setChefDetails] = useState({});

  return <ChefDetailsContext.Provider value={{ chefDetails, setChefDetails }}>{children}</ChefDetailsContext.Provider>;
};

export default ChefDetailsProvider;
