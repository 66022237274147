import React, { useState, useEffect } from "react";
import "./status.css";
import { RenderCard } from "../Card/Rendercard";
import { callSubscriptionStatusAPI } from "../helpers";
import { ThreeCircles } from "react-loader-spinner";

export const Expired = () => {
  const [expiredOrder, setExpiredOrder] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getExpiredOrder = async () => {
      try {
        const response = await callSubscriptionStatusAPI("EXPIRED");
        setExpiredOrder(response);
      } catch (error) {
        console.error("Error in getexpiredOrder: ", error);
      } finally {
        setLoading(false);
      }
    };

    getExpiredOrder();
  }, []);

  return (
    <div className="container displayCard">
        {loading && (
        <div className="spinnerContainer">
          <div className="spinner">
            <ThreeCircles color="blue" height={110} width={110} ariaLabel="three-circles-rotating" />
          </div>
        </div>
      )}
      {expiredOrder.length > 0 ? (
        expiredOrder.map((item, index) => <RenderCard key={index} subscription={item} />)
      ) : (
        <div>
          <h6>No Expired Subscription Orders</h6>
        </div>
      )}
    </div>
  );
};
