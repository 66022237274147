import React from "react";
import "./footer.css";
import HeaderLogo from "../../images/headerLogo.png";
import GooglePlay from "../../images/googlePlay.png";
import AppStore from "../../images/appStore.png";

function Footer() {
  return (
    <div className="footer" id="footer">
      <div className="footer-container">
        <div className="logo">
          <img className="logo-img" src={HeaderLogo} width="45" height="45" alt="homeal" />
          <h3>Homeal</h3>
        </div>
        <ul className="footer-left">
          <li>
            <a href="/#about">About</a>
          </li>
          <li>
            <a href="https://localzi-public-links.s3.ap-south-1.amazonaws.com/homeal/index.html" rel="noreferrer" target="_blank">
              Terms &amp; Conditions
            </a>
          </li>
          <li>
            <a href="https://localzi-public-links.s3.ap-south-1.amazonaws.com/homeal/index.html" rel="noreferrer" target="_blank">
              Privacy Policy
            </a>
          </li>
          <li>
            <a href="https://merchant.razorpay.com/policy/KUqHUp9DyVqPsU/privacy" rel="noreferrer" target="_blank">
              Payment Privacy Policy
            </a>
          </li>
          <li>
            <a href="https://localzi-public-links.s3.ap-south-1.amazonaws.com/homeal/Return%26Refund.html" rel="noreferrer" target="_blank">
              Payment Refund and Return Policy
            </a>
          </li>
          <li>
            <a href="https://merchant.razorpay.com/policy/KUqHUp9DyVqPsU/terms" rel="noreferrer" target="_blank">
              Payment Terms and Conditions
            </a>
          </li>
          <li>
            <a href="https://localzi-public-links.s3.ap-south-1.amazonaws.com/homeal/Shipping%26Delivery+policies.html" rel="noreferrer" target="_blank">
              Payment Shipping and Delivery Policy
            </a>
          </li>
          <li>
            <p>FSSAI: 11224333001273</p>
          </li>
        </ul>
        <div className="footer-contact">
          <h5>Contact Us</h5>

          <a href="mailto:homeal@localzi.com" rel="noreferrer" target="_blank">
            <i className="fas fa-envelope"></i> homeal@localzi.com
          </a>
          <a href="https://api.whatsapp.com/send?phone=9739813749" rel="noreferrer" target="_blank">
            <i className="fab fa-whatsapp"></i> 9739813749
          </a>
          <a href="http://maps.google.com/?q=24/A First floor, K.R.Colony, Domlur, Karnataka 560071" rel="noreferrer" target="_blank">
            <i className="fa-solid fa-location-dot"></i> 24/A First floor, K.R.Colony, Domlur, Karnataka 560071
          </a>
        </div>
        <div className="footer-follow">
          <h5>Follow Us</h5>
          <div className="sub-follow">
            <div className="follow-icons">
              <a href="https://www.facebook.com/Homeal-107867718214595" rel="noreferrer" target="_blank">
                <i className="fab fa-facebook"></i>
              </a>

              <a href="https://www.instagram.com/homeal.official/" rel="noreferrer" target="_blank">
                <i className="fab fa-instagram"></i>
              </a>

              <a href="https://twitter.com/Homeal9" rel="noreferrer" target="_blank">
                <i className="fab fa-twitter"></i>
              </a>

              <a href="https://www.youtube.com/channel/UCPT0TMw3879ppwtnOlLR5MQ" rel="noreferrer" target="_blank">
                <i className="fab fa-youtube"></i>
              </a>
            </div>
            <div className="follow-icons">
              <a href="https://play.google.com/store/apps/details?id=com.localzi.homeal" target="_blank" rel="noreferrer">
                <img src={GooglePlay} width="120" height="40" alt="googlePlay" />
              </a>
              <a href="https://apps.apple.com/in/app/homeal/id1571860810" target="_blank" rel="noreferrer">
                <img src={AppStore} width="120" height="40" alt="applePlay" />
              </a>
            </div>
          </div>
        </div>
      </div>
      <hr className="hr" />
      <p className="localzi">© 2023 Homeal All Rights Reserved. A PROLOCALZIBIZ TECHNOLOGIES PRIVATE LIMITED Product</p>
    </div>
  );
}
export default Footer;
