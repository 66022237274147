import React, { useState } from "react";
import AdminModal from "../adminModal";
import AdminTabs from "../adminTabs";
import { QRCodeCanvas } from "qrcode.react";
import { ThreeCircles } from "react-loader-spinner";
import GetAPI from "../../APICalls/GetAPI";
import homealLogo from "../../../images/homealLogo.png";
import "./referralGenerate.css";

const ReferralGenerate = () => {
  const [notVerified, setNotVerified] = useState(true);
  const [loading, setLoading] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [userID,setUserID] = useState("");
  const [referralNew, setReferralNew] = useState("");
  const [code, setCode] = useState("");

  const handleVerified = (value) => {
    setNotVerified(value);
  };

  const downloadQRCode = () => {
    const canvas = document.querySelector("canvas");
    const qrCodeURL = canvas.toDataURL("image/png");
    const aEl = document.createElement("a");
    aEl.href = qrCodeURL;
    aEl.download = "QR_Code.png";
    document.body.appendChild(aEl);
    aEl.click();
    document.body.removeChild(aEl);
  };

  const handlePhoneNumberChange = (event) => {
    setPhoneNumber(event.target.value);
  };
  const handleReferralChange = (e) => {
    setReferralNew(e.target.value);
  };

  const handleSubmit1 = (e) => {
    e.preventDefault();
    setReferralNew(e.value);
    window.location.href = `https://homeal.app/referral?campaignID="${referralNew}"`;
  };

  const trackReferal = (userID) => {
    window.location.href = `https://homeal.app/referral?campaignID="${userID}"`;
  };

  const handleSubmit = (event) => {
    setLoading(true);
    event.preventDefault();
    const phonePattern = /^[0-9]{10}$/;

    if (!phonePattern.test(phoneNumber)) {
      setLoading(false);
      alert("Please enter a valid 10-digit phone number");
      return;
    }
    let userApi;

    if (
      process.env.REACT_APP_NODE_ENV === "PRODUCTION" ||
      process.env.REACT_APP_NODE_ENV === undefined
    ) {
      userApi = process.env.REACT_APP_PROD_USERAPI;
    } else {
      userApi = process.env.REACT_APP_DEV_USERAPI;
    }

    const userPhoneApi = `${userApi}?userMobile=91${phoneNumber}`;

    GetAPI(userPhoneApi)
      .then((res) => {
        setCode(res.data.body.referralCode);
        setUserID(res.data.body.userID);
        setLoading(false);
      })
      .catch((e) => {
        console.error(e);
        alert("Unable to get the qr code");
      });
  };

  return (
    <div>
      <AdminTabs />
      {notVerified ? (
        <AdminModal handleVerified={handleVerified} />
      ) : (
        <div>
          <div className="phonecontainer">
            {loading && (
              <div className="spinnercontainer">
                <div className="spinner">
                  <ThreeCircles
                    color="blue"
                    height={110}
                    width={110}
                    ariaLabel="three-circles-rotating"
                  />
                </div>
              </div>
            )}
            <div className="inputcontainer">
              <form onSubmit={handleSubmit}>
              <h3 className="qrheader"><strong>Generate Qrcode</strong></h3>
                <input
                  className="input"
                  type="tel"
                  id="phone"
                  name="phone"
                  onChange={handlePhoneNumberChange}
                  value={phoneNumber}
                  placeholder="Enter phone number"
                />
                <button className="submitButton" type="submit">
                  Submit
                </button>
              </form>
               
              <form onSubmit={handleSubmit1}>
              <h3 className="referralcheck"><strong>Referral count Check</strong></h3>
                <input
                  className="input1"
                  type="string"
                  name="referralcodecheck"
                  onChange={handleReferralChange}
                  value={referralNew}
                  placeholder="Enter referral code"
                />
                <button className="submitButton1" type="submit">
                  Submit
                </button>
              </form>
            </div>
          </div>

          <div className="container">
            {code && (
              <QRCodeCanvas
                fgColor="black"
                bgColor="white"
                value={`https://homeal.app/refer?referralCode=${code}`}
                includeMargin={true}
                margin={10}
                imageSettings={{
                  src: homealLogo,
                  excavate: true,
                }}
                size={350}
              />
            )}
            {code && (
              <div>
                <input
                  className="download"
                  type="button"
                  value="Download"
                  onClick={downloadQRCode}
                />
                <button className="track" onClick={() => trackReferal(userID)}>
                  Track
                </button>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default ReferralGenerate;
