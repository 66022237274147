import React, { useState, useRef, useEffect } from "react";
import CreatableSelect from "react-select/creatable";
import "./addIngredient.css";
import GetAPI from "../APICalls/GetAPI";
import PutAPI from "../APICalls/PutAPI";

const AddIngredient = (props) => {
  //new Ingredient related initializations
  const ingredientOptions = useRef([]);
  const [newIngredient, setNewIngredient] = useState("");
  const [newUnits, setNewUnits] = useState("");
  const [newPrice, setNewPrice] = useState("");
  const [itemID, setItemID] = useState("");
  const [alreadyExists, setAlreadyExists] = useState(false);
  let [fetchItems, setFetchItems] = useState([]);
  const [ingredientSaveToDB, setIngredientSaveToDB] = useState(false);
  const [ingredientFailedToSaveToDB, setIngredientFailedToSaveToDB] = useState(false);
  const [reload, setReload] = useState(false);
  let fetchIngredients;
  let environment = process.env.REACT_APP_NODE_ENV;
  if (environment === "PRODUCTION") {
    fetchIngredients = process.env.REACT_APP_PROD_INGREDIENTS_URL;
  } else {
    fetchIngredients = process.env.REACT_APP_DEV_INGREDIENTS_URL;
  }

  useEffect(() => {
    setReload(false);

    GetAPI(`/ingredients`)
      .then((res) => {
        let items = res.data;
        setFetchItems(items);
        let set = new Set([]);
        items.body.map((item, index) => {
          set.add({
            value: item.ingredientName,
            label: item.ingredientName,
          });
          ingredientOptions.current = Array.from(set);
          return true;
        });
      })
      .catch((e) => {
        console.error(e);
        alert("Error occured");
      });

    // eslint-disable-next-line
  }, [reload]);

  // function to add new ingredients
  const handleNewIngredient = () => {
    if (alreadyExists) {
      const dataToPut = {
        ingredientID: itemID,
        ingredientName: newIngredient,
        itemUnits: newUnits,
        itemPrice: newPrice,
      };

      PutAPI(fetchIngredients, dataToPut)
        .then((res) => {
          if (res.data.statusCode === 200) {
            setIngredientSaveToDB(true);
            setReload(true);
            setNewIngredient("");
            setNewUnits("");
            setNewPrice("");
          } else {
            setNewIngredient("");
            setNewUnits("");
            setNewPrice("");
            setIngredientFailedToSaveToDB(true);
          }
        })
        .catch((e) => {
          setNewIngredient("");
          setNewUnits("");
          setNewPrice("");
          setIngredientFailedToSaveToDB(true);
          console.log("Error occurred. Please try again. Error =", e);
          alert("Error occurred. Please try again later");
        });
    } else {
      const dataToPut = {
        ingredientName: newIngredient,
        itemUnits: newUnits,
        itemPrice: newPrice,
      };

      PutAPI(fetchIngredients, dataToPut)
        .then((res) => {
          if (res.data.statusCode === 200) {
            setIngredientSaveToDB(true);
            setReload(true);
            setNewIngredient("");
            setNewUnits("");
            setNewPrice("");
          } else {
            setNewIngredient("");
            setNewUnits("");
            setNewPrice("");
            setIngredientFailedToSaveToDB(true);
          }
        })
        .catch((e) => {
          setNewIngredient("");
          setNewUnits("");
          setNewPrice("");
          setIngredientFailedToSaveToDB(true);
          console.log("Error occurred. Please try again. Error =", e);
          alert("Error occurred. Please try again later");
        });
    }
  };

  const handleFocus = () => {
    setNewIngredient("");
    setNewUnits("");
    setNewPrice("");
    setIngredientSaveToDB(false);
    setIngredientFailedToSaveToDB(false);
  };

  const handleNewIngredientChange = (selected) => {
    setIngredientSaveToDB(false);
    setIngredientFailedToSaveToDB(false);
    if (selected != null) {
      setNewIngredient(selected.value);
      fetchItems.body.map((data) => {
        if (data.ingredientName.toUpperCase() === selected.value.toUpperCase()) {
          setNewUnits(data.itemUnits);
          setNewPrice(data.itemPrice);
          setItemID(data.ingredientID);
          setAlreadyExists(true);
        }
        return true;
      });
    } else {
      setNewPrice("");
      setNewUnits("");
      setAlreadyExists(false);
    }
  };

  return (
    <div className="addIngredientTab">
      <h4>Add a new Ingredient</h4>
      <div className="newIngredients">
        <div className="col-sm-12 col-md-6 col-lg-3 part">
          <label>Select Ingredient</label>
          <CreatableSelect
            options={ingredientOptions.current}
            placeholder={newIngredient !== "" ? newIngredient : "Select Ingredient"}
            value={newIngredient}
            onMenuOpen={handleFocus}
            onChange={handleNewIngredientChange}
          />
        </div>
        <div className="col-sm-12 col-md-6 col-lg-3 part">
          <label>Select Units</label>
          <select className="form-control" value={newUnits} onChange={(event) => setNewUnits(event.target.value)}>
            <option hidden>Select Units</option>
            <option>kg</option>
            <option>gm</option>
            <option>L</option>
            <option>ml</option>
            <option>min</option>
            <option>Pc</option>
          </select>
        </div>
        <div className="col-sm-12 col-md-6 col-lg-3 part">
          <label>Enter per Price </label>
          <input
            type="text"
            className="form-control"
            placeholder="Enter per Price "
            value={newPrice}
            onChange={(e) => {
              setNewPrice(e.target.value);
            }}
          />
        </div>
        <div className="col-sm-12 col-md-6 col-lg-3 part">
          <label className="addlabel">Add</label>
          <div>
            <button type="button" className="btn btn-outline-primary add" onClick={handleNewIngredient}>
              Add Ingredient
            </button>
          </div>
        </div>
        {ingredientSaveToDB && (
          <div className="alert alert-primary fade show" role="alert">
            <strong>Ingredient Saved Successfully</strong>
            <button type="button" className="close" data-dismiss="alert" aria-label="Close"></button>
          </div>
        )}

        {ingredientFailedToSaveToDB && (
          <div className="alert alert-danger fade show" role="alert">
            <strong>Failed to Save Ingredient. Please try again</strong>
            <button type="button" className="close" data-dismiss="alert" aria-label="Close"></button>
          </div>
        )}
      </div>
    </div>
  );
};

export default AddIngredient;
