import React, { useState, useEffect } from "react";
import "./chefDetails.css";
import Select from "react-select";
import { ThreeCircles } from "react-loader-spinner";
import { TiDelete } from "react-icons/ti";
import moment from "moment";
import AdminTabs from "../adminTabs";
import AdminModal from "../adminModal";
import GetAPI from "../../APICalls/GetAPI";
import PutAPI from "../../APICalls/PutAPI";
import PostAPI from "../../APICalls/PostAPI";

const ChefDetails = () => {
  const [chefs, setChefs] = useState([]);
  const [allChefs, setAllChefs] = useState([]);
  const [selectedUserID, setSelectedUserID] = useState({});
  const [chefDetails, setChefDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [userAvailability, setUserAvailability] = useState(null);
  const [date, setDate] = useState(null);
  const [notVerified, setNotVerified] = useState(true);

  const handleVerified = (value) => {
    setNotVerified(value);
  };

  let environment = process.env.REACT_APP_NODE_ENV;
  let fetchChefDetails;
  let fetchAllChefDetails;
  if (environment === "PRODUCTION" || environment === undefined) {
    fetchChefDetails = process.env.REACT_APP_PROD_USERAPI;
    fetchAllChefDetails = process.env.REACT_APP_PROD_ORDERSTATUS_COMPLETED;
  } else {
    fetchChefDetails = process.env.REACT_APP_DEV_USERAPI;
    fetchAllChefDetails = process.env.REACT_APP_DEV_ORDERSTATUS_COMPLETED;
  }

  useEffect(() => {
    const getChefDetails = () => {
      let completeDetailsURL = fetchChefDetails + "?userType=Chef";

      GetAPI(completeDetailsURL)
        .then((res) => {
          let responseData = res.data;
          let responseBody = responseData.body;
          if (responseData.statusCode === 200) {
            let set = new Set([]);
            let allChefSet = new Set([]);
            responseBody.forEach((item) => {
              if (item.isHomealKitchen === "TRUE") {
                set.add({
                  value: item,
                  label: item.userName + " - " + item.userKitchen,
                });
                setChefs(Array.from(set));
                setLoading(false);
              }
              allChefSet.add({
                value: item,
                label: item.userName + " - " + item.userKitchen + " - " + item.userID,
              });
              setAllChefs(Array.from(allChefSet));
            });
          }
        })
        .catch((e) => {
          console.log("Error occurred. Error =" + e);
          setLoading(false);
          alert("Error occurred. Error =" + e);
        });
    };
    getChefDetails();
  }, []);

  const handleChefClick = (value) => {
    setChefDetails(value);
    setUserAvailability(value.isUserAvailable);
  };

  const handleChefSettlementClick = (value) => {
    setSelectedUserID({ userID: value.userID, userName: value.userName, userMobile: value.userMobile });
  };

  const handleDelete = () => {
    setSelectedUserID({});
  };

  const handleUserAvailability = () => {
    setLoading(true);
    const dataToPut = {
      isUserAvailable: !userAvailability,
      userID: chefDetails.userID,
    };

    PutAPI(fetchChefDetails, dataToPut)
      .then((res) => {
        window.location.reload();
      })
      .catch((e) => {
        setLoading(false);
        console.log("Error occurred. Please try again. Error =", e);
      });
  };

  const handleCompleted = () => {
    setLoading(true);
    let notifyChefAPI;
    if (environment === "PRODUCTION" || environment === undefined) {
      notifyChefAPI = process.env.REACT_APP_PROD_NOTIFYCHEF;
    } else {
      notifyChefAPI = process.env.REACT_APP_PROD_NOTIFYCHEF;
    }
    if (date === undefined || date === null) {
      alert("Enter a date");
      return;
    }
    const dataToPost = {
      chefID: selectedUserID.userID,
      deliveryDate: moment(date).format("YYYY-MM-DD"),
    };

    PostAPI(fetchAllChefDetails, dataToPost)
      .then((res) => {
        alert("Successfully turned to completed!!");
        setSelectedUserID({});

        const headers = {
          "Content-Type": "application/json",
          Token: `${process.env.REACT_APP_WASSENGER_TOKEN}`,
        };

        const message =
          "We have credited 🤑 your account through Razorpay platform for this week settlement.\nPlease check your bank statement 🏦 for clarification.";

        const dataToPost = {
          phone: selectedUserID.userMobile,
          message: message,
        };
        PostAPI(notifyChefAPI, dataToPost, headers)
          .then((res) => {
            if (res.data.deliveryStatus.toUpperCase() === "QUEUED") {
              alert("your message is queued, will be delivered soon!!!");
              window.location.reload();
            } else {
              alert("your message has been delivered successfully!!!");
              window.location.reload();
            }
          })
          .catch((e) => {
            alert("Failed to deliver the message. Send message manually.");
            console.log("Error occurred. Please try again. Error =", e);
          });
      })
      .catch((e) => {
        alert("Failed to turn to completed!! try again later!");
        setSelectedUserID({});
        console.log("error: " + e);
      });
  };

  const getLastFriday = () => {
    const currentDate = new Date();
    const daysToLastFriday = (currentDate.getDay() + 7 - 5) % 7;
    const lastFriday = new Date(currentDate);
    lastFriday.setDate(currentDate.getDate() - daysToLastFriday);
    const formattedLastFriday = lastFriday.toISOString().split('T')[0];
    return formattedLastFriday;

  };

  return (
    <div>
      <AdminTabs />
      {notVerified ? (
        <AdminModal handleVerified={handleVerified} />
      ) : (
        <div className="chefDetails container">
          {loading && (
            <div className="spinnerContainer">
              <div className="spinner">
                <ThreeCircles color="blue" height={110} width={110} ariaLabel="three-circles-rotating" />
              </div>
            </div>
          )}
          <h1>Chef Details Section</h1>
          <h5>Availability Switch</h5>
          <Select options={chefs} onChange={(e) => handleChefClick(e.value)} placeholder="Search by Username or kitchen Name" />
          {userAvailability !== null && (
            <button type="button" className="btn btn-primary btn-sm btn-block my-3" onClick={handleUserAvailability}>
              {userAvailability ? "Turn OFF userAvailability" : "Turn ON userAvailability"}
            </button>
          )}
          <h5>Chef Settlement</h5>
          <Select options={allChefs} onChange={(e) => handleChefSettlementClick(e.value)} placeholder="Search by Username or kitchen Name" />
          <input type="date" value={date} onChange={(e) => setDate(e.target.value)}   max={getLastFriday()}/>
          {Object.keys(selectedUserID).length > 0 && (
            <div>
              <h6>Selected Chef for settlement complete</h6>
              <div className="allchefData">
                <p>
                  {selectedUserID.userID} - {selectedUserID.userName}
                </p>
                <TiDelete
                  size={25}
                  className="delete"
                  onClick={() => {
                    return handleDelete(selectedUserID);
                  }}
                />
              </div>
              <button
                className="btn btn-info btn-block"
                onClick={() => {
                  return handleCompleted();
                }}
              >
                Change Status to Completed
              </button>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default ChefDetails;
