function redirect() {
  const urlParams = new URLSearchParams(window.location.search);
  const referralCode = urlParams.get("referralCode");

  var userAgent = navigator.userAgent || navigator.vendor || window.opera;
  if (userAgent.includes("iPhone")) {
    return window.location.replace(
      "https://apps.apple.com/us/app/homeal/id1571860810"
    );
  } else if (userAgent.includes("Android")) {
    return window.location.replace(
      referralCode !== null ?  `https://play.google.com/store/apps/details?id=com.localzi.homeal&referrer=utm_source=${referralCode}&utm_medium=awesome-med&utm_campaign=awesome-campaign`
      : "https://play.google.com/store/apps/details?id=com.localzi.homeal"
    );
  }
   else {
    return window.location.replace(
      "https://homeal.app"
    );
   }
}

export default redirect;
