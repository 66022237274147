import React, { useState, useEffect } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./RichTextEditor.css";
import "./BlogContainer.css";
import "./BlogCategories/IndividualBlogs/IndividualBlogs.css";
import PostAPI from "../APICalls/PostAPI";
import ConfirmModal from "./ConfirmModal";
import AdminTabs from "../Admin/adminTabs";
import AdminModal from "../Admin/adminModal";

const RichTextEditor = () => {
  const [notVerified, setNotVerified] = useState(true);
  const [selectedCategoryBlog, setSelectedCategoryBlog] = useState([]);
  const [content, setContent] = useState("");
  const [finalData, setFinalData] = useState("");
  const [fileName, setFileName] = useState("");
  const [showModal, setShowModal] = useState(false);
  const handleCloseModal = () => setShowModal(false);
  const handleShowModal = () => setShowModal(true);
  const modules = {
    toolbar: [
      [
        { header: "1" },
        { header: "2" },
        { header: "3" },
        { header: "4" },
        { header: "5" },
        { header: "6" },
      ],
      ["bold", "italic", "underline", "strike"],
      [{ list: "ordered" }, { list: "bullet" }],
      ["link", "image"],
      [{ script: "sub" }, { script: "super" }],
      [{ indent: "-1" }, { indent: "+1" }],
      [{ align: [] }],
      ["blockquote", "code-block"],
      ["clean"],
    ],
  };

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "list",
    "bullet",
    "link",
    "image",
    "script",
    "blockquote",
  ];

  const handleVerified = (value) => {
    setNotVerified(value);
  };

  const handleContentChange = (value) => {
    setContent(value);
    parseContent(value);
  };

  const parseContent = (content) => {
    let parsedContent = content;
    const images = parsedContent.match(/<img[^>]+>/g);
    if (images) {
      images.forEach((imgTag) => {
        const updatedImgTag = imgTag.replace(/"/g, "'");
        const updatedImgWithStyles = updatedImgTag.replace(
          /<img(.*?)>/,
          `<img$1 style="height: 360px; width: 760px; padding: 10px;">`
        );
        parsedContent = parsedContent.replace(imgTag, updatedImgWithStyles);
      });
    }
    const matches = parsedContent.match(/<h1[^>]*>(.*?)<\/h1>/);
    if (matches && matches.length > 1) {
      setFileName(matches[1]);
    }
    setFinalData(parsedContent);
  };

  const handleSubmit = (thumbnailImage) => {
    // Handle submission logic here
    console.log("Submitted Content:", content);
    console.log("Submitted final:", finalData);
    let saveBlogAPI, saveThumbnailAPI;
    let environment = process.env.REACT_APP_NODE_ENV;
    if (environment === "PRODUCTION") {
      saveBlogAPI = process.env.REACT_APP_PROD_BLOGAPI;
      saveThumbnailAPI = process.env.REACT_APP_PROD_FILEUPLOAD;
    } else {
      saveBlogAPI = process.env.REACT_APP_DEV_BLOGAPI;
      saveThumbnailAPI = process.env.REACT_APP_DEV_FILEUPLOAD;
    }

    const dataToPost = {
      data: finalData,
      blogName: fileName,
      categories: selectedCategoryBlog,
    };

    PostAPI(saveBlogAPI, dataToPost)
      .then((res) => {
        if (res.data.statusCode === 200) {
          //alert("Blog Posted Successfully!");
          if (thumbnailImage) {
            const base64Data = thumbnailImage.replace(
              "data:image/png;base64,",
              ""
            );
            const dataToPostThumbnail = {
              blogID: res.data.body.blogID,
              fileType: ".png",
              fileID: res.data.body.blogID,
              flag: "BLOG",
              fileWithBase64: base64Data,
            };
            PostAPI(saveThumbnailAPI, dataToPostThumbnail)
              .then((res) => {
                if (res.data.statusCode === 200) {
                  handleCloseModal();
                  setSelectedCategoryBlog([]);
                  alert("Blog Posted Successfully!");
                  setContent("");
                  setFinalData("");
                  setFileName("");
                } else {
                  alert("error");
                  handleCloseModal();
                }
              })
              .catch((e) => {
                console.log("error ", e);
                alert("error");
              });
          }
        } else {
          alert("error");
          handleCloseModal();
        }
      })
      .catch((e) => {
        console.log("error ", e);
        alert("error");
      });
  };

  useEffect(() => {
    parseContent(content);
  }, []);
  console.log(selectedCategoryBlog);
  return (
    <div>
       <AdminTabs />
      {notVerified ? (
        <AdminModal handleVerified={handleVerified} />
      ) : (
        <>
      <div className="rich-text-editor-container">
        <div className="rich-text-editor-wrapper">
          <h1>Editor</h1>
          <ReactQuill
            theme="snow"
            value={content}
            onChange={handleContentChange}
            modules={modules}
            formats={formats}
          />
        </div>
      </div>
      <div className="submit-button-sticky">
        <button
          type="button"
          className="btn btn-success btn-sm btn-block my-2"
          onClick={handleShowModal}
        >
          Submit
        </button>
      </div>
      <ConfirmModal
        title="Add"
        selectedCategoryBlog={selectedCategoryBlog}
        setSelectedCategoryBlog={setSelectedCategoryBlog}
        handleSubmit={handleSubmit}
        show={showModal}
        handleClose={handleCloseModal}
      />
       </>
      )}
     
    </div>
  );
};

export default RichTextEditor;
