import React from "react";
import "./ratings.css";
import { FaGooglePlay, FaAppStoreIos } from "react-icons/fa";
import { BsGlobe2 } from "react-icons/bs";
import homealLogo from "../../images/homealLogo.png";

const Rating = () => {

  return (
    <div className="container ratingPage">
        
        <h5 className="my-5">
    <img src={homealLogo} className="logoSize float-left" alt="Homeal Logo" />
    <span className="text-right">
        🌟🍱 Loved your experience with Homeal? We'd be thrilled if you could take a moment to rate our service and food! 😊👇 Your feedback is valuable to us, helping us spread the word and reach even more wonderful people through word of mouth! 🗣️🙌
    </span>
</h5>
        <a href="https://play.google.com/store/apps/details?id=com.localzi.homeal" target="_blank" rel="noreferrer" class="btn btn-primary btn-block active" role="button" aria-pressed="true">Rate us in Google Play Store <FaGooglePlay /></a>
        <a href="https://apps.apple.com/in/app/homeal/id1571860810" target="_blank" rel="noreferrer" class="btn btn-primary btn-block active" role="button" aria-pressed="true">Rate us in App Store <FaAppStoreIos /></a>
        <a href="https://g.page/r/CerF22RXAep_EBM/review" target="_blank" rel="noreferrer" class="btn btn-primary btn-block active" role="button" aria-pressed="true">Rate us on Website <BsGlobe2 /></a>
        <img src="https://homeal-favourites-store.s3.ap-south-1.amazonaws.com/appdata/rating.png" className="ratingImg" alt="..." />
    </div>
  )
};

export default Rating;
