import React from "react";
import CheckDate from "./Utils/CheckDate";
import IsMinimumTotal from "./Utils/isMinimumTotal";
import "./RenderPromo.css";

const RenderPromo = ({ item, handleApplyPromo }) => {
  let isBetween = CheckDate(item.effectiveDate, item.expiryDate);
  let IsMinimum = IsMinimumTotal(parseInt(item.minimumItemTotal));
  let isDisabled = !isBetween || !IsMinimum;

  return (
    <div>
      <div className="dotted-border">
        <div>
          <p className="promoID">{item.promoID}</p>
          <p className="promoDescription">{item.description}</p>
        </div>
        <button disabled={isDisabled} className="btn btn-success btn-sm my-2" type="button" onClick={() => handleApplyPromo(item.promoID)}>
          Apply
        </button>
      </div>
    </div>
  );
};

export default RenderPromo;
