import React from "react";
import "./about.css";

function About() {
  return (
    <div className="about" id="about">
      <h3>About Us</h3>
      <div className="about-content">
        <div className="about-left">
          <p>
            Homeal is a platform that serves delicious home food to our home food cravers. You can order your craving food from a home chef of your
            selection in your locality and enjoy the home meal to get healthier every day by eating yummy, tasty, healthy food. The food is prepared
            at chefs' homes where they put in all the love and care to make the food fresh, and we deliver in neatly packed meal boxes. Each plate of
            food is prepared using fresh and locally grown vegetables with authentic regional recipes. We have created a platform wherein you can
            register as Home Chef in few clicks and prepare your signature dishes by serving the customer. We assist you in delivering the food to the
            cravers.
          </p>
        </div>
        <div className="about-right">
          <p>
            Homeal encourage our home chef to become entrepreneurs by assisting with zero commission which includes Food promotion, Food online
            delivery in Bangalore, Food licensing, Price suggestion, Menu Management and Accounts Management.
          </p>
          <p>
            Our vision is to provide an opportunity to the Homemakers by chore a source of financial stability to them. Our Home Chefs can serve any
            homemade food items to customers. Customers can order food by choosing dishes from a chef's menu. We are determined to provide our craver
            with quality service, convenient delivery, and pick up from all of their favored homemade food.
          </p>
        </div>
      </div>
    </div>
  );
}
export default About;
