import moment from "moment";
import PutAPI from "../../APICalls/PutAPI";
import PostAPI from "../../APICalls/PostAPI";
import GetAPI from "../../APICalls/GetAPI";

let environment = process.env.REACT_APP_NODE_ENV;
let userAPI, notifyChefAPI, fileUploadApi, performanceAPI, emiAPI, chefMenuAPI;
if (environment === "PRODUCTION" || environment === undefined) {
  userAPI = process.env.REACT_APP_PROD_USERAPI;
  notifyChefAPI = process.env.REACT_APP_PROD_NOTIFYCHEF;
  fileUploadApi = process.env.REACT_APP_PROD_FILEUPLOAD;
  performanceAPI = process.env.REACT_APP_PROD_CHEFDETAILSAPI;
  emiAPI = process.env.REACT_APP_PROD_CHEFEMI;
  chefMenuAPI = process.env.REACT_APP_PROD_CHEFMENU;
} else {
  userAPI = process.env.REACT_APP_DEV_USERAPI;
  notifyChefAPI = process.env.REACT_APP_DEV_NOTIFYCHEF;
  fileUploadApi = process.env.REACT_APP_DEV_FILEUPLOAD;
  performanceAPI = process.env.REACT_APP_DEV_CHEFDETAILSAPI;
  emiAPI = process.env.REACT_APP_DEV_CHEFEMI;
  chefMenuAPI = process.env.REACT_APP_DEV_CHEFMENU;
}

export const handleStatus = (status, setChefLevelData, setLoading) => {
  let userChefLevelURL = userAPI + "?chefLevel=" + status;

  GetAPI(userChefLevelURL)
    .then((res) => {
      if (res.data.statusCode == 200 || res.data.statusCode == 201) {
        setChefLevelData(res.data.body);
        setLoading(false);
      } else {
        alert("Failed to get chefs data. Please try again later");
        setLoading(false);
      }
    })
    .catch((e) => {
      console.error(e);
      alert("Failed to get chefs data. Please try again later");
      setLoading(false);
    });
};

export const handleVerify = (
  selectedChefData,
  comments,
  setLoading,
  setShowVerifyPopup
) => {
  const dataToPut = {
    userID: selectedChefData.userID,
    isVerified: true,
    verifiedTimestamp: moment().valueOf(),
    comments: comments,
    chefStatus: "VERIFIED",
  };

  PutAPI(userAPI, dataToPut)
    .then((res) => {
      notifyChef({ setShowVerifyPopup, selectedChefData });
    })
    .catch((e) => {
      setLoading(false);
      alert("Failed to verify Chef.Try again!");
      setShowVerifyPopup(false);
      console.log("Error occurred. Please try again. Error =", e);
    });
};

const notifyChef = ({ selectedChefData, setShowVerifyPopup }) => {
  const headers = {
    "Content-Type": "application/json",
    Token: `${process.env.REACT_APP_WASSENGER_TOKEN}`,
  };

  const message =
    "Hello Chef,\nYou are verified as a Chef on our platform.\nYou can start adding menus in app now 🎊\n*Welcome to Homeal* 🙏🏼\nStart adding your menus in the app to get good orders";
  alert("Verified chef successfully!!!");

  const dataToPost = {
    phone: selectedChefData.userMobile,
    message: message,
  };

  PostAPI(notifyChefAPI, dataToPost, headers)
    .then((res) => {
      if (res.data.deliveryStatus.toUpperCase() === "QUEUED") {
        alert("your message is queued, will be delivered soon!!!");
        setShowVerifyPopup(false);
        window.location.reload();
      } else {
        alert("your message has been delivered successfully!!!");
        setShowVerifyPopup(false);
        window.location.reload();
      }
    })
    .catch((e) => {
      setShowVerifyPopup(false);
      alert(
        "Failed to deliver the message. Chef is Verified please send message manually."
      );
      console.log("Error occurred. Please try again. Error =", e);
    });
};

export const handleUpdateChef = (
  selectedChefData,
  profilePicture,
  setLoading
) => {
  setLoading(true);

  const base64Data = profilePicture.replace("data:image/png;base64,", "");

  const dataToPost = {
    userID: selectedChefData.userID,
    fileType: ".png",
    fileID: selectedChefData.userID,
    flag: "CHEF",
    fileWithBase64: base64Data,
  };

  let environment = process.env.REACT_APP_NODE_ENV;
  let fileUploadApi;

  if (environment === "PRODUCTION" || environment === undefined) {
    fileUploadApi = process.env.REACT_APP_PROD_FILEUPLOAD;
  } else {
    fileUploadApi = process.env.REACT_APP_DEV_FILEUPLOAD;
  }

  PostAPI(fileUploadApi, dataToPost)
    .then((res) => {
      if (res.data.statusCode === 200) {
        alert("Uploaded image successfully");
      } else {
        alert("Error uploading image, please try again");
      }
      setLoading(false);
      window.location.reload();
    })
    .catch((e) => {
      setLoading(false);
      console.error(e);
      alert("Error uploading image, please try again");
    });
};

export const handlePerformance = (
  data,
  setChefPerformance,
  setSelectedChefData
) => {
  let chefPerformanceURL =
    performanceAPI + "?chefID=" + data.userID + "&type=CHEFDETAILS";

  GetAPI(chefPerformanceURL)
    .then((res) => {
      if (res.data.statusCode === 201 || res.data.statusCode === 200) {
        setChefPerformance(res.data.body);
        setSelectedChefData(data);
      } else {
        alert("Error in getting chef emi data!!!");
      }
    })
    .catch((e) => {
      console.error(e);
      alert("Error in getting chef emi data. Please try again later");
    });
};

export const handleEmiStatus = (
  data,
  setChefsEMIData,
  setChefName,
  setLoading
) => {
  const getChefsEMI = () => {
    let userChefsURL = emiAPI + "?chefID=" + data.userID;

    GetAPI(userChefsURL)
      .then((res) => {
        if (res.data.statusCode === 201 || res.data.statusCode === 200) {
          setChefsEMIData(res.data.body);
          setChefName(data.userName);
        } else {
          alert("Error in getting chef emi data!!!");
        }
      })
      .catch((e) => {
        console.error(e);
        alert("Error in getting chef emi data. Please try again later");
        setLoading(false);
      });
  };

  getChefsEMI();
};

export const handleShowMenu = (data, setSelectedChefData, setSelectedChefMenu) => {
    setSelectedChefData(data);

    let chefsMenuURL = chefMenuAPI + "?userID=" + data.userID;

    GetAPI(chefsMenuURL)
      .then((res) => {
        if (res.data.body.length > 0) {
          setSelectedChefMenu(res.data.body);
        } else {
          alert("Chef has not added any menu to show!!!");
        }
      })
      .catch((e) => {
        alert(
          "Some technical issue in fetching the menu!!! Please try again later"
        );
        console.log("Error occurred. Error =" + e);
      });
  };

  export  const handleUnverify = (comments, selectedChefData, setLoading) => {
    setLoading(true);
    const dataToPut = {
      userID: selectedChefData.userID,
      isVerified: false,
      unverifiedTimestamp: moment().valueOf(),
      comments: comments,
      chefStatus: "REMOVE",
    };

    PutAPI(userAPI, dataToPut)
      .then((res) => {
        if (res.data.statusCode === 200 || res.data.statusCode === 201) {
          alert("Successfully Removed and Unverified the chef");
          window.location.reload();
        }
      })
      .catch((e) => {
        setLoading(false);
        console.log("Error occurred. Please try again. Error =", e);
      });
  };

  export const handleChefStatus = (status, comments, data, setLoading) => {
    setLoading(true);
    const dataToPut = {
      userID: data.userID,
      isVerified: false,
      comments:
        comments === "" || comments === "N/A" ? data.comments : comments,
      chefStatus: status.toUpperCase(),
    };

    PutAPI(userAPI, dataToPut)
      .then((res) => {
        window.location.reload();
      })
      .catch((e) => {
        setLoading(false);
        console.log("Error occurred. Please try again. Error =", e);
      });
  };

  export const getChefStatusCounts = (setCount, setLoading) => {
    let userChefsURL = userAPI + "?userType=Chef";

    GetAPI(userChefsURL)
      .then((res) => {
        if (res.data.statusCode == 200 || res.data.statusCode == 201) {
          setCount(res.data.body);
        }
        setLoading(false);
      })
      .catch((e) => {
        console.error(e);
        alert("Failed to get chefs data. Please try again later");
        setLoading(false);
      });
  }