
import React,{useState,useEffect} from 'react'
import BlogGeneralCard from "./BlogGeneralCard";
import "./BlogTrendingContainer.css";

import GetAPI from "../APICalls/GetAPI";
const BlogTrendingContainer = () => {
  
  const [data,setData]=useState([])
  const getBlogs=()=>{

    let environment = process.env.REACT_APP_NODE_ENV;
    let getSingleBlogApi;
        if (environment === "PRODUCTION" || environment === undefined) {
          getSingleBlogApi = process.env.REACT_APP_PROD_BLOGAPI;
        } else {
          getSingleBlogApi = process.env.REACT_APP_DEV_BLOGAPI;
        }
        
    GetAPI(`${getSingleBlogApi}?type=All`)
    .then((res)=>{
      if (res.data.statusCode === 200) {
        setData(res.data.body);
    }
  })
    .catch(err=>{
      console.log(err)
    })
  }
useEffect(()=>{
  getBlogs()
},[])
  return (
    <div>
      <div className="trending-container-heading">
      <span>Trending Stories</span>
      <div className="trending-container-heading-line"></div>
      </div>
      <div className="trending-container">
        {data.slice(0,8).map((data,index)=>{
            return(
              <BlogGeneralCard data={data} index={index} />
            )
        })}
    </div>
    </div>
  )
}

export default BlogTrendingContainer