import React from "react";
import "./print.css";
import homealLogo from "../../images/homealLogo.png";

const Print = (props) => {
  const data = props.history.location.state;

  const handlePrint = () => {
    window.print();
  };

  return (
    <div className="print">
      <img src={homealLogo} className="logoSize" alt="..." />
      <p>
        Order ID: <strong> {data.orderID} </strong>
      </p>
      <p>
        Craver Name: <strong> {data.craverDetails.craverName} </strong>
      </p>
      <p>
        Deliver By: <strong> {data.deliverBy.split("-")[0]} </strong>
      </p>
      <table>
        <tr>
          <th>No.</th>
          <th>Name</th>
          <th>Description</th>
          <th>Qty</th>
        </tr>
        {data.orderedItems.map((item, id) => (
          <tr>
            <td>{id + 1}</td>
            <td>
              {item.menuName} <strong>{item.menuStyle}</strong>
            </td>
            <td>{item.description}</td>
            <td>{item.quantity}</td>
          </tr>
        ))}
      </table>
      <p>
        <strong> Special Instructions: {data.specialCookingInstruction} </strong>
      </p>
      <p>
        <strong> Payable Amount : {data.cost.payableAmount}/- </strong>
      </p>
      <p>
        <strong> Payment Status : {data.paymentStatus}/- </strong>
      </p>
      <button id="btnPrint" className="hidden-print" onClick={handlePrint}>
        Print
      </button>
    </div>
  );
};

export default Print;
