import "./App.css";
import React from "react";
import Home from "./Home";
import Ssl from "./ssl";
import Ingredients from "./components/Ingredients/ingredients";
import { Switch, Route } from "react-router-dom";
import Order from "./components/Order/order";
import RegisterAsChef from "./components/RegisterAsChef/registerAsChef";
import Feedback from "./components/Feedback/feedback";
import Billing from "./components/Billing/billing";
import Redirect from "./components/Redirect/redirect";
import ChefStory from "./components/ChefStories/chefStory";
import BlogContainer from "./components/BlogSection/BlogContainer";
import Admin from "./components/Admin/adminTabs";
import OrderStatus from "./components/Admin/OrderStatus";
import Subscription from "./components/Admin/Subscription/Subscription";
import Print from "./components/Admin/print";
import Finance from "./components/Admin/Finance/finance";
import Wallet from "./components/Admin/Wallet/wallet";
import ChefDetails from "./components/Admin/ChefDetails/chefDetails";
import Chefs from "./components/Admin/ChefVerification/Chefs";
import Products from "./components/Products/Products";
import ChefNewMenu from "./components/Admin/ChefVerification/ChefNewMenu";
import ReferralGenerate from "./components/Admin/ReferralGenerate/referralGenerate";
import OldRejectedSubscription from "./components/Admin/Subscription/OldRejectedSubscription";
import PushNotificationPage from "./components/Admin/PushNotifications/pushNotificationPage";
import KitchenMenu from "./components/KitchenMenu/KitchenMenu";
import CheckoutScreen from "./components/KitchenMenu/CheckoutScreen";
import OrderProvider from "./components/KitchenMenu/OrderProvider";
import ChefDetailsProvider from "./components/KitchenMenu/ChefDetailsProvider";
import Rating from "./components/Admin/Rating";
import Referral from "./components/Referrals/referral";
import Sub from "./components/Admin/Subscription2.0/subscription";
import SubscriptionProvider from "./components/Admin/Subscription2.0/context/SubscriptionProvider";
import BlogPost from "./components/BlogSection/BlogPost";
import SingleCategories from "./components/BlogSection/SingleCategories";
import RichTextEditor from "./components/BlogSection/RichTextEditor";
import EditRichTextEditor from "./components/BlogSection/EditRichTextEditor";
import BlogsEdit from "./components/BlogSection/BlogsEdit";
import Metrics from "./components/Admin/Metrics/metrics";
import { HelmetProvider } from 'react-helmet-async';
import CorporateOrder from "./components/Admin/CorporateOrders/corporateOrder";

function App() {
  return (
    <HelmetProvider>
    <div className="App">
      <OrderProvider>
        <ChefDetailsProvider>
        <SubscriptionProvider>
          <Switch>
            <Route exact path="/" component={Home}></Route>
            <Route path="/.well-known/pki-validation/godaddy.html" component={Ssl}></Route>
            <Route path="/ingredients" component={Ingredients}></Route>
            <Route path="/order" component={Order}></Route>
            <Route path="/feedback" component={Feedback}></Route>
            <Route path="/bangalore/home-chef-registration" component={RegisterAsChef}></Route>
            <Route path="/billing" component={Billing}></Route>
            <Route path="/refer" component={Redirect}></Route>
            <Route path="/chefStory" component={ChefStory}></Route> 
            <Route path="/blogsAdd" component={RichTextEditor}></Route>          
            <Route path="/blogseditor/:blogID" component={EditRichTextEditor}></Route>
            <Route exact path="/blogs/:blogName/:blogID" component={BlogPost} />
            <Route path="/blogs" component={BlogContainer}></Route>
            <Route path="/blogsEdit" component={BlogsEdit}></Route>
            <Route path="/singleCategory/:title" component={SingleCategories}></Route>
            <Route path="/admin" component={Admin}></Route>
            <Route path="/orderStatus" component={OrderStatus}></Route>
            <Route path="/subscription" component={Subscription}></Route>
            <Route path="/old" component={OldRejectedSubscription}></Route>
            <Route path="/print" component={Print}></Route>
            <Route path="/finance" component={Finance}></Route>
            <Route path="/wallet" component={Wallet}></Route>
            <Route path="/chefDetails" component={ChefDetails}></Route>
            <Route path="/corporateOrder" component={CorporateOrder}></Route>
            <Route path="/chefVerification" component={Chefs}></Route>
            <Route path="/products" component={Products}></Route>
            <Route path="/newChefMenu" component={ChefNewMenu}></Route>
            <Route path="/referralGenerate" component={ReferralGenerate}></Route>
            <Route path="/pushNotification" component={PushNotificationPage}></Route>
            <Route path="/kitchenMenu" component={KitchenMenu}></Route>
            <Route path="/checkout" component={CheckoutScreen} />
            <Route path="/rating" component={Rating} />
            <Route path="/referral" component={Referral}></Route>
            <Route path="/sub" component={Sub}></Route>
            <Route path="/metrics" component={Metrics}></Route>
          </Switch>
          </SubscriptionProvider>
        </ChefDetailsProvider>
      </OrderProvider>
  
    </div>
    </HelmetProvider>
  );
}
export default App;
