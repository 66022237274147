import GetAPI from "../../APICalls/GetAPI";

export const Tab = ({ tabId, isActive, count, handleTabClick }) => (
    <li className="nav-item" role="presentation">
      <button
        className={`nav-link mx-2 ${isActive ? 'active' : ''}`}
        id={`${tabId}-tab`}
        data-toggle="tab"
        data-target={`#${tabId}`}
        type="button"
        role="tab"
        aria-controls={tabId}
        aria-selected={isActive}
        onClick={() => handleTabClick(tabId)}
      >
        {tabId.toUpperCase()} - {count}
      </button>
    </li>
  );

  export const TabContent = ({ tabId, isActive, children }) => (
    <div
      className={`tab-pane fade ${isActive ? 'show active' : ''}`}
      id={tabId}
      role="tabpanel"
      aria-labelledby={`${tabId}-tab`}
    >
      {children}
    </div>
  );

  export const StatusDropdown = ({ showCard, handleStatus, subscriptionData }) => (
    <div className="btn-group">
            <button type="button" className="btn btn-info btn-sm dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              {showCard !== "" ? showCard : subscriptionData.adminStatus}
            </button>
            {subscriptionData.paymentStatus !== undefined && subscriptionData.paymentStatus === "PENDING" ?
            <div className="dropdown-menu">
            <p className="dropdown-item" onClick={() => handleStatus("New")}>
              New
            </p>
            <p className="dropdown-item" onClick={() => handleStatus("Removed")}>
                Removed
              </p>
            </div>
         :
            <div className="dropdown-menu">
              <p className="dropdown-item" onClick={() => handleStatus("Chef not Available")}>
                Chef not Available
              </p>
              <p className="dropdown-item" onClick={() => handleStatus("Requested Chef")}>
                Requested Chef
              </p>
              <p className="dropdown-item" onClick={() => handleStatus("Chef Rejected")}>
                Chef Rejected
              </p>
              <p className="dropdown-item" onClick={() => handleStatus("Renew Rejected")}>
                Renew Rejected
              </p>
              <p className="dropdown-item" onClick={() => handleStatus("Removed")}>
                Removed
              </p>
            </div>}
           
          </div>
  )

  export const callSubscriptionStatusAPI = async (status) => {
    let getsubscriptionHistoryAPI;
    let environment = process.env.REACT_APP_NODE_ENV;
  
    if (environment === "PRODUCTION" || environment === undefined) {
      getsubscriptionHistoryAPI = process.env.REACT_APP_PROD_SUBSCRIPTIONHISTORY;
    } else {
      getsubscriptionHistoryAPI = process.env.REACT_APP_DEV_SUBSCRIPTIONHISTORY;
    }
  
    let completeStatusUrl = getsubscriptionHistoryAPI + "?status=" + status;
  
    try {
      const res = await GetAPI(completeStatusUrl);  
      if (res.data.statusCode === 200 || res.data.statusCode === 201) {
        return JSON.parse(res.data.body); 
      } else {
        throw new Error("Error in fetching subscription ID's. Technical error. Please try again");
      }
    } catch (e) {
      console.error("Error occurred. Error =", e);
      throw e; 
    }
  };

  export const handleAssignChef = async  (subscriptionData) => {
    let getChefsAPI;
    let environment = process.env.REACT_APP_NODE_ENV;
     if (environment === "PRODUCTION" || environment === undefined) {
      getChefsAPI = process.env.REACT_APP_PROD_GETCHEFBYDIS;
    } else {
      getChefsAPI = process.env.REACT_APP_DEV_GETCHEFBYDIS;
    }

    const lat = subscriptionData.craverDetails.addressCoordinates.lat;
    const lng = subscriptionData.craverDetails.addressCoordinates.lng;

    let getChefsURL = getChefsAPI + "?lat=" + lat + "&lng=" + lng;
    const customHeader = {
        "Content-type": "application/json; charset=UTF-8",
        Authorization: `${process.env.REACT_APP_TOKEN}`
      }
    try {
      const res = await GetAPI(getChefsURL, customHeader); 
      if (res.status === 200 || res.status === 201) {
        let set = new Set([]);
        res.data.forEach((item) => {
          set.add({
            value: item.chefID,
            label: item.chefName + " - " + item.chefDistance,
            isDisabled: item.isUserAvailable !== undefined ? item.isUserAvailable.toUpperCase() === "TRUE" ? false : true : false
          });
        });
          return Array.from(set)
      } else {
        throw new Error("Error in fetching subscription ID's. Technical error. Please try again");
      }
    } catch (e) {
      console.error("Error occurred. Error =", e);
      throw e; 
    }

  }

  export const RenderShowCard = ({ showCard, handleAdminStatus }) => {
    let comments;
    return (
      <div>
        <div className="input-group input-group-sm mb-3">
          <div className="input-group-prepend">
            <span className="input-group-text" id="basic-addon1">
              Comments
            </span>
          </div>
          <input
            className="form-control"
            type="text"
            aria-label="Comments"
            aria-describedby="basic-addon1"
            value={comments}
            onChange={(e) => {
              comments = e.target.value;
            }}
          />
        </div>

        <button
          type="button"
          className="btn btn-dark btn-sm"
          onClick={() => {
            handleAdminStatus(showCard, comments);
          }}
        >
          Save Admin Status
        </button>
      </div>
    );
  };


  export const daysRemaining = (endDate) => {
    const end = new Date(endDate);
    const today = new Date();
    const difference = end - today;
    const daysRemaining = Math.ceil(difference / (1000 * 60 * 60 * 24));

    return daysRemaining;
}

export const downloadBase64Excel = (excelBuffer) => {
    
  let base64String = excelBuffer;

  const downloadLink = document.createElement("a");
  const byteCharacters = atob(base64String);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);
  const blob = new Blob([byteArray], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });

  downloadLink.href = URL.createObjectURL(blob);
  let excelFileName = `Expired&AboutToExpireSubscriptions.xlsx`;
  downloadLink.download = excelFileName;
  downloadLink.click();
}