/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState } from "react";
import "antd/dist/antd.min.css";
import "./chefStorySection.css";
import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu";

const data = [
  {
    id: 0,
    chefName: "Home Chef Nagarani",
    imageURL: "https://d2nahbmqd5vvug.cloudfront.net/chefPhotos/nagarani.png",
    description:
      "Home Chef Nagarani is proof that age is just a number. Striving to create an identity for herself, she transformed her talent into an earning career. By bringing different flavors of Tamilnadu to the table with her traditional culinary skills, she makes many Homeal customers reminisce about their grandmother’s aroma.",
  },
  {
    id: 1,
    chefName: "Home Chef Suman",
    imageURL: "https://d2nahbmqd5vvug.cloudfront.net/chefPhotos/suman.png",
    description:
      "Suki's Kitchen, An entrepreneur, a homemaker, self-motivated and passionate about cooking, Suman likes to cook and serve food. She carries 23 years of work experience and has worked in the corporate world. Her main intention in starting this service is to help senior citizens in the community whose children are abroad, and it would be difficult for them with day-to-day cooking. She gives Homely, Healthy, and Hygiene homemade food. She has been in this service for the last three years and catering across Bangalore South. Her strength is catering to South Indian traditional authentic Brahmin food. She has now joined hands with us to expand the taste of her cooking to many of our cravers. Her signature breakfast dishes are Akki roti with tomato chutney, Paddu with shenga chutney, Poori, and channa masala. Lunch are Jolada roti, Bisibelebath, Vegetable Pulao, Vegetable Biryani, Avial, Puliyogre. She is part of the homeal team and inspires many home chefs to build their own identity.",
  },
  {
    id: 2,
    chefName: "Home Chef Naresh",
    imageURL: "https://d2nahbmqd5vvug.cloudfront.net/chefPhotos/Naresh.png",
    description:
      "Home Chef Naresh has been exploring his remarkable culinary skills for over 15 years. His palette offers a unique variety of North Indian, Chinese and Continental cuisine. His talent goes to crossing the highest order target of homemade food delivery in Bangalore in a short time at Homeal.",
  },
  {
    id: 3,
    chefName: "Home Chef Sheela",
    imageURL: "https://d2nahbmqd5vvug.cloudfront.net/chefPhotos/sheela.png",
    description:
      "Home Chef Sheela, a strong mother and an excellent cook from a small village specializes in Nati style homemade food. With her ability to prepare bulk orders single handedly, she has been able to waddle through the financial crisis during the pandemic by delivering customers with her healthy and tasty homemade food made with hygiene and safety.",
  },
  {
    id: 4,
    chefName: "Home Chef Venkatesh",
    imageURL: "https://d2nahbmqd5vvug.cloudfront.net/chefPhotos/venkatesh.png",
    description:
      "Home Chef Venkatesh has magic in his hands. With 18 plus years of his experience, he prepares all kinds of South Indian food delicacies. Especially UttarKarnataka foods are his signature dishes. Driven by passion along with his incredible cooking skills, he is able to win the heart of many home food cravers.",
  },
  {
    id: 5,
    chefName: "Home Chef Sowmya Sudhi",
    imageURL: "https://d2nahbmqd5vvug.cloudfront.net/chefPhotos/sowmyasudhi.png",
    description:
      "Home Chef Soumya Sudhi brings innovation to the table through her creative baking skills.  Proudly owning her own business ‘Soumya’s Choco happy luscious’ did not stop her from expanding her platform to reach people with her delicious pastries and cookies. ",
  },
  {
    id: 6,
    chefName: "Home Chef Prabha",
    imageURL: "https://d2nahbmqd5vvug.cloudfront.net/chefPhotos/prabha.png",
    description:
      "Home Chef Prabha makes yummy delicious healthy varieties of eggless cakes. Baking makes her happy. Mainly all her cakes are prepared with healthy ingredients. after joining Homeal she is able to get a lot of party orders. from kids to elders, all are great fan of her cakes.",
  },
  {
    id: 7,
    chefName: "Home Chef Arundhati",
    imageURL: "https://d2nahbmqd5vvug.cloudfront.net/chefPhotos/arundathi.png",
    description:
      "Home Chef Arundhati is a passionate chef , who prepares delicious Khova jamoon and other sweets. She was a person who was only serving her sweets to her friends and families. but now after joining Homeal many of the cravers are able to try out her delicious sweets.",
  },
  {
    id: 8,
    chefName: "Home Chef Sahana",
    imageURL: "https://d2nahbmqd5vvug.cloudfront.net/chefPhotos/sahana.png",
    description:
      "Home Chef Sahana, being a mother and a corporate working professional did not stop her from starting her own business. Currently, she is serving cravers who are looking for authentic homemade vegetarian food. This is an example that Women are born achievers irrespective of their situation.",
  },
  {
    id: 9,
    chefName: "Home Chef Kavya",
    imageURL: "https://d2nahbmqd5vvug.cloudfront.net/chefPhotos/kavya.png",
    description:
      "Home Chef Kavya loves cooking. cooking is something that makes her happy.  she prepares mouthwatering dishes. her hands are magical and after joining Homeal she is able to connect to many cravers and provide soul nourishing home food.",
  },
  {
    id: 10,
    chefName: "Home Chef Sowmya",
    imageURL: "https://d2nahbmqd5vvug.cloudfront.net/chefPhotos/sowmya.png",
    description:
      "Home Chef Sowmya was born in a remote village and currently is living in Bangalore. She always dreamt of starting her own business but circumstances were difficult. This did not stop her to follow her passion and onboarding in homeal.  She is serving many cravers through homeal and she made her own identity. We salute for her passion and hard work.",
  },
];

const chefStory = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [selected, setSelected] = useState([]);
  const [readMore, setReadMore] = useState(-1);
  const isItemSelected = (id) => !!selected.find((el) => el === id);

  const handleRead = (index) => {
    setReadMore(readMore === index ? -1 : index);
  };

  const handleClick =
    (id) =>
    ({ getItemById, scrollToItem }) => {
      const itemSelected = isItemSelected(id);

      setSelected((currentSelected) => (itemSelected ? currentSelected.filter((el) => el !== id) : currentSelected.concat(id)));
    };

  function Card({ onClick, selected, src, description, itemId }) {
    const visibility = React.useContext(VisibilityContext);

    return (
      <div onClick={() => onClick(visibility)} tabIndex={0}>
        <div className="card">
          <a href="/chefStory">
            <img className="card-img-top" src={src} alt="Card cap" />
          </a>
          <div className="card-body">
            <p className="card-text">{readMore === itemId ? description : description.slice(0, 200)}</p>
            <p className="card-read" onClick={() => handleRead(itemId)}>
              {readMore === itemId ? "read less..." : "read more..."}
            </p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="blog">
      <h3>Meet Our Home Chefs</h3>
      <ScrollMenu>
        {data.map((item, idx) => (
          <Card
            itemId={item.id} // NOTE: itemId is required for track items
            title={item.id}
            key={item.id}
            src={item.imageURL}
            description={item.description}
            onClick={handleClick(item.id)}
            selected={isItemSelected(item.id)}
          />
        ))}
      </ScrollMenu>
    </div>
  );
};

export default chefStory;
