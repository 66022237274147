import React, { useState, useEffect } from "react";
import "./pushNotification.css";
import { ThreeCircles } from "react-loader-spinner";
import AdminTabs from "../adminTabs";
import AdminModal from "../adminModal";
import PushNotificationByMenuType from "./pushNotificationByMenuType";
import PushNotificationForAllUsers from "./pushNotificationForAllUsers";
import PushNotificationForNewUser from "./pushNotificationForNewUser";
import PushNotificationFromLastOrder from "./pushNotificationFromLastOrder";
import PushNotificationviaWhatsApp from "./pushNotificationviaWhatsApp";
import PromotionPageModal from "./pushNotificationModal";
import PutAPI from "../../APICalls/PutAPI";

const PromotionPage = () => {
  const [loading, setLoading] = useState(false);
  const [notVerified, setNotVerified] = useState(true);
  const [show, setShow] = useState(false);
  const [data, setData] = useState({});
  const [key, setKey] = useState("Send Push Notification By Menu Type");

  useEffect(() => {
    const getActiveTab = JSON.parse(localStorage.getItem("activePNTab"));
    if (getActiveTab) {
      setKey(getActiveTab);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("activePNTab", JSON.stringify(key));
  }, [key]);

  const handleVerified = (value) => {
    setNotVerified(value);
  };

  const handleClose = () => setShow(false);

  const RenderTabTitle = ({ title }) => {
    return (
      <a
        className={`list-group-item list-group-item-action ${
          title === key ? "active" : ""
        }`}
        id={`list-${title.toLowerCase().replace(/\s+/g, "-")}-list`}
        data-toggle="list"
        href={`#list-${title.toLowerCase().replace(/\s+/g, "-")}`}
        role="tab"
        aria-controls={`list-${title.toLowerCase().replace(/\s+/g, "-")}`}
        onClick={() => setKey(title)}
      >
        {title}
      </a>
    );
  };

  const RenderTabContent = ({ tab, content }) => {
    return (
      <div
        className={`tab-pane fade ${tab === key ? "show active" : ""}`}
        id={`list-${tab.toLowerCase().replace(/\s+/g, "-")}`}
        role="tabpanel"
        aria-labelledby={`list-${tab.toLowerCase().replace(/\s+/g, "-")}-list`}
      >
        {content}
      </div>
    );
  };

  const handleSubmitPromotionData = () => {
    setLoading(true);
    let environment = process.env.REACT_APP_NODE_ENV;
    let promotionDataAPI;
    if (environment === "PRODUCTION" || environment === undefined) {
      promotionDataAPI = process.env.REACT_APP_PROD_PUSHNOTIFICATION;
    } else {
      promotionDataAPI = process.env.REACT_APP_DEV_PUSHNOTIFICATION;
    }

    PutAPI(promotionDataAPI, data)
      .then((res) => {
        if (res.data.statusCode === 200) {
          setLoading(false);
          alert(res.data.body);
          setShow(false);
        } else {
          setLoading(false);
          alert("Failed to send push notification, Try again later!! ");
          setShow(false);
        }
      })
      .catch((e) => {
        console.error(e);
        setLoading(false);
        alert("Failed to send push notification, Try again later!! ", e);
        setShow(false);
      });
  };

  return (
    <div className="promotion">
      {loading && (
        <div className="spinnerContainer">
          <div className="spinner">
            <ThreeCircles
              color="blue"
              height={110}
              width={110}
              ariaLabel="three-circles-rotating"
            />
          </div>
        </div>
      )}
      {show ? (
        <PromotionPageModal
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
          centered
          handleClose={handleClose}
          handleSubmitPromotionData={handleSubmitPromotionData}
        />
      ) : null}
      <AdminTabs />
      {notVerified ? (
        <AdminModal handleVerified={handleVerified} />
      ) : (
        <div className="row my-5 mx-5">
          <div className="col-4">
            <div className="list-group" id="list-tab" role="tablist">
              <RenderTabTitle title="Send Push Notification By Menu Type" />
              <RenderTabTitle title="Send Push Notification for All Users" />
              <RenderTabTitle title="Send Push Notification for New Users" />
              <RenderTabTitle title="Send Push Notification from Last Ordered Date" />
              <RenderTabTitle title="Send Push Notification via Whats App" />
            </div>
          </div>
          <div className="col-8">
            <div className="tab-content" id="nav-tabContent">
              <RenderTabContent
                content={<PushNotificationByMenuType setLoading={setLoading} />}
                tab="Send Push Notification By Menu Type"
              />
              <RenderTabContent
                content={
                  <PushNotificationForAllUsers
                    setLoading={setLoading}
                    setShow={setShow}
                    setData={setData}
                  />
                }
                tab="Send Push Notification for All Users"
              />
              <RenderTabContent
                content={
                  <PushNotificationForNewUser
                    setLoading={setLoading}
                    setShow={setShow}
                    setData={setData}
                  />
                }
                tab="Send Push Notification for New Users"
              />
              <RenderTabContent
                content={
                  <PushNotificationFromLastOrder
                    setLoading={setLoading}
                    setShow={setShow}
                    setData={setData}
                  />
                }
                tab="Send Push Notification from Last Ordered Date"
              />
              <RenderTabContent
                content={
                  <PushNotificationviaWhatsApp
                    setLoading={setLoading}
                    setShow={setShow}
                    setData={setData}
                  />
                }
                tab="Send Push Notification via Whats App"
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PromotionPage;
