import React from "react";
import { useState } from "react";
import moment from "moment";

const PushNotificationFromLastOrder = ({ setShow, setData }) => {
  const [lastOrderedInput, setLastOrderedInput] = useState("");
  const [titleInput, setTitleInput] = useState("");
  const [messageInput, setMessageInput] = useState("");

  const handleSubmit = () => {
    if (lastOrderedInput === "") {
      setShow(false);
      alert("Please select the lastOrderedInput to submit");
      return;
    }
    if (titleInput === "") {
      setShow(false);
      alert("Please enter the title to submit");
      return;
    }
    if (messageInput === "") {
      setShow(false);
      alert("Please enter the message to submit");
      return;
    }
    setData({
      lastordered: moment(lastOrderedInput).format("YYYY-MM-DD"),
      message: messageInput,
      title: titleInput,
    });
    setShow(true);
  };

  return (
    <div className="card ">
      <div className="card-body mx-2">
        <h3 className="my-3  mx-2 ">Send notifications for last orders</h3>
        <div>
          <form>
            <div className="form-row">
              <div className="col-sm-12 col-md-8 col-lg-6 my-2">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text" id="message">
                      Last ordered
                    </span>
                  </div>
                  <input
                    type="date"
                    value={lastOrderedInput}
                    onChange={(e) => {
                      setLastOrderedInput(e.target.value);
                    }}
                    className="form-control "
                    placeholder="Select last ordered Date"
                    required
                  />
                </div>
              </div>
              <div className="col-sm-12 col-md-8 col-lg-6 my-2">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span
                      className="input-group-text"
                      id="validationTooltipUsernamePrepend"
                    >
                      Title
                    </span>
                  </div>
                  <input
                    type="text"
                    value={titleInput}
                    onChange={(e) => setTitleInput(e.target.value)}
                    className="form-control "
                    id="Title"
                    placeholder="Title"
                    aria-describedby="Title"
                    required
                  />
                </div>
              </div>
            </div>

            <div className="form-row">
              <div className="col-sm-12 col-md-12 col-lg-12 my-3">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span
                      className="input-group-text"
                      id="validationTooltipUsernamePrepend"
                    >
                      Message
                    </span>
                  </div>
                  <input
                    type="text"
                    value={messageInput}
                    onChange={(e) => setMessageInput(e.target.value)}
                    className="form-control "
                    id="Message"
                    placeholder="Message"
                    aria-describedby="Message"
                    required
                  />
                </div>
              </div>
            </div>
          </form>
          <div className="col-sm-12 mx-2 my-5">
            <button
              className="btn btn-primary btn-block"
              onClick={handleSubmit}
            >
              {" "}
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PushNotificationFromLastOrder;
