import React, { useState, useEffect } from "react";
import "./feedback.css";
import OrderedItem from "../Order/orderedItem";
import GetAPI from "../APICalls/GetAPI";

const Order = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const orderID = urlParams.get("orderID");
  const [feedbackFound, setFeedbackFound] = useState(false);
  const [feedbackDetails, setFeedbackDetails] = useState({
    chefID: null,
    comment: null,
    craverID: null,
    experience: null,
    orderID: null,
    packing: null,
    quantity: null,
    taste: null,
    temperature: null,
  });

  let environment = process.env.REACT_APP_NODE_ENV;
  /* function to call order details */
  const FeedbackData = () => {
    let feedbackAPI, orderAPI;

    if (environment === "PRODUCTION" || environment === undefined) {
      feedbackAPI = process.env.REACT_APP_PROD_FEEDBACKAPI;

      orderAPI = process.env.REACT_APP_PROD_ORDERAPI;
    } else {
      feedbackAPI = process.env.REACT_APP_DEV_FEEDBACKAPI;
      orderAPI = process.env.REACT_APP_DEV_ORDERAPI;
    }

    let completeOrderURL = feedbackAPI + "?orderID=" + orderID;

    GetAPI(completeOrderURL)
      .then((res) => {
        if (Object.keys(res.data.body).length === 0) {
          setFeedbackFound(false);
        } else {
          setFeedbackFound(true);
          setFeedbackDetails((prevState) => ({
            ...prevState,
            orderID: res.data.body.orderID,
            chefID: res.data.body.chefID,
            comment: res.data.body.comment,
            craverID: res.data.body.craverID,
            experience: res.data.body.experience,
            packing: res.data.body.packing,
            quantity: res.data.body.quantity,
            taste: res.data.body.taste,
            temperature: res.data.body.temperature,
          }));

          let completeOrderURL = orderAPI + "?orderID=" + orderID;

          GetAPI(completeOrderURL)
            .then((res) => {
              setFeedbackDetails((prevState) => ({
                ...prevState,
                chefName: res.data.body.chefDetails.chefName,
                chefMobileNumber: res.data.body.chefDetails.chefMobile,
                craverName: res.data.body.craverDetails.craverName,
                craverMobileNumber: res.data.body.craverDetails.craverMobile,
                itemsOrdered: res.data.body.orderedItems,
                menuType: res.data.body.menuType,
              }));
            })
            .catch((e) => {
              console.error(e);
              alert("Unable to get feedback details");
            });
        }
      })

      .catch((e) => {
        console.error(e);
        alert("Unable to get feedback details");
      });
  };

  useEffect(() => {
    FeedbackData();
  });

  useEffect(() => {}, [feedbackDetails]);
  return (
    <div className="order">
      {feedbackFound ? (
        <div>
          <h3>feedback Details</h3>
          <h3>
            Order ID:{"  "}
            {orderID}
          </h3>
          {feedbackDetails.orderStatus !== null && (
            <div className="card">
              <div className="card-body">
                <ul className="list-group list-group-flush">
                  <li className="list-group-item active">Chef Details</li>
                  <li className="list-group-item">
                    <strong>Chef Name: </strong> {feedbackDetails.chefName}
                  </li>
                  <li className="list-group-item">
                    <strong>Chef Mobile No: </strong>{" "}
                    <a href={"tel:" + feedbackDetails.chefMobileNumber} rel="noreferrer">
                      {feedbackDetails.chefMobileNumber}
                    </a>
                  </li>
                </ul>
                <ul className="list-group list-group-flush">
                  <li className="list-group-item active">Craver Details</li>
                  <li className="list-group-item">
                    <strong>Craver Name: </strong> {feedbackDetails.craverName}
                  </li>
                  <li className="list-group-item">
                    <strong>Craver Mobile No: </strong>{" "}
                    <a href={"tel:" + feedbackDetails.craverMobileNumber} rel="noreferrer">
                      {feedbackDetails.craverMobileNumber}
                    </a>
                  </li>
                </ul>

                <ul className="list-group list-group-flush">
                  <li className="list-group-item active">Order Details</li>
                  <li className="list-group-item">
                    <strong>Menu Type: </strong> {feedbackDetails.menuType}
                  </li>
                </ul>

                <ul className="list-group list-group-flush">
                  <li className="list-group-item active">Ordered Items</li>
                  <div className="itemHeadingContainer">
                    <p className="itemNameHeading">
                      <strong>Item Name</strong>
                    </p>
                    <p>
                      <strong>Item Price</strong>
                    </p>
                    <p>
                      <strong>Item Quantity</strong>
                    </p>
                  </div>
                  {feedbackDetails.itemsOrdered != undefined && feedbackDetails.itemsOrdered.map((item) => <OrderedItem key={1} item={item} />)}
                </ul>
                <ul className="list-group list-group-flush">
                  <li className="list-group-item active">Feedback Details</li>
                  <li className="list-group-item">
                    <strong>Taste: </strong> {feedbackDetails.taste}
                  </li>
                  <li className="list-group-item">
                    <strong>Temperature: </strong> {feedbackDetails.temperature}
                  </li>
                  <li className="list-group-item">
                    <strong>Quantity: </strong> {feedbackDetails.quantity}
                  </li>
                  <li className="list-group-item">
                    <strong>Packing: </strong> {feedbackDetails.packing}
                  </li>
                  <li className="list-group-item">
                    <strong>Experience: </strong> {feedbackDetails.experience}
                  </li>
                  <li className="list-group-item">
                    <strong>Comments: </strong> {feedbackDetails.comment}
                  </li>
                </ul>
              </div>
            </div>
          )}
        </div>
      ) : (
        <div>
          <h2>No feedback found</h2>
        </div>
      )}
    </div>
  );
};
export default Order;
