import React, { useState, useEffect } from "react";
import "./subscription.css";
import { HashLink as Link } from "react-router-hash-link";
import { ThreeCircles } from "react-loader-spinner";
import SubscriptionCard from "./SubscriptionCard";
import GetAPI from "../../APICalls/GetAPI";
import PutAPI from "../../APICalls/PutAPI";

function Subscriptions() {
  const [activeKey, setActiveKey] = useState("NEW");
  const [oldSubscriptionOrderFound, setOldSubscriptionOrderFound] = useState(false);
  const [oldSubscriptionOrders, setOldSubscriptionOrders] = useState([]);
  const [oldSubscriptionOrdersCount, setOldSubscriptionOrdersCount] = useState(0);
  const [renewRejectedSubscriptionOrderFound, setRenewRejectedSubscriptionOrderFound] = useState(false);
  const [renewRejectedSubscriptionOrders, setRenewRejectedSubscriptionOrders] = useState([]);
  const [renewRejectedSubscriptionOrdersCount, setRenewRejectedSubscriptionOrdersCount] = useState(0);

  const [notVerified, setNotVerified] = useState(true);
  const [chefs, setChefs] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getAdminDetails = localStorage.getItem("AdminDetails");
    if (getAdminDetails) {
      setNotVerified(false);
    }
  }, []);

  useEffect(() => {
    const getActiveTab = JSON.parse(localStorage.getItem("activeTab"));
    if (getActiveTab) {
      setActiveKey(getActiveTab);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("activeTab", JSON.stringify(activeKey));
  }, [activeKey]);

  let environment = process.env.REACT_APP_NODE_ENV;

  useEffect(() => {
    /* function to call order details */
    const subscriptionOrderStatus = () => {
      let subscriptionOrderAPI;
      if (environment === "PRODUCTION" || environment === undefined) {
        subscriptionOrderAPI = process.env.REACT_APP_PROD_SUBSCRIPTION_ORDER_API;
      } else {
        subscriptionOrderAPI = process.env.REACT_APP_DEV_SUBSCRIPTION_ORDER_API;
      }

      let subscriptionNewOrderURL = subscriptionOrderAPI + "?isChefAssigned=false";
      let subscriptionUpdatedOrderURL = subscriptionOrderAPI + "?isChefAssigned=true";

      GetAPI(subscriptionNewOrderURL)
        .then((res) => {
          let oldSet = new Set();
          if (Object.keys(res.data.body).length === 0) {
            setOldSubscriptionOrderFound(false);
          } else {
            setOldSubscriptionOrderFound(true);
            res.data.body.forEach((item) => {
              if (
                item.adminStatus.toUpperCase() !== "NEW" &&
                item.adminStatus.toUpperCase() !== "N/A" &&
                item.adminStatus.toUpperCase() !== "SENT INVOICE" &&
                item.adminStatus.toUpperCase() !== "REQUESTED CHEF" &&
                item.adminStatus.toUpperCase() !== "CHEF NOT AVAILABLE" &&
                item.adminStatus.toUpperCase() !== "CHEF REJECTED"
              ) {
                oldSet.add(item);
              }
            });

            setOldSubscriptionOrders(Array.from(oldSet));
            setOldSubscriptionOrdersCount(Array.from(oldSet).length);

            oldSet = new Set();
          }
        })
        .catch((e) => {
          console.error(e);
          alert("Unable to get data");
        });

      GetAPI(subscriptionUpdatedOrderURL)
        .then((res) => {
          if (Object.keys(res.data.body).length === 0) {
            setRenewRejectedSubscriptionOrderFound(false);
          } else {
            setRenewRejectedSubscriptionOrderFound(true);

            let renewRejectedSet = new Set();
            res.data.body.forEach((item) => {
              if (item.adminStatus.toUpperCase() === "REMOVED") {
                renewRejectedSet.add(item);
              }
            });

            setRenewRejectedSubscriptionOrders(Array.from(renewRejectedSet));
            setRenewRejectedSubscriptionOrdersCount(Array.from(renewRejectedSet).length);

            renewRejectedSet = new Set();
          }
        })
        .catch((e) => {
          console.error(e);
          alert("Unable to get data");
        });
    };

    subscriptionOrderStatus();
  }, []);

  let classNameInactive = "nav-link";
  let classNameActive = "nav-link active show";
  let classNameInactiveComponent = "tab-pane fade";
  let classNameActiveComponent = "tab-pane fade show active";

  const handleAssignChef = (data) => {
    setLoading(true);
    let getChefsAPI;
    if (environment === "PRODUCTION" || environment === undefined) {
      getChefsAPI = process.env.REACT_APP_PROD_GETCHEFBYDIS;
    } else {
      getChefsAPI = process.env.REACT_APP_DEV_GETCHEFBYDIS;
    }

    const lat = data.craverDetails.addressCoordinates.lat;
    const lng = data.craverDetails.addressCoordinates.lng;

    let getChefsURL = getChefsAPI + "?lat=" + lat + "&lng=" + lng;

    GetAPI(getChefsURL)
      .then((res) => {
        setLoading(false);
        if (res.data.length > 0) {
          let set = new Set([]);
          res.data.forEach((item) => {
            set.add({
              value: item.chefID,
              label: item.chefName + " - " + item.chefDistance + "Km",
            });
            setChefs(Array.from(set));
          });
        }
      })
      .catch((e) => {
        console.error(e);
        alert("Unable to get chef data");
      });
  };

  const handleAdminStatus = (showCard, comments, data) => {
    setLoading(true);
    let environment = process.env.REACT_APP_NODE_ENV;
    let subscriptionOrderAPI;
    if (environment === "PRODUCTION" || environment === undefined) {
      subscriptionOrderAPI = process.env.REACT_APP_PROD_SUBSCRIPTION_ORDER_API;
    } else {
      subscriptionOrderAPI = process.env.REACT_APP_DEV_SUBSCRIPTION_ORDER_API;
    }

    const dataToPut = {
      subscriptionID: data,
      admincomments: comments === undefined ? "N/A" : comments,
      adminStatus: showCard,
    };

    PutAPI(subscriptionOrderAPI, dataToPut)
      .then((res) => {
        window.location.reload();
      })
      .catch((e) => {
        console.error(e);
        setLoading(false);
        alert("Unable to recharge wallet outsides. Please try again later");
      });
  };

  const RenderListTitle = ({ keyValue, value, count }) => {
    return (
      <li className="nav-item">
        <a
          className={activeKey === keyValue ? classNameActive : classNameInactive}
          id={`${value}-tab`}
          data-toggle="tab"
          href={`#${value}`}
          role="tab"
          onClick={() => {
            setActiveKey(keyValue);
          }}
          aria-controls={value}
          aria-selected="true"
        >
          {keyValue} {count}
        </a>
      </li>
    );
  };

  return (
    <div className="subscription container">
      {loading && (
        <div className="spinnerContainer">
          <div className="spinner">
            <ThreeCircles color="blue" height={110} width={110} ariaLabel="three-circles-rotating" />
          </div>
        </div>
      )}
      {notVerified ? (
        <div>
          <h1>Your not authorized to access this page</h1>
          <div className="verifyButtonsContainer">
            <Link to="/">
              <button type="button" className="btn btn-info">
                Go Home
              </button>
            </Link>
            <Link to="/admin">
              <button type="button" className="btn btn-info">
                Go to Admin Panel to Login
              </button>
            </Link>
          </div>
        </div>
      ) : (
        <div>
          <Link to="/subscription">
            <button type="button" className="btn btn-sm btn-info my-2">
              Go to new/updated Subscriptions
            </button>
          </Link>
          <ul className="nav nav-tabs listgroup" id="myTab" role="tablist">
            <RenderListTitle keyValue="RENEW REJECTED" value="renewrejected" count={renewRejectedSubscriptionOrdersCount} />
            <RenderListTitle keyValue="OLD" value="old" count={oldSubscriptionOrdersCount} />
          </ul>
          <div className="tab-content" id="myTabContent">
            <div
              className={activeKey === "RENEW REJECTED" ? classNameActiveComponent : classNameInactiveComponent}
              id="renewrejected"
              role="tabpanel"
              aria-labelledby="renewrejected-tab"
            >
              <div className="SubscriptionRender">
                {renewRejectedSubscriptionOrderFound ? (
                  renewRejectedSubscriptionOrders.map((data, id) => (
                    <SubscriptionCard
                      id={id + 1}
                      data={data}
                      chefs={chefs}
                      handleAssignChef={handleAssignChef}
                      handleAdminStatus={handleAdminStatus}
                    />
                  ))
                ) : (
                  <h1>No Renew rejected Available</h1>
                )}
              </div>
            </div>
            <div
              className={activeKey === "OLD" ? classNameActiveComponent : classNameInactiveComponent}
              id="old"
              role="tabpanel"
              aria-labelledby="old-tab"
            >
              <div className="SubscriptionRender">
                {oldSubscriptionOrderFound ? (
                  oldSubscriptionOrders.map((data, id) => (
                    <SubscriptionCard
                      id={id + 1}
                      data={data}
                      chefs={chefs}
                      handleAssignChef={handleAssignChef}
                      handleAdminStatus={handleAdminStatus}
                    />
                  ))
                ) : (
                  <h1>No old Available</h1>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
export default Subscriptions;
