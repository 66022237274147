import React, { useState, useEffect, useRef } from "react";
import SelectField from "react-select";
import CreatableSelect from "react-select/creatable";
import "./addDish.css";
import GetAPI from "../APICalls/GetAPI";
import PostAPI from "../APICalls/PostAPI";
import PutAPI from "../APICalls/PutAPI";

const AddDish = (props) => {
  //dish related initializations
  const dishOptions = useRef([]);
  const [dish, setDish] = useState("");
  const [dishID, setDishID] = useState(null);
  const [dishQuantity, setDishQuantity] = useState("");
  const [dishQuantityUnit, setDishQuantityUnit] = useState(null);
  const [dishServingPerPlate, setDishServingPerPlate] = useState("");
  const [dishNumberOfPlates, setDishNumberOfPlates] = useState(null);
  const [dishPrice, setDishPrice] = useState("");
  //dish ingredients related initializations
  const ingredientOptions = useRef([]);
  const [ingredient, setIngredient] = useState(null);
  const [selectedIngredient, setSelectedIngredient] = useState(null);
  const [ingredientQuantity, setIngredientQuantity] = useState("");
  const [ingredientUnit, setIngredientUnit] = useState(null);
  const [ingredientUnitOptions, setIngredientUnitOptions] = useState([]);
  //ingredients Display related initializations
  const [ingredientsDisplay, setIngredientsDisplay] = useState([]);
  const ingredientsDisplayValue = useRef([]);

  //total and calculation related Initializations
  const [total, setTotal] = useState(0);
  const [showTotal, setShowTotal] = useState(false);
  //api hook up related Initializations
  const dishIngredient = useRef("");
  const ingredientID = useRef("");
  const dishIngredientQuantity = useRef("");
  const unit = useRef("");
  const price = useRef(0);
  const [itemSaveToDB, setItemSaveToDB] = useState(false);
  const [failedToSaveToDB, setFailedToSaveToDB] = useState(false);

  let [fetchItems, setFetchItems] = useState([]);
  let [fetchDish, setFetchDish] = useState([]);
  const [dishError, setDishError] = useState(false);
  const [error, setError] = useState(false);
  const [duplicateError, setDuplicateError] = useState(false);
  const [showPrice, setShowPrice] = useState(false);
  const [reload, setReload] = useState(false);
  let fetchIngredients, fetchDishes;
  let commission = process.env.REACT_APP_COMMISSION;

  useEffect(() => {
    if (props.tab === 1) {
      setReload(true);
    } else {
      setReload(false);
    }
  }, [props.tab]);

  // function to handle dropdown for ingredients
  const handleSelectIngredientValueChange = (selectedDish) => {
    setSelectedIngredient(selectedDish);
    setIngredientUnitOptions([]);
    if (selectedDish != null) {
      setIngredient(selectedDish.value);

      fetchItems.body.map((data) => {
        if (data.ingredientName !== undefined && data.ingredientName.toUpperCase() === selectedDish.value.toUpperCase()) {
          if (
            data.itemUnits !== undefined &&
            (data.itemUnits.toUpperCase() === "KG" || data.itemUnits.toUpperCase() === "GM" || data.itemUnits.toUpperCase() === "G")
          ) {
            setIngredientUnitOptions([
              {
                value: "kg",
                label: "Kg",
              },
              {
                value: "g",
                label: "g",
              },
            ]);
          }
          if (data.itemUnits !== undefined && (data.itemUnits.toUpperCase() === "L" || data.itemUnits.toUpperCase() === "ML")) {
            setIngredientUnitOptions([
              {
                value: "l",
                label: "l",
              },
              {
                value: "ml",
                label: "ml",
              },
            ]);
          }
          if (data.itemUnits !== undefined && data.itemUnits.toUpperCase() === "MIN") {
            setIngredientUnitOptions([
              {
                value: "min",
                label: "min",
              },
            ]);
          }
          if (data.itemUnits !== undefined && data.itemUnits.toUpperCase() === "PC") {
            setIngredientUnitOptions([
              {
                value: "Pc",
                label: "Pc",
              },
            ]);
          }
        }
        return true;
      });
    }
  };

  const handleSelectQuantityUnitChange = (unit) => {
    setDishError(false);
    setDishQuantityUnit(unit);
  };

  const handleSelectIngredientUnitChange = (selectedIngredientUnit) => {
    setError(false);
    setIngredientUnit(selectedIngredientUnit);
  };

  // function to handle dropdown for items
  const handleSelectDishValueChange = (selectedDish) => {
    setDishError(false);
    if (selectedDish != null) {
      setDish(selectedDish);
      fetchDish.body.map((data) => {
        if (data.itemName !== undefined && data.itemName.toUpperCase() === selectedDish.value.toUpperCase()) {
          setDishPrice(data.suggestedPrice);
          if (data.suggestedPrice !== "") {
            setShowPrice(true);
          } else {
            setShowPrice(false);
          }
          setDishID(data.itemID);
          setDishQuantity(data.itemQuantity);
          setDishQuantityUnit(data.itemQuantityUnit);
          setDishServingPerPlate(data.itemServing);
          setDishNumberOfPlates(data.noOfPlates);

          const completedUrl = `${fetchDishes}?itemID=${data.itemID}`;

          GetAPI(completedUrl)
            .then((res) => {
              if (res.data.body === "Data Not Available") {
                setIngredientsDisplay([]);
              } else {
                ingredientsDisplayValue.current = res.data.body;
                setIngredientsDisplay(res.data.body);
                let sum = 0;
                for (let i = 0; i < res.data.body.length; i++) {
                  sum += res.data.body[i].ingredientPrice;
                }
                setTotal(total + sum);
              }
            })
            .catch((e) => {
              console.error(e);
              alert("Error occured, please try again later");
            });
        }
        return true;
      });
    }
  };

  const handleRemoveIngredients = () => {
    setSelectedIngredient(null);
    setIngredientUnit(null);
  };

  let environment = process.env.REACT_APP_NODE_ENV;

  if (environment === "PRODUCTION") {
    fetchIngredients = process.env.REACT_APP_PROD_INGREDIENTS_URL;
    fetchDishes = process.env.REACT_APP_PROD_ITEM_URL;
  } else {
    fetchIngredients = process.env.REACT_APP_DEV_INGREDIENTS_URL;
    fetchDishes = process.env.REACT_APP_DEV_ITEM_URL;
  }

  useEffect(() => {
    setReload(false);

    GetAPI(fetchDishes)
      .then((res) => {
        let dishes = res.data;
        setFetchDish(dishes);
        let set = new Set([]);
        dishes.body.map((item, index) => {
          set.add({
            value: item.itemName,
            label: item.itemName,
          });
          dishOptions.current = Array.from(set);
          return true;
        });
      })
      .catch((e) => {
        console.error(e);
        alert("Error occured, please try again later");
      });

    GetAPI(fetchIngredients)
      .then((res) => {
        let items = res.data;
        setFetchItems(items);
        let set = new Set([]);
        items.body.map((item, index) => {
          set.add({
            value: item.ingredientName,
            label: item.ingredientName,
          });
          ingredientOptions.current = Array.from(set);
          return true;
        });
      })
      .catch((e) => {
        console.error(e);
        alert("Error occured, please try again later");
      });

    // eslint-disable-next-line
  }, [reload]);

  const handleSubmit = () => {
    setDuplicateError(false);
    if (ingredient == null) {
      setError(true);
      return;
    }
    if (ingredientQuantity === "") {
      setError(true);
      return;
    }
    if (ingredientUnit === null) {
      setError(true);
      return;
    }
    dishIngredient.current = ingredient;
    dishIngredientQuantity.current = ingredientQuantity;
    unit.current = ingredientUnit.value;
    let totalCost = 0;
    fetchItems.body.map((data) => {
      if (data.ingredientName.toUpperCase() === dishIngredient.current.toUpperCase()) {
        ingredientID.current = data.ingredientID;
        if (data.itemUnits.toUpperCase() === "G" || data.itemUnits.toUpperCase() === "GM" || data.itemUnits.toUpperCase() === "ML") {
          if (unit.current.toUpperCase() === "KG" || unit.current.toUpperCase() === "L") {
            price.current = data.itemPrice * (dishIngredientQuantity.current * 1000);
          } else {
            price.current = data.itemPrice * dishIngredientQuantity.current;
          }
        } else {
          if (unit.current.toUpperCase() === "G" || unit.current.toUpperCase() === "GM" || unit.current.toUpperCase() === "ML") {
            price.current = data.itemPrice * (dishIngredientQuantity.current / 1000);
          } else {
            price.current = data.itemPrice * dishIngredientQuantity.current;
          }
        }
      }
      return true;
    });

    setSelectedIngredient(null);
    setIngredientQuantity("");
    setIngredientUnit(null);
    setIngredientUnitOptions([]);
    ingredientsDisplayValue.current.push({
      ingredientID: ingredientID.current,
      ingredientName: dishIngredient.current,
      ingredientQuantity: dishIngredientQuantity.current,
      ingredientUnit: unit.current,
      ingredientPrice: price.current,
    });

    var result = ingredientsDisplayValue.current.reduce((unique, o) => {
      if (unique.some((obj) => obj.ingredientID === o.ingredientID)) {
        setDuplicateError(true);
      } else {
        setDuplicateError(false);
        unique.push(o);
        totalCost = totalCost + o.ingredientPrice;
      }
      return unique;
    }, []);
    setTotal(totalCost);
    setIngredientsDisplay(result);
  };

  const handleDelete = (item) => {
    setDuplicateError(false);
    setIngredientsDisplay(ingredientsDisplay.filter((data) => data.ingredientName.toUpperCase() !== item.ingredientName.toUpperCase()));
    ingredientsDisplayValue.current = ingredientsDisplay.filter((data) => data.ingredientName.toUpperCase() !== item.ingredientName.toUpperCase());
    setIngredientsDisplay(ingredientsDisplayValue.current);
    setTotal(total - item.ingredientPrice);
  };

  const handleTotal = () => {
    if (dish === "" || dishQuantity === "" || dishQuantityUnit === null || dishServingPerPlate === "") {
      setDishError(true);
      return;
    } else {
      setDishError(false);
    }
    setShowTotal(true);
  };

  // function to handle reset
  const handleReset = () => {
    setShowTotal(false);
    setTotal(0);
    setIngredientQuantity("");
    setIngredientsDisplay([]);
    setDish("");
    setDishID(null);
    setDishQuantity("");
    setDishQuantityUnit(null);
    setDishServingPerPlate("");
    setDishNumberOfPlates(null);
    setDishPrice("");
    ingredientsDisplayValue.current = [];
    setIngredientsDisplay([]);
    setShowTotal(false);
    setTotal(null);
    handleRemoveIngredients(dish);
  };

  const handleUpdate = () => {
    setDish("");
    setDishID(null);
    setDishQuantity("");
    setDishQuantityUnit(null);
    setDishServingPerPlate("");
    setDishNumberOfPlates(null);
    setDishPrice("");
    ingredientsDisplayValue.current = [];
    setIngredientsDisplay([]);
    setShowTotal(false);
    setTotal(null);
    if (dish.__isNew__) {
      const postData = {
        itemName: dish.value,
        isHealthy: "",
        nutritionalData: {},
        itemQuantity: dishQuantity,
        itemQuantityUnit: dishQuantityUnit.value,
        itemServing: dishServingPerPlate,
        itemServingUnit: dishQuantityUnit.value,
        ingredients: ingredientsDisplay,
        portion: dishServingPerPlate + " " + dishQuantityUnit.value,
        suggestedPrice: (
          (parseFloat(total.toFixed(2)) + parseFloat((parseFloat(commission) / 100) * parseFloat(total.toFixed(2)))) /
          dishNumberOfPlates
        ).toFixed(2),
        noOfPlates: dishNumberOfPlates,
      };
      PostAPI(fetchDishes, postData)
        .then((res) => {
          if (res.data.statusCode === 201) {
            setReload(true);
            setItemSaveToDB(true);
          } else setFailedToSaveToDB(true);
        })
        .catch((e) => {
          setFailedToSaveToDB(true);
          console.log("Error occurred. Please try again. Error =", e);
          alert("Error Occured. Please try again later");
        });
    } else {
      const putData = {
        itemID: dishID,
        itemName: dish.value,
        isHealthy: "",
        nutritionalData: {},
        itemQuantity: dishQuantity,
        itemQuantityUnit: dishQuantityUnit.value,
        itemServing: dishServingPerPlate,
        itemServingUnit: dishQuantityUnit.value,
        ingredients: ingredientsDisplay,
        portion: dishServingPerPlate + " " + dishQuantityUnit,
        suggestedPrice: (
          (parseFloat(total.toFixed(2)) + parseFloat((parseFloat(commission) / 100) * parseFloat(total.toFixed(2)))) /
          dishNumberOfPlates
        ).toFixed(2),
        noOfPlates: dishNumberOfPlates,
      };
      PutAPI(fetchDishes, putData)
        .then((res) => {
          if (res.data.statusCode === 200) {
            setReload(true);
            setItemSaveToDB(true);
          } else setFailedToSaveToDB(true);
        })
        .catch((e) => {
          setFailedToSaveToDB(true);
          console.log("Error occurred. Please try again. Error =", e);
          alert("Error Occured. Please try again later");
        });
    }
  };

  const renderCard = (card, index) => {
    return (
      <div className="card shadow p-2 bg-white rounded col-xs-6 col-sm-4 col-md-3 col-lg-2" key={index}>
        <div className="card-body">
          <p
            className="cross"
            onClick={() => {
              handleDelete(card);
            }}
          >
            &#10006;
          </p>
          <div className="card-header">{card.ingredientName}</div>
          <ul className="list-group list-group-flush">
            <li className="list-group-item">
              {card.ingredientQuantity} {card.ingredientUnit}
            </li>
            <li className="list-group-item">Rs. {card.ingredientPrice.toFixed(2)}</li>
          </ul>
        </div>
      </div>
    );
  };

  const unitOptions = [
    { value: "g", label: "g" },
    { value: "ml", label: "ml" },
    { value: "pcs", label: "pcs" },
  ];

  const handleFocus = () => {
    setReload(true);
    setDish("");
    setDishID(null);
    setDishQuantity("");
    setDishQuantityUnit(null);
    setDishServingPerPlate("");
    setDishNumberOfPlates(null);
    setDishPrice("");
    ingredientsDisplayValue.current = [];
    setIngredientsDisplay([]);
    setShowTotal(false);
    setTotal(null);
    setItemSaveToDB(false);
    setFailedToSaveToDB(false);
  };

  const handleIngredientFocus = () => {
    setItemSaveToDB(false);
    setFailedToSaveToDB(false);
    setError(false);
    setDuplicateError(false);
    setSelectedIngredient(null);
    setIngredientQuantity("");
    setIngredientUnit(null);
  };

  return (
    <div className="addDishTab">
      <div className="addDish">
        <h4>Dish Details</h4>
        <div className="dishes">
          <div className="col-sm-12 col-md-6 col-lg-3 part dropdown1">
            <label>Select Dish</label>
            <CreatableSelect
              options={dishOptions.current}
              value={dish}
              autoFocus={true}
              onMenuOpen={handleFocus}
              placeholder="Ex. Idly "
              onChange={handleSelectDishValueChange}
            />
          </div>
          <div className="col-sm-8 col-md-4 col-lg-2 part">
            <label>Enter the Quantity</label>
            <input
              type="number"
              className="form-control"
              placeholder="Ex. 50pcs"
              value={dishQuantity}
              onChange={(e) => {
                setDishError(false);
                setDishQuantity(e.target.value);
              }}
            />
          </div>
          <div className="col-sm-4 col-md-2 col-lg-1 part">
            <label className="unit">g/ml/pcs</label>
            <SelectField
              options={unitOptions}
              placeholder={dishQuantityUnit != null ? dishQuantityUnit : "units"}
              value={dishQuantityUnit}
              onChange={handleSelectQuantityUnitChange}
            />
          </div>
          <div className="col-sm-8 col-md-4 col-lg-2 part">
            <label>Dish Serving Per Plate</label>
            <input
              type="number"
              className="form-control"
              placeholder="Ex. 3pcs"
              value={dishServingPerPlate}
              onChange={(e) => {
                setDishError(false);
                setDishServingPerPlate(e.target.value);
                setDishNumberOfPlates(dishQuantity / e.target.value);
              }}
            />
          </div>
          <div className="col-sm-4 col-md-2 col-lg-1 part">
            <label className="unit">g/ml/pcs</label>
            <input
              type="text"
              className="form-control"
              placeholder={dishQuantityUnit != null ? dishQuantityUnit : "units"}
              value={dishQuantityUnit != null ? dishQuantityUnit.value : ""}
            />
          </div>
          <div className="col-sm-6 col-md-3 col-lg-2 part">
            <label>Number of Plates</label>
            <input
              type="number"
              className="form-control"
              placeholder="Number of Plates"
              value={dishQuantity !== "" && dishServingPerPlate !== "" ? Math.floor(dishQuantity / dishServingPerPlate) : 0}
            />
          </div>
          {showPrice && (
            <div className="col-sm-6 col-md-3 col-lg-2 part">
              <label>Enter Dish Price</label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter Dish Price "
                value={dishPrice}
                onChange={(e) => {
                  setDishError(false);
                  setDishPrice(e.target.value);
                }}
              />
            </div>
          )}
          {dishError && (
            <div className="alert alert-danger fade show" role="alert">
              <strong>Enter all the fields</strong>
              <button type="button" className="close" data-dismiss="alert" aria-label="Close"></button>
            </div>
          )}
        </div>
        {/* End of Dish Row */}
        {/* Start of Add Ingredients */}
        <h4>Add Ingredients</h4>
        <div className="dishes">
          <div className="col-sm-12 col-md-6 col-lg-3 part">
            <SelectField
              options={ingredientOptions.current}
              value={selectedIngredient}
              placeholder={selectedIngredient !== null ? selectedIngredient : "Select Ingredient"}
              onMenuOpen={handleIngredientFocus}
              onChange={handleSelectIngredientValueChange}
            />
          </div>
          <div className="col-sm-12 col-md-6 col-lg-3 part">
            <input
              type="number"
              className="form-control"
              placeholder="Enter Quantity"
              value={ingredientQuantity}
              onChange={(e) => {
                setError(false);
                setIngredientQuantity(e.target.value);
              }}
            />
          </div>
          <div className="col-sm-12 col-md-6 col-lg-3 part">
            <SelectField
              options={ingredientUnitOptions}
              value={ingredientUnit}
              placeholder="Select Unit"
              onChange={handleSelectIngredientUnitChange}
            />
          </div>
          <div className="col-sm-12 col-md-6 col-lg-3 part">
            <button type="button" className="btn btn-outline-dark btn-block" onClick={handleSubmit}>
              Add
            </button>
          </div>
        </div>
        {error && (
          <div className="alert alert-danger fade show" role="alert">
            <strong>Enter all the fields</strong>
            <button type="button" className="close" data-dismiss="alert" aria-label="Close"></button>
          </div>
        )}
        {duplicateError && (
          <div className="alert alert-danger fade show" role="alert">
            <strong>Ingredient already existing. To update this ingredient cancel existing ingredient and add again.</strong>
            <button type="button" className="close" data-dismiss="alert" aria-label="Close"></button>
          </div>
        )}
      </div>
      <div>
        <h4>Ingredients</h4>
        <div className="displayDishes">{ingredientsDisplay.map(renderCard)}</div>
      </div>
      {/* start of buttons */}
      <div className="buttonsContainer">
        <div className="col-sm-12 col-md-6 buttonPart">
          <button type="button" className="btn btn-outline-dark btn-block" onClick={handleReset}>
            Clear
          </button>
        </div>
        <div className="col-sm-12 col-md-6 buttonPart">
          <button type="button" className="btn btn-outline-dark btn-block" onClick={handleTotal}>
            Calculate Total
          </button>
        </div>
      </div>
      {/* end of Buttons */}
      {/* start of total display */}
      {showTotal && (
        <div className="totalDisplay">
          <div className="col-sm-12 col-md-6 col-lg-3 partTotal">
            <input
              type="text"
              className="form-control"
              placeholder="Enter Item Name "
              id={"input-item"}
              value={dish.value}
              onChange={(e) => {
                setItemSaveToDB(false);
                setFailedToSaveToDB(false);
                setDish(e.target.value);
              }}
            />
          </div>
          <div className="col-sm-12 col-md-6 col-lg-3 partTotal">
            <p className="form-control">
              <strong>Actual Cost: Rs.</strong> {total.toFixed(2)}
            </p>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-3 partTotal">
            <p className="form-control">
              <strong>Commission:</strong> {commission}%
            </p>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-3 partTotal">
            <p className="form-control">
              <strong>Total Cost:</strong> {parseFloat(total.toFixed(2)) + parseFloat((parseFloat(commission) / 100) * parseFloat(total.toFixed(2)))}
            </p>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-3 partTotal">
            <p className="form-control">
              <strong>Per Serving: Rs.</strong>{" "}
              {(
                (parseFloat(total.toFixed(2)) + parseFloat((parseFloat(commission) / 100) * parseFloat(total.toFixed(2)))) /
                dishNumberOfPlates
              ).toFixed(2)}
            </p>
          </div>
          <div className="col-sm-12 mb-5 partTotal">
            <button type="button" className="btn btn-outline-dark btn-block" onClick={handleUpdate}>
              Save Dish
            </button>
          </div>
          {/* end of total display */}
        </div>
      )}
      {itemSaveToDB && (
        <div className="alert alert-primary alert-dismissible fade show" role="alert">
          <strong> Dish is Saved</strong>
          <button type="button" className="close" data-dismiss="alert" aria-label="Close"></button>
        </div>
      )}

      {failedToSaveToDB && (
        <div className="alert alert-danger alert-dismissible fade show" role="alert">
          <strong>Failed to Save Dish. Please try again</strong>
          <button type="button" className="close" data-dismiss="alert" aria-label="Close"></button>
        </div>
      )}
    </div>
  );
};

export default AddDish;
