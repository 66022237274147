/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/rules-of-hooks */
import React from "react";
import "./adminCard.css";
import { Modal } from "react-bootstrap";

const DaasRetryPopup = ({ show, confirmDaasRetry, handleDismiss }) => {
  const handleClose = () => {
    handleDismiss();
  };

  return (
    <Modal size="lg" show={show} backdrop="static">
      <Modal.Header>
        <Modal.Title>Are you sure you want retry Daas booking?</Modal.Title>
        <span onClick={handleClose}>&#10006;</span>
      </Modal.Header>
      <Modal.Body>
        <h5>Please confirm only if there is any pickup issue</h5>
      </Modal.Body>
      <Modal.Footer>
        <>
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => {
              confirmDaasRetry();
            }}
          >
            Confirm
          </button>
          <button
            type="button"
            className="btn btn-secondary"
            data-dismiss="modal"
            onClick={() => handleDismiss()}
          >
            Close
          </button>
        </>
      </Modal.Footer>
    </Modal>
  );
};

export default DaasRetryPopup;
