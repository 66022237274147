import React from "react";
import "./navbar.css";
import { HashLink as Link } from "react-router-hash-link";
import HeaderLogo from "../../images/headerLogo.png";

function Navbar() {
  return (
    <div className="navbarContainer">
      <nav className="navbar navbar-expand-lg navbar-dark bg-light">
        <a className="navbar-brand text-white" href="/">
          <img src={HeaderLogo} width="35" height="35" alt="Homeal" />
          Homeal
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav ml-auto">
            <li className="nav-item active">
              <Link className="nav-link" data-toggle="collapse" data-target=".navbar-collapse.show" to="/">
                Home <span className="sr-only">(current) </span>
              </Link>
            </li>
            <li className="nav-item active">
              <Link className="nav-link" data-toggle="collapse" data-target=".navbar-collapse.show" to="/bangalore/home-chef-registration">
                Register as Chef
              </Link>
            </li>
            <li className="nav-item active">
              <Link className="nav-link" data-toggle="collapse" data-target=".navbar-collapse.show" to="/products">
                Products
              </Link>
            </li>
            <li className="nav-item active">
              <Link className="nav-link" data-toggle="collapse" data-target=".navbar-collapse.show" to="/#about">
                About Us
              </Link>
            </li>
            <li className="nav-item active">
              <Link className="nav-link" data-toggle="collapse" data-target=".navbar-collapse.show" to="/chefStory">
                Chef Stories
              </Link>
            </li>
            <li className="nav-item active">
              <Link className="nav-link" data-toggle="collapse" data-target=".navbar-collapse.show" to="/blogs">
                Blogs
              </Link>
            </li>
            <li className="nav-item active">
              <Link className="nav-link" data-toggle="collapse" data-target=".navbar-collapse.show" to="/#Faq">
                FAQ
              </Link>
            </li>
            <li className="nav-item active">
              <Link className="nav-link" data-toggle="collapse" data-target=".navbar-collapse.show" to="/#footer">
                Contact Us
              </Link>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  );
}
export default Navbar;
