import React, { useState, useContext } from "react";
import Modal from "../Modal/modal";
import SubscriptionContext from "../context/subscriptionContext";
import "./rendercard.css";

export const RenderCard = ({ key, subscription }) => {
  const [showModal, setShowModal] = useState(false);
  const { setSubscriptionData } = useContext(SubscriptionContext);

  const handleCross = () => {
    setSubscriptionData({});
    setShowModal(false);
  };

  return (
    <div class="subscription2">
      {showModal && (
        <Modal
          showModal={showModal}
          subscriptionID={subscription.subscriptionID}
          handleClose={handleCross}
        />
      )}
      <div
        class="card shadow p-3 mb-5 bg-white rounded"
        onClick={() => setShowModal(true)}
      >
        <p>Subscription ID -{subscription.subscriptionID} </p>
         <p>Subscription Status - {subscription.subscriptionStatus}</p>
         <p>CraverName - {subscription.craverName}</p>
         <p>Mobile Number - {subscription.craverMobile}</p>
         <p>MenuType - {subscription.menuType}</p>
      </div>
    </div>
  );
};
