/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState } from "react";
import "./adminCard.css";
import { Modal, Dropdown, DropdownButton } from "react-bootstrap";

const RefundToWalletModal = ({
  title,
  show,
  setShowRefundToWallet,
  handleRefund,
  selectedOrder,
  handleDismiss,
}) => {
  const [selectedReason, setSelectedReason] = useState("");
  const [otherReason, setOtherReason] = useState("");
  const [refundAmt, setRefundAmt] = useState(0);
  const [refundType, setRefundType] = useState(0);

  const handleSelect = (e) => {
    setSelectedReason(e);
  };

  const handleRefundSelect = (e) => {
    setRefundType(e);
  }

  const handleRefundClick = () => {
    const dataToPost = {
        orderID: selectedOrder.orderID,
        userID: selectedOrder.craverID,
        userName: selectedOrder.craverDetails.craverName,
        userMobile: selectedOrder.craverDetails.craverMobile,
        REASON: selectedReason,
        amount: Number(refundAmt),
        OTHERREASON: otherReason,
        REFUNDTYPE: refundType
    }
    handleRefund(dataToPost);
    setRefundType("");
    setRefundAmt(0);
    setSelectedReason("");
    setOtherReason("");
    setShowRefundToWallet(false);
  }

  return (
    <Modal show={show} backdrop="static">
      <Modal.Header>
        <Modal.Title>
          {title} for orderID {selectedOrder.orderID}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="redundSection">
        <div>
        <p>UserName - {selectedOrder.craverDetails.craverName}</p>
        <p>UserMobile - {selectedOrder.craverDetails.craverMobile}</p>
        </div>
        <DropdownButton
          id="dropdown-basic-button btn-sm my-2"
          title={refundType || "Refund Type"}
          onSelect={handleRefundSelect}
        >
          <Dropdown.Item eventKey="WALLET">WALLET</Dropdown.Item>
          <Dropdown.Item eventKey="SOURCE">SOURCE</Dropdown.Item>
        </DropdownButton>
        
        </div>
        <DropdownButton
          id="dropdown-basic-button btn-sm"
          title={selectedReason || "Reason"}
          onSelect={handleSelect}
        >
          <Dropdown.Item eventKey="REJECTED">REJECTED</Dropdown.Item>
          <Dropdown.Item eventKey="CANCELLED">CANCELLED</Dropdown.Item>
          <Dropdown.Item eventKey="NOT DELIVERED">NOT DELIVERED</Dropdown.Item>
          <Dropdown.Item eventKey="OTHERS">OTHERS</Dropdown.Item>
        </DropdownButton>
        {selectedReason === "OTHERS" ? (
          <input
            type="text"
            value={otherReason}
            onChange={(e) => setOtherReason(e.target.value)}
            className="form-control mt-2"
            placeholder="Enter reason"
          />
        ) : null}
        <div className="input-group input-group-sm  mb-3 mt-2">
          <div className="input-group-prepend">
            <span className="input-group-text" id="amt">
              Refund Amt
            </span>
          </div>
          <input
            type="number"
            value={refundAmt}
            onChange={(e) => setRefundAmt(e.target.value)}
            className="form-control"
            placeholder="Enter refund amt"
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className="btn btn-primary"
          onClick={handleRefundClick}
          disabled={!selectedReason || refundAmt == 0 || refundType == ""}
        >
          Refund
        </button>
        <button
          type="button"
          className="btn btn-secondary"
          onClick={() => {
            setRefundType("");
            setRefundAmt(0);
            setSelectedReason("");
            setOtherReason("");
            handleDismiss();
        }}
        >
          Close
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default RefundToWalletModal;
