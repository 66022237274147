import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import CouponModalDisplay from "./CouponModalDisplay";
import OrderContext from "./OrderContext";
import "./PromoCode.css";

const PromoCode = () => {
  const [showApply, setShowApply] = useState(false);
  const [showCouponModel, setShowCouponModel] = useState(false);
  const [promoCodes, setPromoCodes] = useState([]);
  const [showPromoText, setShowPromoText] = useState(false);
  const { totalAmount, setDiscountAmount, promoCode, setPromoCode } = useContext(OrderContext);

  let environment = process.env.REACT_APP_NODE_ENV;
  const headers = {
    "Content-Type": "application/json",
    Authorization: "website"
  };
  let getPromoCodes;
  if (environment === "PRODUCTION" || environment === undefined) {
    getPromoCodes = process.env.REACT_APP_PROD_PROMO;
  } else {
    getPromoCodes = process.env.REACT_APP_DEV_PROMO;
  }
  useEffect(() => {
    const getAllPromos = () => {
      let completePromoURL = getPromoCodes + "?chefID=ALL";

      axios
        .get(completePromoURL, {
          headers,
        })
        .then((response) => {
          if (response.data.statusCode === 200) {
            setPromoCodes(response.data.body);
          }
        })
        .catch((error) => {
          console.log("Error occurred while fetching all promo codes. Error =" + error);
          alert("Error occurred while fetching all promo codes!!!");
        });
    };
    getAllPromos();
  }, []);

  const handlePromoChange = (promoValue) => {
    if (promoValue.length > 0) {
      setShowApply(true);
    } else {
      setShowApply(false);
    }
    setPromoCode(promoValue);
  };

  const handleSeeCoupons = () => {
    setShowCouponModel(true);
  };

  const handleClose = () => {
    setShowCouponModel(false);
  };
  const VerifyPromo = (value) => {
    let discount = 0;
    promoCodes.forEach((data) => {
      if (value.toUpperCase() === data.promoID.toUpperCase()) {
        const discountType = data.discountType.toUpperCase();
        if (discountType.toUpperCase() === "PERCENT OFF") {
          discount = (totalAmount * data.discountAmount) / 100;
          return;
        } else {
          discount = totalAmount - data.discountAmount;
          return;
        }
      }
    });
    return Math.floor(discount);
  };

  const handleApplyPromo = (value) => {
    setShowCouponModel(false);
    let discount = VerifyPromo(value);
    setDiscountAmount(discount);
    setPromoCode(value);
    setShowPromoText(true);
    setInterval(function () {
      setShowPromoText(false);
    }, 3000);
  };

  const handleClearPromo = () => {
    setPromoCode("");
    setDiscountAmount(0);
    setShowApply(false);
  };
  return (
    <div class="promo card shadow">
      {showCouponModel && (
        <CouponModalDisplay showModal={showCouponModel} handleClose={handleClose} promoCodes={promoCodes} handleApplyPromo={handleApplyPromo} />
      )}
      <div class="card-body">
        <div class="input-group">
          <input
            type="text"
            value={promoCode}
            onChange={(e) => handlePromoChange(e.target.value)}
            class="form-control"
            placeholder="Enter coupon code"
            aria-label="couponCode"
          />
          <span class="input-group-text cross" onClick={() => handleClearPromo()}>
            {" "}
            &#10060;
          </span>
          {showApply ? (
            <button class="btn btn-outline-secondary btn-sm" type="button" onClick={() => handleApplyPromo(promoCode)}>
              Apply Coupon
            </button>
          ) : (
            <button class="btn btn-outline-dark btn-sm" type="button" onClick={() => handleSeeCoupons()}>
              See Coupons
            </button>
          )}
        </div>
        {showPromoText ? (
          <div class="alert alert-success my-1" role="alert">
            Applied coupon discount successfully!!!
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default PromoCode;
