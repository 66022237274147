import React from "react";
import "./faq.css";

function Faq() {
  return (
    <div className="Faq" id="Faq">
      <hr />
      <h3>Frequently Asked Questions</h3>
      <div id="accordion">
        <div className="card">
          <div className="card-header" id="headingOne">
            <h5 className="mb-0">
              <p data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                <i className="fas fa-chevron-circle-down"></i> What is Homeal?
              </p>
            </h5>
          </div>
          <div id="collapseOne" className="collapse show" aria-labelledby="headingOne" data-parent="#accordion">
            <div className="card-body">
              <p className="paragraph">
                Homeal provides homemade food items to the craver who is willing to have authentic homemade food. Enjoy the meal when your hungry for
                homemade food and if cooking is your passion, then join us to follow your passion as Homeal Chef.
              </p>
            </div>
          </div>
        </div>
        <div className="card">
          <div className="card-header" id="headingTwo">
            <h5 className="mb-0">
              <p className="collapsed" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                <i className="fas fa-chevron-circle-down"></i> How to order homemade food from Homeal?
              </p>
            </h5>
          </div>
          <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
            <div className="card-body">
              <p className="paragraph">
                You can order Homemade Food from Homeal through our app. You can search for which food item you want to eat. Select the item you want
                to order. Food will be delivered to you at the provided address at your doorsteps without fail.
              </p>
            </div>
          </div>
        </div>
        <div className="card">
          <div className="card-header" id="headingThree">
            <h5 className="mb-0">
              <p className="collapsed" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                <i className="fas fa-chevron-circle-down"></i> Can you deliver food from popular restaurants near me?
              </p>
            </h5>
          </div>
          <div id="collapseThree" className="collapse" aria-labelledby="headingThree" data-parent="#accordion">
            <div className="card-body">
              <p className="paragraph">
                No, we do not deliver any restaurant food, we have designated chefs who cook healthy food according to the customer’s choice and we
                deliver them at you doorstep.
              </p>
            </div>
          </div>
        </div>
        <div className="card">
          <div className="card-header" id="headingFour">
            <h5 className="mb-0">
              <p className="collapsed" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                <i className="fas fa-chevron-circle-down"></i> What kind of food do you deliver?
              </p>
            </h5>
          </div>
          <div id="collapseFour" className="collapse" aria-labelledby="headingFour" data-parent="#accordion">
            <div className="card-body">
              <p className="paragraph">
                We deliver only Homemade food which includes multiple cuisines, according to the customer’s preferences. Such as South Indian, North
                Indian, Chinese, Bakery, Continental, and many more.
              </p>
            </div>
          </div>
        </div>
        <div className="card">
          <div className="card-header" id="headingFour">
            <h5 className="mb-0">
              <p className="collapsed" data-toggle="collapse" data-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                <i className="fas fa-chevron-circle-down"></i> What is the eligibility to be a chef at Homeal?
              </p>
            </h5>
          </div>
          <div id="collapseFive" className="collapse" aria-labelledby="headingFive" data-parent="#accordion">
            <div className="card-body">
              <p className="paragraph">Your love and care to serve cravers with passion for cooking and hygienic delicacies.</p>
            </div>
          </div>
        </div>
        <div className="card">
          <div className="card-header" id="headingFour">
            <h5 className="mb-0">
              <p className="collapsed" data-toggle="collapse" data-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                <i className="fas fa-chevron-circle-down"></i> Who will deliver my food?
              </p>
            </h5>
          </div>
          <div id="collapseSix" className="collapse" aria-labelledby="headingSix" data-parent="#accordion">
            <div className="card-body">
              <p className="paragraph">
                Homeal will deliver food at your doorstep, and we provide a pickup facility by yourself to drop down delivery.
              </p>
            </div>
          </div>
        </div>

        <div className="card">
          <div className="card-header" id="headingFive">
            <h5 className="mb-0">
              <p className="collapsed" data-toggle="collapse" data-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
                <i className="fas fa-chevron-circle-down"></i> What is Homeal Subscription?
              </p>
            </h5>
          </div>
          <div id="collapseSeven" className="collapse" aria-labelledby="headingSeven" data-parent="#accordion">
            <div className="card-body">
              <p className="paragraph">
                Homeal offers customers with regular doorstep delivery services of healthy, tasty and customized home food at affordable prices.
              </p>
            </div>
          </div>
        </div>

        <div className="card">
          <div className="card-header" id="headingSix">
            <h5 className="mb-0">
              <p className="collapsed" data-toggle="collapse" data-target="#collapseEight" aria-expanded="false" aria-controls="collapseEight">
                <i className="fas fa-chevron-circle-down"></i> How do I Subscribe?
              </p>
            </h5>
          </div>
          <div id="collapseEight" className="collapse" aria-labelledby="headingEight" data-parent="#accordion">
            <div className="card-body">
              <p className="paragraph">
                Download the app and click on the subscription section, select the meals you want, and select the date, when you want to start from.
                Your Subscription will be started from that day.
              </p>
            </div>
          </div>
        </div>

        <div className="card">
          <div className="card-header" id="headingSeven">
            <h5 className="mb-0">
              <p className="collapsed" data-toggle="collapse" data-target="#collapseNine" aria-expanded="false" aria-controls="collapseNine">
                <i className="fas fa-chevron-circle-down"></i> How frequently should I make the payment for Subscription?
              </p>
            </h5>
          </div>
          <div id="collapseNine" className="collapse" aria-labelledby="headingNine" data-parent="#accordion">
            <div className="card-body">
              <p className="paragraph">Weekly prepaid subscriptions are available, and accordingly payment can be made for every week.</p>
            </div>
          </div>
        </div>

        <div className="card">
          <div className="card-header" id="headingEight">
            <h5 className="mb-0">
              <p className="collapsed" data-toggle="collapse" data-target="#collapseTen" aria-expanded="false" aria-controls="collapseTen">
                <i className="fas fa-chevron-circle-down"></i> Do I get Same food everyday?
              </p>
            </h5>
          </div>
          <div id="collapseTen" className="collapse" aria-labelledby="headingTen" data-parent="#accordion">
            <div className="card-body">
              <p className="paragraph">No, our home chefs keep everyday different menu to keep your taste buds happy.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Faq;
