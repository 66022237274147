/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState } from "react";
import "./adminCard.css";
import { Modal } from "react-bootstrap";

const StatusConfirmModal = ({ PreviousState, NextState, selectedOrderID, show, handleConfirmStatusChange, handleDismiss }) => {
  const [reason, setReason] = useState("");
  return (
    <Modal show={show} backdrop="static">
      <Modal.Header>
        <Modal.Title>
          {PreviousState} to {NextState}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h5>
          Are your sure to change the order status from {PreviousState} to {NextState}
        </h5>
        {NextState.toUpperCase() === "REJECTED" && (
          <div>
            <h5>Enter reason for rejection</h5>
            <div className="input-group mb-3">
              <div className="input-group-prepend">
                <span className="input-group-text" id="basic-addon1">
                  Rejected Reason
                </span>
              </div>
              <input
                type="text"
                value={reason}
                onChange={(e) => setReason(e.target.value)}
                className="form-control"
                placeholder="reason"
                aria-label="reason"
                aria-describedby="basic-addon1"
              />
            </div>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className="btn btn-primary"
          onClick={() => {
            handleConfirmStatusChange(selectedOrderID, NextState, reason);
          }}
        >
          Change to {NextState}
        </button>

        <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={() => handleDismiss()}>
          Close
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default StatusConfirmModal;
