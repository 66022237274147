import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import RenderItems from "./RenderItems";
import "./DisplayMenu.css";
import ChefDetailsContext from "./ChefDetailsContext";
import { ThreeCircles } from "react-loader-spinner";
import { FaSearch } from "react-icons/fa";
import OrderContext from "./OrderContext";

const DisplayMenu = () => {
  const { chefDetails } = useContext(ChefDetailsContext);
  const [menu, setMenu] = useState([]);
  const [filteredMenu, setFilteredMenu] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const { totalOrders } = useContext(OrderContext);

  useEffect(() => {
    setLoading(true);
    let environment = process.env.REACT_APP_NODE_ENV;

    let newChefMenuAPI;
    if (environment === "PRODUCTION" || environment === undefined) {
      newChefMenuAPI = process.env.REACT_APP_PROD_CHEFMENU;
    } else {
      newChefMenuAPI = process.env.REACT_APP_DEV_CHEFMENU;
    }

    const getChefsMenu = () => {
      let completeURL = newChefMenuAPI + "?userID=" + chefDetails.userID;

      axios({
        method: "get",
        url: completeURL,
        timeout: 5000,
        headers: {
          "Content-Type": "application/json",
          Authorization: "website"
        },
      })
        .then((response) => {
          setLoading(false);
          if (response.data.statusCode === 200) {
            if (response.data.body.length > 0) {
              setMenu(response.data.body);
              setFilteredMenu(response.data.body);
            }
          }
        })
        .catch((error) => {
          setLoading(false);
          console.log("Error occurred in getting chef menu. Error =" + error);
        });
    };

    getChefsMenu();
  }, [chefDetails]);

  useEffect(() => {}, [menu]);

  const checkQuantity = (data) => {
    let quantity = 0;
    totalOrders.forEach((order) => {
      if (order.menuID === data.menuID) {
        quantity = order.quantity;
      }
    });
    return quantity;
  };

  const handleSearch = (value) => {
    if (value !== "") {
      let filteredData = [];
      filteredData = menu.filter((data) => data.itemName.toLowerCase().includes(value.toLowerCase()));

      const updatedData = filteredData.map((item) => {
        const quantity = checkQuantity(item, totalOrders);
        return { ...item, quantity };
      });
      setFilteredMenu(updatedData);
    } else {
      const updatedData = menu.map((item) => {
        const quantity = checkQuantity(item, totalOrders);
        return { ...item, quantity };
      });

      setFilteredMenu(updatedData);
    }
    setSearchValue(value);
  };

  const handleClearSearch = () => {
    setSearchValue("");
    const updatedData = menu.map((item) => {
      const quantity = checkQuantity(item, totalOrders);
      return { ...item, quantity };
    });
    setFilteredMenu(updatedData);
  };

  return (
    <div>
      {/* SearchMenu */}
      {/* DisplayMenu */}
      <div className="displayItemSection">
        <div className="input-group mb-3">
          <span className="input-group-text" id="Search">
            <FaSearch size={20} />
          </span>
          <input
            type="text"
            value={searchValue}
            onChange={(e) => handleSearch(e.target.value)}
            className="form-control"
            placeholder="Search Items"
            aria-label="Username"
            aria-describedby="Search"
          />
          <span class="input-group-text cross" onClick={() => handleClearSearch()}>
            {" "}
            &#10060;
          </span>
        </div>
        {loading && (
          <div className="spinnerContainer">
            <div className="spinner">
              <ThreeCircles color="blue" height={110} width={110} ariaLabel="three-circles-rotating" />
            </div>
          </div>
        )}
        <RenderItems menu={filteredMenu} />
      </div>
    </div>
  );
};

export default DisplayMenu;
