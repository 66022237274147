import React, { useState } from "react";
import "./Card.css";
import moment from "moment";
import { FaUser } from "react-icons/fa";
import { FaPhoneAlt } from "react-icons/fa";
import { FcTwoSmartphones } from "react-icons/fc";
import { RiHomeHeartFill } from "react-icons/ri";
import { CgCalendarDates } from "react-icons/cg";
import { FaCalendarCheck } from "react-icons/fa";
import { MdStars } from "react-icons/md";
import { FaMapMarkerAlt } from "react-icons/fa";
import { extractLatLong } from "../Helpers";
import { MdOutlineEventAvailable } from "react-icons/md";
import { MdNotInterested } from "react-icons/md";

const Card = ({
  data,
  type,
  handleChefNewMenu,
  handleChefStatus,
  handleShowMenu,
  handleUnVerifyChef,
  handleVerifyChef,
  handleProfileUpdate,
  handleEmiStatus,
  handlePerformance,
  showActive,
}) => {
  const [showCard, setShowCard] = useState("");
  let comments;
  const [isMenuVisible, setIsMenuVisible] = useState(false);
  let latLong;
  if (data.userAddress && data.userAddress.coordinates) {
    latLong = extractLatLong(data.userAddress.coordinates);
  }

  const handleStatus = (status) => {
    setShowCard(status);
  };

  const toggleMenuVisibility = () => {
    setIsMenuVisible(!isMenuVisible);
  };

  const RenderShowCard = ({ showCard }) => {
    return (
      <div>
        <input
          className="form-control"
          type="text"
          value={comments}
          onChange={(e) => {
            comments = e.target.value;
          }}
        />
        <button
          type="button"
          className="btn btn-dark btn-sm mt-1"
          onClick={() => {
            handleChefStatus(showCard, comments, data);
          }}
        >
          Save Chef Status
        </button>
      </div>
    );
  };

  return (
    <div className="col-xs-12 col-sm-6 col-md-4 col-lg-3 chefCard">
      <div className="cardTitleContainer">
        <p className="titleText">Chef Basic Details</p>
      </div>
      <div className="rowDisplay">
        <p>#{data.userID}</p>
        <a href={"tel:" + data.userMobile} rel="noreferrer">
          <FaPhoneAlt size={16} color="#49243E" /> {data.userMobile}
        </a>
      </div>
      <div className="rowDisplay">
        <p>
          Serves Bulk: {"\t"}
          {data.servesBulk ? "Yes" : "No"}
        </p>
        {data.chefAlternateNumber ?
        <a href={"tel:" + data.chefAlternateNumber} rel="noreferrer">
          <FcTwoSmartphones size={20} color="#49243E" /> {data.chefAlternateNumber}
        </a> : null}
      </div>
      <div className="rowDisplay">
        <p>
          <FaUser size={18} color="#EF9A53" /> {data.userName}
        </p>
        <p>
          <RiHomeHeartFill size={22} color="#1C3879" /> {data.userKitchen}
        </p>
      </div>
      {data.isUserAvailable ?
      <p>
        <MdOutlineEventAvailable size={22} color="green" />{" "}
         Available
      </p> :
      <p>
        <MdNotInterested size={22} color="red" />{" "}
         Not Available
      </p>}
      {type.toUpperCase() !== "REMOVED" && (
        <>
          <div className="rowDisplay">
            <p>
              <CgCalendarDates size={22} color="#1C3879" />{" "}
              {moment(data.creationDate).format("YYYY-MM-DD")}
            </p>
            <p>
              <MdStars size={22} color="#F3CA52" /> {type.toUpperCase() !== "VERIFIED" ? data.priorityScore : parseFloat(data.avgRating).toFixed(1)}
            </p>
            {type.toUpperCase() === "VERIFIED" && (
              <p>
                <FaCalendarCheck size={18} color="green" />{" "}
                {moment(data.verifiedTimestamp).format("YYYY-MM-DD")}
              </p>
            )}
          </div>
          {latLong && latLong.latitude && latLong.longitude && (
            <p>
              <a
                href={`http://maps.google.com/?q=${latLong.latitude},${latLong.longitude}`}
                rel="noreferrer"
                target="_blank"
              >
                <FaMapMarkerAlt size={16} color="#404258" />{" "}
                {data.userAddress.address}
              </a>
            </p>
          )}
          {type.toUpperCase() === "VERIFIED" && (
            <>
              <div
                className="cardTitleContainer"
                onClick={toggleMenuVisibility}
              >
                <p className="titleText">Chef Menu Count</p>
              </div>
              {isMenuVisible && (
                <div className="rowDisplayWrap">
                  <p>Breakfast({data.Breakfast})</p>
                  <p>Lunch({data.Lunch})</p>
                  <p>Snacks({data.Snacks})</p>
                  <p>Dinner({data.Dinner})</p>
                  <p>Ready to Serve({data["Ready To Serve"]})</p>
                  <p>Festive Delights({data["Festive Delights"]})</p>
                </div>
              )}
            </>
          )}
        </>
      )}
      <div className="rowDisplayWrapButtons">
        {type.toUpperCase() !== "VERIFIED" ? (
          <>
            <button
              className="btn btn-info btn-text btn-sm"
              onClick={() => handleChefNewMenu(data)}
            >
              Registered Menu
            </button>
            <button
              className="btn btn-success btn-text btn-sm"
              onClick={() => handleVerifyChef(data)}
            >
              Verify Chef
            </button>
          </>
        ) : (
          <>
          <button
              className="btn btn-info btn-text btn-sm"
              onClick={() => handleChefNewMenu(data)}
            >
              Registered Menu
            </button>
            <button
              className="btn btn-primary btn-text btn-sm"
              onClick={() => handleShowMenu(data)}
            >
              Verified Menu
            </button>
            <button
              className="btn btn-danger btn-text btn-sm"
              onClick={() => handleUnVerifyChef(data)}
            >
              UnVerify
            </button>
            <button
              className="btn btn-success btn-text btn-sm"
              onClick={() => handleEmiStatus(data)}
            >
              Show Emi Status
            </button>
            <button
              className="btn btn-warning btn-text btn-sm"
              onClick={() => handleProfileUpdate(data)}
            >
              Update Profile
            </button>
            <button
              className="btn btn-primary btn-text btn-sm"
              onClick={() => handlePerformance(data, showActive)}
            >
              Chef Performance
            </button>
          </>
        )}
         {type.toUpperCase() !== "VERIFIED" &&
        type.toUpperCase() !== "REMOVED" ? (
          <button
            className="btn btn-danger btn-text btn-sm"
            onClick={() => handleUnVerifyChef(data)}
          >
            UnVerify
          </button>
        ) : null}
        {type.toUpperCase() !== "VERIFIED" && (
          <>
            <button
              type="button"
              className="btn status-btn btn-info btn-sm dropdown-toggle"
              data-toggle="dropdown"
              aria-expanded="false"
            >
              {type.toUpperCase() === "VERIFIED"
                ? "Verified"
                : showCard || data.chefStatus || "Add Status"}
            </button>
            <div className="dropdown-menu">
            <li
                className="dropdown-item"
                onClick={() => handleStatus("Registered")}
              >
                Registered
              </li>
              <li
                className="dropdown-item"
                onClick={() => handleStatus("Contacted")}
              >
                Contacted
              </li>
              <li
                className="dropdown-item"
                onClick={() => handleStatus("Training")}
              >
                Training
              </li>
              <li
                className="dropdown-item"
                onClick={() => handleStatus("Menu/Catalog")}
              >
                Menu/Catalog
              </li>
              <li
                className="dropdown-item"
                onClick={() => handleStatus("Audit/Sample")}
              >
                Audit/Sample
              </li>
            </div>
            {showCard && <RenderShowCard showCard={showCard} />}
            <p>
              <strong>Comments</strong>: {data.comments || "No Comments"}
            </p>
          </>
        )}
      </div>
    </div>
  );
};

export default Card;
