import React, { useState, useEffect } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./RichTextEditor.css";
import "./BlogContainer.css";
import "./BlogCategories/IndividualBlogs/IndividualBlogs.css";
import GetAPI from "../APICalls/GetAPI";
import PostAPI from "../APICalls/PostAPI";
import ConfirmModal from "./ConfirmModal";
import { useParams } from "react-router-dom";

const RichTextEditor = () => {
  const { blogID } = useParams();
  const [blogData, setBlogData] = useState(null)
  const [blogImage, setBlogImage] = useState("")
  const [content, setContent] = useState("");
  const [finalData, setFinalData] = useState("");
  const [fileName, setFileName] = useState("");
  const [showModal, setShowModal] = useState(false);
  const handleCloseModal = () => setShowModal(false);
  const handleShowModal = () => setShowModal(true);
  const [selectedCategoryBlog, setSelectedCategoryBlog] = useState([]);

  const getBlogs = () => {
    let environment = process.env.REACT_APP_NODE_ENV;
    let getSingleBlogApi;
    if (environment === "PRODUCTION" || environment === undefined) {
      getSingleBlogApi = process.env.REACT_APP_PROD_BLOGAPI;
    } else {
      getSingleBlogApi = process.env.REACT_APP_DEV_BLOGAPI;
    }
    GetAPI(`${getSingleBlogApi}?type=blogID&blogID=${blogID}`)
      .then((res) => {
        console.log("get res ", res);
        if (res.data.statusCode === 200) {
          setBlogImage(res.data.body.thumbNailUrl)
          setBlogData(res.data.body);
          setSelectedCategoryBlog(res.data.body.categories);
          setContent(res.data.body.data);
          setFinalData(res.data.body);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    getBlogs();
  }, []);

  const modules = {
    toolbar: [
      [
        { header: "1" },
        { header: "2" },
        { header: "3" },
        { header: "4" },
        { header: "5" },
        { header: "6" },
      ],
      ["bold", "italic", "underline", "strike"],
      [{ list: "ordered" }, { list: "bullet" }],
      ["link", "image"],
      [{ script: "sub" }, { script: "super" }],
      [{ indent: "-1" }, { indent: "+1" }],
      [{ align: [] }],
      ["blockquote", "code-block"],
      ["clean"],
    ],
  };

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "list",
    "bullet",
    "link",
    "image",
    "script",
    "blockquote",
  ];

  const handleContentChange = (value) => {
    setContent(value);
    parseContent(value); // Call parseContent when content changes
  };

  const parseContent = (content) => {
    let parsedContent = content;
    const images = parsedContent.match(/<img[^>]+>/g);
    if (images) {
      images.forEach((imgTag) => {
        const updatedImgTag = imgTag.replace(/"/g, "'");
        const updatedImgWithStyles = updatedImgTag.replace(
          /<img(.*?)>/,
          `<img$1 style="height: 360px; width: 760px; padding: 10px;">`
        );
        parsedContent = parsedContent.replace(imgTag, updatedImgWithStyles);
      });
    }
    console.log("pc ", parsedContent);
    const matches = parsedContent.match(/<h1[^>]*>(.*?)<\/h1>/);
    if (matches && matches.length > 1) {
      setFileName(matches[1]);
    }
    setFinalData(parsedContent);
  };

  const handleSubmit = (thumbnail) => {
    // Handle submission logic here
    console.log("Submitted Content:", content);
    console.log("Submitted final:", finalData);
    let saveBlogAPI, saveThumbnailAPI;
    let environment = process.env.REACT_APP_NODE_ENV;
    if (environment === "PRODUCTION") {
      saveBlogAPI = process.env.REACT_APP_PROD_BLOGAPI;
      saveThumbnailAPI = process.env.REACT_APP_PROD_FILEUPLOAD;
    } else {
      saveBlogAPI = process.env.REACT_APP_DEV_BLOGAPI;
      saveThumbnailAPI = process.env.REACT_APP_DEV_FILEUPLOAD;
    }

    const dataToPost = {
      blogName: fileName,
      data: finalData,
      categories: selectedCategoryBlog,
      blogID:blogID,
      thumbNailUrl:thumbnail
    };

    console.log("data to update ", dataToPost);
    PostAPI(saveBlogAPI, dataToPost)
      .then((res) => {
        console.log("res ", res);
        if (res.data.statusCode === 200) {
          handleCloseModal();
          alert("Blog Updated Successfully!");
          // check whether res thumbnail is matching with sent thumbnail
          // if (res.data.body.thumbNailUrl !== thumbnail){           
          //     const base64Data = thumbnail.replace(
          //       "data:image/png;base64,",
          //       ""
          //     );
          //     const dataToPostThumbnail = {
          //       blogID: res.data.body.blogID,
          //       fileType: ".png",
          //       fileID: res.data.body.blogID,
          //       flag: "BLOG",
          //       fileWithBase64: base64Data,
          //     };
          //     PostAPI(saveThumbnailAPI, dataToPostThumbnail)
          //       .then((res) => {
          //         if (res.data.statusCode === 200) {
          //           handleCloseModal();
          //           alert("Blog Updated Successfully!");
          //         } else {
          //           alert("error");
          //           handleCloseModal();
          //         }
          //       })
          //       .catch((e) => {
          //         console.log("error ", e);
          //         alert("error");
          //       });
          //   } else {
          //     handleCloseModal();
          //   }
          }      
      })
      .catch((e) => {
        console.log("error ", e);
        alert("error");
      });
  };

  useEffect(() => {
    parseContent(content); // Parse content on initial render
  }, []); // Empty dependency array means it runs once on mount

  return (
    <div>
      <div className="rich-text-editor-container">
        <div className="rich-text-editor-wrapper">
          <h1>Editor</h1>
          <ReactQuill
            theme="snow"
            value={content}
            onChange={handleContentChange}
            modules={modules}
            formats={formats}
          />
        </div>
      </div>
      <div className="submit-button-sticky">
        <button
          type="button"
          className="btn btn-success btn-sm btn-block my-2"
          onClick={handleShowModal}
        >
          Update
        </button>
      </div>
      <ConfirmModal
      blogImage={blogImage}
        blogData={blogData}
        selectedCategoryBlog={selectedCategoryBlog}
        setSelectedCategoryBlog={setSelectedCategoryBlog}
        title="Update"
        handleSubmit={handleSubmit}
        show={showModal}
        handleClose={handleCloseModal}
      />
    </div>
  );
};

export default RichTextEditor;
