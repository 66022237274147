import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import PostAPI from "../APICalls/PostAPI";
import GetAPI from "../APICalls/GetAPI";

const AdminModal = ({ handleVerified }) => {
  const [name, setName] = useState("");
  const [mobileNumber, setMobileNumber] = useState(null);
  const [showMobileError, setShowMobileError] = useState(false);
  const [showNameError, setShowNameError] = useState(false);
  const [autoFocusMobile, setAutoFocusMobile] = useState(false);
  const [responseOtp, setResponseOtp] = useState("");
  const [enteredOtp, setEnteredOtp] = useState("");
  const [otpError, setOtpError] = useState(false);
  const [otpSent, setOtpSent] = useState(false);

  useEffect(() => {
    const getAdminDetails = localStorage.getItem("AdminDetails");
    if (getAdminDetails) {
      handleVerified(false);
    }
  }, []);

  let fetchOtp, userAPI;
  let environment = process.env.REACT_APP_NODE_ENV;
  if (environment === "PRODUCTION") {
    fetchOtp = process.env.REACT_APP_PROD_FETCHOTP;
    userAPI = process.env.REACT_APP_PROD_USERAPI;
  } else {
    fetchOtp = process.env.REACT_APP_DEV_FETCHOTP;
    userAPI = process.env.REACT_APP_DEV_USERAPI;
  }

  const handleSendOTP = () => {
    if (name === "") {
      setShowNameError(true);
      return;
    }

    if (mobileNumber == null || mobileNumber.length < 10) {
      setShowMobileError(true);
      return;
    }

    const dataToPost = JSON.stringify({
      userMobile: "+91".concat(mobileNumber.toString()),
      otpType: "SMS",
    });

    PostAPI(fetchOtp, dataToPost)
      .then((res) => {
        setResponseOtp(res.data.body);
        setTimeout(() => {
          setOtpSent(false);
        }, 5000);
        setOtpSent(true);
      })
      .catch((e) => {
        setOtpError(true);
      });
  };

  const handleVerify = () => {
    if (environment === "PRODUCTION") {
      if (parseInt(enteredOtp) === parseInt(responseOtp)) {
        callUserExistCheck(mobileNumber);
      }
    } else {
      if (parseInt(enteredOtp) === 1234) {
        callUserExistCheck(mobileNumber);
      }
    }
  };

  // function to check user exists on successfull otp verification
  const callUserExistCheck = (userMobile) => {
    const completeUserURL = userAPI + "?userMobile=" + "91".concat(userMobile);
    GetAPI(completeUserURL)
      .then((res) => {
        let responseData = res.data;
        let responseBody = responseData.body;

        if (responseData.statusCode === 200 && Object.keys(responseBody).length > 0) {
          if (responseBody.isAdmin !== undefined && responseBody.isAdmin.toUpperCase() === "TRUE") {
            handleVerified(false);
            localStorage.setItem("AdminDetails", JSON.stringify(responseBody));
          } else {
            alert("You are unauthorized to access Admin Page");
            window.location.reload();
          }
        } else {
          registerAsAdmin();
        }
      })
      .catch((e) => {
        console.error(e);
        alert("Unable to get user details for wallet recharge");
      });
  };

  const registerAsAdmin = () => {
    const dataToPost = {
      userMobile: "+91".concat(mobileNumber),
      userName: name,
      appVersion: 106,
      userType: "Chef",
    };

    PostAPI(userAPI, dataToPost)
      .then((res) => {
        let responseData = res.data;
        if (responseData.statusCode === 200 || responseData.statusCode === 201) {
          handleVerified(true);
        } else {
          handleVerified(true);
        }
      })
      .catch((e) => {
        handleVerified(true);
      });
  };

  const handleEnter = () => {
    setAutoFocusMobile(true);
  };

  return (
    <div className="card container my-5">
      <div className="card-body">
        <h3>Login</h3>
        <div className="input-group input-group-sm mb-3">
          <div className="input-group-prepend">
            <span className="input-group-text" id="inputGroup-sizing-sm">
              Name
            </span>
          </div>
          <input
            type="text"
            value={name}
            autoFocus
            onChange={(e) => {
              setShowNameError(false);
              setName(e.target.value);
            }}
            className="form-control"
            aria-label="Small"
            aria-describedby="inputGroup-sizing-sm"
            onKeyDown={handleEnter}
          />
        </div>
        {showNameError && (
          <div className="alert alert-danger" role="alert">
            Enter your Name
          </div>
        )}
        <div className="input-group input-group-sm mb-3">
          <div className="input-group-prepend">
            <span className="input-group-text" id="inputGroup-sizing-sm">
              +91
            </span>
          </div>
          <input
            type="text"
            pattern="[0-9]*"
            maxLength={10}
            value={mobileNumber}
            onChange={(e) => {
              setShowMobileError(false);
              setMobileNumber((v) => (e.target.validity.valid ? e.target.value : v));
            }}
            autoFocus={autoFocusMobile}
            className="form-control"
            aria-label="Small"
            aria-describedby="inputGroup-sizing-sm"
          />
        </div>
        <Button variant="primary btn-sm my-2" onClick={handleSendOTP}>
          Send Otp
        </Button>
        {otpSent && (
          <div className="alert alert-success" role="alert">
            OTP sent
          </div>
        )}
        {showMobileError && (
          <div className="alert alert-danger" role="alert">
            Enter a valid mobile number
          </div>
        )}
        <p>Enter OTP to Verify</p>
        <div className="input-group input-group-sm mb-3">
          <input
            type="text"
            pattern="[0-9]*"
            maxLength={4}
            value={enteredOtp}
            onChange={(e) => {
              setOtpError(false);
              setEnteredOtp((v) => (e.target.validity.valid ? e.target.value : v));
            }}
            className="form-control"
            aria-label="Small"
            aria-describedby="inputGroup-sizing-sm"
          />
        </div>
        {otpError && (
          <div className="alert alert-danger" role="alert">
            Failed to send Otp. Please try again!!
          </div>
        )}
        {otpError && (
          <div className="alert alert-danger" role="alert">
            Failed to send Otp. Please try again!!
          </div>
        )}

        <Button variant="primary" onClick={handleVerify}>
          Verify
        </Button>
      </div>
    </div>
  );
};

export default AdminModal;
