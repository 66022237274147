import React from "react";
import { Modal } from "react-bootstrap";
// import "./modal.css";

const ConfirmModal = ({ showModal, handleClose, handleConfirm, totalPrice }) => {
  return (
    <Modal
      className=""
      show={showModal}
      size="lg"
      scrollable={true}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header>
        <Modal.Title>Are you sure you want to confirm this Order?</Modal.Title>
        <p
          className="cross"
          onClick={handleClose}
        >
          {" "}
          &#10060;{" "}
        </p>
      </Modal.Header>
      <Modal.Body>
  <h5>Total Price: {totalPrice}</h5>
  <div className="d-flex justify-content-end mt-3">
    <button onClick={handleConfirm} className="btn btn-primary mx-2">Confirm</button>
    <button onClick={handleClose} className="btn btn-secondary">Cancel</button>
  </div>
</Modal.Body>

    </Modal>
  );
};

export default ConfirmModal;
