import React, { useState, useEffect, memo } from "react";
import "./chefs.css";
import AdminTabs from "../adminTabs";
import AdminModal from "../adminModal";
import ChefComponent from "./ChefComponent";
import { ThreeCircles } from "react-loader-spinner";
import ChefMenuModal from "./ChefMenuModal";
import ConfirmPopupModal from "./ConfirmPopupModal";
import ProfilePopupModal from "./ProfilePopupModal";
import ChefEmiHistoryPopup from "./ChefEmiHistoryPopup";
import ChefPerformancePopup from "./ChefPerformancePopup";
import VerifiedChefComponent from "./VerifiedChefComponent";
import {
  handleVerify,
  handleStatus,
  handleUpdateChef,
  handlePerformance,
  handleEmiStatus,
  handleShowMenu,
  handleUnverify,
  handleChefStatus,
  getChefStatusCounts,
} from "./services";

const Chefs = () => {
  const [notVerified, setNotVerified] = useState(true);
  const [key, setKey] = useState("");
  const [loading, setLoading] = useState(true);
  const [showMenuModal, setShowMenuModal] = useState(false);
  const [selectedChefData, setSelectedChefData] = useState(null);
  const [selectedChefMenu, setSelectedChefMenu] = useState([]);
  const [showVerifyPopup, setShowVerifyPopup] = useState(false);
  const [showUnVerifyPopup, setShowUnVerifyPopup] = useState(false);
  const [showProfilePopup, setShowProfilePopup] = useState(false);
  const [chefsEMIData, setChefsEMIData] = useState(null);
  const [showEmiPopup, setShowEmiPopup] = useState(false);
  const [chefName, setChefName] = useState(null);
  const [chefLevelData, setChefLevelData] = useState([]);
  const [chefPerformance, setChefPerformance] = useState(null);
  const [showPerformance, setShowPerformance] = useState(false);
  const [showActive, setShowActive] = useState(true);
  const [count, setCount] = useState({
    Registered: 0,
    Contacted: 0,
    Training: 0,
    "Menu/Catalog": 0,
    "Audit/Sample": 0,
    Verified: 0,
    Removed: 0,
  });

  const chefTypes = [
    "Registered",
    "Contacted",
    "Training",
    "Menu/Catalog",
    "Audit/Sample",
    "Verified",
    "Removed",
  ];

  const tabData = [
    { id: "Registered", title: "Registered", count: count.Registered },
    { id: "Contacted", title: "Contacted", count: count.Contacted },
    { id: "Training", title: "Training", count: count.Training },
    { id: "Menu/Catalog", title: "Menu/Catalog", count: count["Menu/Catalog"] },
    { id: "Audit/Sample", title: "Audit/Sample", count: count["Audit/Sample"] },
    { id: "Verified", title: "Verified", count: count.Verified },
    { id: "Removed", title: "Removed", count: count.Removed },
  ];

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(150);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentChefs = chefLevelData.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  useEffect(() => {
    const getActiveTab = JSON.parse(localStorage.getItem("activeTab"));
    if (getActiveTab) {
      setKey(getActiveTab);
    }
  }, []);

  useEffect(() => {
    if (key !== "") {
      setLoading(true);
      localStorage.setItem("activeTab", JSON.stringify(key));
      handleStatus(key, setChefLevelData, setLoading);
    }
  }, [key]);

  useEffect(() => {
    getChefStatusCounts(setCount, setLoading);
  }, []);

  const handleVerified = (value) => {
    setNotVerified(value);
  };

  const RenderTabs = memo(({ id, title, count }) => {
    return (
      <li className="nav-item">
        <a
          className={key === id ? classNameActive : classNameInactive}
          id={`${id}-tab`}
          data-toggle="tab"
          href={`#${id}`}
          role="tab"
          onClick={() => {
            setChefLevelData([]);
            setKey(id);
          }}
          aria-controls={id}
          aria-selected="true"
        >
          {title} {count}
        </a>
      </li>
    );
  });

  const RenderComponent = memo(({ id, children }) => {
    return id === key ? (
      <div
        className={
          key === id ? classNameActiveComponent : classNameInactiveComponent
        }
        id={id}
        role="tabpanel"
        aria-labelledby={`${id}-tab`}
      >
        {children}
      </div>
    ) : null;
  });

  useEffect(() => {
    if (selectedChefMenu !== null && selectedChefMenu.length > 0) {
      setShowMenuModal(true);
    } else {
      setShowMenuModal(false);
    }
  }, [selectedChefMenu]);

  const handleCross = () => {
    setSelectedChefData(null);
    setSelectedChefMenu(null);
  };

  const handleVerifyChef = (data) => {
    setShowVerifyPopup(true);
    setSelectedChefData(data);
  };

  const handleUnVerifyChef = (data) => {
    setShowUnVerifyPopup(true);
    setSelectedChefData(data);
  };

  const handleProfileUpdate = (data) => {
    setSelectedChefData(data);
    setShowProfilePopup(true);
  };

  const handleDismiss = () => {
    setShowVerifyPopup(false);
    setShowUnVerifyPopup(false);
    setShowProfilePopup(false);
    setShowEmiPopup(false);
    setChefsEMIData(null);
    setChefName(null);
    setChefPerformance(null);
    setShowPerformance(false);
  };

  useEffect(() => {
    if (chefsEMIData !== null) {
      setShowEmiPopup(true);
    }
  }, [chefsEMIData]);

  useEffect(() => {
    if (chefPerformance !== null) {
      setShowPerformance(true);
    }
  }, [chefPerformance]);

  const handleConfirmPopup = (comments) => {
    handleVerify(selectedChefData, comments, setLoading, setShowVerifyPopup);
  };

  const UpdateChef = (profilePicture) => {
    handleUpdateChef(selectedChefData, profilePicture, setLoading);
  };

  const handleChefPerformance = (data, showActive) => {
    handlePerformance(
      data,
      showActive,
      setChefPerformance,
      setSelectedChefData
    );
  };

  const handleEmi = (data) => {
    handleEmiStatus(data, setChefsEMIData, setChefName, setLoading);
  };

  const ShowMenu = (data) => {
    handleShowMenu(data, setSelectedChefData, setSelectedChefMenu);
  };

  const UnverifyChef = (comments) => {
    handleUnverify(comments, selectedChefData, setLoading);
  };

  const ChefStatus = (status, comments, data) => {
    if(status.toUpperCase() === "REGISTERED") {
      handleChefStatus("", comments, data, setLoading);
    } else {
      handleChefStatus(status, comments, data, setLoading);
    }
   
  };

  let classNameInactive = "nav-link";
  let classNameActive = "nav-link active show";
  let classNameInactiveComponent = "tab-pane fade";
  let classNameActiveComponent = "tab-pane fade show active";

  const totalPages = Math.ceil(chefLevelData.length / itemsPerPage);

  return (
    <div>
      <AdminTabs />
      {notVerified ? (
        <AdminModal handleVerified={handleVerified} />
      ) : (
        <div className="chefs">
          {loading && (
            <div className="spinnerContainer">
              <div className="spinner">
                <ThreeCircles
                  color="blue"
                  height={110}
                  width={110}
                  ariaLabel="three-circles-rotating"
                />
              </div>
            </div>
          )}
          {showMenuModal && selectedChefData !== null && (
            <ChefMenuModal
              showModal={showMenuModal}
              selectedChefData={selectedChefData}
              selectedChefMenu={selectedChefMenu}
              handleClose={handleCross}
            />
          )}
          {showVerifyPopup && (
            <ConfirmPopupModal
              heading="Are you sure you want to verify the chef?"
              show={showVerifyPopup}
              handleConfirmPopup={handleConfirmPopup}
              handleDismiss={handleDismiss}
              buttonText="Verify Chef"
            />
          )}
          {showUnVerifyPopup && (
            <ConfirmPopupModal
              heading="Are you sure you want to unverify the chef?"
              show={showUnVerifyPopup}
              handleConfirmPopup={UnverifyChef}
              handleDismiss={handleDismiss}
              buttonText="Un Verify Chef"
            />
          )}
          {showProfilePopup && (
            <ProfilePopupModal
              data={selectedChefData}
              show={showProfilePopup}
              handleUpdateChef={UpdateChef}
              handleDismiss={handleDismiss}
            />
          )}
          {showEmiPopup && chefsEMIData !== null && (
            <ChefEmiHistoryPopup
              data={chefsEMIData}
              show={showEmiPopup}
              chefName={chefName}
              handleDismiss={handleDismiss}
            />
          )}
          {showPerformance && chefPerformance !== null && (
            <ChefPerformancePopup
              data={chefPerformance}
              show={showPerformance}
              selectedChefData={selectedChefData}
              handleDismiss={handleDismiss}
            />
          )}
          <ul className="nav nav-tabs listgroup" id="myTab" role="tablist">
            {tabData.map((tab) => (
              <RenderTabs
                key={tab.id}
                id={tab.id}
                title={tab.title}
                count={tab.count}
              />
            ))}
          </ul>
          {key !== "Verified" ?
          <nav aria-label="Page navigation example">
            <ul class="pagination">
              <li class="page-item">
                <a
                  class="page-link"
                  href="#"
                  aria-label="Previous"
                  onClick={() =>
                    paginate(currentPage > 1 ? currentPage - 1 : 1)
                  }
                >
                  <span aria-hidden="true">&laquo;</span>
                </a>
              </li>
              {[...Array(totalPages).keys()].map((number) => (
                <li
                  class={`page-item ${
                    number + 1 === currentPage ? "active" : ""
                  }`}
                >
                  <a
                    class="page-link"
                    href="#"
                    onClick={() => paginate(number + 1)}
                  >
                    {" "}
                    {number + 1}
                  </a>
                </li>
              ))}
              <li class="page-item">
                <a
                  class="page-link"
                  href="#"
                  aria-label="Next"
                  onClick={() =>
                    paginate(
                      currentPage < totalPages ? currentPage + 1 : totalPages
                    )
                  }
                >
                  <span aria-hidden="true">&raquo;</span>
                </a>
              </li>
            </ul>
          </nav> : null}

          <div className="tab-content" id="myTabContent">
            {chefTypes.map((type) => (
              <RenderComponent
                key={type}
                id={type}
                children={
                  type === "Verified" ?
                  <VerifiedChefComponent
                    type={type}
                    handleShowMenu={ShowMenu}
                    handleUnVerifyChef={handleUnVerifyChef}
                    handleChefStatus={ChefStatus}
                    handleProfileUpdate={handleProfileUpdate}
                    handleEmiStatus={handleEmi}
                    handlePerformance={handleChefPerformance}
                    chefLevelData={chefLevelData}
                    showActive={showActive}
                    setShowActive={setShowActive}
                  /> 
                  : 
                  <ChefComponent
                  type={type}
                  handleUnVerifyChef={handleUnVerifyChef}
                  handleChefStatus={ChefStatus}
                  handleVerifyChef={handleVerifyChef}
                  chefLevelData={currentChefs}
                />
                }
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};
export default Chefs;
