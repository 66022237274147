import React, { useState } from "react";
import "./ingredients.css";
import AddDish from "./addDish";
import AddIngredient from "./addIngredient";

function Ingredients() {
  const [tabValue, setTabValue] = useState(1);

  return (
    <div className="ingredients">
      <ul className="nav nav-tabs" id="myTab" role="tablist">
        <li className="nav-item">
          <a
            className="nav-link active"
            id="home-tab"
            data-toggle="tab"
            href="#home"
            role="tab"
            onClick={() => {
              setTabValue(1);
            }}
            aria-controls="home"
            aria-selected="true"
          >
            Add Dish
          </a>
        </li>
        <li className="nav-item">
          <a
            className="nav-link"
            id="profile-tab"
            data-toggle="tab"
            href="#profile"
            role="tab"
            onClick={() => {
              setTabValue(2);
            }}
            aria-controls="profile"
            aria-selected="false"
          >
            Add Ingredient
          </a>
        </li>
      </ul>
      <div className="tab-content" id="myTabContent">
        <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
          <AddDish tab={tabValue} />
        </div>
        <div className="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
          <AddIngredient tab={tabValue} />
        </div>
      </div>
    </div>
  );
}
export default Ingredients;
