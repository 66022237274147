import React, { useState, memo } from "react";
import Card from "./Card";
import "./ChefComponent.css";
import moment from "moment";


const VerifiedChefComponent = memo(
    ({
      type,
      handleShowMenu,
      handleUnVerifyChef,
      handleChefStatus,
      handleProfileUpdate,
      handleEmiStatus,
      chefLevelData,
      handlePerformance,
      showActive,
      setShowActive,
    }) => {
      const [activePage, setActivePage] = useState(1);
      const [inactivePage, setInactivePage] = useState(1);
      const itemsPerPage = 100;
  
      const paginate = (page, setActive) => setActive(page);
  
      const activeChefs = chefLevelData.filter((data) => data.isUserAvailable);
      const inactiveChefs = chefLevelData.filter((data) => !data.isUserAvailable);
  
      const paginateActive = (pageNumber) => paginate(pageNumber, setActivePage);
      const paginateInactive = (pageNumber) => paginate(pageNumber, setInactivePage);

      const handleChefNewMenu = (data) => {
        window.open(
          `/newChefMenu?chefID=${data.userID}&kitchenName=${data.userKitchen}&ChefName=${data.userName}&mobileNumber=${data.userMobile}`,
          "_blank"
        );
      };
  
  
      const renderCards = (dataList) => {
        return dataList
        .map((data) => (
          <Card
            key={data.userID}
            data={data}
            type={type}
            handleShowMenu={handleShowMenu}
            handleUnVerifyChef={handleUnVerifyChef}
            handleChefStatus={handleChefStatus}
            handleChefNewMenu={handleChefNewMenu}
            handleProfileUpdate={handleProfileUpdate}
            handleEmiStatus={handleEmiStatus}
            handlePerformance={handlePerformance}
            showActive={showActive}
          />
        ));
      };
  
      return (
        <>
          <div className="btn-group btn-group-sm" role="group" aria-label="Small button group">
            <button
              type="button"
              className={`btn ${showActive ? "btn-primary" : "btn-outline-dark"}`}
              onClick={() => setShowActive(true)}
            >
              Available Chefs - {activeChefs.length}
            </button>
            <button
              type="button"
              className={`btn ${!showActive ? "btn-primary" : "btn-outline-dark"}`}
              onClick={() => setShowActive(false)}
            >
              Unavailable Chefs - {inactiveChefs.length}
            </button>
          </div>
          <nav aria-label="Page navigation example">
            <ul className="pagination">
              {showActive
                ? Array.from({ length: Math.ceil(activeChefs.length / itemsPerPage) }).map((_, index) => (
                    <li key={index} className={`page-item ${activePage === index + 1 ? "active" : ""}`}>
                      <button className="page-link" onClick={() => paginateActive(index + 1)}>
                        {index + 1}
                      </button>
                    </li>
                  ))
                : Array.from({ length: Math.ceil(inactiveChefs.length / itemsPerPage) }).map((_, index) => (
                    <li key={index} className={`page-item ${inactivePage === index + 1 ? "active" : ""}`}>
                      <button className="page-link" onClick={() => paginateInactive(index + 1)}>
                        {index + 1}
                      </button>
                    </li>
                  ))}
            </ul>
          </nav>
          <div className="rowDisplayCards">
            {showActive
              ? renderCards(activeChefs.slice((activePage - 1) * itemsPerPage, activePage * itemsPerPage))
              : renderCards(inactiveChefs.slice((inactivePage - 1) * itemsPerPage, inactivePage * itemsPerPage))}
          </div>
         
        </>
      );
    }
  );
  
  export default VerifiedChefComponent;
  

