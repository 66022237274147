import React, { useContext } from "react";
import { FaCopy } from "react-icons/fa";
import "./TopCard.css";
import ChefDetailsContext from "./ChefDetailsContext";

const TopCard = () => {
  const { chefDetails } = useContext(ChefDetailsContext);
  const currentURL = window.location.href;
  const handleCopyLink = () => {
    const textarea = document.createElement("textarea");
    textarea.value = currentURL;
    document.body.appendChild(textarea);

    textarea.select();
    document.execCommand("copy");

    document.body.removeChild(textarea);

    alert("Link copied to clipboard!");
  };
  return (
    <div className="TopCard">
      <div className="card my-2 shadow TopCard">
        <div className="card-body">
          <div>
            {/* Add Logo */}
            <img src="https://d2nahbmqd5vvug.cloudfront.net/homeal/homealWhiteLogo.png" className="whiteLogoSize" alt="..." />
            {/* Add kitchen Name */}
            <h6>{chefDetails.userKitchen}</h6>
          </div>
          {/* Add Copy Link */}
          <FaCopy size={30} color="#060644" onClick={() => handleCopyLink()} />
        </div>
      </div>
    </div>
  );
};

export default TopCard;
