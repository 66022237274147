import React, { useState } from "react";
import "./wallet.css";
import { ThreeCircles } from "react-loader-spinner";
import AdminTabs from "../adminTabs";
import AdminModal from "../adminModal";
import GetAPI from "../../APICalls/GetAPI";
import PostAPI from "../../APICalls/PostAPI";
import { useEffect } from "react";

const Wallet = () => {
  const [mobile, setMobile] = useState(null);
  const [amount, setAmount] = useState(null);
  const [loading, setLoading] = useState(false);
  const [notVerified, setNotVerified] = useState(true);
  const [userApiResponse, setUserApiResponse] = useState(null);

  const handleVerified = (value) => {
    setNotVerified(value);
  };

  let environment = process.env.REACT_APP_NODE_ENV;
  const handleCreditWallet = () => {
    setLoading(true);

    let userAPI, walletCreditAPI;

    if (environment === "PRODUCTION" || environment === undefined) {
      userAPI = process.env.REACT_APP_PROD_USERAPI;
      walletCreditAPI = process.env.REACT_APP_PROD_BACKEND_SERVICE;
    } else {
      userAPI = process.env.REACT_APP_DEV_USERAPI;
      walletCreditAPI = process.env.REACT_APP_DEV_BACKEND_SERVICE;
    }

    let completeUserURL = userAPI + "?userMobile=91" + mobile;

    GetAPI(completeUserURL)
      .then((res) => {
        setUserApiResponse(res);
        let completeWalletURL =
          walletCreditAPI +
          "/wallet/success/" +
          res.data.body.userID +
          "?userID=" +
          res.data.body.userID +
          "&amount=" +
          amount +
          "&phone=+91" +
          mobile +
          "&email=developer@localzi.com";
        PostAPI(completeWalletURL, null)
          .then((res) => {
            setLoading(false);
            alert("wallet recharged successfully!");
            window.location.reload();
          })
          .catch((e) => {
            console.error(e);
            setLoading(false);
            alert("Unable to recharge wallet outsides. Please try again later");
          });
      })
      .catch((e) => {
        console.error(e);
        alert("Unable to get user details for wallet recharge");
      });
  };

  useEffect(() => {}, [userApiResponse]);

  return (
    <div>
      <AdminTabs />
      {notVerified ? (
        <AdminModal handleVerified={handleVerified} />
      ) : (
        <div className="wallet container">
          {loading && (
            <div className="spinnerContainer">
              <div className="spinner">
                <ThreeCircles color="blue" height={110} width={110} ariaLabel="three-circles-rotating" />
              </div>
            </div>
          )}
          <h1>Wallet Credit Section</h1>
          <h5>Enter Mobile Number</h5>
          <div className="input-group">
            <div className="input-group-prepend">
              <span className="input-group-text" id="basic-addon1">
                +91
              </span>
            </div>
            <input
              type="text"
              className="form-control"
              maxLength={10}
              placeholder="Enter Mobile Number"
              onChange={(e) => setMobile(e.target.value)}
            />
          </div>
          <h5>Enter Amount in Wallet to be Credited</h5>
          <input type="text" className="form-control" placeholder="Enter Amount to be credited" onChange={(e) => setAmount(e.target.value)} />
          <button type="button" className="btn btn-primary" onClick={() => handleCreditWallet()}>
            Credit
          </button>
        </div>
      )}
    </div>
  );
};

export default Wallet;
