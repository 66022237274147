import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import GetAPI from "../APICalls/GetAPI";
import { Helmet } from 'react-helmet-async';

const BlogPost = () => {
  const [data, setData] = useState(null);
  const [dynamicDescription, setDynamicDescription] = useState('');
  const [dynamicImageUrl, setDynamicImageUrl] = useState('');
  const { blogID } = useParams();

  const getBlog = async () => {
    try {
      const environment = process.env.REACT_APP_NODE_ENV;
      const getSingleBlogApi = (environment === "PRODUCTION" || environment === undefined)
        ? process.env.REACT_APP_PROD_BLOGAPI
        : process.env.REACT_APP_DEV_BLOGAPI;

      const res = await GetAPI(`${getSingleBlogApi}?type=blogID&blogID=${blogID}`);
      if (res.data.statusCode === 200) {
        setData(res.data.body);
      } else {
        console.error("Error fetching blog data:", res.data.message);
      }
    } catch (err) {
      console.error("Error fetching blog data:", err);
    }
  };

  useEffect(() => {
    getBlog();
  }, [blogID]);

  useEffect(() => {
    if (data) {
      document.title = data.title;
      setDynamicDescription(data.description); // Ensure 'description' exists in your data
      setDynamicImageUrl(data.thumbNailUrl);
    }
  }, [data]);

  const parseContent = (content) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(content, "text/html");
    const images = doc.querySelectorAll("img");
    images.forEach((image) => {
      image.style.height = "360px";
      image.style.width = "760px";
      image.style.padding = "10px";
    });
    return doc.body.innerHTML;
  };

  const parsedContent = parseContent(data?.data || "");

  if (!data) {
    return <div>Loading...</div>;
  }

  return (
    <div className="container-fluid">
      <Helmet>
        <title>{data.title}</title>
        <meta property="og:description" content={dynamicDescription} />
        <meta property="og:image" content={dynamicImageUrl} />
        <meta property="og:url" content={`https://homeal.app/blog/${blogID}`} />
        <meta property="og:type" content="article" />
      </Helmet>
      <div className="blogpage-container">
        <div className="blogpage-wrapper">
          <div
            className="rich-text-blog"
            dangerouslySetInnerHTML={{ __html: parsedContent }}
          />
        </div>
      </div>
    </div>
  );
};

export default BlogPost;
